import React from 'react';

import whiteLogo from './../../assets/white-logo.png';
import darkLogo from './../../assets/dark-logo.png';

import { ThemeContext } from '../../ThemeContext';

const Logo = ({ className = '', style = {} }) => {
  const { theme } = React.useContext(ThemeContext);

  return (
    <img
      src={theme !== 'light' ? whiteLogo : darkLogo}
      style={style}
      alt="Logo"
      className={className}
    />
  );
};

export default Logo;
