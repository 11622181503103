import React, { useCallback } from 'react';
import { Vector3 } from 'three';

const ControlButton = ({ children, onClick, className }) => {
  return (
    <button
      className={`btn btn-outline-secondary px-1 py-0 ${className}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

const Controls = ({ cameraControlRef, onReset }) => {
  const animateToPosition = useCallback(
    (x, y, z) => {
      if (cameraControlRef.current) {
        const targetPosition = new Vector3(0, 0, 0);
        cameraControlRef.current.setLookAt(
          x,
          y,
          z,
          targetPosition.x,
          targetPosition.y,
          targetPosition.z,
          true
        );
      }
    },
    [cameraControlRef]
  );

  const viewPositions = {
    y: () => animateToPosition(0, 12, 0),
    z: () => animateToPosition(12, 0, 0),
    x: () => animateToPosition(0, 5, 12),
  };

  const handleReset = useCallback(() => {
    animateToPosition(0, 5, 12);
    onReset();
  }, [animateToPosition, onReset]);

  return (
    <div
      className="btn-group"
      style={{ position: 'absolute', top: 10, right: 10 }}
    >
      <ControlButton
        size="micro"
        onClick={viewPositions.x}
        className="rounded-r-none"
      >
        <i className="bi bi-arrow-down-left"></i>
      </ControlButton>
      <ControlButton
        size="micro"
        onClick={viewPositions.y}
        className="rounded-l-none"
      >
        <i className="bi bi-arrow-up"></i>
      </ControlButton>
      <ControlButton
        size="micro"
        onClick={viewPositions.z}
        className="rounded-r-none rounded-l-none"
      >
        <i className="bi bi-arrow-down-right"></i>
      </ControlButton>
      <ControlButton
        size="micro"
        onClick={handleReset}
        className="rounded-l-none mb-auto"
        title="Reset Animation"
      >
        <i className="bi bi-arrow-repeat"></i>
      </ControlButton>
    </div>
  );
};

export default Controls;
