import React, { useContext, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { ThemeContext } from '../../ThemeContext';
import './proba.scss';
import slika from './../../assets/sorex.png';
import companyLogo from './../../assets/footer logo.png';
import Devider from './../Divider';

import {
  FaXTwitter,
  FaDiscord,
  FaInstagram,
  FaLinkedin,
} from 'react-icons/fa6';

const Proba = () => {
  const { theme } = useContext(ThemeContext);
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Add your subscription logic here
      setStatus('success');
      setMessage('Thank you for subscribing!');
      setEmail('');
      setTimeout(() => {
        setStatus('');
        setMessage('');
      }, 3000);
    } catch (error) {
      setStatus('error');
      setMessage('Something went wrong. Please try again.');
    }
  };

  return (
    <div className="snap-section">
      <footer
        className={`footer ${
          theme === 'dark' ? 'bg-dark text-white' : 'bg-light text-dark'
        }`}
      >
        <div className="footer-content">
          {/* Sponsors Section */}
          <Container className="sponsors-section">
            <Row className="text-center mb-4 lower-heading">
              <Col>
                <h3 className="fw-bold">Trusted By</h3>
              </Col>
            </Row>
            <Row className="justify-content-center align-items-center">
              <Col xs={6} md={3} className="text-center">
                <img
                  src={slika}
                  alt="Sponsor 1"
                  className="sponsor-logo img-fluid"
                  style={{ maxHeight: '50px' }}
                />
              </Col>
            </Row>
          </Container>
          <div>
            <Devider width="50%" />
          </div>
          {/* <hr className="my-5 opacity-25" /> */}

          {/* Main Footer Content */}
          <Container className="main-footer">
            <Row className="justify-content-between main-footer-row">
              {/* Company Info */}
              <Col lg={6} md={6} className="company-info-col">
                <div className="footer-logo-section">
                  <img
                    src={companyLogo}
                    alt="Quarter Master Logo"
                    className="footer-logo mb-4"
                    style={{ maxHeight: '50px' }}
                  />
                  <p className="company-description ">
                    Quarter Master by Mind Made is revolutionizing project
                    management through intuitive design and smart automation. We
                    transform complex projects into manageable quarters,
                    enabling teams to achieve their goals with precision while
                    streamlining workflows for exceptional results.
                  </p>
                </div>
              </Col>

              {/* Quick Links */}
              <Col lg={4} md={4} className="quick-links-col">
                <div className="quick-links-wrapper">
                  <h5 className="fw-bold mb-4">Quick Links</h5>
                  <ul className="list-unstyled footer-links">
                    <li className="mb-3">
                      <a href="/" className="text-decoration-none fw-medium">
                        Home
                      </a>
                    </li>
                    <li className="mb-3">
                      <a
                        href="/pricing"
                        className="text-decoration-none fw-medium"
                      >
                        Pricing
                      </a>
                    </li>
                    <li className="mb-3">
                      <a
                        href="/download"
                        className="text-decoration-none fw-medium"
                      >
                        Download
                      </a>
                    </li>
                    <li className="mb-3">
                      <a href="/faq" className="text-decoration-none fw-medium">
                        FAQ
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>

            {/* Subscribe Section */}
            {/* <Row className="justify-content-center mt-5">
              <Col md={8} lg={6} className="text-center">
                <div className="subscribe-section">
                  <h5 className="subscribe-title mb-3">
                    Subscribe to Our Newsletter
                  </h5>
                  <p className="subscribe-description mb-4">
                    Stay updated with our latest news and updates
                  </p>
                  <form onSubmit={handleSubmit} className="subscribe-form">
                    <div className="input-group">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                      <button
                        type="submit"
                        className={`btn ${
                          theme === 'dark' ? 'btn-light' : 'btn-dark'
                        }`}
                      >
                        Subscribe
                      </button>
                    </div>
                    {message && (
                      <div className={`message ${status}`}>{message}</div>
                    )}
                  </form>
                </div>
              </Col>
            </Row> */}

            {/* Social Links */}
            <Row className="justify-content-center">
              <Col className="text-center">
                <div className="social-links">
                  <a
                    href="#"
                    className="mx-3 social-icon"
                    style={{ color: 'var(--icon-color)' }}
                  >
                    <FaXTwitter size={24} />
                  </a>
                  <a
                    href="#"
                    className="mx-3 social-icon"
                    style={{ color: '#5865F2' }}
                  >
                    <FaDiscord size={24} />
                  </a>
                  <a
                    href="#"
                    className="mx-3 social-icon"
                    style={{ color: '#E4405F' }}
                  >
                    <FaInstagram size={24} />
                  </a>
                  <a
                    href="#"
                    className="mx-3 social-icon"
                    style={{ color: '#0A66C2' }}
                  >
                    <FaLinkedin size={24} />
                  </a>
                </div>
                <div className="email-section mt-4">
                  <a href="info@mindmade.ai" className="email-link">
                    info@mindmade.ai
                  </a>
                </div>
              </Col>
            </Row>

            {/* Copyright */}
            <Row className="mt-4">
              <Col className="text-center">
                <div>
                  <Devider width="25%" />
                </div>
                <p className="copyright fw-medium">
                  © {new Date().getFullYear()} MindMade. All rights reserved.
                </p>
              </Col>
            </Row>
          </Container>
        </div>
      </footer>
    </div>
  );
};

export default Proba;
