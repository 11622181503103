// src/components/Divider/index.jsx
import React from 'react';
import './divider.scss';

const Divider = ({ width = '60px', color = '#28a745', className = '' }) => {
  return (
    <div
      className={`custom-divider ${className}`}
      style={{
        '--divider-width': width,
        '--divider-color': color,
      }}
    />
  );
};

export default Divider;
