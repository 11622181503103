import React from 'react';
import { ThemeProvider } from './ThemeContext';
import './style/theme.scss';
import './style/variables.scss';

import './style/App.scss';
import AppRoutes from './AppRoutes';

function App() {
  return (
    <ThemeProvider>
      <AppRoutes />
    </ThemeProvider>
  );
}

export default App;
