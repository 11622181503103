import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../../style/Features.scss';

import logo from '../../assets/sorex.png';
import { DOWNLOAD_URL } from '../../utils/urls';
import drevo from '../../assets/drevo.png';
import code from '../../assets/homepage.png';
import ImageViewer from '../../components/ImageViewer';
import AnimatedPointCloudVisualization from '../../components/PointCloud/AnimatedPointCloud';
import Logo from '../../components/Logo';
import Proba from '../../components/Proba'; // Import Proba instead of Footer
import CustomCard from '../../components/CustomCard';
import shield from '../../assets/Shield.png';
import Secur from '../../components/Logosecur';
const Home = () => {
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;

      const sections = document.querySelectorAll('.feature-container');

      sections.forEach((section, index) => {
        const sectionTop = index * windowHeight;
        const sectionBottom = (index + 1) * windowHeight;

        if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
          section.classList.add('active');
        } else {
          section.classList.remove('active');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Initial check

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="home-page features-container bg-main text snap-page">
      {/* Hero Section */}
      <section className="hero-section snap-section d-flex">
        <div className="container">
          <div className="row align-items-center w-100 h-100">
            <div className="col-md-6 my-auto">
              <div className="hero-content mx-3 ">
                <h1>
                  Meet{' '}
                  <Logo
                    className="my-auto ms-2"
                    style={{
                      maxHeight: '55px',
                      verticalAlign: 'middle',
                      display: 'inline-block',
                      marginRight: '-5px',
                      position: 'relative',
                      top: '-5px', // Adjust this value to move the logo up or down
                      left: '-5px', // Adjust this value to move the logo left or right
                    }}
                  />
                  <span
                    style={{
                      position: 'relative',
                      left: '-9px',
                    }}
                  >
                    !
                  </span>
                </h1>
                <h2
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'nowrap',
                  }}
                >
                  Digital
                  <Logo
                    className="mx-2"
                    style={{
                      maxHeight: '38px',
                      display: 'inline-block',
                      verticalAlign: 'middle',
                    }}
                  />
                  <span
                    style={{
                      display: 'inline-block',
                      verticalAlign: 'middle',
                      marginLeft: '-11px', // Adjust this value as needed
                    }}
                  >
                    uartermaster
                  </span>
                </h2>
                <p>
                  Quartermaster AI combines advanced artificial intelligence
                  with strict safety protocols to revolutionize resource
                  management, ensuring responsible AI that works for humanity.
                </p>
                <div className="hero-buttons">
                  <Link to={DOWNLOAD_URL}>
                    <button className="btn btn-success free-test-button">
                      Try for Free
                    </button>
                  </Link>
                  <Link to="/pricing">
                    <button className="btn btn-secondary view-pricing-button">
                      View Pricing
                    </button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <ImageViewer />
            </div>
          </div>
        </div>
      </section>
      {/* Feature Sections */}
      <section className="detailed-features">
        {/* Controlled AGI Section */}
        <div className="feature-container bg-main snap-section ">
          <div className="container">
            <div className="feature-content py-5">
              <div className="feature-text">
                <h2>Taking a Leap Towards Controlled & Safe AGI</h2>
                <p>
                  Introducing a system for building controlled AI execution
                  graphs, enabling precise monitoring and management of
                  artificial intelligence workflows while maintaining optimal
                  performance.
                </p>
              </div>
              <div className="feature-visual">
                <img src={drevo} alt="DREVO" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>

        {/* User Brain Section */}
        <div className="feature-container bg-main snap-section">
          <div className="container">
            <div className="feature-content py-5">
              <div className="feature-text">
                <h2>Worlds First Virtual User Brain</h2>
                <p>
                  Every user builds their personalized AI neural network,
                  creating a unique artificial brain that learns and adapts to
                  individual needs and preferences. This personal AI evolves
                  alongside the user, becoming an increasingly powerful tool for
                  customized assistance.
                </p>
              </div>
              <div className="feature-visual">
                <div className="animated-cloud-container">
                  <AnimatedPointCloudVisualization />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Privacy Section */}
        <div className="feature-container bg-main snap-section data-protection-section">
          <div className="container">
            <div className="feature-content py-5">
              <div className="feature-text">
                <h2>Protecting Your Data</h2>
                <p>
                  Your security is our priority. All user data, including images
                  and personal information, is protected through advanced
                  encryption protocols, ensuring complete privacy and
                  confidentiality of your digital assets.
                </p>
              </div>
              <div className="feature-visual">
                {/* <img src={shield} alt="Code" className="img-fluid" /> */}
                <Secur className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="snap-section d-flex flex-column">
        <Proba />
      </div>
    </div>
  );
};

export default Home;
