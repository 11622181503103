import React, { useContext } from 'react';
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from 'react-router-dom';
import { ThemeContext } from './ThemeContext';
import {
  DOWNLOAD_URL,
  EXAMPLES_URL,
  HOME_URL,
  PRICING_URL,
  FAQ_URL,
} from './utils/urls';

import Navbar from './jsxfolder/navbar';
import Home from './pages/Home';
import Examples from './pages/Examples';
import Pricing from './pages/Pricing';
import Proba from './components/Proba';
import Faq from './pages/FAQs';
import DownloadPage from './pages/DownloadPage';

const AppContent = () => {
  const { theme } = useContext(ThemeContext);
  const location = useLocation();
  const isHome = location.pathname === HOME_URL;

  return (
    <div className="app-container" data-bs-theme={theme}>
      <Navbar />
      <div className="content-wrapper">
        <main className="main-content">
          <Routes>
            <Route exact path={HOME_URL} element={<Home />} />
            <Route exact path={EXAMPLES_URL} element={<Examples />} />
            <Route exact path={PRICING_URL} element={<Pricing />} />
            <Route exact path={DOWNLOAD_URL} element={<DownloadPage />} />
            <Route exact path={FAQ_URL} element={<Faq />} />
          </Routes>
        </main>
        {!isHome && <Proba />} {/* Only show footer if NOT on home page */}
      </div>
    </div>
  );
};

const AppRoutes = () => {
  return (
    <Router>
      <AppContent />
    </Router>
  );
};

export default AppRoutes;
