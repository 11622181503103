import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './customcard.scss';

const Card = ({
  children,
  className,
  width,
  as: Component = 'div',
  index,
  ...props
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    const timer = setTimeout(() => setAnimate(true), 100 * (index || 0));

    return () => {
      window.removeEventListener('resize', handleResize);
      clearTimeout(timer);
    };
  }, [index]);

  const hoverStyle =
    !isMobile && isHovered
      ? {
          transform: 'translateY(-10px)',
          boxShadow: '0 0 30px rgba(0, 255, 0, 0.3)',
        }
      : {};

  return (
    <Component
      className={`card ${className || ''} rounded-4 ${animate ? 'animate' : ''}`}
      style={{
        width: width || '100%',
        transition: 'all 0.3s cubic-bezier(0.34, 1.56, 0.64, 1)',
        ...hoverStyle,
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      {...props}
    >
      {children}
    </Component>
  );
};

Card.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  width: PropTypes.string,
  as: PropTypes.elementType,
  index: PropTypes.number,
};

export default Card;
