import React, { useEffect, useState } from 'react';
import Button from '../../components/Buttons';

import { Copy } from 'react-feather';
import DownloadCard from './DownloadCard';
import Card from '../../components/CustomCard';
import Logo from '../../components/Logo';

import './style.scss';
import { getLastVersion } from '../../api';

const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text).then(
    () => {
      // Optionally, you can show a tooltip or notification that the text was copied
      alert('Copied to clipboard!');
    },
    (err) => {
      console.error('Could not copy text: ', err);
    }
  );
};

const downloadOptions = [
  {
    os: 'windows',
    url: 'https://distribution.quartermaster.io/files/Quartermaster/556bbacb9088a2226783e9a22bc8a1d5/latest/win32/x64/Quartermaster.exe',
    architecture: 'x64',
  },
  {
    os: 'macos',
    url: 'https://distribution.quartermaster.io/files/Quartermaster/556bbacb9088a2226783e9a22bc8a1d5/latest/darwin/x64/Quartermaster.dmg',
    architecture: 'x64',
  },
  {
    os: 'macos',
    url: 'https://distribution.quartermaster.io/files/Quartermaster/556bbacb9088a2226783e9a22bc8a1d5/latest/darwin/arm64/Quartermaster.dmg',
    architecture: 'arm64',
  },
  {
    os: 'linux',
    url: 'https://distribution.quartermaster.io/files/Quartermaster/556bbacb9088a2226783e9a22bc8a1d5/latest/linux/x64/Quartermaster.deb',
    architecture: 'x64',
  },
];

const DownloadPage = () => {
  const [version, setVersion] = useState('0.0.0');

  useEffect(() => {
    // Read json file from `https://distribution.quartermaster.io/files/Quartermaster/556bbacb9088a2226783e9a22bc8a1d5/latest/versions.json`
    // Link returns a json array of objects where the last object is the latest version. Version is stored in the `name` key.
    // Include CORS headers in the response to allow the fetch request to work.
    Promise.resolve()
      .then(() => getLastVersion())
      .then(setVersion)
      .catch((err) => {
        console.log('Failed to fetch version data: ', err);
        console.error(err);
      });
  }, []);

  return (
    <div className="w-100 bg-main text-body">
      <div className="container py-5">
        {/* Header Section */}
        <div className="row justify-content-center">
          <div style={{ textAlign: 'center', paddingTop: '5.5rem' }}>
            <span className="pricing-badge">Version {version}</span>
          </div>
          <div className="col-lg-8 text-center">
            <h1
              className="display-4 mb-4 text-body"
              style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)' }}
            >
              Download{' '}
              <Logo className="my-auto ms-2" style={{ maxHeight: '55px' }} />
              <span
                style={{
                  position: 'relative',
                  left: '-5px',
                }}
              >
                uartermaster
              </span>
            </h1>
            <p className="lead mb-4 text-body">
              <span style={{ fontWeight: 600 }}>
                Choose your platform with Quartermaster's simple download
                options. Every version includes automatic updates, ensuring
                powerful inventory management and seamless feature integration.
              </span>
            </p>
          </div>
        </div>
        {/* Download Options */}
        <div className="row row-cols-1 row-cols-md-3 g-4 pt-7">
          <DownloadCard downloads={downloadOptions} currentVersion="0.8.0" />
        </div>
        <div className="row justify-content-center mb-7 mt-5 pb-5">
          <div className="col-lg-8">
            <div className="warning-message">
              <div className="warning-content mx-3">
                <h4 className="my-2">
                  <span className="warning-icon me-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <path d="M12 2L1 21h22L12 2zm0 3.516L20.297 19H3.703L12 5.516zM11 16h2v2h-2v-2zm0-7h2v5h-2V9z" />
                    </svg>
                  </span>
                  Important Note for macOS Users
                </h4>
                <p>
                  Current applications are not yet code signed. Use the
                  following command to quarantine the application on your macOS:
                </p>
                <div className="code-block d-flex my-2">
                  <code className="my-auto">
                    sudo xattr -r -d com.apple.quarantine Quartermaster.dmg
                  </code>
                  <Button
                    className="btn-sm ms-auto"
                    onClick={() =>
                      copyToClipboard(
                        'sudo xattr -r -d com.apple.quarantine Quartermaster.dmg'
                      )
                    }
                  >
                    <Copy size={16} />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* System Requirements */}
        <div className="row my-5 py-5">
          <div className="col-12">
            <h2 className="h3 mb-4 text-body">System Requirements</h2>

            <div style={{ display: 'flex' }}>
              {' '}
              {/* Center the card */}
              <Card width="750px">
                {' '}
                {/* Using the width prop instead of style */}
                <div className="card-body">
                  <div className="requirements-grid">
                    <div className="requirement-item">
                      <span className="requirement-label">OS:</span>
                      <span className="text-muted">
                        Windows 10/11 (64-bit), macOS 10.15+, or Ubuntu 20.04+
                      </span>
                    </div>
                    <div className="requirement-item">
                      <span className="requirement-label">Memory:</span>
                      <span className="text-muted">4GB RAM minimum</span>
                    </div>
                    <div className="requirement-item">
                      <span className="requirement-label">Storage:</span>
                      <span className="text-muted">300MB free disk space</span>
                    </div>
                    <div className="requirement-item">
                      <span className="requirement-label">Graphics:</span>
                      <span className="text-muted">
                        DirectX 11, Metal, or OpenGL 3.3 compatible GPU
                      </span>
                    </div>
                    <div className="requirement-item">
                      <span className="requirement-label">Internet:</span>
                      <span className="text-muted">
                        Broadband connection for updates and online features
                      </span>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
        {/* Support Section */}
        <div className="row mt-7">
          <div className="col-12 text-center">
            <h2 className="h3 mb-4 text-body">Need Help?</h2>
            <p className="mb-4 text-body">
              If you encounter any issues during installation or need
              assistance, our support team is here to help.
            </p>
            <div className="d-flex justify-content-center">
              <Button
                variant="success"
                className={'btn-lg px-3 py-2'}
                onClick={() =>
                  (window.location.href = 'mailto:info@mindmade.ai')
                }
              >
                Contact Support
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadPage;
