import React from 'react';

const Effects = () => {
  return (
    <>
      <ambientLight intensity={1} />
      <pointLight
        position={[10, 10, 10]}
        intensity={1.5}
        distance={120}
        decay={4}
      />
      <fog attach="fog" args={['#000000', 20, 100]} />
    </>
  );
};

export default Effects;
