export const matrix = [
  [-0.337857,-0.206902,-4.812874],
 [-0.473944,-0.343462,-4.682197],
 [-2.550184,-4.626973,-1.771383],
 [ 5.440011, 0.978543,-0.164569],
 [ 6.768319, 0.246646,-2.485342],
 [-0.295312,-0.276297,-4.790875],
 [ 2.096703, 3.512247,-1.232081],
 [-3.695913,-1.925801, 0.195304],
 [ 2.387447,-0.797222, 1.141343],
 [ 2.919578,-0.205569, 0.229529],
 [ 3.816442,-0.016634,-4.265649],
 [ 1.105296,-0.486092,-5.067354],
 [ 3.816873,-0.13312 ,-4.238499],
 [ 5.678013, 0.35678 ,-0.970484],
 [ 6.843132, 0.208605,-1.911288],
 [ 1.094593, 1.474691,-1.315824],
 [ 4.642663,-1.476079, 0.297958],
 [-2.105709,-2.226235,-0.554701],
 [-0.845448, 1.94551 ,-2.126372],
 [-0.986319, 2.538489,-2.269942],
 [-4.328549, 0.383954, 0.510059],
 [ 1.740896, 0.452087,-2.134644],
 [-0.839933, 2.201757,-2.183142],
 [ 1.434739,-3.751199,-1.919406],
 [-2.242993,-2.05128 ,-1.124777],
 [ 0.247041, 2.149868, 0.363374],
 [ 3.14641 , 0.655273, 1.400117],
 [ 2.914363,-0.698242, 2.162013],
 [ 2.64833 ,-1.392548, 3.144481],
 [ 0.421304, 1.87947 , 0.154682],
 [ 2.637671,-1.406697, 3.147829],
 [-6.322557, 0.010901, 2.099106],
 [ 2.695641,-1.310807, 3.094844],
 [ 3.05695 ,-0.586087, 1.506687],
 [ 2.631586,-1.403337, 3.148656],
 [ 2.031294, 3.519068, 1.196968],
 [ 2.849362, 2.246929, 0.812487],
 [ 2.345111, 0.471723, 3.023951],
 [ 2.636011,-1.396627, 3.145393],
 [ 2.631078,-1.419261, 3.151692],
 [ 2.636023,-1.433117, 3.149574],
 [ 0.617209, 1.890607, 1.929952],
 [ 1.683418, 1.154386, 1.22496 ],
 [ 4.057877, 0.264804,-0.69029 ],
 [ 1.852979,-0.818206, 1.07191 ],
 [ 1.768124,-0.7905  , 1.576357],
 [ 0.358937,-0.342678, 2.4463  ],
 [ 2.056828,-0.993948, 3.810046],
 [ 2.307098,-1.328775, 3.830975],
 [ 2.285185,-1.32103 , 3.839466],
 [-0.589869, 3.208917, 0.944805],
 [ 0.511813, 2.335994, 1.529109],
 [ 0.471988, 2.305345, 1.48225 ],
 [ 2.314118,-1.328552, 3.815936],
 [ 3.649064, 2.307012, 0.986216],
 [ 1.674264,-2.181041,-5.973617],
 [-4.438082,-0.895492, 1.340437],
 [ 6.561895, 0.378629,-3.07672 ],
 [ 0.002827, 2.110538, 1.405516],
 [ 7.803059, 1.452114,-0.185516],
 [ 1.663585,-2.115672,-6.002573],
 [-4.258759, 2.233833,-0.439636],
 [ 1.663915,-2.193297,-5.98157 ],
 [ 0.35814 , 0.435714, 5.895574],
 [-0.04228 , 4.136944,-0.935082],
 [ 2.059861, 1.953422,-0.406652],
 [-0.830735, 3.837485,-0.329686],
 [-0.157376, 2.291844, 5.674047],
 [ 0.331707,-0.053147,-5.12378 ],
 [ 1.146526, 0.667924,-4.69602 ],
 [ 2.142157,-2.810773,-0.035724],
 [ 3.558535, 2.14435 ,-0.527817],
 [ 4.111517, 1.759611,-0.659943],
 [ 1.116191, 4.875849, 2.960265],
 [ 3.389792,-2.83847 , 0.108997],
 [ 3.355063,-2.839776, 0.128878],
 [ 2.617605,-0.819203,-1.031173],
 [ 3.546658, 2.078685,-0.224877],
 [ 3.551395, 2.076607,-0.216371],
 [ 3.990755,-0.802652, 0.603858],
 [ 1.245624, 0.777202,-4.615306],
 [ 0.649417, 0.657088, 4.680537],
 [-6.687677, 0.094644, 1.605035],
 [ 2.396303, 2.212361,-1.238435],
 [-1.288043, 0.937782, 1.487332],
 [-4.362958,-0.746092, 2.815653],
 [ 4.320522,-0.0591  , 3.016647],
 [-4.439683,-0.629112, 2.756805],
 [-2.615697,-0.797057, 3.99318 ],
 [-0.871732, 0.591663,-2.136902],
 [ 3.515794,-2.520054, 0.342132],
 [ 4.532959,-0.488979,-0.262241],
 [ 2.083738,-2.769397, 0.166423],
 [ 2.38699 , 0.648058, 2.045487],
 [ 2.125669,-2.70377 , 0.04611 ],
 [ 2.102075,-2.647668, 0.015529],
 [ 1.531073,-0.080317, 1.648606],
 [ 0.501321, 4.109824, 0.990207],
 [ 2.14132 ,-2.695415, 0.04752 ],
 [ 2.147066,-2.684194, 0.047695],
 [-0.155791, 2.292061, 5.674631],
 [-4.561448,-4.661279,-1.511533],
 [ 0.937713, 1.725699,-0.818612],
 [ 0.966593, 1.692475,-0.858555],
 [ 0.908359, 1.029215,-0.521231],
 [ 3.486626,-2.509347, 0.308297],
 [ 3.427394, 0.853081, 1.278853],
 [-2.274904,-0.057717, 3.144665],
 [-3.352546,-1.574298, 1.007423],
 [-0.81806 , 3.909981,-0.420917],
 [-1.018654, 3.088039,-0.937623],
 [-1.008677, 3.09821 ,-0.964399],
 [ 0.451191,-0.275711, 6.121706],
 [ 5.659203, 4.731888,-0.273993],
 [ 0.537682,-0.365006, 6.091551],
 [ 2.075462, 1.522603,-1.313495],
 [ 1.43419 , 0.959546,-4.524137],
 [ 4.22772 ,-0.758694, 2.521113],
 [ 2.190624, 3.170764, 0.235827],
 [ 5.654442, 4.732816,-0.271841],
 [ 5.008939, 1.154228,-0.401682],
 [ 3.526302,-2.572943, 0.311786],
 [ 0.098052,-0.539397,-2.106152],
 [ 4.245883,-0.733322, 2.489713],
 [ 1.113625,-0.348992, 3.55183 ],
 [ 0.688548, 0.536105, 4.619456],
 [ 0.737746,-0.518166, 3.118133],
 [-0.602778, 3.577922, 0.021876],
 [ 1.85216 , 1.61109 ,-0.225656],
 [ 2.105177, 2.159891,-0.250489],
 [ 1.467206, 0.511821, 1.727347],
 [ 1.984777, 2.020668,-0.078775],
 [ 1.895125, 0.801525, 1.653959],
 [ 1.646327, 1.491232,-0.319288],
 [ 3.61267 ,-0.703238,-0.093048],
 [ 1.862992, 0.856827, 1.664039],
 [ 2.003274, 1.776106,-0.015158],
 [ 1.985718, 1.85303 ,-0.014453],
 [ 1.988714, 1.941965,-0.034209],
 [ 0.987291, 2.895792, 0.554239],
 [ 4.124285, 0.876461,-0.541082],
 [ 4.438679, 0.660439,-0.184541],
 [ 5.445521,-0.030679,-1.072949],
 [ 3.492807, 1.160472,-0.007222],
 [ 6.108157,-0.900016, 0.447034],
 [ 2.1953  , 1.679474,-0.223685],
 [ 2.807026, 1.60371 , 0.949835],
 [ 1.789462, 1.640239,-0.146402],
 [ 2.090377, 1.600934,-0.016464],
 [ 2.290404, 1.175473,-0.176105],
 [ 3.476134,-0.615105,-0.327358],
 [ 2.112116, 0.594894, 1.263335],
 [ 5.520039, 0.559319,-0.242189],
 [ 3.224998,-0.651063, 2.042291],
 [ 2.056299, 1.998202,-0.277496],
 [ 2.109094, 2.113825,-0.281477],
 [ 2.062544, 2.045549,-0.231401],
 [ 2.872445, 0.80635 ,-0.346467],
 [-0.647564, 4.299289,-1.206507],
 [ 1.471236, 3.751877, 0.185704],
 [ 2.737268, 0.670206,-0.532202],
 [ 1.574901, 1.38536 ,-0.276064],
 [ 1.958721, 1.796166, 0.023091],
 [ 1.509317, 0.161591, 1.702798],
 [ 4.097547, 1.571856, 0.211547],
 [ 1.649467, 0.2194  , 0.729047],
 [ 3.125796, 0.12139 ,-0.493044],
 [ 4.743059, 0.328941, 0.036364],
 [ 5.948374, 0.539508,-1.51402 ],
 [ 3.29962 , 1.102196,-0.127384],
 [ 3.385889,-0.156587,-0.30443 ],
 [ 2.158375, 2.191508,-0.088862],
 [ 1.603358, 1.630871,-0.333181],
 [ 1.980115, 1.515424,-0.211524],
 [ 1.767611, 1.673226,-0.45629 ],
 [-0.685839, 4.425458,-1.258129],
 [ 3.0553  , 0.54342 ,-0.380244],
 [ 2.133743, 1.365192,-0.442534],
 [ 1.586925, 2.277258,-0.79416 ],
 [ 2.330384,-1.358957,-1.038956],
 [ 1.64899 , 1.518424,-0.288632],
 [ 2.223102, 1.565237, 0.092992],
 [ 1.888329, 1.081337,-0.203035],
 [ 1.464223, 0.507499, 1.728067],
 [ 0.643655, 0.751232, 4.575116],
 [ 1.840685, 1.489253, 0.18406 ],
 [ 4.391985, 2.756679, 3.014094],
 [ 0.631589, 1.002427, 4.533568],
 [ 0.627094, 0.750952, 4.570923],
 [ 2.834738, 1.490779,-0.245451],
 [ 2.053503, 1.956045,-0.233766],
 [ 2.396068, 1.178353,-0.173016],
 [ 1.111974, 3.195038,-1.443995],
 [ 2.939818, 0.501127,-0.70663 ],
 [-0.618652, 4.385838,-1.276132],
 [ 3.253069, 1.037649,-0.226521],
 [ 4.434846, 2.718608, 2.766594],
 [-0.491956, 4.141217,-1.003817],
 [-0.481251, 4.33617 ,-1.13927 ],
 [ 1.719238, 3.282811, 0.93037 ],
 [ 3.633087, 2.281821, 0.252816],
 [ 3.249662, 2.720757, 0.876821],
 [ 5.654375, 4.730209,-0.271493],
 [ 4.043725, 0.514883, 0.567889],
 [ 1.724098, 2.604493,-1.088687],
 [ 1.809945, 2.551287,-1.138637],
 [ 3.344091, 0.308272, 0.092791],
 [ 1.697849, 2.603717,-1.058473],
 [ 1.114184,-0.321453, 3.393355],
 [ 5.573946, 4.663346,-0.271574],
 [ 1.353351, 0.891822,-4.567803],
 [ 5.619795, 4.735576,-0.258795],
 [ 0.892847, 1.504668, 0.690779],
 [ 2.681088,-2.735167,-1.282013],
 [ 5.748288,-1.276069, 2.104839],
 [ 2.10079 ,-2.763144, 0.101054],
 [ 5.662127, 4.726294,-0.275604],
 [ 0.010977, 0.488351,-4.954875],
 [-0.025994, 0.517812,-4.933595],
 [ 0.049358, 0.431792,-4.965434],
 [ 0.0056  , 0.525613,-4.934002],
 [ 0.007095, 0.482013,-4.945926],
 [-0.022427, 0.598121,-4.879117],
 [-0.003246, 0.560124,-4.921372],
 [-0.005143, 0.591623,-4.903563],
 [ 5.661876, 4.726182,-0.275479],
 [ 2.075329, 1.95189 ,-0.885499],
 [ 1.653799, 1.048183,-2.164861],
 [ 5.461995, 4.770675,-0.191918],
 [ 3.533374,-2.62686 , 0.262461],
 [ 2.415925,-0.409737,-0.878822],
 [ 1.670319,-2.187162,-5.961869],
 [-6.163136,-3.791405, 0.125919],
 [ 0.64842 , 1.524168, 0.876184],
 [-5.671656, 1.389417, 1.620725],
 [-5.860719, 1.029478, 0.61561 ],
 [-5.798578, 1.639021, 1.938385],
 [ 0.658969, 0.523955, 1.306312],
 [-0.627606, 0.92037 ,-3.962904],
 [-6.703691, 1.011254, 1.765088],
 [-5.775798, 1.544217, 2.024124],
 [-5.745321, 1.523744, 1.988972],
 [-5.694514, 1.528695, 1.981375],
 [-5.690669, 1.465111, 1.911738],
 [-4.138001, 2.667192, 0.913688],
 [-3.893508, 2.75406 , 0.79877 ],
 [-5.660598, 1.698932, 1.738527],
 [-5.67843 , 1.563433, 1.909427],
 [-5.683665, 1.560195, 2.002227],
 [-4.13725 , 2.671114, 0.912549],
 [-3.89348 , 2.760465, 0.79889 ],
 [-5.677475, 1.538731, 1.951279],
 [-4.144724, 2.677922, 0.898211],
 [ 5.043082, 4.654474, 1.080878],
 [ 5.900605,-1.555742, 0.104852],
 [ 2.691203, 0.395151, 0.794222],
 [-0.759662, 4.751456, 1.907914],
 [ 1.673653,-2.276634,-5.934245],
 [-0.630126, 0.921905,-3.961993],
 [ 0.247988, 4.259165,-0.896696],
 [ 0.77164 , 4.256182,-0.445914],
 [ 1.088413, 4.060283, 0.156886],
 [ 0.318044, 2.660674, 2.83918 ],
 [ 2.971917, 2.230454, 1.876244],
 [ 1.413955, 1.521462, 3.040879],
 [ 1.165963,-1.684312, 3.875793],
 [ 0.772644,-1.553764, 4.649113],
 [ 1.012832, 1.610131, 5.466457],
 [-6.150503,-3.784489, 0.056413],
 [-1.19161 , 1.561104,-0.968411],
 [ 1.067631, 1.67011 , 5.388373],
 [ 1.032217, 1.624742, 5.437798],
 [-6.769967, 0.310478, 1.724582],
 [-0.207873,-2.681577,-3.19202 ],
 [ 2.233041,-0.93057 ,-3.943202],
 [-1.311812,-1.20059 ,-3.175819],
 [ 0.670387, 3.071082,-0.79758 ],
 [-0.476972,-2.750297,-5.56786 ],
 [-6.803042, 0.718392, 1.656423],
 [-6.164803,-3.778826, 0.106015],
 [-6.094083,-3.852134,-0.198497],
 [-6.094061,-3.822291,-0.156497],
 [-6.073656,-3.796026,-0.19542 ],
 [-5.639503,-1.662472, 0.285646],
 [-2.582807,-4.171405, 0.838593],
 [-5.372958,-2.014733, 0.052231],
 [-2.852528,-1.289447,-0.499541],
 [-2.568618,-0.488716, 0.844362],
 [-5.776938,-0.256329, 0.796428],
 [-6.621974, 1.013939, 1.83864 ],
 [-3.019963,-1.571224,-4.087613],
 [-0.108931,-0.502663, 1.349909],
 [-5.831512,-2.341959, 1.531782],
 [ 6.763308, 0.244394,-2.427993],
 [-6.098024,-3.823145,-0.135039],
 [-2.265758, 1.724558, 2.018343],
 [-5.859736,-2.779366, 0.975764],
 [ 5.347242, 0.277583,-0.116022],
 [-6.131949,-3.839362,-0.038606],
 [-5.867242,-2.73995 , 1.058859],
 [-4.959504, 0.028172, 0.633274],
 [-6.120231,-3.804621,-0.034919],
 [-5.974158, 0.810467, 0.385091],
 [ 5.181146, 2.648672, 2.802155],
 [-3.036679,-1.685855,-4.221889],
 [-4.380283, 0.288248, 1.783442],
 [-4.023016,-1.327243, 1.073032],
 [-4.330966, 0.328455, 1.709249],
 [ 4.308476,-1.138879,-4.125252],
 [-6.151668,-3.827494, 0.056846],
 [-6.127082,-3.838324,-0.058841],
 [ 4.028688, 1.175483, 2.297765],
 [-6.161169,-3.833858, 0.055462],
 [-6.15067 ,-3.829061, 0.033403],
 [-4.351248,-4.920076,-0.596105],
 [ 1.12346 , 4.872988, 2.951586],
 [ 3.518477, 0.564523,-1.525715],
 [-6.113832,-3.804269,-0.047508],
 [-1.034133, 1.59912 ,-1.403207],
 [ 1.772115,-1.346752,-2.802045],
 [ 6.761126, 0.259611,-0.470462],
 [-6.119363,-3.835235,-0.012082],
 [-6.148771,-3.805885, 0.041119],
 [-4.144979,-4.938678,-0.674363],
 [-6.14973 ,-3.82118 , 0.03828 ],
 [-6.114712,-3.808686,-0.041221],
 [-6.097812,-3.866643,-0.208891],
 [-6.156591,-3.783113, 0.097871],
 [-6.098771,-3.787503,-0.060281],
 [-6.117224,-3.80956 ,-0.012595],
 [-5.510041,-0.154022, 0.615561],
 [-6.122447,-3.794177, 0.007009],
 [-2.646368,-3.275418,-3.524493],
 [-3.2489  ,-1.54876 ,-1.891851],
 [-1.361414, 2.777023, 0.208079],
 [ 5.186515,-0.078745,-4.007935],
 [-5.636287, 1.45431 , 1.985606],
 [-6.072559, 0.18155 ,-1.698333],
 [ 0.950923, 1.036178,-1.684343],
 [ 0.730451, 2.505307, 0.628172],
 [ 5.583303, 4.743849,-0.248016],
 [ 1.002514, 1.095847,-1.622784],
 [ 1.631047, 4.61483 , 0.92174 ],
 [ 1.074348, 1.990401, 3.445947],
 [ 0.98281 , 1.111542,-1.618996],
 [-3.781159, 2.672975, 2.054253],
 [ 3.478913,-0.73962 , 0.817098],
 [-5.503926, 0.161511, 3.639852],
 [-5.403888, 0.178864, 2.344711],
 [-5.319013, 0.166288, 3.151613],
 [-5.864743, 1.147262, 4.028213],
 [-5.412383,-0.162891, 0.541417],
 [ 4.75971 , 0.520287,-0.156588],
 [-5.375743,-0.248839, 0.424466],
 [-0.677756,-1.979805,-1.462899],
 [-5.908037, 1.265564, 3.955616],
 [-5.912097, 1.28371 , 3.951527],
 [ 0.514996, 0.290029,-0.443417],
 [-3.157177,-0.910183, 3.070251],
 [-0.517541, 2.354788, 1.727727],
 [ 0.694397, 3.028233, 0.355845],
 [ 0.222117, 1.163397, 3.93257 ],
 [-0.692008, 1.603801, 3.75672 ],
 [ 0.211079,-0.737157,-5.145956],
 [-6.669938, 0.936541, 1.799648],
 [-2.236186,-2.80452 , 2.177527],
 [ 0.225964,-1.782727,-4.526387],
 [-0.212377,-2.149332,-5.527005],
 [-3.211914, 0.333153, 0.925221],
 [ 3.850372,-0.395993, 0.74647 ],
 [ 4.995637, 0.476925,-0.324423],
 [ 0.598031, 2.425016,-1.628695],
 [ 1.164984,-1.415584, 1.208851],
 [-4.719259, 1.43399 , 3.334563],
 [-3.199708, 0.240628,-0.826526],
 [-0.643659, 2.441966, 5.627048],
 [ 0.227138,-2.068254,-4.340432],
 [-0.287257,-2.111432,-3.761961],
 [ 5.110208, 4.642473, 1.202819],
 [-0.233355,-2.143836,-5.515227],
 [-2.976718, 0.052865, 5.27337 ],
 [-4.512399,-0.746085, 1.147605],
 [-0.189135,-2.060971,-5.478459],
 [-7.242188, 0.468075,-0.027391],
 [ 0.125277,-0.678755,-5.169878],
 [ 0.880947,-2.0737  ,-1.724694],
 [-2.131927,-0.746969,-0.473876],
 [-1.709193,-1.675054,-1.155422],
 [ 0.575912,-0.637074,-5.360876],
 [ 0.585846,-0.626507,-5.343904],
 [ 0.233598,-3.776208,-2.605878],
 [-4.388843, 2.250232,-0.057022],
 [-1.443901, 0.956191, 1.320187],
 [-1.087876,-3.123332,-2.850573],
 [-1.196118,-3.039103,-2.813918],
 [-5.987899, 0.179741,-1.551042],
 [-0.04791 , 0.538062,-4.935386],
 [-5.212967,-0.704457, 1.46123 ],
 [-3.571908,-1.553931,-0.292792],
 [-0.692997,-0.561066,-4.597547],
 [ 0.739393,-1.002213,-0.042435],
 [ 3.695271,-2.619337,-3.583017],
 [ 2.051794,-2.637464,-0.048055],
 [ 2.263519,-1.79047 , 0.192429],
 [ 6.834111, 0.208276,-1.907967],
 [-0.754448,-3.0013  ,-3.513119],
 [-0.872609,-3.178393,-3.595548],
 [ 2.987179,-2.821997, 0.694554],
 [ 3.01474 ,-2.883643, 0.615426],
 [ 3.195775, 2.495432, 1.996741],
 [ 2.324817, 3.162683, 1.184741],
 [ 6.164793, 0.231409,-0.827368],
 [-5.620216, 0.895688, 1.90961 ],
 [ 0.798091,-0.966534,-0.043853],
 [ 0.784028,-1.005348, 0.013902],
 [ 7.043068, 2.39646 , 0.032556],
 [ 1.968108,-2.496565,-0.211756],
 [ 2.277301,-0.9227  , 1.272702],
 [ 2.330353,-1.545939,-1.033214],
 [ 2.018959,-2.426173,-0.228957],
 [ 6.910166, 0.196444,-1.907013],
 [ 2.080335, 0.97939 ,-2.878608],
 [ 1.897431, 1.104521,-3.032755],
 [ 3.245096,-0.6276  , 2.067053],
 [ 3.02773 , 0.390846,-1.826657],
 [ 3.732547, 0.230878,-2.286087],
 [ 0.320344, 0.434931, 6.148328],
 [-6.588318, 0.373793, 1.75    ],
 [ 0.332556, 0.479842, 6.119589],
 [ 0.300338, 0.413364, 6.151101],
 [ 0.347514, 0.460125, 6.128631],
 [ 0.34886 , 0.469036, 6.133523],
 [ 0.322101, 0.481281, 6.167831],
 [-3.334405, 3.082489, 2.684382],
 [ 1.482307, 1.065884, 1.076138],
 [ 0.332716, 0.454975, 6.131335],
 [ 0.359773, 0.494431, 6.079178],
 [ 6.903814, 2.527907, 0.009998],
 [-2.74175 , 0.110226, 0.486062],
 [ 5.09377 , 4.653922, 1.18196 ],
 [-5.736814, 1.747056, 2.063494],
 [ 0.351965, 0.4805  , 6.117065],
 [ 1.050908, 4.846473, 3.059724],
 [ 0.177101, 0.41638 , 6.052553],
 [-0.072656,-2.021402,-2.147471],
 [-5.661336, 0.114323, 1.904746],
 [-3.476843, 0.156564, 2.54906 ],
 [-5.021672,-0.126299, 1.439175],
 [ 2.426453, 2.065762, 0.266665],
 [ 2.005218,-3.317328,-1.574038],
 [-5.631563, 0.781352, 1.958522],
 [-5.810351, 1.011438, 0.890692],
 [-5.256441,-0.000254, 1.668752],
 [-3.448715, 0.183699, 2.546163],
 [-5.043739, 2.139959, 1.613459],
 [-3.448016, 0.177564, 2.555882],
 [ 6.603322,-0.446279,-0.805224],
 [ 2.350688,-1.084718,-3.694438],
 [-1.899221, 0.144476, 0.86108 ],
 [-1.88019 , 0.22296 , 0.941419],
 [-3.228553,-1.974723,-0.967912],
 [-6.701525, 0.337774, 1.91865 ],
 [-1.564799, 1.095595, 5.562142],
 [-2.764295, 0.248717, 5.859123],
 [ 6.31499 , 0.222254,-1.066241],
 [-2.839533, 0.178059, 5.821517],
 [-1.574857, 3.54012 , 1.537728],
 [-6.693566, 1.049621, 1.842081],
 [-3.720218, 0.101449, 2.382349],
 [-2.583574,-0.811788, 6.124928],
 [ 6.695579, 1.057559,-0.122005],
 [ 2.553491, 3.533864, 1.47533 ],
 [ 1.984485,-0.090929,-0.264812],
 [-3.68413 ,-3.902844,-0.003033],
 [-1.151468,-0.811141,-1.833846],
 [ 5.447815, 3.878443, 1.108077],
 [-2.985909,-0.910213,-1.982778],
 [-2.564848, 2.483247, 0.245636],
 [ 1.821687, 5.486046, 1.492528],
 [ 2.535325,-0.050395, 0.233953],
 [-5.73094 ,-0.967256,-0.387807],
 [-0.500056, 2.40263 , 5.602379],
 [-6.822337, 1.168607, 1.832269],
 [-6.488533, 1.057441, 1.827395],
 [-6.708107, 1.015382, 1.797026],
 [-3.018046, 2.198904,-0.304782],
 [-3.047924, 2.217697,-0.255027],
 [-0.188694,-0.322166,-2.394549],
 [ 5.043836, 2.380177,-0.206825],
 [ 4.8297  , 0.973739,-0.509272],
 [ 0.60004 ,-1.914879,-3.35054 ],
 [-2.980078, 2.162318,-0.381343],
 [ 4.996584, 0.552677,-0.675864],
 [ 1.523929, 0.743984,-0.020502],
 [-1.859346,-1.09063 ,-0.246888],
 [-1.631152, 0.233451, 2.064211],
 [-4.850402,-0.27639 , 1.316604],
 [-6.773397,-2.027128,-0.620753],
 [-5.53101 , 0.584301, 1.878096],
 [-5.74897 , 1.519254, 2.016861],
 [-5.48909 , 0.779228, 1.86675 ],
 [-5.531519, 0.583678, 1.878468],
 [-6.749383, 0.852666, 1.729581],
 [ 3.778207, 1.499643, 1.171925],
 [-1.050827,-0.402355, 5.562537],
 [-3.817276,-0.330823, 1.445232],
 [-5.469415, 0.924821,-0.206234],
 [-4.111383,-4.827832,-0.022344],
 [-4.1956  ,-4.864549,-1.049987],
 [ 0.084132,-0.59603 ,-5.188271],
 [-1.925129,-0.337082, 0.088187],
 [-1.59059 , 0.291382, 0.339535],
 [ 0.342509,-3.669381,-2.907944],
 [ 0.587273, 1.594325, 0.126083],
 [-4.41494 ,-2.370335,-2.650393],
 [-5.94057 ,-0.039709, 1.551941],
 [-0.340144,-1.161985,-4.957419],
 [ 2.530806, 3.065748, 4.293336],
 [ 0.022004,-1.23605 ,-4.771791],
 [ 5.527685,-0.163251, 0.654181],
 [-0.350179,-1.240027,-4.989529],
 [-0.336446,-1.246002,-5.006853],
 [ 3.741423, 2.117436,-0.532177],
 [ 0.253407, 3.072794, 2.953976],
 [ 4.038198,-1.056419, 0.709116],
 [ 5.397108, 0.303972,-0.441386],
 [-5.257033,-0.586929, 0.394805],
 [ 3.129609, 0.490131, 2.85085 ],
 [-7.309174, 0.417903,-0.031593],
 [-7.240292, 0.506269,-0.006239],
 [-3.109483, 0.105649, 2.127768],
 [ 1.836694, 2.227528, 1.212476],
 [-2.245706, 1.941385, 2.214876],
 [-2.175354, 0.268571, 3.638981],
 [ 4.625455, 0.586636,-0.170276],
 [ 2.783161,-2.228194,-0.003948],
 [ 4.070276,-0.783181, 0.57167 ],
 [ 3.018532,-3.079019, 0.617206],
 [ 3.03757 , 0.243308, 3.164443],
 [ 3.552397,-2.430358, 0.400895],
 [ 3.423592,-2.857102, 0.1091  ],
 [ 3.024222,-3.077805, 0.612281],
 [ 3.369378,-2.853174, 0.125732],
 [ 3.532896,-2.479702, 0.403204],
 [ 3.400132,-2.859956, 0.117508],
 [ 3.025356,-3.075033, 0.616325],
 [ 3.348542,-2.852196, 0.132001],
 [-6.738095, 1.081858, 1.766114],
 [ 0.921211,-1.380021, 0.979358],
 [-0.000357,-0.752629, 1.23179 ],
 [-2.805648, 2.810115, 0.549831],
 [ 0.75542 ,-1.057262,-0.029845],
 [ 0.821112,-1.070884,-0.059161],
 [ 0.822825,-1.016554,-0.001027],
 [ 0.746712,-1.060749,-0.070069],
 [-0.560536,-2.633609,-5.293686],
 [-2.380848,-5.045448,-0.473623],
 [ 2.004664, 3.17711 , 0.839842],
 [ 2.861285, 3.24919 , 0.147005],
 [ 0.186808, 2.769279, 3.020978],
 [ 4.091361, 4.095615,-0.192606],
 [ 2.917617, 2.921109, 0.198938],
 [ 2.185005, 2.825308, 0.708332],
 [ 5.421128, 0.53179 , 1.409224],
 [ 2.344572,-0.101871,-0.462831],
 [ 2.68188 , 3.733942, 0.110151],
 [ 2.446659, 2.471774, 0.46168 ],
 [ 5.417844,-0.000019, 1.480411],
 [ 2.245147, 3.090121, 0.703463],
 [ 2.973204, 3.046174, 0.047395],
 [ 5.419647, 0.406248, 1.572803],
 [ 1.137943, 4.864622, 2.933841],
 [ 1.421375, 4.890025, 3.304555],
 [ 1.119732, 4.872431, 2.973817],
 [ 4.432435,-0.352716, 0.041801],
 [ 6.733305, 0.266416,-0.483911],
 [ 1.162238, 4.880197, 2.903481],
 [ 5.424939,-0.204019, 1.189183],
 [ 3.914193, 3.519695, 1.773058],
 [ 0.818349,-1.424662, 0.9133  ],
 [ 2.875873, 2.373014, 0.90847 ],
 [ 1.474503, 4.876027, 3.364963],
 [ 2.458059,-0.985853,-1.207479],
 [ 4.032959,-0.333825,-0.146175],
 [ 2.512447,-0.567144,-1.272827],
 [ 5.326047, 0.883442, 1.16017 ],
 [ 6.749439, 0.289278,-0.416908],
 [ 2.290541,-3.047179, 0.708189],
 [ 1.457133, 4.884799, 3.363317],
 [ 1.395872,-0.207167, 0.603551],
 [ 5.371173, 0.672953, 1.311952],
 [-0.050551, 2.868236, 2.675618],
 [ 2.749395,-0.378908,-1.075869],
 [-0.029278, 2.674246, 2.721404],
 [ 0.580173, 5.835656,-0.422577],
 [-3.913651,-4.421125,-0.820174],
 [ 5.566975,-0.406866, 0.792711],
 [ 2.367345,-0.068142,-0.061588],
 [ 0.550679,-1.727252, 1.072014],
 [-4.080421,-4.800899,-0.609135],
 [ 6.064659,-0.759775, 0.251052],
 [ 0.291196, 1.34007 , 5.112479],
 [-5.010358,-0.461716, 1.182739],
 [ 3.871656, 1.439362, 1.11283 ],
 [-3.359969,-3.082085, 0.487497],
 [ 7.335962, 0.068669,-0.188361],
 [-4.773254,-2.866186, 0.612717],
 [-6.05808 , 0.151281,-1.65644 ],
 [ 4.850555, 0.492534, 0.19637 ],
 [-1.702459,-1.84576 , 0.693073],
 [ 6.568579, 0.387236,-3.116348],
 [-3.514396,-3.214263,-0.070265],
 [-3.317208,-3.232959, 0.173637],
 [-5.721265,-0.595627, 0.431527],
 [-4.790283,-0.049337, 1.359054],
 [-4.791643,-0.164476, 1.390985],
 [-1.833179,-3.928666,-2.626092],
 [ 3.679043, 0.556391,-4.665054],
 [ 0.169152, 1.8706  ,-0.849896],
 [ 0.33149 ,-0.125409,-5.143106],
 [-4.790952,-0.164434, 1.391273],
 [-4.784932,-0.167295, 1.397788],
 [ 4.777307, 0.521348, 0.036475],
 [-0.456807, 1.322146,-2.32908 ],
 [-4.785682,-0.171301, 1.393839],
 [ 4.378157, 0.683396,-0.044552],
 [-4.59833 ,-2.790405, 0.604548],
 [-4.635787,-0.52068 , 1.395561],
 [ 1.400662,-3.293752,-3.878676],
 [ 6.938498, 2.437892, 0.033846],
 [-3.263656,-0.129302, 6.860912],
 [-0.128788,-2.297128,-0.710041],
 [ 2.948074,-2.803171, 0.694953],
 [ 0.883434, 1.592814,-3.962311],
 [-6.649457, 0.445779, 1.731972],
 [ 0.566874, 2.494363, 2.681052],
 [-2.697082,-4.094269, 0.979012],
 [ 1.832305, 5.565825, 1.145317],
 [-2.760375,-4.125915, 1.087947],
 [-3.083091,-1.137825, 2.617588],
 [-2.739356,-4.182247, 0.983825],
 [-2.986312,-1.069681, 2.666039],
 [-2.397077,-5.083563,-0.388925],
 [-5.882297, 1.046066, 0.460038],
 [-5.950276, 1.005154, 0.491032],
 [-5.84939 , 1.021355, 0.704271],
 [ 4.076021,-0.90295 ,-3.687143],
 [ 6.524594, 0.440046,-3.189568],
 [-0.040226,-0.651747, 1.306858],
 [-2.688903,-4.33801 , 0.602952],
 [-1.232677,-3.046678, 0.82125 ],
 [-2.559847,-4.284046, 0.847869],
 [-2.621619,-4.206135, 0.830497],
 [-2.614722,-4.190166, 0.88461 ],
 [-0.787093, 2.187346,-2.129766],
 [ 4.484165,-0.469488,-2.382251],
 [-4.964431,-0.036561, 1.417206],
 [-4.961027,-3.097115, 0.925485],
 [-6.056612, 0.170587,-1.69254 ],
 [ 4.744177, 1.173505, 1.538677],
 [-4.858861,-0.363606, 1.14064 ],
 [-2.044197, 0.448374, 2.478539],
 [ 1.704049,-2.514895, 0.611985],
 [-4.709768,-0.177721, 1.430021],
 [-4.958075,-3.104075, 0.916426],
 [-4.394096,-0.883444, 1.468671],
 [-0.71194 , 0.571132, 4.033535],
 [ 0.152416, 2.928802, 2.789984],
 [ 2.378596,-0.4707  ,-0.637826],
 [-0.463736,-1.891443, 1.486385],
 [-0.622164,-1.713186, 1.461379],
 [ 0.360944, 2.205566,-0.177529],
 [ 1.504747,-0.898458, 3.484845],
 [ 1.551382,-1.007715, 3.450486],
 [ 1.571694,-0.914343, 3.506506],
 [ 0.680903, 1.615547, 0.090991],
 [ 0.825101, 1.872748, 1.306614],
 [-7.366987, 1.240231, 0.648511],
 [-2.426629, 0.779273, 0.277732],
 [-7.259913, 0.376666,-0.001851],
 [ 1.007164,-0.220807, 3.253977],
 [ 0.216328, 2.6851  , 2.751355],
 [ 0.327104, 4.033253, 2.670458],
 [ 0.340329, 4.075314, 2.644383],
 [-5.589736,-0.627499, 0.88486 ],
 [-3.286136,-1.195786, 2.237735],
 [-1.572527,-0.557206, 1.544925],
 [ 0.333142, 4.001077, 2.65369 ],
 [-3.359356,-1.165184, 2.286065],
 [-3.489599,-1.287655, 2.334403],
 [-5.664669,-0.562947, 0.841008],
 [-0.497548, 4.152946, 1.743513],
 [-4.890526,-0.368002, 1.143159],
 [ 2.024717, 0.825158, 2.221099],
 [-4.740463,-0.393256, 1.246297],
 [-4.682722,-0.847635,-2.622984],
 [-0.488189, 1.573529, 2.553589],
 [-5.551337,-1.114778, 0.757197],
 [-5.45773 ,-0.951157, 0.798071],
 [-5.401568,-0.714017, 0.812125],
 [-7.259377, 0.365838, 0.000899],
 [-5.382034,-0.568777, 1.060262],
 [-5.569357,-1.139926, 0.732714],
 [-5.168084, 1.592489,-1.075648],
 [-5.203968,-0.579604, 0.947479],
 [-5.248519,-0.601737, 0.868399],
 [-5.356785,-0.670436, 0.702416],
 [ 6.250533, 0.651774,-0.125072],
 [-5.084524,-0.371822, 1.164934],
 [-5.393429,-0.79525 , 0.715815],
 [-0.030066, 1.453088,-0.450401],
 [-2.601358,-3.761678,-0.527309],
 [ 1.592093,-0.027149, 0.57754 ],
 [ 1.134758, 0.973074,-1.720403],
 [-1.050636,-0.850215, 0.082924],
 [ 0.756196, 0.77435 ,-1.820613],
 [ 1.108014, 1.104466, 0.425717],
 [ 1.009986, 1.613055, 0.639378],
 [ 1.632419, 4.331942,-0.227034],
 [-2.431886, 0.320445, 2.105405],
 [-0.401743, 0.672751, 4.138626],
 [-4.249603, 0.388102, 1.09291 ],
 [-2.405254,-0.091898, 1.820756],
 [-0.019288, 1.439504,-4.345485],
 [ 2.490409, 2.475745, 3.846169],
 [ 2.514129, 2.450051, 3.83526 ],
 [ 1.563639, 1.669132, 0.77111 ],
 [ 2.921818, 2.594886, 1.736267],
 [-7.297375, 0.456298,-0.030503],
 [-0.357323,-4.573011,-1.726837],
 [-5.023569,-0.219664, 1.41862 ],
 [-1.521436, 1.928243,-1.344335],
 [-6.66831 , 0.662578, 1.722632],
 [-6.651618, 0.557399, 1.641909],
 [-5.80565 , 0.211587, 0.539584],
 [-4.923175,-0.131447, 0.952722],
 [ 1.967093,-2.365226, 1.160921],
 [-7.100233, 0.455673, 0.012515],
 [ 4.281338, 0.556433,-0.190962],
 [ 6.899278, 2.425048,-0.04624 ],
 [-5.894871, 0.493197, 0.112494],
 [-5.902145, 0.317032, 0.213225],
 [-5.587711,-0.540604, 0.374733],
 [-2.849036, 1.666667,-1.345528],
 [-6.667193, 0.517814, 1.614696],
 [-6.705853, 0.783443,-0.08638 ],
 [-6.666003, 0.642952, 0.028422],
 [-6.316467, 0.198265, 1.711031],
 [-7.034691, 0.749519, 0.241599],
 [-7.141506, 0.514415, 0.043381],
 [-4.578627,-3.746331, 0.335322],
 [-2.927191,-2.351956, 0.401789],
 [ 2.55009 ,-0.826307,-1.593522],
 [-4.912571, 0.179064, 0.078327],
 [-4.912194, 0.177835, 0.042   ],
 [-7.543499, 0.774712, 0.422399],
 [-7.228267, 0.936209, 0.496587],
 [-7.290007, 0.439733,-0.024974],
 [-7.223607, 0.584568,-0.011178],
 [-7.292532, 0.546622, 0.057167],
 [-7.266358, 0.471165, 0.000545],
 [-7.369881, 0.632303, 0.161052],
 [-4.99523 ,-0.129265, 1.417321],
 [-7.221017, 0.472872, 0.020802],
 [ 4.871301,-0.397891,-0.00476 ],
 [-7.268173, 0.459261, 0.024676],
 [-7.280733, 0.449282,-0.025427],
 [-7.546505, 0.756797, 0.424957],
 [ 6.22928 , 0.337688,-0.608461],
 [-7.535808, 0.766601, 0.425175],
 [ 1.028269,-2.280717,-2.056872],
 [ 2.07339 ,-0.806822,-1.707976],
 [-7.241251, 0.469826,-0.008225],
 [ 2.082386, 0.896229,-1.76871 ],
 [-6.854823, 0.763533, 0.048563],
 [-7.052926, 0.430107, 0.040169],
 [-6.364497, 0.367061, 0.097848],
 [-6.531112, 0.493228, 0.095847],
 [ 5.87366 , 1.679034, 1.29971 ],
 [-7.424286, 0.928406, 0.463392],
 [-7.315167, 0.866665, 0.402936],
 [-6.577099, 0.499973, 0.093838],
 [-7.249408, 0.542684, 0.025694],
 [-7.290158, 0.457162,-0.018442],
 [-0.946882, 1.067918,-3.748374],
 [-6.192059, 1.008616,-0.285988],
 [ 5.192832, 0.523387,-2.170116],
 [-0.904901,-3.470465, 0.990764],
 [-0.189453, 1.21986 , 2.434968],
 [-0.777047,-1.032346,-1.162751],
 [-0.511734,-1.718727, 1.382686],
 [ 2.666698,-1.062884,-0.861092],
 [ 4.14963 ,-0.099955,-0.023767],
 [-2.665174,-0.698438, 4.221251],
 [ 2.689468, 0.928273,-1.438839],
 [-3.830916, 2.182279, 0.886795],
 [-6.694055, 0.872588,-0.134537],
 [-6.69347 , 0.873744,-0.134684],
 [-0.742905,-0.229845, 2.79386 ],
 [-6.911562, 0.957106, 0.0536  ],
 [-6.879559, 0.783673, 0.048605],
 [-1.284021, 2.066572, 6.346163],
 [-6.589188, 0.782636, 0.128123],
 [-6.542467, 0.801964, 0.199303],
 [-2.6534  ,-0.135159, 6.379578],
 [ 2.969702, 0.790674, 0.753686],
 [-2.666328,-0.133149, 6.367528],
 [-6.679219, 0.826869,-0.103771],
 [-6.586514, 0.783166, 0.122168],
 [-6.679219, 0.826869,-0.103771],
 [-7.50899 , 0.770466, 0.402197],
 [-6.446774, 1.718734,-1.010236],
 [-7.276555, 0.46684 ,-0.020948],
 [ 3.84291 , 1.692091, 1.490623],
 [-4.703883, 1.806372,-1.180942],
 [ 0.360157,-0.273005, 0.155483],
 [-0.509743,-3.817173, 0.895596],
 [ 0.047592, 0.796205, 5.532968],
 [ 0.173058, 0.859237, 5.564332],
 [ 3.294228, 0.48709 , 3.29161 ],
 [-2.290996, 2.84152 , 2.618665],
 [ 3.166927, 0.481004, 3.287587],
 [ 0.558993, 0.964392, 5.651211],
 [ 0.60731 , 0.971175, 5.669996],
 [ 0.56539 , 0.96394 , 5.652478],
 [ 4.057401,-0.056026, 1.809784],
 [ 2.949005, 5.03542 ,-0.598949],
 [ 2.954826, 5.040491,-0.594099],
 [ 2.867038, 4.973558,-0.667   ],
 [ 2.938478, 5.037346,-0.608558],
 [ 0.633584, 2.279175,-2.892617],
 [ 0.62702 , 2.211742,-2.94851 ],
 [ 0.651173, 2.059904,-3.004184],
 [ 0.458788, 2.43364 , 0.388604],
 [ 0.537144, 2.263994, 0.399367],
 [-0.187138, 5.409437, 2.359821],
 [-0.183808, 5.393759, 2.35293 ],
 [ 3.171674,-0.602995, 2.169729],
 [ 0.020452,-0.403715, 1.36993 ],
 [-0.176617,-0.431682, 1.280805],
 [ 0.506055, 0.119391,-0.430187],
 [ 0.679004, 1.151686, 2.042785],
 [ 0.87536 , 0.175538,-0.26135 ],
 [ 2.333907, 0.501989, 1.901115],
 [ 0.917088, 0.155244,-0.243351],
 [ 1.069679, 0.197541,-0.227925],
 [ 0.996141, 0.164911,-0.25405 ],
 [-2.531604,-0.087408, 1.448662],
 [-0.224065,-0.456155, 1.166576],
 [-0.094169,-0.485214, 1.160458],
 [-7.241735, 0.209347,-0.131331],
 [-7.273274, 0.302758,-0.092976],
 [ 1.79372 ,-1.190341, 2.456341],
 [-1.278449, 4.223761, 3.500181],
 [ 0.089637, 2.674128, 3.275173],
 [-1.055385, 2.637877, 3.909462],
 [-2.37178 , 0.368038, 2.110194],
 [-1.981906, 1.272977, 3.355114],
 [-6.68328 , 0.478659, 1.61739 ],
 [-6.697784, 0.523837, 1.608209],
 [ 6.326013, 1.342473, 0.952088],
 [-5.866665, 0.153495, 0.20299 ],
 [-5.808759, 0.215567, 0.538713],
 [-4.443367, 1.658974, 2.302518],
 [-6.693722, 0.4997  , 1.670672],
 [-6.723325, 0.162163, 1.671616],
 [ 2.786428,-0.561141, 1.935111],
 [-1.214989, 2.337146, 1.195784],
 [ 1.762762,-1.309504,-1.285481],
 [ 2.453671,-1.082024,-3.804351],
 [ 2.63663 , 1.295952, 0.32697 ],
 [-0.619434, 2.042558,-1.970867],
 [ 1.569995,-2.800016,-3.528631],
 [ 2.485292,-0.972087,-3.831531],
 [ 0.278195, 1.385029,-0.007696],
 [ 6.729012, 0.058549,-0.21375 ],
 [ 2.44874 ,-1.014904,-3.805573],
 [ 2.504324,-0.947189,-3.653642],
 [ 1.257267,-2.983297,-1.675392],
 [-0.029638,-2.289403,-0.714037],
 [ 5.065377, 4.559957, 1.356387],
 [ 0.296999,-3.65994 ,-2.950688],
 [ 0.836064,-1.713183,-1.500295],
 [ 1.118178,-1.941815,-1.302491],
 [ 0.363043,-3.643861,-2.875285],
 [-0.659418,-2.55119 ,-1.490973],
 [ 0.32976 ,-3.62113 ,-2.884325],
 [ 2.414426,-0.23424 ,-0.276252],
 [-0.866645,-3.588733, 0.933964],
 [-0.859241,-3.560897, 0.94963 ],
 [-0.500159,-3.829866, 0.922181],
 [-0.819336,-1.007247,-1.166529],
 [-0.307177, 2.006657, 2.658762],
 [-0.310029, 2.026077, 2.665381],
 [ 3.236682,-1.597904, 1.566856],
 [ 5.184633, 0.375411,-2.018714],
 [-1.065449,-3.088578, 1.088011],
 [-0.298656, 2.002303, 2.674862],
 [-0.919955,-3.167382, 1.094346],
 [-1.019304,-3.539355, 1.089497],
 [ 5.820711, 0.248065,-1.387542],
 [ 3.167025, 2.333286,-0.145581],
 [ 1.358659, 4.876111, 3.269147],
 [ 2.172274, 0.124996,-0.893347],
 [ 2.364091,-3.048769, 0.686017],
 [ 3.849796, 3.473642, 1.672508],
 [ 2.458204,-3.056737, 0.68038 ],
 [ 3.986439, 0.7154  ,-0.123859],
 [-0.688632, 2.270558, 2.987285],
 [-0.833765, 2.165871, 3.070493],
 [-0.818991, 2.088026, 3.114653],
 [-0.408663, 2.435202, 2.810286],
 [ 2.65062 , 3.845464, 0.092531],
 [ 0.017206, 2.912661, 2.600867],
 [ 0.394105, 2.740081, 1.868523],
 [ 0.820938, 3.415137, 2.527822],
 [-0.67956 , 0.477324, 0.062469],
 [ 0.46061 , 3.237423, 2.764962],
 [-0.02822 , 3.034502, 2.520789],
 [ 0.568805, 5.82244 ,-0.399098],
 [ 0.482569, 3.255156, 2.691668],
 [ 0.805928, 2.766846, 1.49482 ],
 [ 2.205263, 3.577991, 1.650815],
 [ 0.437691, 2.279607, 2.088208],
 [-0.966184,-2.738799, 1.158039],
 [ 3.862833, 2.208983,-0.554244],
 [ 2.205641,-0.589682,-1.21722 ],
 [-2.373303,-0.075652, 3.582978],
 [ 0.506482, 3.233606, 2.642278],
 [ 2.193865,-0.107446, 0.05668 ],
 [-0.779247,-0.0351  , 2.855614],
 [-2.227757, 1.483429, 3.128365],
 [ 0.485824, 3.251045, 2.686028],
 [ 3.283243, 2.329928,-0.194425],
 [ 2.13386 ,-2.79722 , 0.478175],
 [ 3.859341, 3.40053 , 1.55031 ],
 [ 2.521625,-3.022436, 0.639314],
 [ 1.406911, 4.876127, 3.308908],
 [ 2.649739, 3.971781, 0.018211],
 [ 2.325117, 1.57736 , 0.851865],
 [ 5.32097 , 0.429012,-0.930886],
 [ 2.433115,-0.849138,-0.543899],
 [-0.171159, 2.058193, 2.474224],
 [-2.392548,-0.82813 , 2.951005],
 [ 3.597018,-1.266131, 1.099161],
 [ 1.969584, 3.276801, 3.301043],
 [ 3.907238, 0.492415,-0.213859],
 [-0.864525,-1.00404 ,-1.189086],
 [ 3.494516,-1.402321, 0.830831],
 [-6.854007, 1.270528, 1.800059],
 [ 0.560445, 6.072862, 0.145448],
 [ 5.921751, 3.081104, 1.632125],
 [ 3.29433 , 2.33781 ,-0.182391],
 [ 0.568094, 6.070325, 0.141419],
 [ 5.899158, 3.062348, 1.576537],
 [-1.574631, 1.977638,-0.003215],
 [-0.434971, 0.619994, 1.10012 ],
 [-0.869608, 0.474817, 1.097986],
 [-0.760029, 0.514369, 1.210596],
 [-1.453486, 1.142673, 1.988745],
 [-6.771593, 1.027979, 1.775215],
 [-0.200032, 0.576845, 0.9816  ],
 [-3.853665,-0.469486, 1.883998],
 [ 4.005547, 0.242062, 0.366242],
 [ 6.243547, 0.82184 , 1.912817],
 [ 1.554852, 0.308749, 0.637845],
 [ 2.232717,-1.568174, 1.367763],
 [-5.634663, 0.296203, 2.013433],
 [-5.3659  , 0.127946, 1.812521],
 [-2.597094,-3.713903, 1.398589],
 [-5.571201, 0.167754, 2.162879],
 [-5.575087, 0.131277, 2.118238],
 [-5.394654, 0.004681, 1.719459],
 [-5.139374, 1.043451,-0.26053 ],
 [-5.632092, 0.152997, 2.134948],
 [-4.944139,-0.088821, 1.385438],
 [-0.553419, 3.296525, 0.608982],
 [-2.66545 , 2.035757, 1.96016 ],
 [-3.062638, 2.009261, 1.464246],
 [ 3.318675, 2.188948, 0.586143],
 [ 2.778231, 3.429611, 0.3351  ],
 [ 1.160602, 3.500757, 0.818333],
 [-2.56646 ,-0.541866, 4.131586],
 [ 2.67587 , 3.661705, 0.169835],
 [ 5.541977,-0.631998,-1.395954],
 [-2.542779, 0.234755, 1.74364 ],
 [-2.667269, 0.023009, 1.904092],
 [-3.143165, 1.659293, 0.666833],
 [-1.888433, 1.18644 , 0.776586],
 [-5.708378, 0.704269, 0.083425],
 [-4.419412, 1.683954, 1.498554],
 [ 1.368644,-0.274395, 0.894595],
 [ 0.817348, 0.056669, 4.282667],
 [ 3.838119, 4.371554, 2.104666],
 [ 4.499389, 0.45282 , 2.762259],
 [ 5.114061, 4.88138 , 0.192879],
 [ 0.556973, 2.4493  , 0.146236],
 [ 5.14648 , 4.89806 , 0.151594],
 [ 3.780283, 4.35794 , 1.929951],
 [ 5.603966, 4.703426,-0.284373],
 [-4.022098,-4.759544,-0.348369],
 [ 3.771768, 4.341018, 1.928952],
 [ 2.316824, 0.844381, 2.180499],
 [ 5.019084, 4.940504, 0.251025],
 [ 3.475494, 4.445442, 1.727889],
 [-6.061189, 1.652264, 0.649573],
 [-5.702679, 0.694201, 0.116377],
 [-0.087999,-4.073552,-0.640152],
 [ 4.03584 ,-1.19101 ,-2.119284],
 [ 4.076928,-1.23552 ,-2.157726],
 [-3.32244 ,-1.52495 ,-1.99908 ],
 [-4.428305, 1.847171, 3.315326],
 [-5.479527, 0.911343, 3.555082],
 [-4.692945, 0.588465, 2.719343],
 [-7.145131,-0.194387, 2.872081],
 [ 0.403007,-0.421037,-0.693565],
 [ 2.284241, 0.029007,-2.574127],
 [ 2.192612, 0.13363 ,-2.582284],
 [ 2.190026, 0.089172,-2.533489],
 [ 2.990563,-0.969378,-0.831257],
 [ 5.297037,-0.121905, 0.038347],
 [-0.696699, 4.217441,-0.898761],
 [-0.958555, 3.645527,-0.531916],
 [-2.768261, 2.457461,-0.954606],
 [-0.836702, 0.791982,-1.92912 ],
 [-6.405174, 1.748776,-1.096079],
 [-0.129122,-4.168458,-0.009527],
 [-0.111848,-4.160672, 0.026049],
 [-4.628415, 0.941315, 3.144068],
 [-4.637888, 0.997605, 3.174533],
 [-4.73289 , 0.587178, 2.514992],
 [ 7.828584, 1.573021,-0.163109],
 [ 3.638014, 2.110649,-0.058109],
 [-4.561632, 1.397011, 3.785183],
 [-4.129996,-4.940603,-0.676219],
 [-4.648925, 0.646496, 2.655853],
 [-4.606309, 0.617241, 2.635403],
 [-1.206489, 5.79866 , 0.341738],
 [-4.651807, 1.474323, 3.603899],
 [-4.744055, 0.140576, 1.860437],
 [-4.331055, 0.444819, 3.052246],
 [-4.295354, 1.271658, 2.562657],
 [-4.373311, 1.447711, 4.373854],
 [-5.102943,-0.984534, 1.5128  ],
 [-4.80239 , 0.441329, 2.45742 ],
 [-4.329097, 1.040727, 2.211608],
 [-3.648964,-0.094624, 0.568415],
 [-4.788528, 0.619035, 2.479566],
 [-4.724602,-0.086217, 2.849381],
 [-4.53008 ,-1.058284, 3.174334],
 [ 4.271037, 0.848038, 0.061621],
 [-4.706058, 0.628019, 2.557508],
 [-4.519812, 0.802159, 2.395349],
 [-2.273571, 2.894092, 1.234288],
 [-3.478642,-1.476479,-1.731631],
 [ 1.192415, 0.120878,-3.398599],
 [ 1.505076, 5.139178,-1.150243],
 [ 3.274452, 4.606644, 1.677914],
 [ 3.357647, 4.517492, 1.715525],
 [ 3.909727, 1.349742, 1.010245],
 [ 5.369338, 0.35175 , 1.878502],
 [ 3.330163, 4.439995, 1.696787],
 [ 3.909068, 1.251181, 0.918364],
 [ 5.450689, 0.321027, 1.655617],
 [ 3.355892, 4.538331, 1.733796],
 [ 4.051165, 1.685365, 1.422634],
 [ 5.284391, 0.345021, 1.94996 ],
 [ 3.347451, 4.516531, 1.733533],
 [ 4.120908, 1.502901, 1.394085],
 [ 5.423748, 0.360511, 1.528909],
 [ 3.302408, 4.403361, 1.675012],
 [ 4.099027, 1.684878, 1.437822],
 [ 3.316183, 4.442304, 1.700177],
 [ 4.058439, 1.706392, 1.448817],
 [ 3.3449  , 4.51248 , 1.7352  ],
 [ 4.132622, 1.751669, 1.429371],
 [ 3.35125 , 4.518667, 1.720683],
 [ 4.129613, 1.780079, 1.464014],
 [ 3.348792, 4.514121, 1.731446],
 [ 3.818012, 1.108988, 0.592151],
 [ 3.340126, 4.498422, 1.725049],
 [ 4.146205, 1.777457, 1.421986],
 [ 3.354204, 4.537836, 1.73881 ],
 [ 4.105145, 1.690036, 1.418769],
 [ 3.351534, 4.527915, 1.741813],
 [ 3.542471, 1.292905, 1.121455],
 [ 3.350608, 4.530698, 1.744816],
 [ 4.052488, 1.699971, 1.379173],
 [ 3.354714, 4.546497, 1.763872],
 [ 4.022297, 1.587012, 1.405504],
 [ 5.475768, 0.335051, 1.612998],
 [ 3.345851, 4.519136, 1.747844],
 [ 3.104318, 0.739287, 0.234331],
 [ 3.239755, 1.667174, 0.865433],
 [ 3.085708, 0.925481, 0.57397 ],
 [ 3.411515, 4.702946, 1.711222],
 [ 3.379736, 4.812855, 1.743512],
 [ 3.360579, 4.806258, 1.738939],
 [ 3.381862, 4.786413, 1.716019],
 [ 3.364619, 4.781068, 1.736233],
 [ 3.371122, 4.803729, 1.744968],
 [ 3.380878, 4.821971, 1.742593],
 [ 3.361505, 4.789097, 1.745632],
 [ 3.370813, 4.779077, 1.743104],
 [ 3.374423, 4.78956 , 1.741014],
 [ 3.341615, 4.723444, 1.765025],
 [ 3.388193, 4.821867, 1.747363],
 [ 3.183294, 0.661922, 0.09247 ],
 [ 3.408871, 4.729057, 1.708285],
 [ 3.414619, 4.769867, 1.688576],
 [ 3.392363, 4.77231 , 1.699552],
 [-6.74276 , 1.15572 , 1.664089],
 [ 1.455068,-0.234045,-1.13834 ],
 [-5.596537, 0.39122 , 1.623743],
 [-6.06317 , 0.022977, 1.670205],
 [ 2.503814,-1.687717, 0.021981],
 [ 2.728258,-1.052276,-0.77744 ],
 [-0.701899,-1.627334, 1.10092 ],
 [ 2.525697,-0.991492,-0.986003],
 [ 7.334451, 0.067677,-0.191983],
 [-1.217568,-1.299032, 0.109123],
 [ 2.403511,-1.520589,-0.954297],
 [ 2.262961,-1.792407, 0.30378 ],
 [ 1.565252,-1.415072,-0.884304],
 [ 4.693656, 0.010833, 0.19651 ],
 [-0.662377,-1.66256 , 1.16357 ],
 [ 4.256751, 0.065161, 0.196551],
 [-3.419776, 1.005585, 2.011529],
 [ 6.562459, 0.388278,-3.112872],
 [ 3.878824,-1.14855 ,-2.155346],
 [ 4.187753, 4.233296,-0.173694],
 [-0.01243 , 0.025158, 0.094068],
 [-4.47007 , 1.10486 , 2.313446],
 [-4.641321, 1.693326, 3.450222],
 [-4.922604, 0.23752 , 1.709205],
 [-1.206057, 5.308158, 1.247487],
 [-3.870199, 0.221868, 2.228065],
 [-5.911791, 1.28875 , 3.963348],
 [-4.607071, 1.683588, 3.435414],
 [-5.374096, 0.251281, 1.419143],
 [-0.555811,-1.712791, 1.375021],
 [ 0.90305 ,-3.668069,-4.259998],
 [ 6.981344, 2.450891, 0.02129 ],
 [-0.321119,-2.906253,-5.73326 ],
 [-3.95021 ,-4.709553,-0.323794],
 [ 4.312593, 1.215456,-0.332171],
 [-3.20003 ,-1.664072,-1.875418],
 [-6.576411,-2.281354,-0.378457],
 [-6.536104,-2.351043,-0.361306],
 [ 6.783945, 0.232373,-2.30715 ],
 [-6.38631 ,-1.710446,-0.519035],
 [-1.308795,-2.924849,-2.737091],
 [ 2.405908, 1.411499, 0.54864 ],
 [ 1.144525,-0.972493,-0.42242 ],
 [-1.66957 , 0.705589,-2.313126],
 [ 5.361175, 0.562304,-2.154857],
 [-6.532013,-2.242757,-0.370011],
 [-6.599949,-2.354297,-0.325874],
 [-5.399295,-0.958507,-0.011755],
 [-5.826032,-0.044025, 0.468693],
 [-5.506591,-0.554646, 0.40507 ],
 [-6.559393,-2.247425,-0.379803],
 [-3.76807 , 0.078604, 1.654076],
 [-0.276004,-3.61562 ,-4.231366],
 [-6.4133  ,-2.084236,-0.294583],
 [-6.733864,-2.350196,-0.435959],
 [-5.15164 ,-0.870387, 0.359447],
 [-2.251074,-0.307725,-0.115349],
 [-5.631988,-1.413841, 0.025947],
 [ 3.808819, 1.101983, 2.815894],
 [-6.669105,-2.321989,-0.452108],
 [-1.188429,-2.888525,-2.094123],
 [ 0.988708,-1.344688,-3.700435],
 [-4.451779,-1.85377 ,-0.990747],
 [-2.631302,-3.301726,-3.48722 ],
 [-3.416433,-3.378145,-0.340089],
 [-1.21943 ,-2.95552 ,-2.103952],
 [-3.79281 ,-1.330797,-0.519553],
 [ 1.360884, 0.054927,-2.34463 ],
 [ 1.345427,-0.000076,-2.351738],
 [ 5.207739,-0.277031,-0.644703],
 [-3.526815, 2.362715, 4.809192],
 [-4.515819, 0.133392,-1.359505],
 [ 0.324982,-2.449067,-3.451984],
 [-0.406414,-3.179759,-5.272885],
 [ 2.39554 ,-0.343929,-2.891198],
 [ 2.018159,-1.025823,-1.522272],
 [ 0.842311,-2.113741,-2.034542],
 [ 1.02281 ,-1.929359,-1.915519],
 [-0.926486,-3.29255 ,-3.694471],
 [-0.713955,-3.378784,-4.729379],
 [ 2.844694,-2.591901,-3.832863],
 [ 4.225437,-1.473114,-2.879419],
 [-0.453912,-3.244935,-4.598105],
 [ 3.654818,-2.544416,-3.821759],
 [ 6.499359, 0.241833,-1.589215],
 [-2.820994,-1.775964,-4.80513 ],
 [-4.09014 , 0.764804, 6.040966],
 [-4.113123, 0.771313, 6.048811],
 [ 4.737777, 0.901838,-0.101631],
 [-4.103995, 0.526104, 6.101905],
 [-1.048752,-0.374947, 0.832007],
 [ 0.545292, 2.097574,-0.248288],
 [-1.140593,-0.378101, 1.156908],
 [-1.1638  ,-0.389794, 1.227777],
 [-0.779693,-0.493813, 0.922505],
 [-0.95117 ,-0.426304, 1.030752],
 [-0.847678,-0.503931, 0.905683],
 [ 3.080188,-1.928055,-0.098645],
 [ 1.869169,-0.968583,-0.455106],
 [-0.610393,-0.494216, 0.710413],
 [ 3.112825,-1.975802,-0.045292],
 [-0.53894 ,-0.534561, 0.527788],
 [ 1.695822,-0.88091 ,-1.382553],
 [ 1.911079,-1.245805,-1.396708],
 [ 6.333779, 3.169358, 0.101645],
 [-0.670622,-0.474249, 0.742303],
 [ 4.81822 , 0.325204,-0.506643],
 [-2.44084 , 1.727443, 2.502188],
 [-2.739853, 2.667763, 2.357598],
 [-2.540807, 1.923683, 2.526982],
 [ 1.410706, 3.379354, 1.096325],
 [-2.389821, 1.743295, 2.550096],
 [ 0.626441, 0.412575, 3.249997],
 [ 0.62524 ,-1.954578,-0.516816],
 [-0.193277, 0.050945, 4.636018],
 [-2.671744, 2.430107,-0.9038  ],
 [-0.920693,-0.434211, 0.993289],
 [-1.250544, 0.533187, 0.060043],
 [-2.739016, 2.266606, 0.428932],
 [ 0.312087, 0.628416, 0.146127],
 [ 2.184034,-0.327741,-0.415509],
 [ 2.729898, 1.855559, 1.800378],
 [ 4.625438, 0.656934, 0.277696],
 [ 3.035043, 0.310785, 0.159046],
 [-1.059825,-1.417646, 5.323701],
 [-1.02622 ,-0.365536, 1.13018 ],
 [-1.62228 , 0.854178,-0.1587  ],
 [-1.390281, 0.328723, 0.236311],
 [-2.703134, 2.213999, 0.473466],
 [-1.527672, 0.761629,-0.088504],
 [-2.657002, 2.211056, 0.483872],
 [ 2.197887,-1.219875,-0.196616],
 [ 2.629768,-1.034108,-1.127848],
 [ 1.048392,-0.888488, 0.033555],
 [ 2.617923,-1.0747  ,-1.002094],
 [ 2.466213, 1.517631, 1.37947 ],
 [ 7.026338, 2.501892, 0.003973],
 [-0.357001,-4.569595,-1.727022],
 [-2.480475,-0.231101, 4.301347],
 [ 0.510126,-0.597332,-1.238937],
 [ 1.594917,-0.124261,-0.198483],
 [ 6.217523, 3.361814, 0.122423],
 [ 0.304218,-0.002394,-0.308781],
 [-1.22872 , 3.386711,-0.543594],
 [ 0.752344, 3.468319,-0.164152],
 [ 0.016438, 3.770291, 0.11202 ],
 [-0.345922, 4.845531, 0.598219],
 [ 2.34161 ,-0.323529,-2.694853],
 [ 2.512048,-0.629676, 1.218967],
 [ 3.382822, 4.522858, 1.769964],
 [ 2.056952,-1.161301, 1.539264],
 [ 7.778687, 1.643441,-0.136699],
 [ 5.624626, 4.73534 ,-0.263767],
 [ 0.442417, 0.061384,-0.317814],
 [-0.735105, 0.698603, 0.255944],
 [ 1.446608, 1.403895, 0.619771],
 [ 1.53913 , 3.259966,-1.436504],
 [ 1.572269, 3.284931,-1.415033],
 [ 1.528525, 3.250905,-1.402699],
 [ 1.543434, 3.260239,-1.420518],
 [-1.424248, 0.529566,-3.56458 ],
 [ 1.142228, 1.73909 , 0.398479],
 [-1.418438, 0.513039,-3.566248],
 [-5.217847,-0.859814, 1.380255],
 [ 0.856067, 1.564824, 1.040343],
 [ 1.177055, 2.496821,-0.726768],
 [ 5.535235, 0.373342,-0.810531],
 [ 0.236678, 1.893052,-0.830508],
 [ 0.165651, 1.705256,-1.037533],
 [ 0.714907, 0.539031,-1.697191],
 [ 0.295268, 1.491823,-1.460338],
 [ 1.542079, 4.232104,-0.183031],
 [ 1.7452  , 1.781698,-0.145379],
 [ 0.613808, 1.827907,-0.027019],
 [ 4.490729, 1.336456,-1.197665],
 [ 3.462064, 0.408061,-0.227135],
 [ 3.510329, 0.989081,-0.233964],
 [ 3.007461, 3.129515, 0.913056],
 [ 4.389211, 0.526656,-0.189729],
 [ 2.966115, 3.293938, 1.097238],
 [-1.349524, 3.39523 ,-0.557192],
 [-1.919907, 3.015681,-0.501993],
 [-2.352142, 1.59403 , 3.725334],
 [ 6.525728, 0.43039 ,-3.126446],
 [-2.358352, 1.059406, 3.807054],
 [-2.747135, 0.442298, 3.890916],
 [ 0.981942, 0.577739,-0.858965],
 [ 3.006736, 3.245706, 0.17547 ],
 [ 0.18032 , 2.907146, 3.013246],
 [ 0.049443, 2.953428, 2.846824],
 [-0.394999, 2.50289 , 2.639056],
 [ 2.916131,-3.301466,-1.78268 ],
 [-2.740196, 2.693689, 2.349212],
 [ 4.394582, 0.933097,-0.145806],
 [-7.260116,-0.29513 , 2.885053],
 [-2.978373,-0.517819, 4.059476],
 [-6.761828,-0.360246, 3.153564],
 [-0.564324,-0.067349, 4.482762],
 [-2.816834, 1.184983, 3.662219],
 [-6.881652,-0.293081, 2.960734],
 [-2.502218, 1.326174, 3.643613],
 [ 0.54176 , 4.059365, 0.411411],
 [-6.398355,-0.433408, 3.09998 ],
 [-0.511698, 4.346251, 0.57638 ],
 [-0.816301, 1.4984  , 4.193787],
 [-4.678314,-0.494488, 1.489201],
 [-3.290365,-1.737502,-4.333375],
 [-2.492878,-1.90899 ,-3.670612],
 [-4.242544,-1.402804, 1.203119],
 [ 5.454203, 0.772644, 0.509599],
 [-3.090158,-2.423681,-2.531559],
 [ 4.462543, 0.340677,-0.042165],
 [-0.345128,-1.182732,-5.289412],
 [-4.58175 ,-3.159115, 0.09287 ],
 [ 6.702947, 0.323078,-0.066488],
 [-3.104928, 0.863202, 3.587123],
 [ 3.717927,-1.200254, 1.274099],
 [-0.25661 , 3.971834, 0.557842],
 [ 1.085526,-5.120815,-0.864778],
 [-0.611633, 4.291348, 0.643651],
 [ 0.400739, 0.141694, 1.316794],
 [ 0.828665, 2.902069,-0.914595],
 [-2.992395, 0.977549, 3.578872],
 [-5.267525,-0.809332, 0.736935],
 [ 0.371623, 0.147997,-0.220529],
 [-2.440012, 1.124391, 3.828945],
 [ 0.030881, 2.767751, 2.734546],
 [-5.854544,-0.53692 , 2.877351],
 [ 5.567848, 0.554303,-1.465163],
 [-5.686987,-0.767668, 2.996709],
 [-0.558813, 4.431349, 0.58374 ],
 [-0.008569,-1.013955, 0.644904],
 [-5.951175,-0.847046, 2.58779 ],
 [-5.043102,-0.407133, 1.847934],
 [-3.651814, 1.914686, 2.831635],
 [-5.350439,-0.960264, 3.176621],
 [-4.189559,-0.787121, 3.644318],
 [-3.788217, 1.051434, 3.583553],
 [-4.122769,-2.72646 ,-0.19265 ],
 [-1.165853, 2.638824, 1.472724],
 [-1.437734, 0.192646,-1.051291],
 [-1.858436, 0.044423,-0.602709],
 [-0.260206,-3.748471, 1.595995],
 [ 1.864998, 0.349671, 0.472453],
 [-2.778528, 1.686144, 2.654663],
 [-6.503392,-0.426702, 3.140548],
 [-4.577718, 0.972902,-1.787262],
 [-5.660631,-0.801476, 3.023886],
 [-0.869239, 0.623033,-2.11186 ],
 [-5.352296,-0.895176, 3.156922],
 [-3.623944, 1.92572 , 2.840633],
 [-4.292097,-0.240066, 2.135557],
 [-3.493719, 0.421267, 3.296904],
 [-3.455828, 0.463511, 3.294814],
 [ 7.266788, 0.081773,-0.220697],
 [-3.41769 , 0.388382, 3.664015],
 [-0.582681, 3.483753, 0.976799],
 [-5.031409,-0.594808, 1.535542],
 [-5.11479 ,-1.004275, 1.549526],
 [-0.879857, 1.531253, 4.150486],
 [ 0.155194, 1.226362, 1.000816],
 [-5.241315,-0.198656, 4.843303],
 [ 7.343943, 2.25543 ,-0.025827],
 [-5.164032,-0.080604, 5.035603],
 [ 0.418843, 0.151988,-0.316729],
 [-5.159822,-0.091344, 5.010452],
 [-3.226136, 0.320283,-2.05639 ],
 [ 5.196051,-0.075862,-4.012737],
 [-1.223953, 0.158308, 1.232135],
 [-4.50735 , 0.104361, 1.970091],
 [-0.555833,-0.333267, 3.186064],
 [-4.416823,-0.230238, 1.47313 ],
 [-7.058951,-0.315308, 2.961028],
 [-1.394242, 0.246786, 3.288833],
 [-3.113654,-0.564286,-0.431041],
 [-4.965985,-0.082729, 1.424025],
 [-0.844707, 2.448625, 0.702147],
 [ 3.761563, 0.897545, 0.683111],
 [-7.062735,-0.258258, 3.082534],
 [-0.990552,-0.154279, 5.038757],
 [-3.116134,-3.400545,-0.374509],
 [-0.315986, 3.529926, 1.369975],
 [-0.302205, 3.85732 , 1.514848],
 [-0.374408, 3.923088, 1.55753 ],
 [ 0.132322, 2.355495, 0.639811],
 [-4.48128 , 0.090357, 1.987571],
 [-1.583131,-0.735813, 1.469628],
 [-6.961552,-0.295187, 3.11668 ],
 [-6.281061, 0.055204, 1.908536],
 [-7.136806, 0.512302, 0.008869],
 [ 0.396622, 2.720801, 0.300223],
 [-2.605686,-0.60141 , 4.286663],
 [-2.772007,-0.298761, 3.805729],
 [ 4.469606, 1.65523 , 1.997746],
 [-2.570653,-0.422488, 4.14909 ],
 [-0.603227, 0.18203 , 2.34406 ],
 [ 1.757556, 3.643445, 1.20942 ],
 [-1.987854, 1.002175, 3.761241],
 [-7.361757,-0.317438, 2.758507],
 [-0.700958, 3.71026 ,-0.292152],
 [-0.258809, 4.39416 , 0.554011],
 [ 3.125572, 0.408473, 0.301397],
 [ 3.405214, 0.193669, 1.191553],
 [-3.309766,-3.414876,-0.355291],
 [-1.695072,-0.748169, 1.007123],
 [-1.409403, 0.466481, 3.308982],
 [-0.447703, 4.209532, 0.498562],
 [ 6.807286, 2.155061, 0.057818],
 [ 4.912671, 1.334738, 0.822094],
 [ 5.709818, 0.276132,-1.299236],
 [-0.447777, 4.225146, 0.633063],
 [ 0.751127, 1.253641,-1.411481],
 [ 0.521515, 2.009424,-0.99296 ],
 [ 0.493526, 2.479152,-0.85059 ],
 [ 0.47288 , 2.525681,-1.003499],
 [ 0.459362, 2.683847,-0.854416],
 [ 0.593798, 2.322867,-1.047543],
 [ 0.583519,-2.315933, 2.71772 ],
 [-0.363495, 4.983064, 0.486102],
 [ 1.227838, 1.362057,-1.562455],
 [-0.342405, 4.919199, 0.560392],
 [-0.324635, 4.080966, 0.638915],
 [-1.068472, 0.221857, 1.698496],
 [-0.804778, 2.069997,-2.157109],
 [-0.421244, 4.208541, 0.575071],
 [-0.420559, 4.316569, 0.608405],
 [-0.191987, 4.381981, 0.545025],
 [ 0.311635,-0.055073,-1.549796],
 [ 0.006582, 3.070038, 0.562855],
 [-0.361625, 4.968914, 0.591476],
 [-0.177551, 3.489072, 0.461869],
 [ 0.268203, 2.234947, 0.322653],
 [-0.462677, 4.425175, 0.59561 ],
 [-0.382323, 5.008226, 0.815746],
 [-7.29509 ,-0.243547, 3.049195],
 [-0.478466, 4.463514, 0.612942],
 [-0.786567, 0.773923,-1.894485],
 [ 4.724777, 0.438775,-0.134245],
 [ 3.953138, 2.198281,-0.623784],
 [ 0.211029, 2.40271 , 3.937965],
 [ 0.274856, 2.094544, 3.723428],
 [-0.274929, 1.622875, 2.358048],
 [ 1.333271, 2.417802, 2.811288],
 [ 3.845509, 1.92104 ,-0.282028],
 [-0.48933 , 4.485785, 0.601351],
 [-2.494511,-4.280706, 0.835797],
 [ 4.054749, 4.114315,-0.191639],
 [ 0.586549,-2.32907 , 2.710278],
 [ 1.229355,-3.340315, 0.466054],
 [-0.634395, 4.261598, 0.690261],
 [-0.826054,-3.18069 ,-3.508424],
 [ 6.344498, 0.293285,-2.209187],
 [ 4.899481, 1.940997,-1.068815],
 [ 4.706717,-1.613188,-0.073853],
 [ 2.603073, 0.39301 ,-0.856184],
 [ 2.541531, 0.2944  ,-1.44153 ],
 [ 1.875855, 3.712189,-1.172736],
 [ 2.534262, 0.310724,-1.435886],
 [ 1.932304, 3.660143,-1.200832],
 [-0.394181, 5.008556, 0.654391],
 [ 5.278617, 1.178308, 1.920925],
 [ 6.742415, 0.256921,-0.518272],
 [-0.326046, 5.009509, 0.607349],
 [-0.391043, 5.018963, 0.617085],
 [ 5.79502 , 0.319468,-0.77873 ],
 [ 0.758036, 0.202053, 2.007419],
 [ 4.054554, 4.050996,-0.187672],
 [ 0.581898,-2.325924, 2.711739],
 [ 6.989843,-0.168148,-0.11447 ],
 [ 0.871484, 0.849652,-1.512251],
 [-0.690964,-2.598452,-0.278579],
 [-0.089458, 4.279587, 0.714389],
 [-0.363993, 4.913563, 0.594997],
 [-1.213664, 3.354246,-0.520051],
 [-0.44    , 4.217681, 0.497422],
 [-0.321506, 4.282391, 0.577965],
 [-0.38984 , 5.023736, 0.624993],
 [-7.267383,-0.251249, 3.010264],
 [ 1.55125 , 2.78376 , 1.890852],
 [ 0.675395, 0.752299, 0.613305],
 [-0.459975, 4.429219, 0.593019],
 [ 1.827317,-1.00078 ,-2.736273],
 [ 6.893056, 2.473236,-0.032009],
 [-1.173538, 3.504443,-0.627966],
 [-1.714928, 1.776827, 1.375919],
 [ 1.434295, 2.122049, 3.858595],
 [-0.491305, 4.127219, 1.720674],
 [-0.015789, 4.085794, 0.620527],
 [ 0.183614,-0.706656,-2.089842],
 [ 3.560499,-1.134   ,-0.335306],
 [-1.676391, 1.830398, 1.334693],
 [-0.736132, 2.527154, 4.114015],
 [ 4.884065, 3.633257, 0.088405],
 [-1.348354, 1.374422, 4.235342],
 [ 4.500387, 0.733585, 2.726729],
 [-2.95443 ,-2.493136, 0.750426],
 [ 4.554017, 0.674388, 2.777172],
 [-0.207618, 3.946098, 1.089861],
 [-0.471472, 3.811087, 1.736351],
 [-0.332082, 3.880442, 1.461313],
 [ 0.029271, 4.187747, 0.66079 ],
 [ 0.962183,-2.237405,-1.324451],
 [ 0.189313, 1.252191, 0.878348],
 [-1.398467, 1.433219, 1.999804],
 [-2.30906 , 0.657948,-0.370516],
 [-1.695703, 1.220511,-0.092982],
 [-0.176118, 3.79324 , 1.12197 ],
 [ 0.133227, 3.098977, 0.329451],
 [ 6.75317 , 0.223658,-2.363991],
 [-0.625485, 1.706587,-1.999717],
 [ 2.835872, 1.820925, 1.379002],
 [-0.124184, 3.944684, 0.811057],
 [-0.17576 , 3.928543, 0.67835 ],
 [ 0.029967, 3.361358, 1.050635],
 [ 0.268821, 0.016711,-1.493175],
 [ 5.252872, 0.273127,-0.044754],
 [-7.262571,-0.239724, 3.034937],
 [-6.61651 ,-2.264584,-0.431798],
 [ 4.490578, 4.032694,-0.311121],
 [ 0.617892,-0.158975,-0.118157],
 [ 4.861036,-0.231324,-0.328412],
 [ 1.204761,-3.345445, 0.480628],
 [ 1.102767,-2.291023,-1.252043],
 [ 0.3313  ,-1.623608,-1.596444],
 [ 0.467073,-0.495633,-0.807329],
 [-0.427608, 4.802329, 0.598629],
 [-5.828028,-1.835691, 1.256122],
 [-1.739908, 1.805237, 1.441255],
 [-2.201502,-0.918948, 0.939971],
 [ 1.396955, 2.038555, 3.969721],
 [-1.397706, 1.483999, 1.938203],
 [-5.739192,-1.879519, 1.056187],
 [ 1.341814, 0.588928, 0.680511],
 [-7.083096,-0.274566, 2.898297],
 [-6.611115,-2.31069 ,-0.369172],
 [-7.086005,-0.243683, 3.216341],
 [ 7.265455, 2.307653,-0.06498 ],
 [-0.15096 , 3.827126, 1.076143],
 [-0.156156,-0.647249, 3.126276],
 [ 3.597728,-0.992127, 0.709653],
 [ 4.770361,-1.247014,-0.059762],
 [-1.815752, 1.131711, 5.921302],
 [-2.08538 , 1.017265, 6.098296],
 [-0.165392, 3.622287, 4.000731],
 [ 3.879247,-0.463676, 0.850332],
 [-2.028501, 1.037417, 5.964456],
 [ 1.516073, 0.653049, 1.029051],
 [ 4.234701, 1.760189, 1.055328],
 [-1.514706,-5.101443,-2.685599],
 [ 4.75301 ,-1.287065,-0.062304],
 [-2.061929,-1.426926,-4.557476],
 [ 1.420308,-0.663501,-3.536643],
 [ 2.330299,-0.178722,-2.98209 ],
 [ 4.730356, 0.658048,-0.457802],
 [ 3.710239, 1.613805, 0.356878],
 [ 3.793846, 1.466068, 0.5181  ],
 [-1.257686, 1.707277, 0.474299],
 [-7.207509,-0.222496, 3.09764 ],
 [ 0.465966,-1.898985,-3.1055  ],
 [ 1.223886, 0.155324, 6.003733],
 [ 7.741732, 1.76969 ,-0.130497],
 [-5.474123, 0.615735, 3.593771],
 [ 1.727841, 2.419196,-0.196709],
 [-2.335366, 2.263615, 0.386979],
 [-7.342812,-0.281552, 2.840273],
 [-0.047579, 3.264767, 1.067008],
 [-0.172972, 3.583268, 1.358063],
 [ 6.644863, 1.867537, 0.019352],
 [-0.616232,-0.250417, 2.638496],
 [-4.679737,-0.644093, 1.419403],
 [-0.421215, 4.027503, 1.543592],
 [-0.94048 , 0.09822 , 2.12404 ],
 [ 2.027937, 1.659633, 2.390608],
 [ 4.09045 , 4.230832,-0.140731],
 [ 3.385685, 1.731764,-0.355999],
 [-2.65515 ,-3.50619 , 0.241591],
 [-1.493565,-0.5383  , 1.115907],
 [ 1.415354, 2.093036, 3.906231],
 [-0.952089, 0.064073, 2.221655],
 [-0.170448, 3.559053, 1.170547],
 [ 0.226727, 5.240397, 3.022323],
 [-3.875772, 0.360812,-0.634378],
 [-2.497702,-0.845207, 4.031433],
 [-0.053782, 3.356682, 1.351813],
 [-6.478572,-0.362397, 3.306809],
 [-6.115576,-0.272101, 3.56698 ],
 [-7.403992,-0.32206 , 2.78307 ],
 [-1.281144, 1.38266 , 3.179147],
 [-0.66992 , 1.742628,-2.032923],
 [-1.410223,-0.994635, 4.390992],
 [ 7.313786, 2.304612,-0.013689],
 [-0.972725,-0.349087, 2.488659],
 [-6.521286,-0.721555, 2.708089],
 [-1.969186,-1.570658, 1.056102],
 [ 6.986288, 2.543114,-0.039447],
 [-6.977   ,-0.325075, 3.11767 ],
 [ 0.278041, 5.186115, 3.018512],
 [-0.746717, 0.812664, 4.180458],
 [ 5.238224, 1.254722, 2.282355],
 [ 1.376737, 1.957246, 4.023571],
 [ 1.844222, 2.346687, 0.22589 ],
 [-3.929883, 0.235718, 0.347152],
 [-7.038455,-0.23933 , 3.154342],
 [ 0.907024, 0.345382, 1.8983  ],
 [-4.567808,-0.101236, 4.320556],
 [ 0.856988, 0.377592, 1.894014],
 [ 4.889923, 3.577436, 0.093397],
 [-4.191695, 1.017068, 1.092215],
 [-2.849545,-0.715003, 4.157906],
 [-3.880132,-0.408037, 3.376926],
 [-1.802911, 1.143961,-0.191205],
 [-6.714725,-0.372733, 3.169324],
 [-2.661175,-0.702591, 4.284832],
 [-1.501781, 0.482312, 3.201411],
 [-0.747475,-0.182247, 2.558211],
 [ 4.831894,-2.134907,-0.134384],
 [ 0.16932 , 0.159411, 4.794411],
 [ 0.243835, 0.64906 , 4.632472],
 [-0.783496,-0.146467, 2.514983],
 [-7.27763 ,-0.212467, 3.126587],
 [ 3.372339, 2.063342, 0.238917],
 [-3.853766, 1.524639, 3.532049],
 [-4.746207,-0.427153, 3.408006],
 [-5.412478, 0.40113 , 3.52368 ],
 [ 5.853561, 2.21099 , 0.052854],
 [ 0.212584, 0.545492, 4.687833],
 [ 0.205326, 5.227435, 3.037699],
 [ 0.795036, 0.147017, 1.150234],
 [-0.746293, 2.162484,-2.111754],
 [-6.033307,-0.508433, 2.71568 ],
 [-3.406199,-0.239117, 1.724386],
 [-0.391463, 4.754961, 0.594582],
 [-2.283929, 1.531947,-0.691711],
 [-7.01376 ,-0.30019 , 3.190466],
 [ 1.482405, 3.728181, 0.848854],
 [-2.266639,-0.449097, 1.22769 ],
 [ 0.032408, 3.723715, 4.105018],
 [-0.394761, 4.965933, 0.587574],
 [-5.175354,-0.08396 , 5.072372],
 [-2.30779 , 0.885745, 5.965951],
 [-0.91786 ,-0.319401, 2.400964],
 [-0.937862,-0.335003, 2.532107],
 [ 0.039588, 3.826035, 4.116516],
 [-0.116661, 3.771221, 0.263701],
 [ 0.44506 ,-1.531419,-3.946049],
 [-0.687028, 2.472013, 5.634879],
 [-1.262493, 0.358977, 1.53478 ],
 [ 1.088453, 0.655514, 2.803941],
 [-0.403607, 4.867917, 0.621239],
 [ 5.108871, 4.46267 , 1.120559],
 [-0.397448, 4.941563, 0.670857],
 [-2.209944,-1.791275, 3.106174],
 [-0.718679,-0.295667, 2.464926],
 [-0.265266, 4.588279, 0.532284],
 [-0.37636 , 4.675708, 0.571511],
 [ 1.037803, 0.604176, 2.876474],
 [ 0.266534, 1.115245, 3.840919],
 [-0.503585,-0.332033, 0.445692],
 [ 0.364333, 4.197972, 1.250019],
 [ 2.727307,-0.785131, 2.620567],
 [-1.194068, 0.339898, 1.53985 ],
 [-0.224379, 3.601704, 0.658905],
 [ 6.245548, 0.318384,-2.193123],
 [-1.731136, 1.863717, 1.393965],
 [ 5.909819, 0.297067, 0.480876],
 [-1.774478, 0.264426, 1.635451],
 [ 0.274804,-1.647003,-1.864202],
 [-2.579551, 0.182604, 2.306641],
 [-0.779119, 0.678263,-0.095026],
 [-2.550139, 0.146557, 3.578689],
 [-7.223334,-0.216999, 3.221064],
 [ 3.092986, 1.029728, 2.48626 ],
 [-6.81266 ,-0.361697, 3.113732],
 [ 1.316617, 0.889866, 3.702038],
 [ 2.240641, 1.49101 , 0.443417],
 [ 0.265364,-3.319367,-3.559296],
 [-0.524371, 2.153723,-1.68232 ],
 [ 3.157069, 0.665697, 0.213307],
 [ 6.965358, 2.537506,-0.048977],
 [-7.115482,-0.2407  , 3.209791],
 [-4.863597,-0.074556, 1.409544],
 [-0.862861, 3.319251, 1.754527],
 [-2.573152, 2.821602, 1.816963],
 [ 7.417482, 0.195205,-0.129769],
 [ 5.150023, 4.654889, 1.322623],
 [-2.130136,-3.14546 , 0.078877],
 [ 1.887326, 3.283706, 1.675636],
 [-5.006065,-0.177879, 1.436043],
 [-2.541449, 2.86181 , 1.801871],
 [-7.203328,-0.220068, 3.132952],
 [ 6.134447, 2.056416, 0.179538],
 [-6.796216, 0.742924, 1.621695],
 [ 0.30668 ,-0.833704,-1.577937],
 [-0.585192, 0.363173,-4.067247],
 [-2.090259,-3.144124, 0.144417],
 [ 1.283086,-1.740637, 2.492478],
 [ 0.375222, 0.620472,-1.844006],
 [ 1.292465,-1.742624, 2.493655],
 [-0.354957, 1.62531 ,-0.860838],
 [-0.269277, 1.626497,-0.912119],
 [-6.774922, 1.05259 , 1.809845],
 [ 6.086429,-0.81749 , 0.327297],
 [-7.038098,-0.245418, 2.767026],
 [-1.210814, 2.109768,-2.961505],
 [ 0.453359, 0.936414,-2.016029],
 [-3.431956,-2.109955, 0.481369],
 [ 0.028901, 3.727916, 4.228313],
 [-1.797828, 1.242588, 5.62914 ],
 [ 1.388646,-0.106763, 2.678384],
 [ 1.445416, 0.491068, 2.375012],
 [ 1.512404,-0.151537, 2.668422],
 [ 0.700279, 2.452108, 0.799173],
 [ 1.064652,-1.20482 , 4.782372],
 [ 1.667181,-0.597451, 2.512642],
 [ 6.144687, 0.289457,-1.204642],
 [ 1.15011 ,-1.070116, 4.896486],
 [ 1.522285, 0.036252, 2.576069],
 [ 1.421814, 0.434067, 2.474373],
 [ 1.545181, 2.454934, 1.032253],
 [ 1.362006,-0.78525 , 5.062991],
 [ 3.154342, 2.411486, 2.883776],
 [-0.74524 , 4.183048,-0.78569 ],
 [ 3.154854, 2.422091, 2.905552],
 [-1.06317 , 1.620821,-2.942332],
 [-1.236367, 2.158931,-2.986917],
 [-1.204187, 2.083964,-2.96956 ],
 [-3.269074,-3.258223,-0.437818],
 [ 0.459952, 0.507452,-1.847791],
 [ 7.023112, 2.481464, 0.026937],
 [-1.221011, 2.030994,-2.961999],
 [ 1.773044, 1.081959,-2.558576],
 [ 4.874521,-0.399512,-0.041563],
 [-5.012604,-1.318427, 0.499605],
 [-0.777358, 4.803115, 1.97393 ],
 [-4.140399,-4.92698 ,-0.674806],
 [ 0.099545,-5.203156,-0.588474],
 [ 0.757626, 1.381596,-0.70886 ],
 [ 2.455343, 1.420091,-1.657657],
 [-4.252906,-4.868007,-0.95733 ],
 [-0.989433, 1.643742,-2.838187],
 [ 0.733474,-0.166274,-1.893844],
 [ 0.937182,-2.28463 ,-2.11518 ],
 [ 0.773384,-0.496265,-1.669231],
 [-1.171732, 2.021265,-2.798338],
 [ 0.909479, 1.473665,-0.819341],
 [ 0.973595, 1.686886,-0.874735],
 [ 1.376241, 2.668182,-0.983583],
 [ 1.16851 , 2.455416,-0.880589],
 [ 1.196614, 1.847678,-1.08601 ],
 [ 1.117401, 2.374705,-0.910135],
 [ 1.512725, 4.310385, 0.985   ],
 [ 4.217722, 1.0564  ,-0.382184],
 [-3.797694, 2.621284, 1.949074],
 [-2.587427,-4.104614, 1.041397],
 [-2.596587,-4.280723, 0.837923],
 [ 0.497399,-5.249512, 0.065351],
 [ 3.61965 , 3.351149,-0.979114],
 [ 0.391915, 2.841776,-0.003654],
 [ 3.817245, 3.462164,-0.961119],
 [ 4.846224,-2.146922,-0.127635],
 [-3.697008,-1.107258, 3.342072],
 [ 7.82019 , 1.469369,-0.19193 ],
 [ 2.585963, 0.617483, 0.003897],
 [ 2.880639, 0.996589,-2.310801],
 [-1.230026, 2.144952,-2.975403],
 [-1.224783, 2.143004,-2.979073],
 [-1.259249, 2.176856,-3.004767],
 [-1.184971, 2.096326,-2.935807],
 [ 5.155914, 2.282241,-0.257571],
 [ 0.684499,-1.765798,-1.812618],
 [-0.87083 , 0.607559,-2.211371],
 [-1.275495, 2.085418,-2.972338],
 [-1.2238  , 2.171235,-2.984683],
 [-1.238228, 2.152986,-2.972139],
 [ 2.098764, 3.54788 , 1.611981],
 [ 1.030474,-5.118359,-0.854156],
 [ 0.776158, 0.779257,-1.780446],
 [ 0.647217, 0.671503,-1.770065],
 [ 5.230707,-0.07396 ,-4.041965],
 [ 0.676912, 0.637888,-1.785086],
 [ 0.224466,-5.199587,-0.613961],
 [ 1.562279, 1.238796,-2.91745 ],
 [ 1.875321, 1.059145,-2.400254],
 [ 1.450397,-1.357655,-3.620337],
 [ 2.685472,-3.679328,-0.279284],
 [ 2.676247,-3.677199,-0.289086],
 [ 2.635807,-3.64084 ,-0.303353],
 [ 2.646491,-3.640942,-0.277537],
 [ 2.630878,-3.608739,-0.260493],
 [ 1.999454,-1.255475,-5.300909],
 [ 3.078045,-2.66954 ,-4.210912],
 [-0.885762,-2.177114,-1.141028],
 [ 0.715491,-1.73337 ,-2.113946],
 [ 0.177993,-5.203186,-0.601186],
 [-4.263642,-4.894107,-0.925568],
 [ 2.659355,-3.674932,-0.297318],
 [ 2.621504,-3.619236,-0.280193],
 [ 7.34452 , 0.063212,-0.200109],
 [ 2.636364,-3.64855 ,-0.296311],
 [ 0.05599 ,-0.319665,-2.272076],
 [ 0.770666,-0.609952,-1.659802],
 [ 2.659234,-3.638412,-0.251081],
 [-7.231267,-0.377857, 2.755084],
 [-2.384002, 0.600363,-0.238327],
 [-2.097574, 4.109731,-0.090126],
 [-0.459472,-0.783039,-1.9927  ],
 [ 0.203871,-1.264998,-4.157552],
 [ 0.336635,-1.427052,-3.926709],
 [ 0.902537,-0.747246,-4.998716],
 [ 2.72283 , 0.451649,-2.691305],
 [ 5.769636, 0.172066,-1.470926],
 [ 2.213821,-1.193694,-1.214932],
 [-3.029919,-1.46923 ,-3.625905],
 [ 2.934434, 0.834836, 0.152019],
 [ 3.138789, 0.553846,-0.457431],
 [ 3.371708, 0.965914, 0.563543],
 [-0.59851 , 2.264663,-2.002526],
 [-3.259481,-1.636147,-1.879892],
 [-3.512719, 0.003393, 3.55114 ],
 [ 5.37791 , 2.566569, 0.005283],
 [-1.28593 , 2.044998, 6.334948],
 [-3.293199,-1.889474,-2.56466 ],
 [-4.314002,-2.559004,-1.962754],
 [-3.816334, 1.868645,-1.98928 ],
 [-2.526456,-0.245405, 2.322816],
 [-4.893223,-0.509847, 0.120354],
 [-4.201286,-1.329069,-0.205857],
 [-4.235806,-1.26061 ,-0.003538],
 [-4.188226,-1.27235 ,-0.122559],
 [-4.297186,-1.253837, 0.096791],
 [ 2.357404, 2.448165,-1.395666],
 [-3.457113,-0.675255,-0.377578],
 [-4.299901,-1.098619, 0.513648],
 [-4.32655 ,-1.043011, 0.602851],
 [-4.302127,-0.986777, 0.475188],
 [-4.325359,-0.94878 , 0.513293],
 [-4.315604,-0.962299, 0.505726],
 [-4.340487,-1.159384, 0.369522],
 [-4.233959,-0.954912,-0.176513],
 [-4.943699,-0.817349, 0.02303 ],
 [-4.349257,-1.584036,-0.710693],
 [-4.58451 ,-1.050059, 0.17218 ],
 [-7.261322,-0.316936, 2.742988],
 [-3.229855,-1.638484,-1.866294],
 [-7.231387,-0.296391, 2.834591],
 [-0.475132, 2.157566,-0.498931],
 [ 1.34701 , 2.150032,-0.359099],
 [ 1.530233, 5.276855,-0.594027],
 [ 1.5334  , 5.249062,-0.604909],
 [ 1.529244, 5.27161 ,-0.591891],
 [-1.244304, 2.161119,-3.005872],
 [-0.602344,-0.275366, 6.239905],
 [-0.598761,-0.263655, 6.224133],
 [-7.340126,-0.197857, 3.154919],
 [-1.118208,-0.095763, 2.072928],
 [-0.901527, 1.40047 , 0.386529],
 [-0.440042, 1.65909 ,-0.845825],
 [-1.052293, 1.226822,-0.125376],
 [ 6.422654,-0.181102, 0.809314],
 [ 7.378716, 0.070224,-0.190965],
 [-0.114639, 2.546083, 0.618112],
 [ 0.141285,-0.18135 , 5.759769],
 [ 3.630687,-2.437424,-4.238598],
 [-0.027911,-0.584277, 3.196069],
 [ 6.078146, 0.334169,-1.120152],
 [-2.832782,-2.287496, 0.351718],
 [-0.350669, 1.653476,-0.855429],
 [ 3.307127, 1.203761,-0.193907],
 [ 0.33403 , 2.018156,-0.992781],
 [ 0.339412, 2.164447,-1.129583],
 [ 3.859962, 0.115771,-0.253058],
 [-0.278499, 3.815516, 4.209252],
 [ 2.412046, 1.249234, 2.893243],
 [ 2.876827, 2.095401, 1.772966],
 [-4.543531, 1.386281,-0.560702],
 [-7.314081,-0.22161 , 2.91644 ],
 [ 0.184567, 3.816704, 0.453424],
 [-3.269336,-1.371967,-2.105892],
 [-4.168223,-2.0812  ,-2.115923],
 [-1.839216, 0.350501,-1.087481],
 [-1.077554,-1.703695,-0.895201],
 [-4.689338, 1.861297,-1.197381],
 [-1.418602, 3.510914,-0.515644],
 [ 2.641516, 0.436549, 0.814565],
 [-3.572992,-0.015955,-0.956517],
 [-7.324996,-0.222742, 2.966408],
 [-4.632056, 1.193129,-0.929639],
 [-4.509917, 0.980725, 2.357298],
 [-5.336684, 0.953421,-1.307881],
 [-0.963931, 3.919235, 0.783118],
 [-4.894832,-0.975585,-0.72359 ],
 [-3.570163,-0.055831,-0.975082],
 [ 4.040021, 1.645449,-0.39486 ],
 [-3.547419,-0.096056,-1.067166],
 [-4.049932,-1.072241,-0.481401],
 [-3.359416,-1.84062 ,-0.617808],
 [ 6.456759, 0.243806,-0.885854],
 [ 7.002697, 0.186766,-0.225508],
 [-4.395616,-0.556387, 0.029668],
 [-1.925898, 0.460975, 0.808895],
 [-3.572017, 0.013434,-1.118747],
 [-4.44004 , 0.086472, 2.696292],
 [-4.24605 ,-1.032902,-0.380333],
 [-3.648996,-1.109142,-0.034733],
 [-2.955935,-0.028506, 0.956943],
 [-3.207204,-1.59388 ,-4.122148],
 [ 3.810156, 1.14982 , 1.851288],
 [ 5.596859,-0.935462, 1.072853],
 [-3.60815 ,-0.288654, 2.053465],
 [-4.303844,-0.395608,-1.056571],
 [-4.211944, 2.388486, 1.183659],
 [-5.348141, 1.766213, 0.519867],
 [-4.208563, 2.441453, 1.286433],
 [-4.276788,-0.731037,-1.05584 ],
 [-4.21385 ,-0.247762,-1.048074],
 [-4.278803,-0.477069,-0.949826],
 [-4.094548,-0.225113,-0.96714 ],
 [-4.268941,-0.447049,-0.912147],
 [-4.255564,-0.277618,-0.970731],
 [-4.210636,-0.263867,-0.827115],
 [-4.348609,-0.447228,-0.879774],
 [-4.323248,-0.426212,-0.838888],
 [-4.334999,-0.546155,-0.943384],
 [-4.268942,-0.745269,-1.035543],
 [-4.30532 ,-0.566029,-0.961832],
 [-4.266254,-0.707643,-1.093822],
 [-4.161281, 2.359874, 1.381135],
 [-5.477329, 1.664299, 0.429575],
 [-4.165988,-0.320446,-0.990813],
 [-4.200387,-0.134581,-0.954363],
 [-5.547061, 1.639884, 0.378776],
 [-4.173553, 2.447629, 1.364063],
 [-5.777856, 1.697027, 0.269232],
 [-4.160694, 2.394769, 1.436639],
 [-3.950685, 1.404755, 2.080612],
 [-4.819983,-0.110807, 4.507032],
 [-3.313466,-1.461652,-1.904399],
 [-4.307621,-0.220292,-1.025338],
 [-4.245078,-0.285815,-1.033813],
 [-4.352619,-0.379655,-0.874241],
 [-3.968726, 1.405869, 2.095942],
 [-4.813971,-0.144038, 4.438165],
 [-5.040835,-0.747387, 0.046021],
 [-4.304022,-0.645338,-1.082455],
 [-3.365231,-1.631799,-3.857011],
 [-2.902882,-1.728218,-4.539343],
 [-4.564692,-3.385898,-0.254826],
 [-3.460277,-1.985342,-0.507609],
 [-4.501257,-1.033265, 0.013362],
 [-4.96425 ,-0.892438,-0.384854],
 [-3.186658,-1.671807,-1.844757],
 [-2.852062,-1.720664,-4.609108],
 [ 0.457217, 1.714371,-1.241895],
 [-0.09496 , 1.705658,-1.037288],
 [-0.224799, 1.699996,-0.864275],
 [ 5.079846,-0.035157,-2.380602],
 [-2.671592,-1.934229,-4.87841 ],
 [ 3.613906,-1.952868,-4.137623],
 [-2.712016,-1.817377,-3.792458],
 [ 2.33752 , 0.880994,-1.246653],
 [ 7.023712, 2.486587, 0.024708],
 [-1.861344, 3.219089, 0.893405],
 [-6.622872, 0.590751, 1.728554],
 [-2.622604, 1.812479,-2.227394],
 [-0.256773, 1.183212,-1.299289],
 [ 1.844296, 0.782299, 0.852924],
 [ 0.211226,-0.732814,-0.169789],
 [-2.60019 ,-0.33165 ,-0.127276],
 [-3.242959,-1.743283,-1.162941],
 [ 6.539341, 1.773252, 0.363124],
 [-5.042445,-0.767473, 1.25784 ],
 [-3.540833,-0.217662, 2.224715],
 [-1.655628, 0.111647, 3.570348],
 [-3.17068 ,-1.959306,-1.063335],
 [-3.252293,-4.108056,-1.240895],
 [-1.071138, 4.311174, 3.725015],
 [ 0.974245, 2.599844,-0.811554],
 [ 3.723039, 0.530742,-0.703553],
 [-0.378675, 1.649951,-0.830778],
 [-1.045567, 1.183847,-0.10525 ],
 [-1.122956, 1.141275,-0.003313],
 [-0.585846, 1.529143,-0.681755],
 [ 3.826142, 0.732069,-0.509787],
 [-1.355486,-0.25383 , 0.528431],
 [ 0.282707,-0.801528,-5.017715],
 [ 7.777038, 1.580088,-0.158488],
 [-7.578521,-1.420955, 0.433352],
 [-1.741277,-0.232672, 0.349004],
 [-1.321798,-0.272243, 0.510242],
 [-7.594033,-1.417745, 0.455333],
 [-3.107688,-1.984835,-0.902323],
 [-1.710159,-0.589917,-0.170418],
 [-3.151148,-1.704702,-1.853098],
 [-0.40491 ,-0.926064,-0.15026 ],
 [-0.37364 ,-3.672928,-2.488934],
 [ 7.75254 , 1.736761,-0.137365],
 [ 0.747342, 0.646565,-1.775241],
 [ 0.643343, 5.918811, 0.528201],
 [ 6.60058 ,-0.440795,-0.801181],
 [-3.764078, 2.127086, 0.921628],
 [ 3.408528,-1.198824, 0.535889],
 [ 2.289149, 0.869228,-2.390049],
 [-1.848672,-0.234427, 0.145511],
 [-0.201605, 3.718707, 0.468111],
 [-0.049731, 3.674483, 0.686356],
 [ 0.173745, 3.066561, 0.313045],
 [ 0.585226, 3.984066, 0.541382],
 [ 0.291624, 0.912138,-3.807989],
 [ 0.078213, 3.870228, 0.566831],
 [-1.090732, 2.128101,-2.793258],
 [ 2.38655 , 1.601761, 0.683496],
 [ 1.452034, 2.513567, 1.71704 ],
 [-0.1061  , 3.776602, 1.008231],
 [ 0.633154, 1.539421,-0.812204],
 [ 0.838782, 1.859348,-0.785137],
 [ 0.673008, 1.851557,-0.672727],
 [ 0.538013, 1.752312,-0.925921],
 [ 1.982629, 1.926885,-0.193619],
 [ 0.590324, 2.252468,-0.616467],
 [ 0.171309, 2.289493,-0.108708],
 [ 0.461976, 2.051823,-0.1887  ],
 [ 0.499331, 2.643928,-0.783207],
 [ 0.873856, 0.591161,-1.747148],
 [ 0.710301, 2.607791,-0.558822],
 [ 0.545064, 2.629741,-0.695567],
 [ 0.85359 , 1.584619,-1.679455],
 [ 0.856467, 1.384623,-1.283373],
 [ 1.035488, 0.912815,-1.587692],
 [ 4.146737, 1.219138, 0.458689],
 [ 5.038453, 0.325783, 0.44598 ],
 [ 1.074068, 2.475746,-0.607615],
 [ 2.717097,-0.380412,-0.942334],
 [ 1.995537, 1.293221, 0.390095],
 [-0.056046, 3.735169, 0.670354],
 [ 0.565973, 4.008561, 0.557626],
 [ 0.28634 , 0.914209,-3.833905],
 [ 0.088679, 3.861715, 0.56621 ],
 [-1.079196, 2.124042,-2.771418],
 [ 2.318601, 2.820888, 0.705783],
 [ 2.498652, 0.253387,-0.356765],
 [ 0.578263, 1.501044,-1.050807],
 [ 0.657222, 1.90911 ,-0.711021],
 [ 0.507434, 1.835956,-0.89199 ],
 [ 1.278514, 2.124139,-0.300553],
 [ 0.620206, 2.288149,-0.709118],
 [ 0.107459, 2.289109,-0.106374],
 [ 0.773222, 2.342513,-0.636839],
 [ 0.629331, 2.43547 ,-0.47052 ],
 [ 0.495061, 2.645578,-0.782996],
 [ 0.870708, 0.600831,-1.741379],
 [ 0.677974, 2.497029,-0.644992],
 [ 0.510095, 2.63049 ,-0.722014],
 [ 0.846746, 1.563377,-1.686269],
 [ 0.887341, 1.305355,-1.298252],
 [ 1.008041, 1.013031,-1.634807],
 [-2.37555 ,-0.506671,-0.067154],
 [-0.675831, 0.226347, 0.496112],
 [ 0.440985,-1.873027,-1.260524],
 [-1.11102 ,-3.400797, 0.020031],
 [ 2.82076 , 1.647216, 1.798969],
 [-7.183273,-0.228728, 3.120457],
 [ 5.331207, 0.279743,-2.325684],
 [-2.46774 ,-0.187686,-1.523905],
 [-7.488503,-1.383906, 0.386178],
 [-0.941856,-0.085326,-0.783939],
 [-0.739067,-2.754446,-1.610445],
 [-0.07281 ,-0.44323 , 3.15888 ],
 [-0.659615, 0.797029, 2.913124],
 [-2.488985, 0.207682, 0.766228],
 [-0.780248, 0.198519, 0.472658],
 [-1.088061,-3.427747,-0.005428],
 [-4.215677,-0.884046, 1.218652],
 [-2.392418, 0.2169  , 3.888318],
 [-1.017436, 1.289172, 0.402543],
 [-1.000007, 1.370874, 0.334562],
 [ 0.17871 ,-1.970464,-1.876083],
 [-1.135665, 1.242985, 0.483859],
 [-1.320124,-0.269208, 0.513886],
 [ 1.161725, 2.390085, 2.446189],
 [-1.035823, 1.237411, 0.466799],
 [ 3.064304, 0.588737,-0.023608],
 [ 2.313723, 1.37773 ,-0.073723],
 [ 1.817214, 0.244642, 1.29909 ],
 [-0.081177, 2.592347, 0.641759],
 [-2.438973, 1.651157, 2.928223],
 [ 4.332549, 1.217305, 1.338952],
 [-6.125896, 2.160628, 1.706671],
 [-3.969024,-0.355663, 0.57532 ],
 [ 5.529387, 0.604518, 1.231915],
 [ 0.003187, 3.402745, 2.979417],
 [-1.61651 , 0.996248, 3.251871],
 [-4.013119, 0.741989, 6.337998],
 [-3.796331,-4.713627,-0.168072],
 [-0.251184, 1.459276,-0.870638],
 [-2.438736,-0.645093,-0.272781],
 [-1.835081, 0.929425, 0.032085],
 [-7.117554,-0.288396, 2.725233],
 [-7.312652,-0.201598, 3.169827],
 [-1.551752, 2.08552 , 3.77541 ],
 [ 0.111662,-1.763301,-2.769769],
 [-0.083874,-1.440819,-3.494614],
 [ 0.759181, 1.017086,-1.312062],
 [ 4.808242, 0.159593, 3.004654],
 [-1.480551,-1.359419,-0.652729],
 [ 0.879911,-0.793961,-5.009585],
 [-0.152747,-1.60868 ,-3.477387],
 [ 0.705273,-0.584876,-5.25666 ],
 [ 2.401895, 0.384984,-0.418856],
 [ 0.263255, 2.168336, 0.264155],
 [ 3.785095, 0.010859,-0.256718],
 [ 2.679615,-0.343218,-0.741551],
 [ 4.012155, 0.42576 ,-0.304776],
 [ 2.088367, 1.16899 ,-0.745723],
 [ 0.280933, 2.134459, 0.280889],
 [ 2.513771,-0.983954,-1.023351],
 [-2.108017,-0.083529, 3.125429],
 [-1.607583, 0.029382, 0.000338],
 [-1.859813, 0.362281,-1.194215],
 [-1.371315,-1.610127,-0.630913],
 [-2.944113,-1.713089,-4.493594],
 [ 0.748819,-1.878333,-2.705266],
 [-2.698714,-1.941143,-4.913108],
 [ 0.667738, 2.737602,-0.179623],
 [-0.287363,-2.956455,-5.69335 ],
 [ 3.515931, 0.53826 ,-0.136593],
 [-0.472203,-0.144708,-2.38359 ],
 [ 4.12285 , 1.950106,-0.539159],
 [ 2.526662, 2.535343, 1.924542],
 [ 3.574181, 2.238622, 0.432177],
 [ 3.09425 , 2.246755, 0.49574 ],
 [ 3.061093, 1.631959, 1.30079 ],
 [ 3.188487, 2.639526, 0.993132],
 [ 2.766883, 2.695613, 1.31652 ],
 [ 3.660862, 2.162827,-0.033864],
 [ 3.725108, 2.256117, 0.14456 ],
 [ 1.497635, 2.347442, 2.168289],
 [ 5.030226, 0.767919,-0.618162],
 [ 4.302312, 1.899587,-0.69923 ],
 [ 2.52872 , 2.466258, 3.814571],
 [ 3.341023, 2.445724, 0.313595],
 [ 2.56302 , 2.428581, 3.84767 ],
 [ 4.016651, 1.222539,-0.127418],
 [ 5.005616, 2.323699,-0.134988],
 [ 2.390979, 2.001048, 1.010647],
 [ 2.995174, 2.253119, 0.634551],
 [ 2.645468,-0.085494,-0.22306 ],
 [ 2.663626, 2.517197, 1.916779],
 [ 3.382246, 2.490487, 0.432619],
 [ 3.067487, 1.557173, 1.153716],
 [ 2.688931, 2.653236, 1.660915],
 [ 3.477504, 2.353855, 0.62028 ],
 [ 2.555382, 2.435187, 3.834688],
 [ 3.655799, 2.287808, 0.095217],
 [ 2.833811, 2.314212, 0.805155],
 [ 2.750665, 2.401854, 2.247087],
 [ 2.810158, 0.757441, 0.189672],
 [-5.230464, 0.488775, 3.064178],
 [ 2.669815, 2.435   , 3.850759],
 [ 5.684273, 0.261465,-0.959604],
 [ 2.588228, 2.000684, 1.112735],
 [ 3.011819, 2.398258, 0.10296 ],
 [ 1.412029, 2.835149,-0.896719],
 [ 1.454649, 2.692558,-0.863711],
 [ 1.589727, 2.963437,-1.081677],
 [ 1.567004, 2.944804,-1.015548],
 [ 1.492476, 2.81573 ,-0.932982],
 [ 1.343201, 2.244982,-0.445174],
 [ 1.307573, 2.537206,-0.748395],
 [ 4.409492, 0.401807, 0.663923],
 [-3.202698,-1.874177, 1.909316],
 [ 6.405729, 3.158925, 0.112498],
 [-3.109448,-2.116927, 1.719601],
 [-3.63769 ,-1.911373, 1.215558],
 [-3.446697,-2.077394,-1.102162],
 [-5.741583, 2.159539, 0.314542],
 [-5.72988 , 2.140235, 0.304421],
 [-1.35318 , 0.157227, 3.962962],
 [ 2.411985, 0.675628,-2.044961],
 [-6.650509,-1.359598, 0.538697],
 [ 3.301526, 0.401155,-0.6516  ],
 [-0.173598,-2.275534, 0.071222],
 [-1.041204, 0.157329,-0.1019  ],
 [-1.241586,-1.081156, 0.358246],
 [ 5.421414, 0.439382,-0.601493],
 [-0.833569, 0.767512,-0.21753 ],
 [-0.037924,-0.103685,-0.52895 ],
 [-0.325513, 0.142851,-0.498746],
 [-0.306847, 0.161277,-0.480156],
 [-4.591961,-0.108209, 2.433798],
 [-4.409132, 0.167414, 2.656556],
 [ 6.767394, 2.187591, 0.054283],
 [-3.961844, 3.035716, 0.093894],
 [-0.747684, 1.60536 ,-2.130065],
 [ 0.483024, 1.718801,-1.178456],
 [-5.180285,-0.385478, 0.302905],
 [-5.362238,-0.214786, 0.382323],
 [-5.501926, 0.169167, 3.671264],
 [-0.967333, 3.247613, 0.513251],
 [-5.278941,-0.066631, 0.492703],
 [-3.982385, 0.505571, 1.596536],
 [-6.666347,-0.103596, 2.490921],
 [-7.109905, 0.512432, 0.020205],
 [-5.708593, 0.876632, 3.374221],
 [-5.146304, 0.328055, 2.825813],
 [-1.255081, 2.179911,-3.018518],
 [-4.753547,-1.452121, 2.499372],
 [-4.741407,-1.482598, 2.448891],
 [-4.813453,-0.123725, 1.269674],
 [-1.245162, 2.136678,-2.984652],
 [-0.198062, 1.600949,-0.813052],
 [-1.243811, 2.160953,-2.983289],
 [ 0.275836, 1.602244,-1.09841 ],
 [-0.045876, 1.698647,-0.109021],
 [-4.780373, 1.022493, 3.231256],
 [ 6.140087,-0.140265,-0.709049],
 [-5.033213, 1.086584, 3.306006],
 [ 6.330783, 3.256868, 0.160324],
 [-0.386546, 0.829088,-2.604458],
 [-0.618648, 2.282505, 0.566595],
 [ 5.202465, 0.539124,-0.234997],
 [ 1.491654,-4.211085,-2.231203],
 [ 2.54191 , 1.58264 , 1.430844],
 [-5.834678,-0.917911, 0.290081],
 [ 3.183196, 0.76234 , 0.04803 ],
 [ 4.767644,-0.290313, 1.469956],
 [-4.279661,-4.974309,-0.596634],
 [-4.231771,-4.930383,-0.63109 ],
 [ 0.772986, 5.533271,-0.459935],
 [-0.090773, 1.089174,-0.023039],
 [ 3.004653,-1.171675,-0.055856],
 [ 2.578246,-3.50485 ,-0.241208],
 [ 2.506682,-3.48545 ,-0.29602 ],
 [ 2.37558 ,-3.241874,-0.325315],
 [ 5.478617, 0.434286, 0.589836],
 [ 2.593515,-3.53524 ,-0.246847],
 [ 2.593421,-3.535158,-0.246941],
 [ 2.7848  ,-3.265754,-1.668623],
 [ 0.877794,-1.956494,-1.867608],
 [-0.095242, 1.071764,-0.00266 ],
 [ 5.216901, 4.740441,-0.252601],
 [-1.189722, 2.087195,-2.892012],
 [ 4.144165, 2.740319, 1.190302],
 [-1.496233, 1.872441,-2.976701],
 [ 0.983908, 4.014272, 0.489779],
 [ 4.076647,-1.707451, 0.406388],
 [ 3.444393,-1.193398,-0.411169],
 [ 2.613406, 2.985242,-0.645913],
 [ 0.17154 , 2.973893,-2.101274],
 [ 3.26722 , 2.665701, 0.516284],
 [ 1.238107, 4.190885, 0.578103],
 [-2.230326, 1.811541, 0.214501],
 [ 4.147228, 2.765964, 1.091695],
 [ 0.867983, 0.673152,-1.776884],
 [-1.407435, 1.977944,-2.971567],
 [-1.153707, 2.163185,-2.8509  ],
 [-1.187217, 2.005703,-2.897241],
 [-1.244908, 2.125979,-2.959718],
 [-1.261393, 2.090829,-2.908257],
 [-1.431078, 1.968016,-2.964546],
 [ 0.150756, 3.010395,-2.133764],
 [ 2.087136, 2.859023, 0.317347],
 [ 0.111255,-1.091848, 5.965155],
 [ 1.259775, 4.16338 , 0.568129],
 [ 2.283482,-1.018152,-0.981326],
 [-4.459063, 0.337047, 2.647722],
 [ 0.146364, 2.998465,-2.107969],
 [ 0.104298,-1.088023, 5.974878],
 [ 1.899172, 0.77088 ,-0.606395],
 [-1.251052, 2.105123,-2.88805 ],
 [ 3.22089 ,-1.446466, 0.089009],
 [-1.227356, 2.084725,-2.906851],
 [ 6.410711,-0.342258, 0.797634],
 [-1.436322, 2.263672,-2.796737],
 [ 1.377258, 0.164386,-0.160102],
 [ 0.914167, 5.25708 ,-0.410576],
 [ 0.530544, 1.997487,-0.709088],
 [ 0.748112, 2.86751 ,-0.774354],
 [ 1.788519, 1.876116, 3.002585],
 [ 0.827182, 2.981882,-0.819969],
 [ 0.56463 , 1.876588,-1.246868],
 [ 0.860909, 2.894667,-1.063908],
 [ 0.79275 , 3.112935,-0.821427],
 [ 0.88581 , 5.230534,-0.425134],
 [ 4.201809, 2.646793,-0.064803],
 [ 0.762631, 2.903519,-0.748591],
 [ 0.531161, 1.843165,-1.302464],
 [ 0.877075, 5.266387,-0.428677],
 [ 1.948576, 2.944024, 0.254628],
 [ 0.77137 , 3.115236,-0.766333],
 [ 3.141965, 2.841519, 0.537877],
 [ 2.385701, 0.684952,-1.688287],
 [ 3.936782,-1.121157,-2.001975],
 [ 2.373085,-0.77978 ,-0.808278],
 [ 0.891816, 5.248192,-0.418241],
 [ 4.180284, 2.659962, 0.014971],
 [ 5.603171, 1.702077, 1.344462],
 [ 0.626242, 3.068441,-0.312398],
 [ 0.540759, 1.824947,-1.3012  ],
 [ 1.88728 , 3.165169,-0.142072],
 [ 0.803398, 2.783834,-0.719693],
 [ 1.376669, 2.35083 , 2.630717],
 [ 5.667201, 0.308991,-0.101583],
 [ 2.355597, 0.886132,-1.831156],
 [ 0.538942, 1.856457,-1.276223],
 [ 1.921969, 3.183288,-0.248887],
 [ 3.175253, 2.87161 , 0.553103],
 [ 2.489854,-1.126452,-1.686821],
 [ 0.548629, 1.837194,-1.301028],
 [ 0.899191, 2.850269,-1.130534],
 [ 0.975755,-0.294384,-5.257666],
 [ 1.228588,-0.52133 ,-4.785956],
 [ 4.441771, 1.200686,-0.708973],
 [-0.356901,-4.578805,-1.728507],
 [ 6.459812, 3.19502 , 0.129789],
 [-0.739899, 0.297097,-3.379757],
 [-0.357198,-4.576555,-1.727996],
 [ 1.537307, 1.147314,-2.864394],
 [ 0.292873,-0.436229,-1.328339],
 [ 1.108546, 0.72605 ,-0.286514],
 [-0.443312, 2.186195,-0.30253 ],
 [-0.453968, 4.317037, 0.581471],
 [ 2.017652, 0.046943,-4.722799],
 [ 0.377056, 4.361005, 0.464756],
 [-0.002827, 4.425395, 0.458278],
 [ 0.357035, 3.900702, 0.404492],
 [ 0.348065, 4.260878, 0.54541 ],
 [-0.401773, 5.13394 , 0.688243],
 [-0.380405,-0.558807,-0.139168],
 [-1.344025,-1.752003,-2.659976],
 [-2.982443,-1.697534,-4.616065],
 [ 5.885166, 0.34759 ,-0.762703],
 [ 6.521011, 0.407916,-3.146172],
 [ 5.705814, 0.821435, 0.535818],
 [-1.649344,-0.213376, 0.35712 ],
 [-2.155912,-0.227968, 1.01568 ],
 [-1.363767,-1.179101,-0.185496],
 [ 0.124327, 0.925986,-3.37837 ],
 [ 0.417302, 1.63933 , 1.299715],
 [ 0.812796, 1.007836, 1.34244 ],
 [ 0.337109, 2.777977, 0.237825],
 [-1.853865,-1.373625,-0.756508],
 [-1.885286,-1.225408,-0.713315],
 [ 3.169345, 0.089424, 2.563896],
 [-2.268845,-0.915654,-0.544441],
 [-0.422951, 3.207566, 0.005434],
 [-0.204422, 3.422477, 0.172799],
 [-0.793425,-0.556792,-2.163418],
 [-0.846671, 0.66413 ,-3.054728],
 [-1.190484, 0.151881,-3.455002],
 [-0.231013, 1.055866,-0.967043],
 [-1.725868,-0.848653,-3.963347],
 [ 0.660995, 3.128059,-0.823164],
 [-0.147821, 4.909775, 0.630485],
 [-1.911792,-0.510065,-3.622691],
 [-0.346678,-3.004888,-5.415747],
 [-1.85425 ,-0.8273  ,-3.743557],
 [-2.157501,-0.041572,-4.304414],
 [-1.843731,-1.021957,-4.117109],
 [ 3.897431,-3.091608,-3.695268],
 [-0.699535,-2.643042,-4.345088],
 [ 0.049268, 2.266474, 0.069099],
 [-1.932764,-0.855667,-4.167914],
 [-2.765486,-1.616636,-4.378402],
 [-1.266589, 0.919293, 1.466826],
 [-2.45242 ,-1.243791,-3.917359],
 [-0.272272, 3.248739, 0.130261],
 [ 2.700214,-0.745439, 2.556519],
 [-1.003697,-0.653135,-2.639774],
 [-0.953136, 0.556062,-3.185063],
 [-0.19205 , 0.94115 ,-1.011527],
 [ 0.662605, 3.123049,-0.820551],
 [-0.078975, 4.825662, 0.620794],
 [ 5.323637, 0.226492, 1.927094],
 [-1.942304,-0.57985 ,-3.693046],
 [-2.342986,-2.897726,-3.525336],
 [-2.142037,-0.09033 ,-4.279903],
 [-1.890035,-1.01659 ,-4.121899],
 [ 3.894882,-3.030998,-3.665485],
 [ 5.44308 , 0.325337, 1.572882],
 [-0.916739,-2.249065,-4.195012],
 [-1.937125,-0.867237,-4.159481],
 [-0.405518, 2.972837,-0.103818],
 [-2.658813,-1.387058,-4.053105],
 [-2.530822,-1.300144,-4.21716 ],
 [-1.350231,-0.757958,-3.094807],
 [-2.470124,-1.250935,-4.142838],
 [-1.764633,-0.659478,-3.784186],
 [-1.91242 ,-0.785933,-3.87602 ],
 [-1.995706,-0.679834,-3.817875],
 [-2.515229,-2.889246,-3.632462],
 [-2.142288,-0.186572,-4.246545],
 [-2.006025,-1.152216,-4.157225],
 [ 4.074516,-2.929642,-4.249044],
 [-1.919464,-1.316163,-4.120426],
 [-1.942642,-0.855224,-4.167275],
 [-0.39072 , 2.890767,-0.102142],
 [ 2.776092, 0.118766,-1.430588],
 [ 2.988756, 0.171082,-0.38729 ],
 [-2.648982,-3.363525,-3.553483],
 [ 2.715629,-0.99052 ,-1.105156],
 [-0.357858,-4.575049,-1.724459],
 [ 2.817974,-0.280197,-2.103254],
 [ 4.338347,-1.508667,-0.826431],
 [-1.949996, 1.414852, 2.335909],
 [-0.355669,-4.572694,-1.728561],
 [ 3.074848,-2.531047,-3.303772],
 [-2.882736,-1.730872,-4.605069],
 [ 2.06927 , 0.426981,-0.300643],
 [-0.268739, 0.104887,-2.58692 ],
 [-1.570781,-1.262153,-2.109168],
 [-7.320653,-0.487129, 2.681473],
 [ 5.517194,-0.17048 , 0.649909],
 [-0.238194, 2.442233, 2.853321],
 [-0.700239, 4.134658, 0.852659],
 [-1.823655,-1.309404,-2.254136],
 [-2.707122, 2.490548,-0.913643],
 [-1.925899,-3.018905,-0.756652],
 [ 3.478318, 2.380123, 0.023205],
 [ 3.617638, 1.601136,-0.548763],
 [-0.357929,-4.558207,-1.719695],
 [-7.210111,-0.24576 , 2.951822],
 [-0.869981, 0.804057,-2.620694],
 [-0.351302,-4.570771,-1.729772],
 [ 7.227626, 0.121331, 0.963779],
 [ 5.41793 , 0.409987, 1.619577],
 [-1.20806 , 2.933064,-0.699561],
 [-4.479941,-3.816289, 0.763644],
 [-3.986544, 0.673272, 6.368957],
 [ 1.666564,-1.490692,-4.834754],
 [-0.337756,-2.476294,-5.379606],
 [-3.503733, 0.85632 ,-2.245166],
 [-1.552191,-2.076367, 0.810675],
 [-2.681395,-0.749198, 2.067445],
 [-0.661047,-3.723395, 0.902865],
 [ 3.154702, 0.392628,-0.425917],
 [ 6.080331,-0.557186, 1.228094],
 [ 1.396988, 2.370994, 2.430627],
 [-2.212182,-1.445299,-0.582771],
 [ 3.965549, 0.632168,-3.204472],
 [ 0.267478,-0.780377,-4.893668],
 [-0.623994, 1.945671,-2.082559],
 [-4.266142, 2.017201, 3.258209],
 [-7.164322,-0.1571  , 2.810369],
 [-2.810387,-0.73742 , 0.485561],
 [-1.934712,-0.390618, 0.474069],
 [-2.388281,-1.154883, 3.133005],
 [ 3.722537, 1.370816, 0.02168 ],
 [-0.742603,-0.369393, 0.665684],
 [ 1.269666, 4.378309,-0.584446],
 [ 1.297165, 4.399821,-0.564002],
 [ 0.435942,-3.415123,-1.866046],
 [ 1.217976, 2.685336, 2.355997],
 [-0.365459, 0.644098, 1.675839],
 [ 0.376564, 1.87489 , 3.649963],
 [ 0.337699, 0.45077 , 5.971383],
 [ 1.415368,-2.431591, 0.093941],
 [ 1.241215, 1.527882, 0.548541],
 [-2.450821, 0.612273,-0.202985],
 [-7.02884 ,-0.289431, 3.191721],
 [-6.63246 , 1.225046, 1.836708],
 [-5.543633,-0.147492,-0.723476],
 [-1.04532 , 2.521047, 3.912698],
 [-1.476199, 1.911913, 3.312734],
 [-1.081554, 2.355444, 3.795331],
 [-2.887629,-0.204634, 1.857367],
 [ 5.13694 , 0.4562  ,-0.383626],
 [-1.338622, 0.526199, 3.247098],
 [-0.025677,-0.372928, 3.333089],
 [-1.232819,-1.672126, 3.303256],
 [-2.806836,-0.520588, 3.570845],
 [-0.769866, 4.464012, 3.615722],
 [-0.742564, 2.747479, 4.070452],
 [ 6.218536,-0.66348 , 1.174399],
 [ 1.95421 ,-0.003258,-4.751156],
 [ 6.541566, 0.394762,-3.10732 ],
 [-2.834795,-0.675954, 4.120231],
 [ 0.135392,-3.438809,-1.055659],
 [-0.493521, 4.160172,-0.804542],
 [ 0.020987, 3.402475, 2.472121],
 [ 0.451618, 4.198383, 0.532103],
 [ 1.45485 ,-4.079308, 0.839422],
 [ 1.828422,-0.455985, 1.523134],
 [ 0.609947,-5.138821,-2.19255 ],
 [-1.041828,-0.086377,-0.925314],
 [ 5.251215,-0.186942,-0.656972],
 [ 4.564238, 1.673297,-0.024023],
 [ 6.418228,-0.179538, 0.803727],
 [ 3.971994, 0.709304,-0.376617],
 [ 0.634345, 2.770656, 4.275498],
 [-0.455965, 0.788553,-0.823042],
 [ 0.367551,-0.192211, 1.613354],
 [ 1.710114,-1.578749, 1.968821],
 [-0.110025, 0.588319, 1.438421],
 [-1.646481, 2.291311, 3.647586],
 [ 6.529852, 1.472534, 0.949152],
 [ 0.439789,-0.626975, 3.232276],
 [ 2.767531,-2.495551,-0.44874 ],
 [ 1.33178 , 4.005278, 3.911613],
 [-4.124485,-4.935705,-0.691824],
 [ 4.424961, 1.287809,-0.721889],
 [-6.639112,-2.303767,-0.377852],
 [-4.308138, 1.906132, 3.272967],
 [-1.729767, 1.669899, 3.174551],
 [ 1.552163, 0.018427, 1.414707],
 [ 4.185377, 0.432593, 0.307616],
 [ 0.742354,-0.44164 ,-5.339993],
 [ 2.780613,-1.446678, 1.420739],
 [-4.569086, 0.7353  , 1.66193 ],
 [-0.089969,-0.339263, 3.230332],
 [ 6.186656, 0.293853,-1.475689],
 [ 2.150641,-0.413837,-1.221762],
 [ 2.166603,-0.38382 ,-1.266349],
 [ 2.120158,-0.153439,-1.71234 ],
 [ 7.852328, 1.411517,-0.201069],
 [-0.819632, 2.729332, 4.092454],
 [ 1.588629, 3.511184, 0.662162],
 [ 1.860765, 2.95268 , 3.251742],
 [-5.573744,-0.114749,-0.773067],
 [ 3.523941, 4.01259 ,-0.290786],
 [-3.165006,-1.426213,-1.866749],
 [-4.683169, 2.087381, 0.079005],
 [-3.751265,-0.651499, 3.439508],
 [ 3.823475, 0.476501,-1.83836 ],
 [ 6.24636 ,-0.387365,-0.431929],
 [-4.316175, 0.657059,-2.454746],
 [-0.175049,-1.788092, 0.119268],
 [-0.746033, 0.460461,-3.457392],
 [-0.679219, 2.327446,-1.947271],
 [ 2.378088, 0.596884, 0.070495],
 [ 0.104066,-0.555101, 3.169228],
 [ 6.839137, 0.207705,-1.91065 ],
 [-3.387096, 0.152843, 0.500956],
 [ 5.880123, 1.795209,-0.697524],
 [ 5.617058,-1.003143, 1.020098],
 [ 0.71241 ,-0.462599,-5.340833],
 [-6.700798, 0.810101, 1.804974],
 [ 3.383473, 2.322   ,-0.320675],
 [ 1.510894,-3.254362,-1.492316],
 [-0.305958,-2.955351,-5.509854],
 [ 6.921028, 0.198047,-1.903702],
 [ 1.128754, 5.770185, 1.136909],
 [ 1.129273, 5.772791, 1.128145],
 [ 3.610866,-0.681526,-0.136398],
 [ 4.444704,-0.253774,-0.439259],
 [ 6.916181, 0.198171,-1.904532],
 [ 0.590158, 0.613291, 3.400564],
 [ 2.421621, 2.726403, 2.523232],
 [ 1.902727,-2.272511, 1.579948],
 [-3.228595,-1.721454,-1.750166],
 [ 1.499418, 1.194805,-2.809718],
 [-0.314617,-2.887974,-3.471527],
 [ 5.934206,-1.566984, 0.147182],
 [-2.928014,-1.705184,-4.830193],
 [-7.186739,-0.270157, 2.87097 ],
 [-0.611768, 0.755482, 4.100886],
 [ 0.800428,-0.502573, 3.37408 ],
 [-3.402029, 1.191768, 1.992478],
 [ 7.846848, 1.537857,-0.183089],
 [ 3.219566, 0.359497,-0.314064],
 [ 5.767914, 0.374835,-1.174113],
 [ 4.046098,-1.227306,-2.17836 ],
 [ 0.492792,-0.384646,-5.306236],
 [-6.653571, 0.889619, 1.748424],
 [-0.204302, 2.75176 , 2.727453],
 [-0.162825, 2.17514 , 4.410355],
 [-2.372803, 0.372888, 2.074146],
 [ 6.700078, 0.240231,-2.364568],
 [ 5.537579, 4.682031,-0.238586],
 [ 2.367552,-2.856234,-1.959091],
 [ 3.670783, 0.873705, 3.336971],
 [ 2.414771, 5.696214,-0.174268],
 [ 0.002355, 3.386556, 2.983962],
 [ 6.211221, 0.380574,-0.458821],
 [ 6.324153,-0.540064, 1.347467],
 [ 1.149916, 2.742773, 2.061818],
 [-3.227126,-0.755314, 4.080186],
 [-6.552414, 0.215978,-0.88373 ],
 [ 3.69736 , 0.877422,-0.190484],
 [ 4.414951, 1.115653,-0.075697],
 [-1.007457,-0.182311, 0.474021],
 [ 3.364661, 1.043123,-0.058083],
 [ 2.252815, 0.800663, 0.967452],
 [ 4.249328,-0.382549, 0.917006],
 [-3.587617,-3.783432, 0.376239],
 [ 6.104037, 0.299715,-1.092651],
 [ 1.832676, 3.317811, 3.573835],
 [ 2.1071  , 0.253216,-0.563299],
 [ 5.392198, 1.119567, 2.333894],
 [ 2.895733, 0.072884,-3.745402],
 [ 0.548618,-0.347449, 0.449555],
 [-1.146782,-4.179538,-0.044419],
 [ 3.39352 , 0.607965,-1.669056],
 [-1.915199,-0.332672,-0.850195],
 [ 1.294487,-4.069172, 0.84774 ],
 [ 4.066883, 1.635188, 2.563672],
 [ 2.420933, 5.694984,-0.177827],
 [ 3.002592, 3.648847, 2.490091],
 [ 5.558071, 0.606653,-0.533242],
 [ 2.932982, 0.05939 ,-1.311708],
 [ 4.374789, 2.64702 , 2.672716],
 [ 3.049601, 0.685217,-0.403698],
 [-0.788595, 4.14743 , 0.850712],
 [ 0.763003,-1.580782, 4.651947],
 [-0.016686, 1.45982 ,-4.331657],
 [ 0.682813,-0.465994,-5.132614],
 [-0.111073,-3.137929,-5.693974],
 [ 3.078761,-2.341829,-4.720672],
 [ 1.480072,-1.089783,-5.300601],
 [ 1.598609,-1.205703,-5.327887],
 [ 1.715038,-1.191336,-5.294665],
 [ 1.465005, 0.861984, 3.778471],
 [ 1.183221,-0.932669, 1.531419],
 [ 0.548012,-0.559238,-5.187546],
 [ 5.489172, 4.757821,-0.254724],
 [-0.311652, 1.516693, 2.373175],
 [-0.403604, 1.418471, 2.321868],
 [ 5.260843,-0.148642, 0.064716],
 [-0.997351, 1.506188, 2.769902],
 [ 5.269644,-0.143961, 0.05219 ],
 [-4.963789,-2.180475, 1.858633],
 [ 6.37466 ,-1.199433, 0.439089],
 [ 3.616135, 1.214719, 1.67098 ],
 [-0.029433,-0.759687, 3.117595],
 [ 2.47026 , 3.317067, 3.35991 ],
 [-0.914724, 2.440975,-1.56075 ],
 [ 1.787549, 3.216222, 3.651138],
 [-0.595577, 1.899478,-2.182324],
 [ 0.204945,-0.652623,-0.344658],
 [ 1.200116, 0.745216, 3.686595],
 [ 1.379589, 1.386564, 0.839038],
 [ 3.226144,-1.260342, 0.197687],
 [ 1.090151, 4.170241, 4.236412],
 [-1.862086,-1.560182, 0.833043],
 [-3.806705, 0.542577, 6.457846],
 [ 5.987406,-0.528977, 1.202969],
 [ 5.572371, 4.720665,-0.243733],
 [-5.509132,-0.007646,-0.804949],
 [-0.168629, 0.564217, 1.524057],
 [-0.132914, 0.579279, 1.563532],
 [ 0.044094, 0.953023, 1.703444],
 [-0.20913 , 0.772465, 1.691605],
 [-0.142907, 0.647509, 1.551197],
 [-0.10123 , 0.708371, 1.560464],
 [ 0.015865, 0.91781 , 1.637518],
 [-0.048175, 0.696736, 1.562977],
 [-3.171465, 0.346149, 0.514465],
 [-3.608436, 0.865602, 2.960922],
 [ 0.29047 ,-0.944682,-5.027266],
 [-0.002619,-1.098351,-5.732707],
 [ 5.20431 , 0.402219, 0.006046],
 [-4.634526,-0.031878, 0.381828],
 [-4.807899, 0.146967, 2.08696 ],
 [-2.683727, 1.703392, 0.604912],
 [-0.977571, 0.597903, 0.387958],
 [ 6.367816,-0.690152, 0.697675],
 [ 1.408112,-0.473333,-4.385705],
 [ 3.6368  ,-2.519866,-3.871705],
 [ 0.370848,-0.152573, 0.737626],
 [-0.511074, 3.219928, 2.129736],
 [-0.186251, 1.847891, 2.531057],
 [ 2.613662, 1.571708, 0.444272],
 [ 3.767977, 0.527455,-0.30044 ],
 [-5.674749,-1.266401, 2.412407],
 [-4.021112, 0.065158, 2.177219],
 [ 3.897829,-0.403699, 1.465326],
 [ 6.519792, 0.228773,-1.728189],
 [-1.000109,-5.220884, 0.036386],
 [-0.617054,-5.821737,-0.262204],
 [-1.085926,-5.343095,-0.024246],
 [-0.582314,-5.758802,-0.648377],
 [ 6.361414, 3.20838 , 0.118886],
 [ 5.326826, 2.218353, 2.340745],
 [ 0.730889,-2.46253 ,-0.203751],
 [-4.728482, 1.756092,-1.96772 ],
 [ 3.154922,-0.198175,-1.569648],
 [-5.527277,-0.627121, 0.338825],
 [-4.914137, 0.178044, 0.040076],
 [-2.47935 ,-3.332183, 1.351653],
 [-5.134803, 0.462833, 2.918207],
 [ 0.12838 , 2.138167, 4.235357],
 [ 3.016546, 0.499512,-0.045266],
 [-3.512169, 0.876408,-2.261822],
 [ 3.070435, 2.99286 , 0.185335],
 [ 6.294461,-0.426486, 1.4317  ],
 [-3.358338, 1.407826, 1.813028],
 [-2.619265, 0.359957, 1.234948],
 [ 5.939461, 3.128243, 1.625616],
 [-2.200895, 0.803459, 2.422586],
 [-3.050322,-1.678035,-4.298739],
 [ 1.112752, 0.558861, 2.936059],
 [ 0.730311,-0.351976, 2.962183],
 [ 6.689084, 0.265663,-0.47578 ],
 [-2.711949,-0.948333, 1.859667],
 [-2.19302 ,-2.769026, 2.191011],
 [ 1.272847,-0.819657, 5.042637],
 [ 6.086731,-0.592051, 1.175367],
 [-1.550026, 0.216931, 2.063434],
 [ 0.904763,-0.493213, 3.30635 ],
 [ 0.380614, 0.049783,-2.776774],
 [-0.568313, 2.240687,-2.171591],
 [ 6.117852, 0.309792,-1.497254],
 [ 0.56534 ,-3.105245, 0.143579],
 [ 3.632576, 0.354186,-1.678986],
 [-0.709076,-2.231543, 2.566463],
 [ 5.118524,-0.356631,-1.145791],
 [ 4.413414, 0.80691 ,-0.414421],
 [-0.191194, 3.383316, 2.931713],
 [-1.382454,-0.540663, 0.350411],
 [-0.516591, 2.563886, 1.525766],
 [ 0.095095, 0.321147, 3.838296],
 [-3.241091,-1.547608,-4.135062],
 [ 1.821375, 3.315081, 3.670107],
 [ 5.366188, 0.342433,-0.073904],
 [ 0.433895, 0.283197,-2.767018],
 [ 0.288414, 1.253291, 2.047433],
 [-1.970113,-0.212716, 4.83053 ],
 [ 6.226606,-0.666168, 1.18577 ],
 [-4.527512, 1.725089,-0.624304],
 [-3.879387,-0.237546, 3.547704],
 [-1.240943,-0.395485, 4.107903],
 [ 4.865486,-0.290406,-0.008997],
 [ 5.840408,-0.827533,-0.381006],
 [-3.261861,-0.13002 , 6.860699],
 [ 6.447171, 0.236226,-0.447686],
 [ 1.824574,-0.900814, 0.865866],
 [-2.71701 , 1.851833, 3.555603],
 [ 3.99589 , 5.102592, 0.295778],
 [-0.357671,-0.64776 , 2.754453],
 [ 4.467729, 1.644519, 2.018073],
 [ 2.216393,-0.986699, 1.504634],
 [-6.749531, 0.915836, 1.791   ],
 [ 4.047203, 0.673213,-1.455865],
 [ 1.888568,-2.415702,-0.13386 ],
 [ 1.117345, 2.942112, 2.807952],
 [ 0.012355, 2.93223 , 2.883795],
 [-3.472548, 0.76828 ,-2.320772],
 [ 1.869686, 0.151937, 2.021888],
 [-0.590032,-5.847447,-0.244888],
 [ 2.841962, 0.693266,-0.086241],
 [-1.069821, 2.883437, 2.192944],
 [ 3.533817, 4.022433,-0.290038],
 [ 0.275277,-0.249405, 0.74714 ],
 [ 2.038075,-1.17373 ,-5.322328],
 [ 3.787558, 0.003054,-3.727037],
 [-0.301827, 4.245649,-0.722266],
 [-5.675014,-1.953326, 0.51528 ],
 [-3.880567,-0.775449, 3.188385],
 [-0.49082 , 2.959075, 1.44516 ],
 [ 6.968659, 2.51398 ,-0.039402],
 [ 2.561904, 1.50269 , 0.533143],
 [-0.231294, 3.501045, 2.944479],
 [-0.036583,-0.333826, 3.133224],
 [-0.691328,-0.294298, 3.36882 ],
 [ 6.855907, 2.209164, 0.08354 ],
 [ 6.873308, 3.040073,-0.046261],
 [ 6.402941,-0.160474, 0.793682],
 [-0.120176,-0.421508, 3.108383],
 [-2.191903,-1.998414,-1.202032],
 [ 1.285915,-0.160261, 0.376593],
 [ 1.84596 ,-1.169408,-5.293982],
 [-1.280025,-2.734323, 0.181657],
 [-0.652734, 2.731865, 4.149838],
 [-3.051402,-2.212154,-2.345151],
 [-1.606983, 1.332947, 0.334049],
 [-2.071557, 0.440597,-0.620829],
 [ 1.482059,-0.387526, 0.72091 ],
 [ 4.988225, 0.22962 ,-0.194597],
 [-3.626728,-1.224325, 3.258623],
 [-1.274726,-1.282344, 0.145477],
 [ 4.628162, 0.638151,-0.341276],
 [-3.359941,-0.626038, 3.491862],
 [-4.87877 ,-0.570579, 3.586108],
 [ 2.978098,-0.022021,-0.799206],
 [-2.508672,-4.185231, 0.850886],
 [-0.235027,-3.026423,-3.512932],
 [-4.096227, 0.184932, 2.050323],
 [-5.154595,-1.812956, 0.725418],
 [ 0.315495, 1.671769, 1.935473],
 [ 0.709118,-0.794104,-0.64204 ],
 [-0.472355,-3.911129, 0.894601],
 [-0.465487,-3.85295 , 0.942284],
 [ 5.195359, 0.549995, 1.42123 ],
 [-0.461116,-3.810279, 0.897109],
 [ 0.042204, 2.341898, 2.894779],
 [ 5.136705, 0.153908, 2.498528],
 [-1.318141, 2.437989,-0.134575],
 [-0.165952,-1.903453, 1.68631 ],
 [-1.268816, 1.874269, 2.540568],
 [-0.807611,-3.470754, 1.239646],
 [ 0.252196,-0.917941,-4.57487 ],
 [ 5.552151, 0.105349, 0.396048],
 [ 2.241631, 3.305671, 3.57464 ],
 [ 3.702043,-0.264379,-0.235332],
 [-2.424395,-1.273428, 3.073737],
 [ 0.810855,-0.597158,-5.002658],
 [ 0.675465,-0.538072,-5.334093],
 [-4.44598 ,-0.326957, 3.644941],
 [-2.199776,-0.188038, 1.610175],
 [ 4.602333, 0.700071,-1.26041 ],
 [-0.653147, 2.46122 , 5.642284],
 [-4.114433, 0.747025, 6.115479],
 [-4.234646,-4.936959,-0.652477],
 [-3.527864, 0.023094, 3.814193],
 [ 0.020938,-0.832726, 5.257255],
 [ 0.54487 , 0.812966, 2.501101],
 [-4.43966 , 0.008374, 1.197298],
 [ 1.352968,-0.024138,-1.108402],
 [ 3.438533, 0.768029, 2.103249],
 [-7.298219,-0.324496, 2.887473],
 [ 1.05872 , 0.591643, 3.170837],
 [ 6.132592, 0.915034, 0.870531],
 [ 4.476205, 0.648191, 1.997622],
 [ 1.636268, 2.290887, 2.327574],
 [-0.947995, 0.437742, 0.388876],
 [-1.22725 , 0.721104, 0.59801 ],
 [-6.816907,-2.031593,-0.646717],
 [-3.077516, 2.022151,-0.830796],
 [-0.181578, 2.997822, 2.930152],
 [-0.190956, 3.139745, 2.891148],
 [ 0.110367, 5.217817, 3.048687],
 [ 3.972064, 0.657678,-1.383342],
 [ 4.400334, 0.371166,-1.188339],
 [-7.335877,-0.246438, 3.023401],
 [ 2.949459,-0.622889, 0.343569],
 [-0.694208, 1.902026,-2.085016],
 [-2.767355,-1.126096, 2.422649],
 [-2.522221, 0.914888, 0.065742],
 [ 0.684364,-2.411839,-0.018961],
 [ 3.218926, 1.700677,-0.121961],
 [-0.715716,-5.780079,-0.230467],
 [-2.074201,-0.754497, 1.238297],
 [ 0.809095,-0.76926 ,-1.967275],
 [ 1.869386, 5.497416, 1.132488],
 [ 1.868814, 5.526302, 1.149689],
 [ 1.883581, 5.53819 , 1.147211],
 [-3.509897,-0.527699, 3.929491],
 [-3.45659 ,-4.224926,-1.119321],
 [-3.050897, 2.247621,-0.739041],
 [-1.883697, 1.042243,-0.261533],
 [-3.837239,-0.176901, 3.759348],
 [-3.941493,-0.267608, 3.885072],
 [-3.605887,-0.413518, 3.907099],
 [-3.969095, 0.085627, 3.501712],
 [-3.932632, 0.035716, 3.564506],
 [-3.834612, 0.060497, 3.523224],
 [ 0.677076,-1.20683 , 0.005342],
 [-4.183851, 1.833187, 3.223031],
 [-3.242804,-0.691906, 3.124235],
 [-2.875677,-0.520222, 3.441693],
 [-2.867888,-1.877206, 3.108247],
 [-2.950883,-0.589901, 3.365738],
 [-3.18272 ,-0.899804, 3.111016],
 [-2.775037,-0.448779, 3.559243],
 [-2.105087,-2.963207, 1.489298],
 [-3.735172, 0.36821 , 3.375802],
 [-3.830825,-0.213904, 3.747319],
 [-3.877727, 0.088297, 3.603761],
 [ 3.963042,-0.224043, 1.940435],
 [-0.561019,-2.663216,-5.275275],
 [-2.622464,-0.301875, 1.767797],
 [ 3.27397 , 1.679951,-0.110444],
 [ 5.793998,-0.854713,-0.309412],
 [ 5.061248, 2.374478,-0.181389],
 [-0.722729, 2.308077,-1.872974],
 [-3.261956, 0.865917,-0.663002],
 [-2.403769,-1.660511, 1.343689],
 [ 1.6362  ,-0.643337,-4.645342],
 [ 6.543201, 1.490574, 0.945041],
 [-1.565774, 0.61793 , 1.687229],
 [-3.4943  , 0.875302,-2.159164],
 [ 6.494527, 0.251866,-2.393454],
 [-1.21049 ,-0.554328, 4.190341],
 [-3.712951,-0.389098, 0.035749],
 [-5.632127,-2.521194,-0.66013 ],
 [-0.453728, 0.714728,-0.352113],
 [ 7.68184 , 1.893312,-0.067108],
 [ 1.713427, 0.407018,-1.706211],
 [-1.071689,-3.384037,-0.938583],
 [-1.791191, 0.284651, 1.916227],
 [-2.584288,-1.263999, 0.382526],
 [-2.27235 ,-1.110937, 3.945984],
 [-2.485074,-2.874967, 1.441016],
 [-2.508245,-2.912678, 1.625581],
 [ 4.481487,-0.039161,-1.316802],
 [ 0.155905,-1.601239,-1.060871],
 [ 4.094682, 0.784366,-2.002079],
 [-2.993948,-1.119906, 0.728721],
 [-5.185195,-0.105276, 5.018794],
 [ 1.697222, 0.920975,-2.06953 ],
 [-2.542568,-3.260348, 1.541324],
 [-2.124393, 1.019263, 6.09556 ],
 [ 0.666068,-0.745137,-5.00179 ],
 [-4.247834,-4.894876,-0.630413],
 [ 5.606236,-1.013961, 1.028942],
 [-1.959419,-0.216404, 2.091688],
 [-0.175211, 2.838284, 2.695946],
 [ 2.795649, 2.388166, 0.973252],
 [ 0.285958, 3.041964, 2.892389],
 [-2.570122,-4.07194 , 1.024738],
 [-0.574218,-2.834983,-3.13214 ],
 [ 1.19901 , 1.816074, 0.903278],
 [ 0.849904, 1.349334, 1.047986],
 [ 1.044848, 1.640315, 0.633989],
 [ 1.114627, 1.516115, 0.681743],
 [ 1.136502, 1.534799, 0.698559],
 [ 1.094808, 1.579169, 0.62783 ],
 [ 1.113803, 1.58628 , 0.627309],
 [ 1.191377, 1.580264, 0.74578 ],
 [ 2.107477, 2.98511 , 0.770576],
 [ 1.177534, 1.471406, 0.637449],
 [ 1.05315 , 1.613135, 0.591927],
 [ 1.087868, 1.596905, 0.799408],
 [ 2.504925, 1.46757 , 0.426248],
 [ 3.785641, 0.826427,-0.507234],
 [ 3.656161,-2.367527,-3.92395 ],
 [ 5.447593, 4.419034,-0.276483],
 [ 3.818736, 1.253008,-1.024107],
 [ 6.585759, 0.377341,-3.116869],
 [ 3.14796 , 0.483421,-0.80957 ],
 [ 1.084829, 1.624424, 0.718184],
 [ 1.127194, 1.577499, 0.605293],
 [ 1.155964, 1.590741, 0.608824],
 [ 1.654632, 1.205242, 1.535946],
 [ 1.121246, 1.631912, 0.575798],
 [ 1.135905, 1.555835, 0.658957],
 [ 1.059179, 1.586082, 0.774781],
 [ 1.139044, 1.607812, 0.575515],
 [ 1.178248, 1.570951, 0.652553],
 [ 1.222494, 1.6014  , 0.488827],
 [ 1.122696, 1.602668, 0.70752 ],
 [ 1.074607,-0.854838,-4.701755],
 [-6.801518, 0.864361, 1.873594],
 [-4.500501, 0.424165, 1.613506],
 [ 3.581318,-2.868245,-3.827826],
 [-3.266495,-1.867297,-0.937475],
 [-7.166488,-0.199241, 3.101655],
 [-4.307613, 1.330253, 3.459462],
 [ 2.999986, 2.263872, 1.897035],
 [ 2.189153, 1.725235, 2.609062],
 [ 2.271756, 3.34034 , 3.52127 ],
 [ 0.667177, 1.163226, 0.041026],
 [ 3.911215,-0.648124,-0.125616],
 [-0.307901, 1.805705, 2.011164],
 [-3.256271, 0.407265,-1.995356],
 [ 3.415351, 3.568191,-0.184628],
 [ 5.16116 , 3.581436, 0.52678 ],
 [ 0.855637,-2.935742,-2.769815],
 [-6.30864 ,-1.651989,-0.452109],
 [-2.557394,-3.757678, 1.21445 ],
 [-0.9192  ,-4.120727,-1.370378],
 [-5.954383, 1.162545, 3.879566],
 [-3.996236,-0.226858, 3.725634],
 [ 6.334225, 3.165158, 0.098341],
 [-1.639932,-0.279894, 0.958605],
 [ 4.843075, 1.790231,-0.773725],
 [ 3.683607,-2.420675,-3.944459],
 [-4.637027, 1.358037, 1.572961],
 [-4.544353, 0.655109, 1.648393],
 [ 3.018298,-0.268638, 0.27608 ],
 [ 0.996456,-1.913806, 2.310607],
 [ 3.758682,-1.159132,-3.807054],
 [-2.431077, 0.881344, 0.302172],
 [-5.91313 , 1.26772 , 3.868965],
 [-4.156747, 0.947184, 1.662984],
 [-0.389284,-1.155679,-5.245   ],
 [-0.202939,-1.145648,-5.228896],
 [-3.147896,-2.4038  ,-2.481009],
 [-5.152979,-0.107536, 4.964359],
 [-4.574581, 0.020272, 5.029863],
 [-7.166141,-0.223833, 3.263483],
 [-5.181808,-0.117141, 4.954623],
 [-0.263607, 3.000931, 2.892735],
 [-0.143274, 0.460701, 1.552326],
 [-5.477103, 0.166498, 3.59075 ],
 [-5.58699 ,-1.833485, 1.233192],
 [-5.710406,-1.849213, 0.70655 ],
 [-7.075211,-0.269774, 2.978837],
 [-0.51607 ,-0.560317,-0.142373],
 [ 6.257948,-0.652054, 0.859739],
 [ 2.331194, 3.96119 , 2.245051],
 [ 1.735898,-2.526841, 0.427242],
 [-4.241028,-4.890664,-0.630838],
 [-2.52537 , 0.556506, 0.41588 ],
 [ 0.524309,-1.018044, 4.343504],
 [-4.813026, 0.821146, 2.971628],
 [ 1.962297, 5.44145 , 1.021743],
 [-4.647939,-1.561674, 0.090672],
 [-1.137779, 0.630912, 3.807187],
 [-1.282354, 0.66443 , 3.880507],
 [-1.084701, 2.1477  , 3.944471],
 [-5.129486, 0.481382, 0.612874],
 [ 0.876817,-0.344712, 0.695187],
 [-2.106431,-0.846361,-0.699692],
 [-0.487339, 0.081058, 1.465528],
 [-3.709804, 0.523214, 0.650455],
 [ 1.586065,-2.335873,-2.216984],
 [-0.723608, 0.332776, 1.289649],
 [ 1.86077 ,-2.173049, 1.910069],
 [-4.717402, 0.715075,-1.02112 ],
 [ 5.12012 , 4.624782, 1.197259],
 [ 6.416539, 3.09297 , 0.054043],
 [-2.581018,-4.01716 , 1.087237],
 [ 4.634524,-1.940483, 1.075786],
 [-1.414297,-3.805116, 0.849039],
 [ 4.949162, 1.979948,-1.311498],
 [-3.10367 , 0.30217 , 1.044204],
 [-0.776488, 2.68494 , 4.063797],
 [ 0.253092, 1.961397, 3.620875],
 [-0.814972, 2.241244, 3.825186],
 [-0.847815, 2.345298, 3.884748],
 [-7.443554,-0.377446, 2.64003 ],
 [-1.854041,-1.211075,-2.008298],
 [ 0.750402, 3.998352,-0.173214],
 [-1.835647,-1.067773,-2.032611],
 [-1.702989,-1.23596 ,-2.08057 ],
 [-1.451189,-1.342518,-2.027885],
 [ 2.178704, 3.1912  , 1.031358],
 [-2.229556, 0.246956,-3.505186],
 [-0.073621,-3.453768,-1.012198],
 [ 5.815076, 3.083354, 1.69201 ],
 [ 0.699119, 0.297173, 3.538048],
 [ 0.611828,-2.115415, 2.17524 ],
 [-1.763568,-0.846947,-1.209408],
 [-1.016724,-0.990222, 0.621645],
 [ 7.398943, 2.079532,-0.111415],
 [-2.423138, 0.910906, 0.90375 ],
 [-1.696383,-0.237191, 1.291753],
 [ 0.423319,-0.083659, 1.58919 ],
 [-1.687121, 1.253165,-0.121913],
 [-1.432709, 0.629878, 3.327303],
 [-0.675721, 2.290433,-2.070223],
 [-2.739123,-0.711877, 4.158142],
 [-2.541153,-0.4861  , 4.380872],
 [ 0.459332, 2.333052, 4.350236],
 [ 0.044687, 2.246231, 4.363254],
 [ 0.275509, 2.139151, 4.417332],
 [ 0.861375, 4.387871,-0.222663],
 [ 0.134502,-0.121798, 5.66609 ],
 [ 0.14536 ,-0.168089, 5.677226],
 [-3.529779,-1.610474,-1.679829],
 [ 1.688334, 3.112591, 3.748307],
 [ 7.748282, 1.778417,-0.133106],
 [ 1.051349,-0.048022, 3.342456],
 [-3.997876, 0.682224, 6.334846],
 [-2.392385,-1.093017, 3.943725],
 [-2.531318,-3.362184, 1.391497],
 [-2.867463, 0.73521 ,-0.471598],
 [ 4.186329, 0.442688, 0.647381],
 [-0.734839, 0.110318, 1.606517],
 [-1.485429, 2.485779, 0.883239],
 [-0.221804, 2.597872, 4.151776],
 [-0.767674, 0.182008, 1.602651],
 [ 1.645198, 0.104633, 1.396716],
 [ 2.983357, 3.154964, 2.751216],
 [-0.265685, 2.632473, 4.206399],
 [ 2.584275, 0.222188,-0.117788],
 [-0.681181, 2.251934,-2.010885],
 [-0.876595, 2.279509, 3.963178],
 [ 1.15125 ,-2.16391 ,-3.033567],
 [ 0.512978, 3.294779, 2.763497],
 [-5.065706, 1.03661 , 3.330407],
 [-4.933686,-2.293486, 1.84741 ],
 [-0.714202, 1.567613,-2.220356],
 [ 0.279895, 1.846663, 3.686942],
 [-6.262671, 0.878312, 3.784439],
 [-3.098956,-2.299711, 0.905906],
 [-7.216099, 0.523594, 0.009102],
 [-1.80801 , 0.509526, 1.347713],
 [ 1.90716 ,-0.822873,-0.33501 ],
 [ 2.341826, 3.3186  , 3.519259],
 [ 0.95891 ,-2.57806 ,-3.358296],
 [-4.144951, 1.92006 , 2.902198],
 [-1.853306,-0.965165, 3.942688],
 [-0.623137,-2.117182, 2.486682],
 [ 6.094758, 0.330319,-1.55238 ],
 [ 5.849318, 0.218244, 1.069257],
 [ 1.611036, 2.964966, 4.311997],
 [-4.800748,-0.131   , 1.500875],
 [ 2.450343, 0.920362, 4.122331],
 [ 3.771335,-2.649638,-3.744322],
 [ 0.328988, 0.489762,-2.914537],
 [ 0.61236 ,-2.469856,-1.022437],
 [-5.972208,-0.029214, 1.933568],
 [-3.551729,-0.803079, 2.806175],
 [-2.658274, 0.503059, 0.712736],
 [-4.328614, 1.894334, 3.339707],
 [-0.626382, 4.400219,-1.137047],
 [ 2.251425, 3.552537, 2.025316],
 [ 0.167586,-1.419944,-0.818205],
 [ 0.23012 , 3.395592, 2.442222],
 [-0.096772, 3.21625 , 2.496927],
 [ 1.591486, 2.962798, 4.322533],
 [-4.768644, 0.308369, 0.135211],
 [-0.033805,-0.441596, 1.382904],
 [ 3.500981, 1.36552 , 0.053064],
 [ 6.576377, 0.372849,-3.062437],
 [ 0.711191,-1.446282,-1.43886 ],
 [ 6.567277, 3.193895, 0.106201],
 [ 0.69625 ,-0.026366,-0.183408],
 [-0.695089,-5.781233,-0.240872],
 [-0.733155, 2.653077, 4.096414],
 [-2.341349,-1.343876, 3.994254],
 [-2.256461,-0.998434, 3.965089],
 [-6.640516,-2.136464,-0.552199],
 [-2.51076 ,-3.73877 ,-0.320515],
 [-2.532214, 1.212994,-1.40589 ],
 [-2.288548, 0.824087,-3.349342],
 [-5.527399,-0.007054,-0.799343],
 [ 0.713545, 0.012892,-1.676595],
 [ 2.944076, 0.727211, 0.310495],
 [-2.53818 ,-3.461017, 1.328119],
 [-7.17882 ,-0.232564, 3.082404],
 [-5.317862, 0.535743,-1.014565],
 [-6.371506,-1.467622,-0.520504],
 [ 2.035051, 0.670285, 0.057259],
 [ 1.485558,-3.930462,-4.22581 ],
 [ 5.51152 , 1.417144, 0.172483],
 [ 1.668444,-0.694797,-2.744956],
 [-6.710106, 1.038793, 1.805407],
 [-0.670445, 2.647789, 4.120164],
 [-0.325502,-3.021592,-5.265985],
 [ 5.344564, 0.195328,-0.737078],
 [-0.324438,-3.909482, 0.952962],
 [ 0.605659,-2.038445,-0.487518],
 [ 3.77613 ,-1.260063, 0.667663],
 [ 1.665703,-3.691533,-3.95979 ],
 [ 3.432692, 0.512831,-2.809987],
 [ 1.651126,-3.538695,-4.307265],
 [-2.125044, 1.01748 , 6.088458],
 [-7.283202,-0.210928, 2.966808],
 [ 2.30224 , 0.97288 , 4.398294],
 [ 0.025986,-1.24807 , 2.58366 ],
 [ 0.13436 ,-0.046226, 3.910229],
 [-3.528995,-0.283159, 2.429756],
 [ 0.141862,-0.043579, 3.857414],
 [ 3.194324,-0.560723, 2.040752],
 [-0.610433, 0.02066 , 2.009377],
 [-7.357982,-0.215849, 3.09642 ],
 [ 1.915593,-0.803574,-0.621667],
 [ 2.785844,-2.498246,-1.385759],
 [-0.745821, 4.735608, 1.903515],
 [ 1.28456 ,-1.683381,-5.157096],
 [ 0.133286, 0.463669, 4.647612],
 [ 0.746309, 1.1021  , 2.353571],
 [ 0.644044, 1.334118, 1.397591],
 [ 0.116629,-0.176705, 5.688991],
 [ 5.159768, 0.25856 , 2.182721],
 [ 2.690954,-0.761437, 2.576674],
 [ 5.242954, 0.57086 ,-0.336438],
 [-7.186128,-0.14569 , 2.815573],
 [-0.042588,-2.337907, 0.499914],
 [ 1.639071,-3.63481 ,-4.526814],
 [ 0.386699, 1.849852, 3.258042],
 [ 1.230181,-4.064743, 0.851432],
 [-5.041082,-1.583566,-0.511365],
 [ 0.197617, 3.385042, 2.450158],
 [ 3.105575,-1.614147, 1.558993],
 [ 1.883243, 0.652508, 1.406842],
 [-0.565976, 0.032017, 2.000266],
 [ 5.055752, 0.021778, 0.350552],
 [-7.31114 ,-0.271267, 2.918739],
 [-2.607383,-4.35883 , 0.696016],
 [-2.312985, 2.33198 , 3.482411],
 [ 7.354334, 0.169527, 0.429384],
 [-0.393517,-0.1639  ,-1.02193 ],
 [ 0.225068,-0.447193,-5.123539],
 [ 4.951623, 0.668386, 0.073864],
 [-0.478618, 2.3385  , 1.739336],
 [-1.929193, 3.002488,-0.609421],
 [ 3.961441, 0.275529,-4.951682],
 [ 0.391437, 1.137313,-3.947582],
 [-4.081145, 1.430921, 3.613321],
 [-0.652154,-3.204564,-2.79885 ],
 [ 3.239237,-0.824531,-0.784111],
 [ 6.554738, 0.390628,-3.101242],
 [ 0.106331, 2.916238, 2.956495],
 [ 7.765232, 1.656455,-0.127031],
 [ 0.107201, 4.558008, 3.728624],
 [-7.363853,-0.217374, 3.179912],
 [-4.97115 , 1.910052, 0.827504],
 [ 1.252507, 1.836672, 1.013271],
 [ 2.856859, 5.406795, 0.135053],
 [ 2.215087, 0.114406, 0.705046],
 [-2.927191, 0.405324, 1.159468],
 [ 1.708087,-0.129961, 0.11026 ],
 [ 2.653471,-0.579883, 2.539213],
 [-0.562745, 2.194973,-1.716239],
 [ 1.55845 , 1.527282, 1.669802],
 [-5.062348, 1.558664, 3.532269],
 [ 0.642591,-0.713233,-0.662313],
 [ 4.482091, 0.824031,-0.096362],
 [ 0.417963,-4.8215  ,-0.079984],
 [ 2.422277, 5.718412,-0.168058],
 [ 6.760021, 0.243757,-2.428013],
 [ 0.00566 , 1.956993, 2.230863],
 [-0.512471, 3.32163 , 2.755259],
 [-1.219202, 2.491294, 3.466583],
 [-0.924625, 2.369254, 3.875155],
 [ 1.511046, 0.374135, 1.837784],
 [ 3.379785, 0.441572,-0.447662],
 [-1.184825, 4.06718 , 2.394414],
 [-0.180438, 1.987147,-1.94891 ],
 [-0.814809, 0.138263,-0.312834],
 [ 3.993186, 0.293704,-4.923126],
 [-0.303301,-2.974148,-5.400567],
 [-0.387851,-1.957365,-5.137144],
 [-0.161074,-3.335451,-4.26286 ],
 [ 2.89952 ,-1.613013, 0.347369],
 [-0.425472,-2.567891,-5.435824],
 [-0.55458 ,-2.660237,-5.356584],
 [ 3.457841,-0.000153,-1.543686],
 [-3.827353,-2.065893, 0.56937 ],
 [ 5.550801, 4.716664,-0.23806 ],
 [ 6.318125, 0.380704,-2.769091],
 [ 2.97056 , 4.157438,-0.206935],
 [ 4.254724,-0.117125, 0.72157 ],
 [ 5.853358, 0.537871,-0.179448],
 [-2.582139,-0.88235 , 2.387679],
 [-2.590761,-0.543271, 1.828693],
 [ 6.102935, 0.251233,-0.258802],
 [-5.923384, 1.316782, 3.983338],
 [ 7.088171,-0.523632,-0.086355],
 [-1.384331,-0.661243,-0.463301],
 [ 7.195126, 0.257572, 0.908621],
 [ 0.553661, 1.636067, 4.332253],
 [-3.390548, 0.984528, 1.997412],
 [-5.947979, 1.389901, 4.015939],
 [ 0.469503, 0.662779, 3.447771],
 [ 0.521479, 0.530839, 3.412871],
 [ 7.001038, 2.455843, 0.011305],
 [-2.158897, 0.633077, 4.607476],
 [-2.837199, 1.060325, 2.705299],
 [-2.450701,-0.479192, 0.110263],
 [ 2.79193 ,-0.158986,-3.749663],
 [ 1.333575, 2.714202,-0.709167],
 [ 0.156289, 3.142273, 3.573634],
 [-1.877314, 0.077581, 3.111844],
 [ 0.136638,-1.029479, 3.618236],
 [-3.872785,-0.800515, 3.803283],
 [-0.952653,-2.683563,-1.271547],
 [-1.665106,-1.321271,-2.072546],
 [-2.642977,-1.584687,-1.81906 ],
 [-4.42239 ,-3.758179,-0.989628],
 [-2.672172,-1.617768,-4.414449],
 [-0.561358,-0.012819,-1.346957],
 [ 1.810764, 1.098611,-2.928654],
 [ 1.755703, 1.216432,-2.752803],
 [-0.592253, 0.904949,-0.824135],
 [-1.475671,-0.660477,-3.764948],
 [-1.008164, 0.485983,-0.646752],
 [ 0.29888 , 3.760586, 4.054092],
 [ 3.546935,-1.363954,-1.694423],
 [ 0.025903, 2.763854,-0.64179 ],
 [-3.234407, 0.706975,-3.167546],
 [-2.690321,-2.929576,-3.601046],
 [-2.347519,-3.204511,-3.349015],
 [-1.948038,-1.733056,-4.255325],
 [-2.66284 ,-1.572118,-4.387812],
 [-2.999029,-2.373991,-0.148694],
 [ 4.693841, 3.722397, 0.090894],
 [-3.094996,-1.400896,-3.347028],
 [-4.353265,-3.731708,-1.101447],
 [-1.210412,-0.698711,-2.331427],
 [-1.967094,-1.708637,-4.249966],
 [-1.827622, 1.181075, 5.494478],
 [-3.193334, 3.273505, 2.221635],
 [ 0.104098, 2.763727, 0.384656],
 [-7.506141,-0.447583, 2.474408],
 [-3.120026, 0.80288 ,-0.738131],
 [ 1.872126, 3.236343, 1.457602],
 [ 7.231645, 0.214124, 0.93779 ],
 [-6.699923, 0.293711, 3.44074 ],
 [-1.276203,-0.92687 ,-1.344829],
 [ 1.057988,-0.216249, 3.254224],
 [-0.326801, 1.727222, 2.720341],
 [-2.440515,-1.206625, 3.945779],
 [-1.713557,-0.478949, 1.09563 ],
 [-4.818125, 1.629641,-0.535524],
 [-2.966486,-0.149476, 0.597372],
 [-2.834171,-0.200663, 0.709926],
 [-1.426131, 0.327125, 3.281667],
 [-0.974732, 0.394384, 3.589406],
 [-1.184689, 0.396993, 3.482519],
 [ 0.33546 , 0.536501, 2.327941],
 [ 0.005458, 0.217751, 2.644291],
 [-1.545462, 0.794928, 3.337488],
 [-0.866913, 0.408891, 3.612931],
 [-0.824914, 0.384691, 3.65715 ],
 [ 5.145758, 0.758229,-0.708075],
 [ 6.111088,-0.608549, 1.19198 ],
 [-0.634241, 2.066792,-1.932787],
 [ 5.873445,-0.792516,-0.436711],
 [ 3.86647 , 2.184302,-0.423862],
 [ 1.694242, 3.162961, 1.020818],
 [ 5.451549, 0.882067, 1.268528],
 [ 4.294939, 0.075281,-1.40426 ],
 [-0.906871,-1.879886,-1.308881],
 [-0.771051, 0.582207, 4.341549],
 [-0.792135, 0.281387, 1.75481 ],
 [-0.505273, 3.022965, 1.389522],
 [ 5.317209, 0.068208,-2.438193],
 [ 2.149223, 3.725194, 1.759583],
 [ 0.072366, 3.056806, 2.906074],
 [ 0.048934, 2.990821, 2.844037],
 [-0.149777, 3.105924, 2.85542 ],
 [ 6.654577, 3.175319, 0.036649],
 [ 6.835334,-0.318445,-3.05207 ],
 [ 3.606231, 5.808204, 1.362565],
 [ 6.835403,-0.31868 ,-3.052835],
 [ 6.98807 , 2.511113,-0.02984 ],
 [ 0.64224 ,-2.096023, 2.172836],
 [-2.809805, 1.405935, 2.852354],
 [ 0.860945, 0.999227, 1.937016],
 [ 3.918429,-0.159745,-1.045071],
 [ 5.985025, 0.168264,-2.804655],
 [ 0.983356,-1.847592, 2.381029],
 [ 0.709989, 4.020975,-0.889659],
 [-1.783247,-1.215134,-2.039645],
 [-7.367961,-0.401431, 2.634277],
 [-7.286046,-0.363745, 2.702107],
 [-2.44179 ,-0.456323,-1.771308],
 [-0.261436,-3.504108,-1.759544],
 [-4.467906,-3.716208,-0.779328],
 [-0.98678 , 0.6145  ,-0.430104],
 [-3.242592, 0.698552,-3.165868],
 [-2.935865,-2.140226,-0.731874],
 [-0.820179, 1.638148, 2.794441],
 [-3.400132, 0.194386, 3.632493],
 [-3.584584, 0.117223, 3.685496],
 [-2.959047,-2.393704, 0.03416 ],
 [-2.851963,-2.687019, 0.092255],
 [-2.665533,-1.567614,-4.322025],
 [ 1.364905,-3.473412,-4.769062],
 [ 0.145617, 2.781543, 0.399962],
 [-3.081678,-1.481429,-1.976242],
 [-1.931295, 1.141885, 5.55187 ],
 [-3.116756, 3.319433, 2.172595],
 [-0.308944,-3.300808,-0.984607],
 [-1.479701,-3.392921,-1.275804],
 [-3.18611 ,-0.241421, 2.140254],
 [-1.716018,-0.499157, 0.438516],
 [ 5.128147,-2.007946,-0.54754 ],
 [ 3.63338 , 0.766661,-0.263376],
 [ 1.867137,-2.18029 , 1.835784],
 [ 1.849685,-2.161657, 1.925092],
 [ 1.319452,-3.099719,-2.306107],
 [-4.178403, 1.852161, 3.504262],
 [ 1.355688, 2.326346, 2.907925],
 [-3.094493,-2.41234 ,-2.472746],
 [ 0.184188,-0.410282,-5.09362 ],
 [-3.353394,-0.715155, 0.381125],
 [ 0.929896, 4.961279,-1.557689],
 [ 0.652703,-1.307458,-1.513049],
 [ 4.440878,-1.643858, 0.445953],
 [-0.863607, 2.651738, 4.08548 ],
 [ 0.505583,-1.700583,-1.316618],
 [ 3.126218,-3.187496,-2.994473],
 [-0.078814,-0.900482, 2.847089],
 [-2.320273,-0.324232, 1.525148],
 [ 0.774412,-1.720703, 0.981816],
 [-0.730229, 0.176903, 1.702223],
 [-1.509004, 1.850017, 3.207702],
 [-0.216982, 2.566162, 4.137129],
 [ 1.372104, 2.466115, 3.619298],
 [ 0.298596, 0.979202,-0.202672],
 [-4.615086,-0.540292, 1.619072],
 [-0.323044,-0.820154, 3.005471],
 [-2.385046,-1.194841, 4.039758],
 [ 0.909016,-0.668075, 3.360898],
 [-1.358613,-0.818264,-1.437693],
 [-2.109764,-0.228278, 1.432177],
 [ 4.16274 , 1.766194, 2.342012],
 [ 2.655951, 3.307993, 3.131111],
 [ 0.727646, 4.01837 ,-0.746526],
 [-7.350526,-0.378205, 2.670927],
 [-2.778226,-1.170875,-2.262759],
 [-3.235114,-1.38974 ,-2.078832],
 [-2.831001,-0.910646,-2.033303],
 [-3.265468,-1.490719,-1.894538],
 [-3.077568,-1.517779,-4.088234],
 [-2.792969,-1.589274,-4.431906],
 [-3.182139,-1.264361,-3.682516],
 [-3.243346,-1.429327,-3.734388],
 [-0.749906, 0.468913,-1.297776],
 [-1.478894, 2.594507, 0.650857],
 [ 0.11828 ,-1.24277 ,-0.293505],
 [-1.045637,-0.404015,-2.353747],
 [-0.078312, 0.297643,-1.277045],
 [-0.578418, 1.188592, 1.089669],
 [-1.782203,-1.217472,-2.129886],
 [-2.550814,-0.44365 ,-1.765945],
 [-2.229214,-0.950493,-1.982277],
 [-1.93247 ,-1.732164,-4.246483],
 [ 1.405021,-3.515188,-4.729858],
 [-1.881971, 1.165833, 5.515604],
 [-2.200166, 2.960409,-0.491319],
 [ 0.555382, 2.953733, 1.214484],
 [ 0.286378, 2.794984, 0.524506],
 [-2.725588,-0.733202,-3.774845],
 [-3.939301,-0.435011,-3.011055],
 [ 0.818683,-0.299495, 1.476478],
 [ 3.5429  ,-0.859568, 1.052973],
 [-7.029917, 0.755679, 0.266181],
 [ 1.838472, 5.483482, 1.467109],
 [ 0.559365,-3.014995,-4.953455],
 [ 0.84165 , 2.817961, 4.337179],
 [-2.269706,-2.994467, 1.95083 ],
 [-1.640928,-1.310015,-2.04998 ],
 [-1.677518,-1.353908,-2.057954],
 [-0.584532,-2.672322,-5.210096],
 [-0.591319,-3.404607, 0.593854],
 [-0.485315,-2.523224,-5.217112],
 [-0.148715, 0.256018,-1.336828],
 [-2.470525,-0.524203,-1.761353],
 [-1.663955,-1.327944,-2.106652],
 [-2.453604,-0.880006,-1.952239],
 [-2.921617,-2.769317,-2.544803],
 [-2.922536,-2.965116,-2.449142],
 [-3.009151,-2.235034,-2.109694],
 [-0.428324,-2.668624,-5.293788],
 [-0.539478,-2.493468,-5.248449],
 [-0.463587,-2.54695 ,-5.207568],
 [-2.616351,-1.605869,-4.344347],
 [-1.62636 ,-0.826376,-3.858513],
 [ 1.380448,-3.510307,-4.689442],
 [-2.92134 ,-3.112258,-2.240802],
 [-2.93685 ,-3.423841,-1.980906],
 [-2.55071 , 0.909281, 2.791598],
 [-1.85626 , 1.146741, 5.501152],
 [-2.12285 , 2.963412,-0.436867],
 [-1.631759,-1.347204,-1.983112],
 [-1.761522,-1.155871,-2.010451],
 [ 2.037441, 3.331227, 0.590219],
 [-0.579186,-3.502622, 0.672927],
 [-0.176161,-3.419134,-0.972859],
 [ 5.628219,-0.320334, 0.334824],
 [-6.940383,-0.312953, 2.967886],
 [-5.039243,-0.047537, 2.922766],
 [ 2.900166, 0.424692, 0.201917],
 [ 5.020882, 0.787303,-1.885026],
 [ 3.442511,-3.049222,-3.373828],
 [ 0.81859 ,-2.55293 , 0.227183],
 [ 5.458579, 4.760737,-0.244084],
 [-0.586214,-0.549607, 0.907723],
 [-0.666081,-0.950055, 0.878704],
 [-0.103034,-0.365949, 1.292467],
 [ 0.609391,-3.480086, 0.087191],
 [ 0.749498,-3.229493, 0.049833],
 [-3.064549, 2.239442,-0.639306],
 [-3.627764,-1.216362,-0.660435],
 [ 4.424501, 0.480231,-0.461983],
 [ 0.279535, 5.136801, 3.02255 ],
 [ 1.236691, 0.272197,-4.954251],
 [-0.423951,-3.113056,-5.112671],
 [ 3.712656,-2.744726,-3.694872],
 [-6.38035 ,-0.826401, 2.597013],
 [-0.335133, 3.626196, 2.960549],
 [-0.305926, 3.540331, 2.941882],
 [-1.56999 ,-2.062677, 0.73594 ],
 [-0.502188,-2.668825,-5.431721],
 [ 4.603903, 0.469618,-0.557709],
 [ 1.233021, 0.894098,-0.09313 ],
 [ 5.222503, 0.404388, 1.787068],
 [-0.230449, 0.693578, 0.112706],
 [-1.751514,-3.421262,-1.595339],
 [ 0.213417,-1.027566,-4.516165],
 [-0.674686,-3.390491, 0.936115],
 [-1.309   , 2.393174,-0.101667],
 [ 0.069285,-2.034247, 1.672463],
 [ 2.314055, 3.340775, 3.526959],
 [ 2.42826 , 5.698084,-0.165538],
 [ 4.23424 ,-1.582337,-0.8272  ],
 [-2.561804,-3.535129, 1.358155],
 [ 4.780614, 4.48507 ,-0.461641],
 [ 3.965535,-1.00064 ,-2.068904],
 [ 4.049487,-1.202003,-2.166051],
 [ 5.483294, 4.679926,-0.260617],
 [ 3.473671,-0.554624, 0.834575],
 [-3.020336,-1.704858,-4.704738],
 [-2.105629,-0.852792,-4.0078  ],
 [ 0.976324, 1.438652,-1.348239],
 [ 0.171127, 2.95559 , 2.968127],
 [-0.111069, 3.275662, 2.925109],
 [ 0.682686,-0.5174  ,-5.487971],
 [-1.582883,-0.586324,-0.361311],
 [ 2.420048, 5.709638,-0.16855 ],
 [-4.188112,-0.904306, 1.319219],
 [ 4.053689, 0.311415, 0.104759],
 [-0.803061, 0.067898, 1.586112],
 [-0.582793, 0.219989, 1.515508],
 [ 6.33141 , 0.281994,-1.657532],
 [-0.606423, 4.206526, 0.824769],
 [-4.967113, 0.622931, 1.273167],
 [-2.461293,-0.174498,-1.331115],
 [ 2.930483,-1.815079, 0.237791],
 [ 6.17099 ,-0.654208, 1.182609],
 [ 3.977512, 0.272412, 1.503406],
 [-2.07779 , 0.524905, 2.48077 ],
 [ 3.969725,-0.243892,-1.147973],
 [ 6.878236, 2.690031,-0.032822],
 [-4.507405,-0.209004, 3.527199],
 [-0.303958, 0.031588, 2.13669 ],
 [-2.413939, 0.990744, 1.855206],
 [ 3.199674,-2.667532,-3.367239],
 [-4.81107 ,-0.476329, 0.946992],
 [-5.190607, 0.306092, 2.144555],
 [-2.180918, 0.280374, 3.71547 ],
 [ 0.621021,-3.495934, 0.067819],
 [-5.522116,-0.234375,-0.818956],
 [-1.626045, 1.594567, 3.653042],
 [-0.807326, 0.565158,-2.157104],
 [-0.037662, 4.79948 , 3.817421],
 [ 0.310868, 1.314054, 3.2683  ],
 [-0.74095 ,-3.684098, 1.01356 ],
 [-0.874686,-3.659717, 1.055383],
 [ 4.505414, 4.04659 ,-0.327694],
 [ 1.056183, 1.392008, 0.635817],
 [ 1.082204, 1.60329 , 0.653045],
 [ 1.108728, 1.626355, 0.614876],
 [ 1.102986, 1.631561, 0.721333],
 [ 1.081613, 1.823179, 0.771915],
 [ 1.045376, 1.646848, 0.807527],
 [ 1.06505 , 1.659982, 0.616188],
 [ 2.848457, 1.316308, 1.475758],
 [ 1.046762, 1.584909, 0.754345],
 [ 1.01049 , 1.583979, 0.791759],
 [ 1.350391, 1.635384, 0.768148],
 [ 1.073573, 1.596729, 0.705764],
 [ 0.863791, 1.500693, 0.773854],
 [ 0.705714, 1.562477, 0.959386],
 [ 1.053703, 1.494411, 0.827966],
 [ 0.704214, 1.469306, 1.011429],
 [ 0.318048,-0.221936, 2.385348],
 [ 0.974452, 1.451243, 0.912731],
 [ 1.091843, 1.55611 , 0.757995],
 [ 1.0876  , 1.361569, 0.905543],
 [-0.412067,-2.695872,-5.422086],
 [ 5.423376, 0.886556, 1.219866],
 [-3.487778, 1.113863, 3.135884],
 [-4.892953,-0.545427, 3.566424],
 [-0.992838, 2.248032, 3.977693],
 [-0.5019  , 3.444066, 2.805411],
 [-2.417183, 0.5547  , 1.067871],
 [ 6.112092, 0.601682,-0.143021],
 [ 1.501552, 2.976961, 4.201136],
 [-0.552836,-0.005044, 2.144037],
 [ 5.182881, 0.379025,-0.075561],
 [-6.384801, 1.753894,-1.139867],
 [ 0.437362,-0.532167,-5.223308],
 [-3.870111,-0.047239, 3.461765],
 [ 3.600734, 1.09476 , 0.118944],
 [ 1.033548,-0.621007,-4.804751],
 [ 3.521756, 0.812102,-0.214371],
 [-3.694231,-0.673841, 3.34575 ],
 [-3.53777 , 0.0792  , 3.802864],
 [-0.514638, 0.010381, 2.033439],
 [-0.903041, 0.210711, 1.699609],
 [-0.796208, 0.196758, 1.605985],
 [-0.567795, 0.563363, 3.694606],
 [-1.63191 ,-0.65196 ,-2.094683],
 [-7.373613,-0.40918 , 2.614071],
 [-0.661852, 0.164803,-1.292773],
 [ 1.72412 , 1.08877 ,-2.924497],
 [-0.656102, 3.821569,-0.699788],
 [ 0.377184, 3.771567, 4.03839 ],
 [ 1.052727, 4.144686,-1.209404],
 [ 0.796083, 1.240845,-1.558097],
 [ 1.006522, 3.386475,-1.393235],
 [-0.574177, 0.973402,-0.816579],
 [-0.538694, 0.868137,-1.203774],
 [ 0.140078, 3.756052, 4.095458],
 [ 3.46228 ,-1.393633,-1.590505],
 [-0.383023,-0.354623,-1.790419],
 [ 2.423908, 0.163589, 0.122541],
 [-3.248043, 0.704701,-3.165636],
 [-1.049961, 2.948183,-0.653937],
 [ 3.787085,-2.826483, 0.295297],
 [ 0.021477, 2.706243,-0.68661 ],
 [ 0.264062, 2.983833,-0.317597],
 [ 1.115093, 3.433227,-1.211039],
 [-2.939715,-2.411931, 0.041394],
 [-2.495951,-1.600017,-4.313806],
 [-2.578573,-1.383475,-4.290278],
 [-7.281108,-0.428693, 2.556933],
 [ 0.023099, 1.917524,-0.051715],
 [ 4.773454, 3.628332, 0.049653],
 [-3.112219,-1.434284,-3.579593],
 [-1.089549,-0.556159,-2.391457],
 [-1.784909,-1.174147,-2.105541],
 [-2.356266, 0.038007,-3.58808 ],
 [-1.419981, 1.143247, 4.204504],
 [ 0.725854,-0.358291,-5.377695],
 [ 3.022848, 0.146058,-2.972527],
 [-2.517683,-3.26828 , 1.573309],
 [-4.516615,-0.27791 , 1.557479],
 [ 2.930692,-0.366928,-1.454877],
 [ 0.856054,-0.670739,-4.936583],
 [-0.713324, 0.199842, 1.794605],
 [-0.388784,-0.003636, 2.052295],
 [-5.003307,-0.205275, 3.075107],
 [ 1.823738,-2.030084, 1.942999],
 [-2.288744,-0.084469, 0.031978],
 [ 1.132279,-3.365704,-3.876897],
 [ 1.322474,-4.007967, 0.844664],
 [-3.350106, 0.27014 , 5.498261],
 [-6.414028,-1.950972,-0.408251],
 [ 0.864241,-4.222789,-0.624115],
 [-2.881693, 3.548122, 1.04393 ],
 [-2.097438, 1.066856, 2.775571],
 [ 3.778251,-0.366451, 0.390313],
 [-2.552462,-3.58095 , 1.331763],
 [-2.578558,-3.810045, 1.201593],
 [ 3.203192, 1.947767, 3.801627],
 [-2.605761, 0.395906, 1.186098],
 [-2.905686, 3.491682, 1.039168],
 [ 2.431432, 5.686677,-0.19257 ],
 [-3.269767, 0.348312,-0.838478],
 [ 1.007145,-1.839548, 2.32866 ],
 [ 1.929212,-2.222707, 1.861691],
 [ 5.213945, 4.570319, 1.187811],
 [-5.504953, 0.084814,-0.797136],
 [-0.598372, 2.660748, 4.147199],
 [-3.93355 , 0.319499, 0.838634],
 [ 1.185783, 0.020058, 0.352672],
 [ 3.699572, 0.526007,-0.974364],
 [ 3.804943, 1.391656,-0.051222],
 [-1.221854,-0.221296, 1.644624],
 [-3.491887,-1.324762,-0.522779],
 [ 4.952503,-2.138957,-0.618914],
 [-5.275443, 0.64355 , 0.123241],
 [ 3.904521, 3.372515, 1.458861],
 [ 4.587342,-1.487615, 0.276478],
 [ 2.771839, 1.796889, 0.350437],
 [ 1.023341,-1.828019, 2.256054],
 [-6.080323, 0.260137,-1.73732 ],
 [ 5.950438, 3.12822 , 1.619228],
 [ 3.967964,-0.219443, 1.92967 ],
 [-4.729097,-0.459085, 3.530887],
 [-0.751642, 4.40378 , 3.898948],
 [ 2.758529, 0.475953, 0.06262 ],
 [ 2.832015, 0.683234,-0.009469],
 [-6.365589,-2.297642,-0.511021],
 [-2.826879,-0.21475 , 2.061363],
 [-0.583405,-2.757777,-5.500317],
 [ 4.159245,-0.134029,-1.476337],
 [ 4.028814,-1.149527,-2.051412],
 [ 4.061007,-1.255084,-2.172648],
 [ 4.585595, 1.695583, 2.646236],
 [ 1.240291,-0.480085,-4.820259],
 [-3.656649,-4.816047,-0.203056],
 [ 6.748398, 0.243889,-2.430612],
 [ 0.045792, 4.087523,-0.927088],
 [ 0.8251  ,-0.798832,-4.298647],
 [-0.087694, 3.208741, 5.453255],
 [-0.841951, 1.257136,-2.309941],
 [ 2.791656,-0.868366, 1.788198],
 [ 4.03261 ,-1.482653, 1.366492],
 [-0.000324, 1.558313, 3.418008],
 [-1.323668, 0.959364, 2.982229],
 [ 5.31616 , 0.076062,-2.406675],
 [-0.135959,-3.583573,-1.134698],
 [-2.076389,-0.02313 , 1.703526],
 [ 0.133201,-3.957564,-2.48761 ],
 [-1.162992,-4.11617 ,-0.019544],
 [ 2.078609, 0.462372,-0.617891],
 [ 0.767887,-1.345845, 4.340344],
 [ 3.393427, 0.69124 ,-1.744416],
 [-0.556829, 2.485652, 5.599981],
 [ 3.99168 ,-1.212158,-2.162377],
 [-2.799958, 0.60209 , 1.050619],
 [ 1.622714,-0.336563,-1.16914 ],
 [-1.495804, 3.062912, 0.247317],
 [-0.250791,-3.015106,-5.588461],
 [-6.577118, 0.797744,-0.453489],
 [-0.151847,-3.074492,-5.562112],
 [ 1.888657,-0.417728, 1.539626],
 [ 0.800692, 0.13054 , 3.981002],
 [ 2.155464,-1.376465,-2.725863],
 [ 2.231199,-1.589616,-2.303834],
 [ 2.642284,-2.496774,-0.499414],
 [ 1.127023,-0.232233,-0.228476],
 [-2.733617,-0.692271, 4.245409],
 [-1.598308, 0.241933, 3.106353],
 [ 4.007145,-0.02238 ,-0.544627],
 [-0.487329,-3.530324,-1.68036 ],
 [-3.091212,-1.68713 ,-4.518621],
 [ 2.271413,-2.100725,-1.726425],
 [ 1.435387,-1.43038 ,-5.181623],
 [ 4.159596, 0.729464,-2.51773 ],
 [ 0.090857, 0.342412,-0.522904],
 [-0.263245,-2.962808,-5.745764],
 [-2.365522, 2.589751, 3.514484],
 [-0.748733,-5.729099,-0.396109],
 [-2.65058 ,-3.216531,-3.539844],
 [ 0.728061,-0.375827,-5.331779],
 [-1.092834, 3.230453, 0.510383],
 [ 6.546476, 1.499578, 0.951477],
 [ 0.116822,-3.939597,-2.488213],
 [ 0.40891 ,-3.656385,-2.817159],
 [-1.999701,-2.110004,-1.056857],
 [-3.783021, 0.045979, 1.371287],
 [ 1.028361, 2.528405, 2.225129],
 [-2.275856,-1.260631,-4.827132],
 [ 2.317358, 1.993491, 0.351432],
 [ 1.110631,-0.500248,-5.030651],
 [ 7.195798, 0.263992, 0.875869],
 [-0.42966 , 1.306539, 2.472071],
 [ 1.476414,-1.435037,-2.631114],
 [ 0.790163,-0.754295,-4.515099],
 [ 2.22446 ,-1.232386,-2.562289],
 [ 0.650488, 2.130436, 3.401522],
 [-0.551472, 0.73395 , 2.719895],
 [-0.369384,-1.157509, 0.808677],
 [ 4.990134, 0.544023, 2.522388],
 [ 0.511117, 0.30455 , 3.420989],
 [ 1.040928, 0.501868,-0.384726],
 [-3.415496,-3.367798,-1.033511],
 [-0.966122,-1.547549, 3.961409],
 [ 4.656862, 0.175301,-3.421589],
 [-1.053976,-0.57069 ,-2.504281],
 [ 0.500561, 3.93045 ,-0.845885],
 [-2.042806,-1.184284,-2.150737],
 [-2.718323,-1.138011,-2.309201],
 [-4.215059,-2.105779, 0.392841],
 [-3.274975,-1.450116,-1.935719],
 [ 0.500505, 3.322213,-1.119838],
 [-2.963143,-1.669978,-4.410901],
 [-3.202482,-1.268824,-3.67218 ],
 [-2.888765,-1.618508,-4.382151],
 [-3.409557,-1.636451,-3.722594],
 [-1.902019,-1.765417,-4.252924],
 [-4.321739,-2.0999  ,-2.109815],
 [-1.902841,-1.761424,-4.259043],
 [-4.446515,-1.819525,-2.026832],
 [-2.667837,-3.08606 ,-3.273299],
 [-4.22158 ,-2.262204,-2.039395],
 [-4.442422,-1.704738,-1.737706],
 [-2.633032,-2.938361,-3.659743],
 [-2.898304,-1.570339,-4.208211],
 [-4.105556,-2.284091,-2.144441],
 [-4.111276,-2.082757,-2.196116],
 [ 0.840184,-3.720131,-1.169283],
 [-2.827867,-1.679523,-4.635287],
 [-3.242072,-2.76375 ,-1.867409],
 [-4.38915 ,-3.752023,-1.063296],
 [ 2.474399,-2.163596,-0.865327],
 [ 2.153789,-1.020842, 0.369365],
 [ 1.064397, 3.414166, 1.761813],
 [ 1.094636, 4.008727, 0.180379],
 [-0.762217, 0.35716 , 0.366715],
 [ 3.279379, 2.547492, 0.308544],
 [ 7.20852 , 0.269132, 0.888716],
 [ 1.87811 , 3.276852, 3.411655],
 [-4.07952 , 0.996305, 2.812428],
 [-3.957746, 1.193135, 3.097679],
 [-3.467116, 1.225646, 1.815808],
 [-4.753405, 0.036319, 4.233849],
 [ 3.934919,-1.162631,-2.07346 ],
 [ 1.703604,-1.18987 ,-2.487544],
 [ 3.02957 ,-2.337889, 1.111353],
 [ 0.105555, 3.869183, 2.333071],
 [ 0.447234, 3.98162 ,-1.032089],
 [-7.231039,-0.257064, 3.090311],
 [-2.874866,-2.345928,-1.815581],
 [ 3.073273,-2.6205  ,-3.553085],
 [ 0.439647, 2.00669 ,-0.010776],
 [ 4.883296, 0.634551, 0.975468],
 [-1.919096,-0.186203, 3.398043],
 [-1.425902,-2.582163, 0.928568],
 [ 0.645758, 4.00041 ,-1.273461],
 [-0.377683, 4.128779,-0.912235],
 [ 1.791016, 1.76251 , 2.278294],
 [ 2.122224,-0.033877,-0.408687],
 [-2.641361, 0.387535,-0.117666],
 [ 1.500677, 3.072813,-0.911547],
 [ 3.189513,-1.049425,-2.981693],
 [ 1.617518,-3.532404,-4.504396],
 [ 4.918245,-0.160281,-0.110164],
 [ 0.834507, 0.376683,-3.625875],
 [ 2.633837,-1.29548 ,-3.010406],
 [ 6.46077 , 0.39779 ,-3.001681],
 [ 1.104426,-3.758001,-1.227067],
 [-1.095807,-3.543056, 1.046301],
 [-2.632549,-0.672965, 4.18655 ],
 [-4.000997, 2.129151, 2.05351 ],
 [ 0.561935, 4.056447,-1.20368 ],
 [-2.620233,-1.569259,-4.593017],
 [ 2.755103,-0.829927,-0.918881],
 [ 3.91748 ,-1.285271,-4.25148 ],
 [ 2.04919 ,-2.207242,-1.5805  ],
 [ 0.347523,-2.494436,-2.482624],
 [-3.165272, 0.616732, 3.15531 ],
 [ 4.57249 , 0.781143, 0.411429],
 [-3.473418,-4.686371,-0.328882],
 [ 1.598712,-2.672358,-2.260312],
 [ 4.051806,-1.190051,-2.140813],
 [ 3.895507,-1.161658,-2.104894],
 [ 5.740883,-1.586723,-0.178301],
 [ 4.050192,-1.192323,-2.133912],
 [ 5.194442, 0.133658,-2.245356],
 [ 3.991531,-1.081542,-2.058217],
 [ 4.060098,-1.236199,-2.171799],
 [ 5.111965, 0.486765,-0.333439],
 [ 2.47042 , 0.504048,-4.170808],
 [-0.401217,-1.247651,-0.964073],
 [-2.73288 ,-0.718853, 1.791185],
 [ 5.37698 , 0.628353,-0.015074],
 [ 3.312454, 0.447443,-2.142052],
 [-5.447686, 0.541526,-0.74434 ],
 [-3.512493, 0.89104 ,-2.270355],
 [ 2.852582,-2.448457,-0.519477],
 [ 4.220379, 0.50995 ,-0.706417],
 [-0.516633, 3.04928 , 2.208493],
 [ 1.574749,-3.53377 ,-4.421792],
 [ 1.617089,-3.618195,-4.756438],
 [-1.165236,-5.323194,-1.709217],
 [ 1.936854,-0.76319 ,-1.857147],
 [ 5.387526, 3.127255,-0.451971],
 [ 3.423742, 2.174104, 0.557059],
 [ 0.569891,-0.313338, 3.842872],
 [ 1.29723 , 3.813486, 0.179461],
 [ 4.819748, 3.594779,-0.138727],
 [ 2.452308,-2.110025,-0.885654],
 [ 2.543167,-2.139441,-0.862348],
 [ 2.33749 ,-1.802562,-0.842693],
 [ 1.799028,-0.105886,-1.241758],
 [-2.735476,-1.647853,-4.539784],
 [-2.9183  ,-3.053228,-2.175507],
 [ 4.784944, 3.380909, 0.709068],
 [-4.497154,-3.464383,-0.738166],
 [-4.500617,-3.826583,-0.80102 ],
 [-4.642651,-2.040478,-0.645104],
 [-2.874914,-3.097046,-2.089128],
 [-2.881191,-3.082636,-2.190689],
 [-1.748265, 1.202549, 5.723839],
 [ 0.947986, 3.387136, 1.849317],
 [ 0.564798, 3.097861, 1.326723],
 [ 0.456643, 2.61737 , 2.139596],
 [-0.153078, 1.555649, 1.500522],
 [ 4.787638, 3.342724, 0.853147],
 [ 0.897375, 3.538354, 1.797322],
 [ 2.846397, 3.065005, 0.938524],
 [ 0.414325, 4.181292, 1.584113],
 [-1.332522,-1.538022,-2.424017],
 [ 0.399637, 4.163742, 1.390428],
 [ 2.005035, 3.53025 , 0.564482],
 [ 1.31318 , 3.76652 , 0.283403],
 [ 1.215268, 4.154878, 0.595585],
 [ 2.840304, 2.546304, 0.663989],
 [ 2.437946,-1.438946, 1.89493 ],
 [ 0.173439,-2.918955, 1.314288],
 [ 0.159676,-2.909848, 1.329222],
 [ 1.862699,-2.161392, 1.858403],
 [ 4.66593 , 0.190423,-3.360895],
 [ 3.755541, 0.485497,-2.668656],
 [ 1.482753,-1.406365,-0.898306],
 [ 5.698507, 3.368523, 1.435159],
 [ 1.121534,-3.716817,-1.264645],
 [ 5.859688,-1.59174 ,-0.025953],
 [-2.738302,-0.259568, 1.450192],
 [ 0.597638,-0.086538, 0.414267],
 [ 4.805393, 1.339346, 0.299441],
 [-5.376202,-0.406253, 0.521429],
 [ 4.112888, 0.791841,-2.591422],
 [-0.723947,-3.248249,-0.413237],
 [ 3.934774,-1.118964,-2.074192],
 [ 5.848779,-1.597463,-0.06597 ],
 [ 2.978517, 0.173395,-3.739722],
 [ 2.539197,-0.820475,-0.851347],
 [ 4.216891,-1.226276,-4.174397],
 [-0.796341,-3.611062,-1.068206],
 [-4.95295 ,-3.118836,-1.234025],
 [-2.466759,-0.727917, 2.854015],
 [-0.975703, 1.091894, 2.957798],
 [ 2.43923 ,-0.543384,-2.347139],
 [ 5.05759 , 1.85856 ,-1.355283],
 [ 1.990281,-1.231127,-2.622643],
 [-0.69436 ,-0.002112,-2.21262 ],
 [-1.295034,-0.916627,-1.881091],
 [ 6.274735, 0.293891,-1.617961],
 [ 2.911301, 1.311785,-0.462977],
 [ 0.514466,-2.088388, 2.170571],
 [ 2.21785 ,-1.246735,-2.399557],
 [-0.508686,-3.320163,-1.097128],
 [ 2.79321 ,-1.681938,-3.889478],
 [-0.107637,-4.174984,-0.121577],
 [ 2.41498 ,-1.250635,-1.291241],
 [ 3.574755, 0.275408, 1.742932],
 [ 5.33194 ,-2.345367, 0.063827],
 [ 4.623777,-2.230832, 0.117035],
 [-1.606827,-2.07014 ,-1.037783],
 [-3.565174,-1.036943, 0.742831],
 [ 2.686595,-2.199408,-4.279245],
 [ 4.20513 , 0.589155,-0.818933],
 [ 0.787742,-0.3218  ,-3.924151],
 [ 6.375612, 0.270578,-1.666126],
 [ 1.367452,-0.119847,-3.391575],
 [-0.524884, 3.862998,-0.931793],
 [ 0.482597, 0.589909,-3.670551],
 [ 2.970089,-1.58341 , 0.143832],
 [ 2.536443,-1.272473, 0.236787],
 [ 3.216494, 1.644986, 1.126217],
 [ 4.733461,-1.4948  ,-0.079931],
 [ 0.53781 ,-0.160718,-2.653617],
 [-1.180914,-0.291099, 1.940835],
 [-3.474424, 2.241359, 4.811045],
 [-0.450863, 4.230746,-0.97002 ],
 [-0.667062, 3.84184 ,-0.600687],
 [ 1.803478, 4.429523, 1.013803],
 [ 3.675175, 2.444745, 1.659549],
 [-3.535345, 2.422599, 4.825756],
 [-4.861118, 0.647511, 0.484838],
 [ 1.732538,-0.070491, 0.580688],
 [-2.001496,-3.502067,-2.623392],
 [ 0.623612, 2.14195 , 3.413488],
 [ 0.825211, 0.106645, 4.122418],
 [-0.604323,-0.378459, 4.65511 ],
 [ 1.360402, 0.750625,-0.093702],
 [ 1.694694, 0.39455 ,-1.202424],
 [ 4.86945 ,-1.878327,-0.630384],
 [-1.316259,-3.480248, 1.018414],
 [-2.369804,-2.77291 , 0.519724],
 [-3.708698,-0.962586, 1.72296 ],
 [-6.716538,-0.484723, 3.041056],
 [-4.439959,-0.824761, 2.445101],
 [-1.387927, 3.364705, 0.517928],
 [-1.515963, 3.491726, 0.481197],
 [ 2.776074, 1.374617,-0.312842],
 [-0.619143, 0.171745,-2.521527],
 [ 1.197433, 0.146188, 6.012665],
 [-2.582747,-4.407987, 0.700151],
 [-5.164855,-0.943192,-0.103284],
 [ 0.063043,-0.410449, 3.376488],
 [ 0.966034, 0.222869, 4.249882],
 [ 0.778369,-0.774022, 3.685236],
 [ 3.87618 , 1.029639, 0.002017],
 [ 5.416724, 0.311442,-2.304153],
 [ 1.401491, 0.670156,-2.231798],
 [ 2.193781,-1.349488,-3.736051],
 [ 1.676667,-1.349867,-2.983779],
 [ 0.405695,-0.25505 , 0.671061],
 [ 0.816544, 0.159522, 0.41418 ],
 [-0.070624, 3.277469, 5.501689],
 [ 2.752613,-3.730528,-0.285266],
 [-2.859056, 0.023677, 1.304294],
 [ 0.732508,-1.204537,-1.371393],
 [ 5.06965 , 2.33622 ,-0.221186],
 [-4.015998,-0.743421,-1.596915],
 [ 0.557662,-3.268369,-3.088233],
 [ 2.703644,-0.912172, 1.87146 ],
 [ 0.212847, 2.443035, 2.839295],
 [-0.675331,-3.136157, 0.556178],
 [ 3.807909,-2.698098,-3.746742],
 [-2.472916,-2.386787,-1.113051],
 [ 3.064165,-1.147769,-3.02732 ],
 [-3.887254,-3.373925,-1.240635],
 [-4.665236,-3.197262,-1.026809],
 [ 5.378514, 0.275355, 0.359511],
 [ 0.733211,-2.5043  ,-0.074261],
 [ 3.482894,-2.669534,-3.705643],
 [ 1.43458 ,-0.348796,-4.905091],
 [ 2.237698,-2.046048,-1.587977],
 [ 2.158496,-1.535205,-2.391246],
 [ 3.099717,-2.950128,-3.07926 ],
 [-0.272413, 4.261052,-1.157493],
 [-0.658195, 4.449551,-1.234714],
 [-0.685864, 4.417845,-1.227971],
 [-0.743616, 4.172178, 0.857073],
 [ 2.402599, 0.752622,-4.234781],
 [-0.800074,-3.61234 , 1.051473],
 [ 1.472692, 1.456823, 3.159621],
 [ 1.597287,-0.372578,-1.228677],
 [ 3.096925,-2.682907,-4.077402],
 [ 1.38045 ,-0.523256,-4.543452],
 [ 1.457605,-3.56563 ,-4.662417],
 [ 0.044244,-1.212549,-5.435357],
 [-2.357953,-0.99305 , 3.092263],
 [-1.057385, 2.54209 , 2.225273],
 [ 1.385261,-0.738711,-3.278109],
 [ 2.274166,-0.803515,-2.862718],
 [-0.540331, 3.981888,-1.047746],
 [-2.706688,-0.01616 , 0.042516],
 [ 0.612226,-2.997728,-0.823399],
 [-3.491826, 0.028375, 0.957817],
 [ 1.464496,-0.587639,-1.321362],
 [ 0.240814,-2.96777 , 0.146127],
 [ 4.042704,-1.255647,-2.140424],
 [ 4.232196,-1.118602,-1.842913],
 [ 5.191074, 0.046144,-1.32114 ],
 [ 2.50901 , 0.376925, 0.465588],
 [-0.307378,-2.942452,-5.386278],
 [ 4.951223, 1.457242,-0.070451],
 [ 4.048615,-1.236843,-2.174141],
 [ 1.989347,-1.484431,-2.412046],
 [-0.429256, 0.000863,-2.469652],
 [ 5.67141 , 1.456294, 0.303779],
 [ 2.284012,-0.706251,-2.664647],
 [ 0.73251 ,-0.896802,-5.216031],
 [ 1.8906  ,-2.192545, 1.772598],
 [ 1.061194,-2.40257 ,-1.302311],
 [ 1.350176,-0.688841,-3.301612],
 [ 0.978164,-3.180403, 1.073959],
 [-0.627572, 2.53324 , 5.599259],
 [-1.89203 ,-0.284756, 0.705057],
 [-3.055159,-2.51423 ,-0.842219],
 [ 4.506061,-0.103395,-0.394301],
 [ 0.71106 ,-0.375047,-5.303742],
 [ 4.037917,-1.244533,-2.160812],
 [-2.949147, 0.844236,-0.943757],
 [ 3.571382, 0.618189, 0.913157],
 [-5.907093,-0.028952,-0.757157],
 [ 1.908364, 1.4974  , 1.906734],
 [ 2.611909, 0.651071,-0.596718],
 [ 0.230212,-0.669439,-0.301766],
 [ 2.271971, 1.822833, 0.507012],
 [-0.076285, 3.26399 , 5.495   ],
 [ 3.238942,-1.538723,-3.471737],
 [ 0.812371, 3.257977,-1.753878],
 [ 1.368579,-0.660419,-4.590466],
 [ 1.039204, 1.695566,-1.074454],
 [ 4.727608,-2.276192,-2.615746],
 [ 0.526348,-2.991905,-3.55634 ],
 [-0.705495,-3.355791,-4.047373],
 [-3.245701,-1.337972,-2.052793],
 [-2.691618,-4.230264, 0.346317],
 [ 1.09466 , 1.964845,-0.488124],
 [ 6.179759, 0.366362,-0.539742],
 [-5.157685, 0.036064, 2.818405],
 [-4.569337, 0.994241, 1.653614],
 [-4.431823, 0.939061, 1.735754],
 [-3.719083, 1.467632, 3.302002],
 [ 0.284934, 1.016599, 0.810379],
 [ 0.319563, 3.591717,-0.539589],
 [ 1.518661, 4.50251 ,-0.613521],
 [ 0.221498, 3.623393,-0.583886],
 [-5.362348, 0.288797, 3.3837  ],
 [-5.267906, 0.508387, 3.428417],
 [-3.812384, 1.609892, 3.543915],
 [-4.39286 , 1.004246, 3.317862],
 [-4.741292,-0.38369 , 3.418264],
 [-0.950303,-3.709681,-2.942857],
 [-1.582796,-3.471179,-2.504473],
 [-5.180581, 0.54093 , 3.255893],
 [-3.800986, 0.736252, 3.651354],
 [-4.863069, 0.571436, 2.534195],
 [-0.843156,-0.366282,-3.007196],
 [-4.281606, 0.99601 , 2.681408],
 [-4.798418,-2.813583,-1.337052],
 [-4.679316, 1.14823 ,-0.49672 ],
 [ 4.035645, 0.44367 ,-0.441452],
 [ 5.151375, 1.236321, 1.077702],
 [-4.12604 , 1.180121,-1.581347],
 [ 4.09649 , 0.532757, 0.04702 ],
 [-2.796815,-0.7593  , 0.412927],
 [-5.097707, 0.660907, 3.15988 ],
 [-4.682856, 1.179253,-0.834889],
 [-4.591062,-0.165024, 0.005563],
 [-4.437261,-0.203043, 0.16399 ],
 [-4.65322 , 1.011331, 0.545642],
 [-3.592061, 0.27114 , 0.77996 ],
 [ 4.653983, 0.230498, 0.237153],
 [-3.484578,-0.967443, 3.312606],
 [-3.315965, 2.875226, 0.317307],
 [ 4.566712, 0.994249, 1.873599],
 [-1.208642,-1.033115, 0.574379],
 [ 3.868344,-1.35692 ,-3.958966],
 [ 6.482125, 0.010666, 2.096906],
 [-0.460486,-0.322922,-1.318715],
 [-1.163236, 0.055995, 1.856709],
 [ 4.981021, 1.481916, 0.944652],
 [-1.038647,-3.829893,-1.189448],
 [-1.570257,-3.518389,-2.515259],
 [-2.013134,-0.378892, 1.10928 ],
 [-1.277211,-2.142049,-0.377803],
 [-1.968446,-0.520968, 0.761546],
 [ 2.132961, 3.308986, 3.204083],
 [ 0.317145,-3.338607, 0.024599],
 [ 5.352025, 3.18951 ,-0.43324 ],
 [-1.195835,-1.536604,-0.30059 ],
 [-2.652649,-1.087956,-0.258988],
 [-1.079061,-1.018121, 0.708771],
 [-1.058641,-0.960082, 0.753062],
 [ 2.847555, 0.686025, 0.058684],
 [ 0.90624 , 1.221286, 4.263286],
 [ 0.251182,-5.580138,-0.939494],
 [-6.695201,-1.896034,-0.642472],
 [-0.825159,-0.521511,-2.930265],
 [-3.399729,-1.594081,-4.02391 ],
 [-0.789417,-4.606182,-3.055815],
 [-0.899865,-4.413586,-3.12331 ],
 [-0.774217,-0.138564,-2.252495],
 [ 5.400123, 3.172443,-0.441805],
 [-3.259012,-4.134217,-1.318308],
 [-0.69798 ,-4.433929,-3.247462],
 [ 3.035431,-2.468328,-4.363322],
 [ 1.450456, 4.089627, 4.174837],
 [-1.724145,-3.411164,-2.827946],
 [ 3.666813,-2.526819,-3.980518],
 [-5.947693,-1.995583,-0.137874],
 [ 3.699   , 0.564922,-4.633854],
 [-3.942791,-0.467023,-2.986863],
 [ 5.038289, 0.454471, 0.018847],
 [-3.297422,-1.417062,-2.541825],
 [ 5.368373, 0.496131,-0.139664],
 [-2.323875,-0.277761, 0.374361],
 [-3.074206,-0.103654, 1.254878],
 [ 4.616689,-1.219816,-0.070413],
 [ 6.486989, 0.233829,-1.678247],
 [-0.457909,-3.73752 ,-3.752045],
 [ 0.839723,-0.916053,-0.564387],
 [-4.971065, 1.822695, 0.86323 ],
 [ 2.83184 , 5.447495, 0.143107],
 [ 1.60564 , 2.040542,-1.478892],
 [-2.94499 ,-0.89189 , 0.369095],
 [-5.402791, 0.360581, 3.476345],
 [ 5.729794, 1.209973, 2.363626],
 [ 4.621313, 0.024315,-0.156261],
 [-3.842714, 1.2471  , 3.242541],
 [-3.745191, 1.579876, 3.43557 ],
 [-0.917956,-0.113492, 1.446929],
 [ 2.462173, 1.140349, 0.221174],
 [ 6.646895, 0.221258,-1.768143],
 [-6.715129,-2.260824,-0.485107],
 [ 3.210753, 1.152345, 3.100007],
 [ 1.051225, 1.669347, 0.789452],
 [ 1.495085, 5.241123, 2.780785],
 [ 0.60475 ,-3.555846, 0.094093],
 [ 6.540966, 0.407453,-3.115885],
 [-3.987366,-1.737299, 1.361858],
 [-0.386389,-3.760638,-2.428771],
 [ 6.131943, 0.247042,-0.85638 ],
 [ 0.78914 ,-1.099018,-0.729727],
 [-1.383197, 1.353469, 2.621866],
 [ 6.58387 , 0.253542,-2.472241],
 [-3.658729,-2.265051,-0.018365],
 [-2.800797,-0.76034 , 0.432906],
 [-1.230306,-2.113082,-0.349801],
 [-0.528641, 2.0269  ,-2.140327],
 [-2.342107,-0.283508, 1.737584],
 [-0.50116 ,-1.1782  ,-4.15013 ],
 [-4.522024,-0.80294 , 2.620224],
 [-4.483087,-0.895571, 3.472193],
 [ 5.498797, 4.636247,-0.292087],
 [-0.338649,-3.588471, 0.205441],
 [-4.159968,-0.226665, 0.432391],
 [ 2.204959,-0.915671, 1.499785],
 [ 2.01317 ,-1.191156, 1.581225],
 [ 1.32073 ,-3.620899,-3.961813],
 [-0.33974 ,-3.419842,-4.219587],
 [-0.302551,-0.613792, 1.655117],
 [-3.444661,-1.597551,-0.497448],
 [ 2.281925, 0.161328,-0.157487],
 [ 3.009832, 1.048226, 0.100004],
 [ 2.499115, 0.305571,-0.161896],
 [ 4.262044, 1.657921, 1.58762 ],
 [ 3.607931,-2.214135,-4.061811],
 [ 5.629564, 0.07878 , 0.504674],
 [ 0.318073,-5.553133,-0.987282],
 [-2.063932, 1.041889, 2.809808],
 [ 5.304908, 0.342596, 0.026219],
 [ 3.005067,-2.203386,-4.27385 ],
 [-2.380037,-1.299259,-0.576551],
 [-0.092478, 0.284821, 4.462378],
 [-0.020862, 0.276681, 4.142423],
 [-0.579639,-2.706203,-2.444978],
 [-7.18455 ,-0.207592, 3.036676],
 [-0.341634, 0.510218, 5.426955],
 [ 6.84914 , 0.133649,-1.831937],
 [-1.874581, 2.086292,-0.675697],
 [ 6.922682, 0.198554,-1.903681],
 [ 6.202779, 0.961362, 1.792067],
 [-3.448374,-2.026294, 0.039388],
 [ 7.076831, 2.31394 , 0.36894 ],
 [-4.021955, 0.624243, 1.35701 ],
 [-4.227986,-4.897183,-0.648883],
 [-4.180341, 0.15069 , 1.192443],
 [ 0.603596,-0.32053 , 1.796162],
 [-4.611243,-3.800461,-0.613314],
 [-1.264005,-3.668363,-0.132886],
 [-3.599379,-2.045933,-0.281728],
 [ 1.466532,-4.060383, 0.82597 ],
 [-0.338166,-2.746036,-1.22299 ],
 [ 1.319197,-0.614022, 1.591462],
 [-2.654867, 0.656969,-0.816506],
 [-2.835263,-0.592909,-1.294086],
 [ 6.945093, 0.196355,-1.894357],
 [ 3.795049, 0.87882 , 0.151646],
 [-3.777679, 0.551878, 3.704007],
 [ 5.276468, 0.491518,-0.520825],
 [ 3.809026,-0.662655,-3.715196],
 [-5.483608, 0.246434, 3.636561],
 [-5.436347, 0.234814, 3.540722],
 [-4.621991, 0.468646, 2.569954],
 [-3.900059,-1.746668,-0.178044],
 [ 1.498046,-3.604684,-4.223639],
 [ 2.44994 ,-0.162261, 0.673944],
 [-3.908085, 2.308158, 3.217568],
 [-0.684372, 2.114241,-2.054184],
 [ 6.265399, 0.289156,-1.268815],
 [ 4.207879, 1.234962, 0.645074],
 [-4.390761, 0.601356, 2.59567 ],
 [-3.144459, 0.082704, 2.227521],
 [ 3.986311, 2.654971,-1.355216],
 [ 5.602651, 4.734001,-0.251549],
 [ 0.820324,-1.094627, 0.095924],
 [-3.263939, 2.916725, 0.256581],
 [-4.685668,-3.008257, 0.536936],
 [ 2.549543, 0.325568, 1.048003],
 [-3.682026, 0.545837,-2.078701],
 [-6.235224,-2.269359,-0.384813],
 [-0.235679,-3.165724,-3.730139],
 [ 4.613445, 0.84652 , 0.105193],
 [ 6.265912, 0.303374,-2.15937 ],
 [-0.430669, 1.679106,-2.357497],
 [-1.245059,-1.126315, 1.773127],
 [-4.491811,-1.915614,-1.124626],
 [-3.301172,-1.833997,-0.948877],
 [-0.903335, 3.493129, 0.499237],
 [-2.569746,-3.748412,-0.526308],
 [-6.426111,-1.950314,-0.420954],
 [-2.202992,-0.740236,-0.073265],
 [ 2.814937,-2.048292, 1.662082],
 [-4.763493, 1.26719 , 1.194218],
 [-2.927989,-1.699335,-4.841724],
 [-1.811159,-0.892063, 0.379274],
 [ 2.791676, 0.832739,-2.102442],
 [ 2.976166, 0.93684 ,-2.285839],
 [ 3.074841, 0.85857 ,-0.047796],
 [ 2.801435, 0.901493,-2.08624 ],
 [ 2.912579, 0.980148,-2.269849],
 [ 1.361487, 2.98271 , 0.895651],
 [ 2.546753, 2.42942 , 3.8971  ],
 [ 2.863542, 0.976256,-2.30084 ],
 [ 2.676052, 0.608705,-1.744714],
 [ 6.37895 , 3.157259, 0.137029],
 [ 6.547529, 1.499945, 0.94975 ],
 [ 0.624484,-3.197212,-2.471558],
 [ 5.924399, 3.094599, 1.596974],
 [-4.387271,-1.802044,-0.981011],
 [ 5.937252, 0.307634,-1.694812],
 [ 2.834526,-2.047842, 1.691416],
 [ 1.894699,-1.454972,-1.215091],
 [-1.221578,-1.721827, 2.090625],
 [-1.669365,-0.780794, 1.060844],
 [ 2.638927, 0.711393, 0.015373],
 [ 0.165129,-0.14502 ,-0.539272],
 [-2.986716,-1.692243,-4.466372],
 [-1.722358,-3.957433,-2.987395],
 [-3.588435,-1.977895,-0.297357],
 [-6.596056,-2.278439,-0.41539 ],
 [-0.36501 ,-4.389101,-1.609112],
 [ 3.287843, 0.619748, 0.502914],
 [-0.746611,-4.761016,-2.955067],
 [-0.523914, 0.907769,-4.327586],
 [ 1.047474,-3.778141,-0.01421 ],
 [-2.087901,-0.738123, 1.12714 ],
 [ 0.054102, 6.26473 , 1.668115],
 [-6.758849, 0.873492, 1.802169],
 [ 4.470361, 0.269316,-0.540958],
 [ 1.245128, 2.902992, 4.329941],
 [-1.367073, 1.30888 , 2.251584],
 [ 5.653378, 4.732622,-0.271711],
 [-1.352973, 1.600162, 1.300624],
 [ 1.464247,-1.595641,-1.304428],
 [ 0.82716 , 0.675315,-0.342882],
 [-2.472459, 0.171018, 2.369326],
 [ 0.508203, 4.03827 ,-1.041148],
 [-3.029903,-1.794497,-1.874571],
 [ 3.754896,-2.755855,-3.907654],
 [-6.730297, 0.945882, 1.769901],
 [-2.789433, 0.199911, 2.399136],
 [ 1.478192,-3.748549,-4.345627],
 [ 3.312918, 0.501289, 2.251806],
 [-4.179089, 0.565028, 1.299693],
 [-1.439534,-2.842596,-2.285783],
 [ 1.525096, 0.155344, 0.971815],
 [ 0.3738  , 0.277745, 0.629138],
 [-4.4381  ,-1.765669,-1.111736],
 [-0.618789, 2.160995,-1.976688],
 [-1.160381,-1.418988, 1.048697],
 [ 2.910846,-3.26484 ,-1.808404],
 [-2.587583,-4.126003, 1.014457],
 [-2.570732,-4.220786, 0.912511],
 [-2.590117,-4.20308 , 0.899029],
 [-2.76713 , 0.387882, 1.593927],
 [ 4.954757, 0.67642 , 0.703948],
 [ 4.752619,-1.504642,-0.065966],
 [ 1.222688, 2.901215, 4.341455],
 [-4.95383 , 1.808046, 0.886383],
 [-3.01408 ,-3.452765,-1.717106],
 [-2.675542, 0.000141, 2.321186],
 [ 0.723349, 1.250043, 4.158128],
 [-1.429128,-0.865705, 0.300456],
 [-1.421195,-0.929937, 0.293138],
 [-1.243025,-1.212753,-0.266384],
 [-0.369351,-1.745388,-0.564112],
 [-0.648153, 2.506531,-1.848376],
 [-1.372182,-2.810179,-3.272319],
 [ 3.785565, 1.121211, 1.026868],
 [-1.718461,-3.446803,-2.811997],
 [-1.192665, 5.79826 , 0.340272],
 [-1.389639,-0.899865, 0.277768],
 [-1.403554,-0.950001, 0.262296],
 [-0.367982,-0.026812,-0.945383],
 [ 4.652479,-2.317421,-0.911944],
 [ 4.827375, 0.534104,-0.07528 ],
 [ 5.563703, 4.715248,-0.244365],
 [ 4.09059 , 2.06255 ,-1.032357],
 [ 4.770753,-1.503564,-0.058866],
 [-0.704164, 4.391185,-1.202193],
 [ 1.668755, 1.950802, 1.235322],
 [ 0.824084, 1.808229, 2.299053],
 [ 0.783203, 2.231238, 2.440094],
 [-1.600285,-0.642867, 0.711151],
 [-1.130155,-1.441991,-0.400018],
 [-1.26618 ,-1.180002, 0.034927],
 [ 4.543231, 0.570681,-0.102296],
 [-1.426547,-1.076879,-0.125642],
 [-0.180135,-4.259594,-0.042011],
 [-0.558421,-0.557072, 3.496174],
 [-4.75476 , 0.463288,-0.305754],
 [-2.548583,-4.145285, 0.950376],
 [-3.239175,-0.237497, 0.715491],
 [ 0.500713,-0.538959,-1.504702],
 [-5.393815,-0.709695, 0.201332],
 [-3.213449, 2.936087, 0.185163],
 [-3.517987,-0.154044, 2.073623],
 [ 2.810987, 0.550869,-0.039641],
 [-0.670042,-2.890703,-5.194468],
 [-1.471037,-2.805542,-2.166071],
 [ 1.207223, 0.150069, 6.010751],
 [ 0.173877,-2.844692,-5.448271],
 [-1.275764,-1.006188, 0.16429 ],
 [-1.431999,-1.076236,-0.13335 ],
 [ 0.774326, 0.507135,-1.21284 ],
 [ 3.847203, 1.24214 , 2.78738 ],
 [-0.60788 ,-3.842789,-2.170536],
 [ 2.752014, 0.71248 , 1.008255],
 [-1.109129, 1.676232, 1.364122],
 [-0.178828, 3.169144, 2.857501],
 [-6.777799, 0.779093, 1.806813],
 [-5.029456, 0.413345, 2.916211],
 [-3.785339, 1.424604, 3.409143],
 [-2.370336,-2.203126,-2.496003],
 [ 0.795674, 0.606082,-1.957607],
 [-4.561662,-3.795717,-0.845834],
 [-4.575922,-3.814115,-0.684492],
 [ 3.720201,-2.510294,-4.116495],
 [-1.649489,-1.70876 ,-4.285724],
 [ 1.645486,-2.75719 ,-4.036493],
 [ 1.867037,-2.093973,-4.055113],
 [ 3.898379,-2.879069,-3.502979],
 [-3.922208, 1.269488, 3.259606],
 [-3.844367, 1.532771, 3.557533],
 [-3.724216, 1.539903, 3.46883 ],
 [ 7.823118, 1.442041,-0.197616],
 [-4.883351, 0.476206, 2.513463],
 [-0.270765,-0.169548, 0.682032],
 [ 5.825947,-1.126961, 2.028318],
 [-2.27677 , 0.811674,-3.345349],
 [ 0.315317, 0.118032,-1.478278],
 [-1.381942,-1.15984 , 0.216846],
 [ 0.562691,-3.399992,-3.882854],
 [ 3.138663, 1.780829, 2.079735],
 [ 4.380467, 1.741383,-0.683642],
 [-0.168281,-1.216105, 1.251813],
 [-0.175213,-2.968267,-5.676009],
 [-6.223727,-2.255643,-0.409119],
 [-1.999528,-3.140877, 1.359991],
 [-0.58878 , 0.000925,-2.965128],
 [ 6.705399, 3.249627,-0.024875],
 [ 0.219485, 2.046565, 0.435229],
 [ 1.291123,-0.811649,-4.775239],
 [-1.258969,-0.981526, 0.179338],
 [-1.361264,-0.838255, 0.313752],
 [-1.377565,-0.935147, 0.257089],
 [-1.374998,-1.008655, 0.186835],
 [-2.499034,-1.371231,-0.602678],
 [-0.915395,-0.022779,-3.928502],
 [-0.885124, 4.393928, 3.870335],
 [ 4.724152,-1.081733,-0.058112],
 [ 4.636497, 0.606038,-0.157083],
 [-6.696718,-2.043719,-0.622582],
 [-3.371519,-2.524694,-1.909745],
 [ 0.284585, 0.159406,-1.349779],
 [-6.683084,-2.10028 ,-0.655468],
 [ 4.244142, 4.098551,-0.084516],
 [ 4.445113, 4.042293,-0.269507],
 [ 5.828293,-1.154842, 2.044846],
 [ 5.200185, 0.626981, 0.940451],
 [ 4.742295, 0.850749, 0.290601],
 [-0.600614, 1.947669,-2.198451],
 [ 3.42364 , 3.307508,-0.200252],
 [-4.595308,-3.81379 ,-0.683967],
 [-6.511373,-2.29747 ,-0.342507],
 [-2.093135,-1.052887, 0.069867],
 [ 0.880203,-0.676461,-4.927398],
 [ 6.540022, 2.099837, 0.00979 ],
 [ 2.834393, 3.906002, 3.524748],
 [ 4.479386, 2.344382,-0.662291],
 [ 4.494016, 2.357587,-0.676898],
 [-0.993585,-1.608945,-0.624289],
 [-1.408578,-3.804517,-3.307264],
 [-1.179163,-2.95447 ,-1.850608],
 [-6.559032,-2.166837,-0.375673],
 [-4.347383, 0.610495, 2.287666],
 [ 2.421741, 0.335322,-4.181711],
 [ 0.688331,-1.346894,-3.335049],
 [-1.181371,-3.406976,-3.715182],
 [ 2.43591 , 0.359554,-4.185101],
 [-3.376889, 1.844299, 0.8984  ],
 [-0.725578,-3.076442, 0.298796],
 [-4.596166,-3.818022,-0.676171],
 [ 2.642496, 0.345734, 2.618473],
 [ 2.958295, 2.594955, 1.834505],
 [ 3.434784, 0.325519,-0.655156],
 [ 0.004474, 0.614021, 0.699663],
 [-0.642885, 2.425959,-2.088227],
 [-0.207383, 0.332423, 0.714963],
 [ 4.640216, 0.050222, 3.055273],
 [-3.625323,-0.842237, 3.317554],
 [-1.582155,-3.553045,-2.607714],
 [-2.035439, 1.874449, 0.99978 ],
 [-3.157832,-0.587866,-0.430092],
 [ 2.247195,-0.733074, 1.31134 ],
 [-4.079703, 0.633762, 2.885118],
 [-4.268312, 0.720307, 2.727253],
 [-3.013731,-1.223403,-0.270136],
 [ 3.433255, 0.239421,-2.089591],
 [ 2.597513, 1.248302, 1.278312],
 [ 3.440699, 2.931832,-1.134482],
 [-0.134693, 3.480818,-0.370788],
 [-2.643615,-1.572021, 0.464166],
 [ 2.815466,-2.459398,-2.819974],
 [ 2.508059, 1.914304, 0.828421],
 [ 1.826954,-0.208262,-0.047566],
 [ 1.260896,-1.696385,-1.45468 ],
 [ 0.447919,-2.949571, 0.186191],
 [-0.734697,-4.22065 ,-4.158449],
 [-1.328724, 3.629868, 0.66103 ],
 [-3.165383,-1.63764 ,-4.232405],
 [ 5.419565, 3.053596,-0.457113],
 [-1.698245,-0.692068, 0.682493],
 [-2.659843,-3.343654,-3.554422],
 [ 4.615233, 1.354052,-1.09692 ],
 [-1.242639,-1.688235, 2.073271],
 [ 3.508126,-2.99075 ,-3.538509],
 [-0.153917,-1.027907, 0.398593],
 [-2.536759,-4.121281, 0.948412],
 [-1.426106,-1.077324,-0.126115],
 [-1.527722, 3.527796, 0.523956],
 [-5.492292,-0.512252, 0.315378],
 [-3.491426,-2.014086,-0.327628],
 [-3.190751,-2.19013 , 0.433475],
 [-3.608317,-2.311704, 0.010297],
 [-1.058735, 0.990122,-0.861701],
 [ 2.393009, 0.478675, 2.798256],
 [-2.024424,-3.058967, 1.275481],
 [ 5.834632,-0.470252, 1.323869],
 [-2.199903,-2.952033, 1.174352],
 [-1.044746,-0.179383, 2.154453],
 [-2.642425, 0.645871,-0.379152],
 [-0.809657, 2.957268,-0.616547],
 [ 2.690433,-0.306284, 0.271076],
 [ 5.448871, 3.769483, 1.094735],
 [ 0.073355, 1.271884, 0.617332],
 [ 0.494117, 0.325628, 1.436221],
 [ 3.651912, 3.748258,-0.281072],
 [ 3.260665,-0.567643, 2.027087],
 [ 2.383953,-0.398041, 1.468655],
 [-0.991468, 0.049571, 0.61502 ],
 [-0.618971,-2.095411, 2.608235],
 [ 2.657185, 2.20854 , 0.006722],
 [ 4.409166, 0.128476, 1.334372],
 [-6.693455,-1.98287 ,-0.59702 ],
 [-4.681585,-3.223068,-0.935742],
 [-1.485485, 1.314523,-0.742275],
 [-1.605382,-0.501584,-0.084733],
 [-1.835663,-0.312319, 0.12184 ],
 [-1.029018, 0.528953,-0.058912],
 [-0.956414, 3.033721,-0.663302],
 [-2.866437,-2.432442,-0.991305],
 [-1.048427, 2.989315,-0.669591],
 [ 2.92293 ,-1.220183,-4.666008],
 [ 0.307616, 0.252392, 0.265656],
 [ 4.239383, 0.446888,-0.098177],
 [ 3.390157, 0.531257, 1.85224 ],
 [-1.3611  ,-0.293819, 1.374302],
 [-0.75994 ,-0.101191, 1.489327],
 [ 7.093205,-0.497367,-0.086715],
 [-6.6068  ,-2.337129,-0.417029],
 [-3.128113, 3.349597, 1.881324],
 [-2.713105, 2.73385 , 0.346269],
 [ 2.588168,-3.62998 ,-0.196374],
 [-2.574917,-3.066631, 1.310392],
 [-2.389646,-0.882926,-0.426082],
 [ 0.27197 ,-0.063719, 1.104398],
 [ 3.120842, 0.203505, 1.334833],
 [-0.044253,-1.092906,-4.224531],
 [-3.230198, 0.621524, 1.017238],
 [ 4.340014, 5.164937,-0.011668],
 [-2.246261,-0.44418 , 2.851162],
 [-2.89982 , 0.083282, 2.17246 ],
 [-3.538432,-1.997867, 1.452878],
 [-4.701492,-0.047509,-0.739513],
 [-3.570915,-0.20068 , 2.040781],
 [ 5.340018, 0.993521, 0.112679],
 [-3.175009, 0.630821,-1.600812],
 [-2.491139,-3.334316, 1.244766],
 [-4.165613,-1.074036, 0.349062],
 [-4.738582, 0.042741, 1.780773],
 [-5.584208,-0.005248,-0.689217],
 [ 5.154111,-0.078808,-3.974293],
 [-3.176549,-3.313654,-0.502969],
 [-1.687568, 0.222735, 2.132396],
 [-1.716364, 0.267369, 2.171296],
 [-5.662004,-2.002352, 0.703739],
 [-2.269884,-1.937232,-2.331186],
 [-1.312892,-3.204342,-3.212828],
 [ 0.668245,-3.45321 ,-3.911873],
 [ 5.320301, 0.861634, 1.970781],
 [ 0.796287,-2.505058, 0.108198],
 [-2.212953,-1.749109, 3.117505],
 [-6.129791,-0.95482 ,-0.440839],
 [ 6.618211, 2.920275, 0.618351],
 [-6.150082,-1.294181,-0.501755],
 [ 1.112669,-2.600737, 0.302374],
 [-0.591825,-0.079888,-2.977233],
 [-3.816484,-0.260456, 3.580861],
 [ 1.376712, 2.388196, 2.684021],
 [ 4.499195, 1.665347, 1.942161],
 [ 0.252577, 1.477089, 2.306297],
 [-3.140311, 0.634305, 0.798706],
 [ 2.925282, 2.052882, 1.932296],
 [ 3.023134, 1.229642, 0.940934],
 [ 4.026391, 0.447016, 0.609921],
 [-3.571043, 0.911025,-2.320415],
 [ 4.064237,-1.736346, 1.359469],
 [ 0.602776, 0.236109, 3.369314],
 [-0.159353, 0.83605 , 1.806504],
 [-0.021445,-0.353856, 3.119798],
 [ 0.454214,-2.230276, 0.360714],
 [-4.459694,-0.160059, 1.331041],
 [ 3.407424,-0.525453,-0.917891],
 [-0.007929,-0.416621, 3.193908],
 [-7.363504,-0.209397, 3.106634],
 [ 1.136146, 0.559755, 3.609206],
 [ 3.382296, 1.685125, 0.686559],
 [-2.771324,-0.911051, 1.316033],
 [ 0.899016, 0.548067, 3.454006],
 [ 3.197577, 1.141163, 3.047126],
 [-2.572315,-4.211936, 0.855459],
 [ 1.049912,-2.124353, 0.820174],
 [-0.947841,-3.130403,-3.64833 ],
 [ 0.084401,-3.092568,-3.641579],
 [ 3.876696, 0.474117, 0.421545],
 [-1.46046 , 0.263256,-1.261265],
 [-6.111078,-1.018708,-0.968286],
 [ 0.273297, 1.727018, 0.349378],
 [ 5.093754, 0.595385,-0.327684],
 [-0.092897,-1.735797,-0.389893],
 [ 3.269188, 2.05527 , 3.981107],
 [-2.778911, 0.162856,-1.39329 ],
 [-0.779905, 0.603139,-1.728965],
 [-0.426597,-5.631231,-0.730458],
 [-1.522177, 0.228585,-0.753934],
 [ 3.960994, 0.800596, 0.77176 ],
 [-6.269825,-1.446509,-0.582858],
 [-1.565137, 0.469812,-0.99921 ],
 [ 0.757986, 0.290801, 3.515737],
 [ 2.027625, 0.839708, 3.277153],
 [-2.077632,-0.783937, 2.841484],
 [ 4.768864, 2.626043, 0.244362],
 [-2.705832, 0.271895,-1.608036],
 [-5.731385,-1.170141, 2.102295],
 [ 6.703399, 0.273272,-0.474168],
 [ 2.330957, 1.71338 , 1.378293],
 [-0.599697,-0.08858 ,-2.953638],
 [-0.636921, 0.370924, 3.907923],
 [-2.13674 , 4.001956, 1.851939],
 [-5.322097, 0.384179,-0.507701],
 [-6.333359,-0.834279,-1.333643],
 [-6.581928,-1.731302,-0.587184],
 [-0.280874,-2.032016, 3.492672],
 [-0.498533,-2.076983, 3.522451],
 [-1.741163, 0.439122,-1.152772],
 [ 4.034656,-0.738   , 0.854045],
 [-0.75618 , 1.421252,-1.139131],
 [-2.896756,-0.394651, 2.200478],
 [-0.694808, 1.950735,-2.073421],
 [-1.27068 , 0.048181,-1.077259],
 [ 6.039497,-0.574072, 1.208668],
 [ 6.286578,-0.645465, 1.202529],
 [-1.210532,-0.017084,-1.320894],
 [-1.615941, 0.658558,-0.875445],
 [-3.156941, 3.186677, 1.996149],
 [ 5.429668, 1.956893, 0.073796],
 [ 0.575382,-0.523303,-0.06444 ],
 [-2.789807, 0.378245,-0.463953],
 [-3.151543,-0.649015,-0.714028],
 [-4.802272, 0.688561,-0.116139],
 [-1.708442, 4.271174, 1.988549],
 [-3.313639,-3.455579,-0.009652],
 [-6.600214,-2.268947,-0.407574],
 [-4.69753 , 1.026611,-0.443619],
 [-4.588721, 0.127523,-1.251648],
 [-6.670362,-1.86754 ,-0.665057],
 [-2.078631, 0.329011,-1.114109],
 [-4.745821,-0.137141, 4.535454],
 [-4.24015 , 0.136991, 5.229531],
 [-5.096447,-0.14234 , 0.650556],
 [-1.068775,-1.354577, 3.312303],
 [ 1.245531, 1.12387 , 3.575491],
 [-4.165792, 0.272549, 1.06027 ],
 [-1.157791,-0.71547 , 3.997698],
 [-0.213614,-2.257766, 3.506989],
 [ 0.409149,-3.577286,-0.831863],
 [ 0.010602,-3.732949,-0.762917],
 [ 1.362528,-0.610787, 0.490483],
 [-5.793568,-0.145978,-0.542841],
 [-2.494425, 1.172471,-1.372204],
 [-4.590767, 0.12353 ,-1.269315],
 [ 0.724881,-2.440128,-0.012625],
 [-0.529923,-0.377995, 3.408647],
 [ 6.299415,-0.641467, 1.244535],
 [-2.25013 , 0.250273,-0.790778],
 [-2.436376,-2.308643,-0.557929],
 [ 5.798791, 0.355106,-1.594578],
 [-0.90774 ,-1.817922, 3.424815],
 [-0.786985,-0.413854,-2.970248],
 [-1.010277,-0.145682, 5.701387],
 [-6.623097,-2.221387,-0.446452],
 [ 1.072016, 2.254306, 3.808153],
 [-0.73972 , 4.492279, 3.956797],
 [-4.58135 , 1.917047,-0.541842],
 [-5.85614 ,-0.320064,-0.860402],
 [-2.183239,-0.814807,-0.340345],
 [ 2.51521 , 2.9182  , 0.175107],
 [-3.516452, 0.759858,-0.614892],
 [-5.906701,-0.302875,-0.790542],
 [-2.031055,-0.8762  , 2.870543],
 [-0.974508,-0.537   , 1.416841],
 [-2.97045 , 2.26001 ,-0.538849],
 [-6.2934  ,-1.377034,-0.506883],
 [-1.291204,-0.309775, 3.499463],
 [-3.919731,-0.249712, 3.950544],
 [ 0.540284, 0.281404, 3.477068],
 [ 1.576303, 3.250883, 3.293621],
 [ 1.639995, 3.422719, 3.370742],
 [ 4.728546, 0.495404,-0.13282 ],
 [-2.264334, 2.977243, 2.448983],
 [ 2.014675, 0.395471,-2.334584],
 [ 4.494896, 1.651857, 1.968087],
 [-7.363716,-0.200805, 3.202109],
 [ 1.856369, 1.698005,-0.918303],
 [-4.636244, 0.425552, 0.159397],
 [ 1.101509,-5.118969,-0.858393],
 [-1.698582,-1.090555, 4.121329],
 [-0.052179,-0.880422, 3.177668],
 [ 0.809285,-1.189417, 0.019772],
 [-0.688539, 1.20154 ,-1.563903],
 [-0.2053  ,-2.130625, 3.530799],
 [-0.37982 ,-0.345152, 3.463778],
 [ 6.567343, 0.390248,-3.135291],
 [-0.068189,-0.855471, 3.134268],
 [ 3.450894,-0.532987,-1.302896],
 [ 1.403062,-1.637123,-1.063422],
 [-2.690505,-0.012853,-1.616827],
 [-1.811515, 0.379658,-1.666245],
 [-6.671317,-1.77183 ,-0.644469],
 [-0.8229  , 2.29456 ,-2.235193],
 [-2.621272, 2.66807 , 0.274155],
 [-2.632251, 3.682348, 1.787724],
 [-3.031031, 0.137461, 4.970199],
 [-2.950317, 0.152908, 5.28273 ],
 [-0.318025,-0.777227, 3.209366],
 [ 3.284258, 2.036709, 3.93848 ],
 [-0.709649, 2.24527 ,-1.820016],
 [-0.565813,-2.063781, 3.489366],
 [-6.660977,-2.064372,-0.623903],
 [-1.849657, 2.145604,-0.64396 ],
 [-0.150486,-2.306677, 3.48016 ],
 [ 1.205661, 1.173283, 3.799808],
 [-1.071517, 1.619335, 3.551871],
 [ 5.757913, 1.588905, 0.109609],
 [-3.063218, 0.932723,-2.900804],
 [ 6.549524,-0.849145,-0.460257],
 [ 0.145789,-1.35255 , 3.92049 ],
 [-0.398471, 0.198199, 4.019722],
 [ 1.317185, 0.446826,-2.709931],
 [-4.079365, 1.282918, 1.424893],
 [ 4.036115, 0.896524,-0.082593],
 [ 2.217443, 0.903451, 2.453911],
 [ 0.310207, 2.954338, 5.314976],
 [-1.395804,-0.839634, 4.140995],
 [-2.40357 ,-0.221733, 3.909739],
 [ 4.142739, 0.880109,-0.270601],
 [ 4.82055 , 0.888428,-0.133122],
 [ 5.245691, 0.510725, 0.190169],
 [-4.515715, 0.356104, 0.12948 ],
 [ 6.609383,-0.490172,-0.84539 ],
 [-0.147684,-4.108522, 0.039694],
 [-1.083724,-1.438962, 5.288941],
 [-1.854749, 1.884574, 0.673808],
 [-1.168273, 0.337727, 3.817359],
 [ 2.856212, 5.398229, 0.136703],
 [-1.501116, 1.366302, 1.428977],
 [-0.186359,-0.504207, 1.526792],
 [ 0.608548,-0.122529, 4.478007],
 [ 0.19472 ,-1.815636,-0.383195],
 [-0.731257,-1.182422, 0.631825],
 [ 0.971818,-1.424363,-1.585893],
 [-1.181478, 4.461832, 2.446899],
 [ 0.738159, 2.070887, 2.594891],
 [ 3.759537, 0.788445,-1.101327],
 [-1.356684, 2.441767, 0.576177],
 [-5.142162,-1.011431,-1.0575  ],
 [-4.146041, 0.425692, 0.090417],
 [-2.901646,-4.623458,-0.309008],
 [-4.270502, 0.042286, 2.918896],
 [-2.856897,-0.301377,-0.148891],
 [ 6.731495, 2.201944, 0.047151],
 [-3.863139, 1.311769, 0.096902],
 [-0.78748 , 1.659723, 4.975808],
 [-3.83106 ,-0.070084, 3.353171],
 [-4.270401, 1.581743,-0.983062],
 [ 5.591165, 4.731506,-0.254591],
 [-2.389189,-1.476471, 0.02808 ],
 [-4.071734,-2.263501, 0.218871],
 [-0.403257, 3.517892, 2.901342],
 [ 4.872101, 1.631418, 0.380899],
 [ 5.872458, 0.424105, 0.55656 ],
 [-0.098686,-2.361388, 3.452941],
 [ 2.885614, 0.10998 , 0.316588],
 [-1.781585, 2.013025, 3.308076],
 [-0.376451, 1.405708, 0.176625],
 [-3.125018, 3.452134, 2.225118],
 [ 4.666725,-0.413389, 2.566495],
 [-3.140562,-0.150173, 0.559807],
 [ 6.098865,-0.60835 , 1.315809],
 [ 0.705226,-2.942283, 0.891687],
 [-2.978627, 3.434957, 1.831177],
 [ 0.853635, 0.995826, 1.664419],
 [-3.113199, 3.304999, 1.956944],
 [-6.585433, 1.782202, 0.489813],
 [ 2.238951, 2.360516, 2.60264 ],
 [-0.561515, 1.463392,-2.05507 ],
 [-0.916194,-4.69276 , 0.16912 ],
 [ 1.317741, 2.167974,-2.347229],
 [-1.973541, 1.043308, 6.009449],
 [-0.789138, 2.394999,-1.999248],
 [-3.370547, 2.807191, 0.64628 ],
 [ 3.367169, 0.601188, 0.866428],
 [-4.043121, 0.026858, 0.974809],
 [ 3.156739, 2.843019, 1.921241],
 [ 1.741764,-2.465883, 0.502099],
 [-3.272915, 0.295401,-0.947378],
 [-4.031686,-1.958384, 0.315189],
 [-3.832692, 1.066898, 0.681988],
 [-3.857049, 1.120158, 0.649006],
 [-3.851606, 1.118164, 0.647755],
 [-3.846704, 1.242429, 0.619773],
 [-5.268275, 0.644388,-1.478986],
 [-3.534278, 2.429942, 4.822232],
 [ 6.246207, 0.892544, 1.944917],
 [ 5.114975, 4.521855, 1.135598],
 [ 0.504688,-0.717097, 0.440785],
 [-4.892872, 0.161922,-0.246572],
 [ 0.001497,-2.457846, 3.386668],
 [-0.11882 ,-2.348159, 3.455385],
 [-1.727094, 0.451888,-1.249032],
 [-0.169383, 0.18231 , 1.099098],
 [-0.64594 , 0.614876,-4.471906],
 [ 1.076337,-0.320126, 3.524901],
 [-3.911231, 0.940654,-1.697307],
 [-6.799233,-1.993784,-0.657575],
 [-5.878123,-0.292799,-0.862414],
 [-4.557989, 2.001333,-0.554209],
 [-4.786324, 0.484783,-0.324169],
 [ 3.947529, 0.779853, 0.395243],
 [-6.74514 ,-2.361332,-0.433683],
 [ 6.422365,-0.354658, 0.794191],
 [-6.683372,-2.059305,-0.620684],
 [-3.31487 , 0.529015,-0.919936],
 [-5.191331, 0.734412,-1.564725],
 [ 0.900699, 0.108045,-1.831505],
 [-4.657704,-0.378251, 1.667532],
 [ 0.306961, 2.95758 , 5.312276],
 [-2.829884,-4.546325, 0.003718],
 [-0.757281, 0.475122, 0.184468],
 [-2.713563, 2.134044,-1.464035],
 [-2.150472, 0.313713,-0.991798],
 [-4.572899, 0.063484, 1.266871],
 [-2.679729,-0.137489, 1.919108],
 [-2.066432,-0.032757, 3.094676],
 [-2.290509,-0.005582, 2.970248],
 [ 4.837337, 1.85104 , 0.035348],
 [-3.337888,-0.111214, 0.009493],
 [ 6.195139, 0.986925, 1.922049],
 [-5.435769,-0.326823, 0.46144 ],
 [-3.918947,-0.052965, 1.938648],
 [ 2.635676, 0.325337, 0.453187],
 [ 2.619977,-0.248705,-0.147833],
 [ 2.024301,-1.74238 , 1.880782],
 [-0.836442,-0.937749, 1.558761],
 [ 1.495525, 4.103554, 1.270648],
 [ 1.143516, 0.522648, 3.080241],
 [-0.576115, 2.353628, 5.526749],
 [-2.807151,-1.209002, 2.440703],
 [-3.795293,-0.098477, 1.972112],
 [-4.604097,-0.159079, 1.303945],
 [-3.128723, 3.389209, 1.750339],
 [-3.101049, 3.397815, 1.753203],
 [-3.220844, 2.915173, 0.596821],
 [ 3.254315, 2.06379 , 4.028044],
 [-5.761441,-0.590062, 0.181858],
 [-5.505052, 0.116839, 0.300149],
 [ 3.529324,-2.986398, 1.397695],
 [-0.876084, 0.668632,-2.050472],
 [-2.856181, 0.076933, 1.514123],
 [ 0.720755,-2.620085,-0.550158],
 [-4.938933,-2.826247,-0.923533],
 [-4.69002 ,-1.613178, 2.335872],
 [-0.001386,-0.713619,-1.71259 ],
 [ 3.427279,-0.185856,-0.016959],
 [-0.998711,-4.735722, 0.187755],
 [-6.051901,-1.376287,-0.373632],
 [-4.226654, 0.145689, 1.138755],
 [-6.642859,-1.972229,-0.573531],
 [-6.414119,-1.395465,-0.552458],
 [-5.133773, 1.383837,-0.898063],
 [-6.514158,-1.654334,-0.56608 ],
 [ 5.962321, 3.095819, 1.603799],
 [-6.609116,-2.253654,-0.449315],
 [ 0.500831,-5.251001, 0.064429],
 [-3.409029,-0.339799, 0.40482 ],
 [-6.145652,-1.146111,-0.439091],
 [ 6.30276 , 0.31944 ,-2.22232 ],
 [-0.993219,-4.752561, 0.195302],
 [-5.694398,-1.219444, 0.160544],
 [ 4.530663,-0.569468, 2.596137],
 [ 2.266475,-1.559983, 0.282776],
 [-0.940644,-1.313134, 1.583934],
 [ 4.360007, 0.535747, 1.143019],
 [-5.442821,-2.605341,-0.714001],
 [ 0.130036,-2.82921 ,-0.6278  ],
 [ 1.82733 , 1.30857 , 2.191309],
 [-5.412703,-2.25096 ,-0.502619],
 [-1.405634,-0.482549, 6.051404],
 [-6.677377,-1.824913,-0.674984],
 [-1.394292,-0.495614, 6.066312],
 [-2.253672,-1.468325,-0.584782],
 [-4.55091 ,-4.652798,-1.522007],
 [ 4.327651, 0.318828, 0.000055],
 [-0.577301, 2.316376, 5.506948],
 [-4.59516 ,-0.231667, 1.443545],
 [-2.455329,-0.092751, 1.054228],
 [-5.20995 , 0.098911, 2.93384 ],
 [-3.907077,-0.879661, 3.154604],
 [ 1.820041,-0.722695, 1.679543],
 [ 3.690802,-1.464797,-0.959979],
 [ 1.590769, 3.488721, 0.997136],
 [-2.993033,-0.703797, 2.709013],
 [ 1.912193, 0.812903, 1.661251],
 [-4.121238, 1.386608, 0.491066],
 [ 2.07482 ,-0.104988, 2.014955],
 [-2.378554,-1.030309, 0.093726],
 [ 1.298329,-2.225979, 0.374971],
 [-7.269927,-0.221059, 3.078842],
 [-7.364861,-0.220813, 3.175364],
 [-2.425498,-0.182058, 1.474287],
 [-3.15403 , 0.219769, 5.133233],
 [-0.895645, 2.855974, 2.016794],
 [-0.873172, 2.897027, 1.932625],
 [-2.544737,-0.111069, 1.525661],
 [ 1.823343,-0.732171, 1.697854],
 [ 3.692514,-1.292543,-1.038139],
 [-4.253756,-0.207745, 0.473295],
 [-4.562774,-2.946172, 0.583202],
 [-0.037127, 3.557866,-2.431136],
 [-3.879638,-0.673609,-0.76109 ],
 [-2.033136,-0.761388, 1.436914],
 [ 5.331539, 0.357887,-2.439291],
 [-2.711661, 2.716081, 0.348537],
 [ 2.469231,-1.283092, 1.014825],
 [ 0.083358,-0.188423, 3.252609],
 [-1.416512,-2.113694, 3.317958],
 [-6.582652, 0.518983, 1.705079],
 [ 0.542761, 0.168347, 3.464176],
 [ 3.532774, 1.904102, 1.915355],
 [-4.836951,-1.333781,-1.005892],
 [-1.101719, 3.873343, 0.756023],
 [-3.408073, 0.844026,-1.887609],
 [-4.033084,-2.223627, 0.872762],
 [ 3.033669, 0.229027,-0.346953],
 [ 1.065301,-2.529435, 0.214979],
 [ 0.92101 ,-2.801026,-2.261673],
 [-4.623105,-3.832057,-0.616785],
 [ 1.669535, 3.699837,-0.935338],
 [-6.642924,-2.235663,-0.443588],
 [-4.593013, 0.978273,-1.821469],
 [ 6.63819 , 2.896401, 0.639303],
 [-6.733375,-2.360612,-0.434875],
 [-5.833547,-1.619263,-0.010035],
 [-4.390443,-0.990513, 0.551027],
 [ 0.802489,-2.611336, 0.272127],
 [-4.710329, 1.204184, 1.800349],
 [-5.856763,-2.147811,-0.447832],
 [ 2.954916, 0.541465, 1.237557],
 [ 4.638771, 0.333055, 0.703883],
 [-0.845627,-1.834404, 3.409764],
 [ 1.39546 ,-1.674361, 1.183983],
 [-4.703443, 1.106627,-0.985642],
 [-2.285162,-2.091138,-2.506723],
 [-3.238302, 2.281393,-0.058874],
 [-5.141708,-0.174735, 2.43648 ],
 [ 2.033157,-0.254853, 1.625191],
 [-0.196884,-1.227052,-5.231336],
 [-2.762734,-2.050525, 3.156073],
 [ 5.267323, 0.671439,-0.55936 ],
 [ 2.428847, 0.771029, 3.578221],
 [ 5.67136 , 4.710759,-0.27463 ],
 [ 6.786055, 0.210376,-1.851063],
 [-3.037597, 1.212533, 3.599945],
 [ 4.594283, 1.065157, 0.738615],
 [ 6.860025, 0.206038,-1.951341],
 [-3.975655,-2.533998,-0.400383],
 [-2.846772, 0.041346, 2.773891],
 [ 1.347524,-1.036598, 0.083893],
 [ 1.210514,-3.327505,-4.039687],
 [-0.291005,-2.541071,-5.335066],
 [ 5.471177, 4.413337,-0.143338],
 [ 2.086106, 2.11065 , 0.998923],
 [ 1.119176,-0.787994, 0.283004],
 [ 6.074264, 1.224811, 1.84701 ],
 [-4.186685, 2.061814, 2.990655],
 [ 2.73029 ,-1.107261,-0.450901],
 [ 4.567375, 1.71766 ,-0.748139],
 [ 3.649023,-0.390075,-0.258927],
 [ 1.657809,-2.107232,-0.229415],
 [ 4.413896, 3.301211, 2.236217],
 [ 4.699266, 2.754461, 2.095002],
 [ 4.57586 , 2.878066, 2.328572],
 [ 4.610065, 2.866189, 2.256873],
 [ 3.448894, 4.243818, 0.355903],
 [ 4.713491, 1.289454, 0.022121],
 [ 4.597195, 0.188867,-1.292536],
 [-0.593339, 2.163838,-2.096876],
 [-1.515154, 0.475088, 1.64081 ],
 [ 4.904346, 1.48743 ,-1.000616],
 [-2.66082 , 2.723925, 0.30324 ],
 [ 5.491123, 4.68449 ,-0.227366],
 [ 3.464009, 4.208881, 0.324191],
 [-0.460054, 1.741387, 2.611427],
 [ 3.756099, 3.736921,-0.322995],
 [ 0.555903, 2.373272, 0.424433],
 [-0.810484,-5.794168,-0.248069],
 [ 1.639115,-3.59185 ,-4.72049 ],
 [ 1.546217, 2.556354, 1.789776],
 [ 0.738462,-1.655382, 4.668385],
 [ 4.445447,-1.169278, 0.025336],
 [ 4.41405 ,-1.006925,-0.021161],
 [ 3.994816, 2.736194,-1.352085],
 [ 3.541945, 0.1062  ,-1.260797],
 [ 2.373668,-2.159523,-0.844094],
 [ 4.453724, 3.371708, 2.121447],
 [ 5.091341, 0.279972,-0.394187],
 [ 4.49188 , 3.387292, 2.069153],
 [ 3.001232, 4.336318,-0.132109],
 [-6.220197, 1.125472, 1.593957],
 [-0.674777, 2.474374, 5.606004],
 [-2.808378, 2.269794, 0.176249],
 [ 3.024587, 4.415463,-0.173719],
 [ 6.826823, 0.230577,-0.275358],
 [ 3.968542, 1.719072,-0.273174],
 [ 3.025684, 4.422712,-0.182184],
 [ 4.817117, 4.975807, 0.443262],
 [ 0.50557 , 5.191237,-1.311752],
 [-3.505388, 3.247464, 0.205195],
 [ 3.929921, 3.349923, 1.432138],
 [ 2.010313, 0.084165, 0.136342],
 [ 7.015928,-0.004724, 0.137459],
 [ 2.48979 ,-1.110301,-1.84524 ],
 [ 3.243532, 2.536852,-0.317602],
 [ 0.400593,-3.04444 ,-2.776177],
 [-0.389587, 1.567562, 2.358216],
 [ 2.201062, 0.717133, 1.685885],
 [-1.932074,-1.074968,-2.077419],
 [ 6.838723, 0.208871,-1.88114 ],
 [ 6.176304, 1.02239 , 1.797085],
 [ 3.60323 , 3.267851,-0.277193],
 [ 0.88905 , 2.805535, 1.235046],
 [ 3.792143, 3.033416,-0.611603],
 [ 3.80382 , 2.856596,-0.593058],
 [ 4.267316, 0.41849 , 0.296139],
 [ 3.792434, 3.048254,-0.620238],
 [ 3.556823, 3.334802,-0.160079],
 [ 4.276258, 1.201176, 0.050601],
 [ 3.832215, 0.096107,-0.219403],
 [ 6.765411, 0.259985,-0.474296],
 [ 0.916178,-1.39287 , 0.822962],
 [ 3.277876, 0.797098, 0.546461],
 [ 4.287089, 1.734042, 2.646664],
 [-4.977779, 2.473081, 1.615959],
 [-4.996271, 2.379784, 1.542489],
 [-4.997499, 2.345371, 1.510514],
 [ 2.487089, 5.451403, 1.396807],
 [ 2.439709, 2.859419, 1.968736],
 [ 2.541897, 5.446164, 1.385261],
 [-2.290283, 0.241599, 0.870038],
 [-1.888172,-0.604024, 0.734037],
 [ 0.092983,-3.34007 ,-3.188678],
 [ 2.518305, 5.450193, 1.392092],
 [ 1.40227 , 3.969804, 4.498632],
 [ 1.407829, 3.98924 , 4.476195],
 [-2.890063,-0.001864,-0.239539],
 [ 5.416912, 0.144556, 0.448374],
 [ 6.019319, 3.667148, 1.316964],
 [ 0.862053,-4.269921,-0.608114],
 [ 1.389223, 3.963136, 4.498601],
 [-5.330719, 0.54323 , 3.258745],
 [-4.076177, 0.770934, 6.105985],
 [-4.741931,-0.129371, 4.529342],
 [-4.735157,-0.132165, 4.718643],
 [ 6.014645, 3.711952, 1.332095],
 [ 5.50299 , 1.284884, 2.735348],
 [ 1.404981, 4.020346, 4.401369],
 [ 0.212416, 1.244133, 5.015662],
 [-0.513112, 0.7171  , 3.186492],
 [ 4.271873,-0.051463, 0.509751],
 [ 2.900168,-1.691641, 0.382763],
 [-3.789665, 0.260521, 5.075962],
 [ 0.053376, 4.270865,-0.533946],
 [-7.333972,-0.194376, 3.214079],
 [-4.435939, 0.091053, 2.828712],
 [ 5.507568, 1.287614, 2.736605],
 [ 6.022881, 3.761686, 1.306232],
 [ 5.477798, 1.199896, 2.539387],
 [-2.675904,-1.096637,-2.376238],
 [-3.150051, 3.421094, 2.769261],
 [ 6.936037, 1.328057, 0.961616],
 [ 2.356277,-0.335353, 2.252479],
 [-4.682502,-1.804621,-0.967382],
 [-0.601551,-2.101207, 2.48263 ],
 [-4.159599, 2.805427, 1.754063],
 [ 0.118117,-1.78423 ,-1.595688],
 [ 1.099535,-5.107344,-0.860555],
 [ 5.479957,-1.300898,-0.885847],
 [ 1.426934, 4.023376, 4.428487],
 [-0.720967, 4.454865,-1.219846],
 [ 3.389504, 4.733438, 1.514934],
 [-1.939225,-0.042414, 3.072182],
 [ 3.822239, 1.324234, 2.880922],
 [ 0.178474,-0.999816, 1.511934],
 [-0.106695,-1.091336, 1.052365],
 [ 4.983995, 0.477262, 2.169796],
 [ 4.757593, 0.442542, 0.099838],
 [-0.442253,-4.695306,-4.175753],
 [-2.678751, 2.913604,-1.87832 ],
 [ 0.896839,-4.242673,-0.612127],
 [ 5.088805,-1.186736,-1.290716],
 [ 5.098234,-1.195394,-1.289565],
 [ 4.220755, 1.043461,-0.505555],
 [ 1.374997, 1.564467,-0.350717],
 [-1.453788, 0.284829, 3.520929],
 [-2.294528, 2.353393, 1.441139],
 [ 4.946014, 0.471016,-3.673582],
 [ 4.945198, 0.474305,-3.668555],
 [ 4.957756, 0.472836,-3.664174],
 [ 4.940533,-0.268905,-0.125502],
 [ 4.958885, 0.477476,-3.648985],
 [-2.347767, 3.756941, 0.245657],
 [ 6.36298 , 0.264476,-1.392237],
 [ 4.956396, 0.473777,-3.662704],
 [ 5.039786,-0.358739,-3.299585],
 [ 4.405205, 0.807044,-0.806848],
 [ 4.834805,-2.147456,-0.135803],
 [ 4.592947, 0.632438,-0.522465],
 [ 3.940372, 2.330523,-0.426267],
 [ 7.200838, 0.185971, 0.912482],
 [ 4.22479 , 0.705483,-0.241855],
 [-6.845246,-0.344005, 3.200198],
 [-4.082212,-0.565781, 5.278182],
 [-0.664496, 2.471638, 5.638319],
 [ 3.440544, 0.708842,-1.306679],
 [-4.071896, 1.395209, 0.164871],
 [ 3.426616, 2.947621,-1.151683],
 [ 6.525689, 0.407185,-3.11816 ],
 [-1.016625,-0.175689, 5.695634],
 [-4.5601  , 0.924461,-0.977534],
 [-2.268778, 0.055218, 0.29412 ],
 [-0.75254 , 2.257203,-2.040458],
 [ 3.139366, 2.303698, 0.386356],
 [ 0.734828,-1.026639, 0.031443],
 [ 6.140996, 0.179074,-0.157697],
 [ 4.551597,-0.192929,-0.05354 ],
 [-2.303896, 2.881562, 2.517587],
 [ 6.107954, 0.233597,-0.841134],
 [ 3.397563,-1.412039,-0.29291 ],
 [ 3.35884 ,-1.400038,-0.366123],
 [ 3.404078,-1.591206,-0.316615],
 [ 3.75472 ,-0.483976,-0.899923],
 [ 0.655964,-1.305068, 4.681542],
 [-1.95433 , 3.009269,-0.614021],
 [ 0.451973,-3.364146, 0.010375],
 [ 7.124418, 2.501506, 0.506092],
 [-0.07683 ,-1.597271, 2.209178],
 [-2.130775, 4.161203,-0.088739],
 [ 3.39702 , 4.816989, 1.556193],
 [ 3.390761, 4.815411, 1.613676],
 [ 3.189971,-0.581454,-0.137292],
 [ 2.836495,-0.461166,-0.178668],
 [ 5.665165, 2.000593,-0.090716],
 [ 3.498322, 0.997885,-1.224453],
 [-4.236244, 1.887931, 3.197828],
 [-1.679772, 1.386717, 1.120135],
 [ 5.104619, 0.646251,-2.213186],
 [-0.55    ,-0.561307,-0.292391],
 [ 2.710293,-0.90249 ,-1.013734],
 [ 1.742207, 0.732324,-1.526921],
 [ 2.146739,-1.383428,-1.887655],
 [-0.044572, 1.179765,-2.568395],
 [ 5.391393, 0.35329 ,-2.452388],
 [ 4.95131 , 0.076273,-3.152867],
 [ 4.605319, 0.754584, 2.030964],
 [ 7.432338, 0.018152,-0.158176],
 [ 5.529763, 0.88072 , 1.773208],
 [ 1.307652, 1.013509, 1.672662],
 [ 4.676561, 1.57364 ,-0.365672],
 [ 3.825788, 2.444313, 0.138351],
 [ 4.747312, 2.295486, 0.063363],
 [ 4.889555, 2.651622, 0.036413],
 [ 3.129618, 3.344387, 0.741427],
 [ 2.214799, 3.618872, 1.679643],
 [-1.100107,-0.432086, 3.369476],
 [ 3.030503, 0.463978, 0.624964],
 [ 0.432081,-0.730544,-5.251838],
 [ 5.369015, 4.686297,-0.229468],
 [-1.464254, 3.386898, 1.260328],
 [ 1.425041, 3.651935, 1.19454 ],
 [-3.891915, 1.010227, 2.607976],
 [ 1.348981, 3.711352, 1.098413],
 [ 1.804946, 3.233248, 1.388372],
 [ 2.80502 , 2.957489, 3.359415],
 [ 3.895048, 4.937243, 1.004019],
 [-0.307001,-2.248481, 4.527927],
 [-6.295929,-0.071161, 2.276131],
 [ 4.123827, 3.036684,-0.273728],
 [-2.970993, 2.865522, 4.137823],
 [ 2.820316, 3.623344, 0.326599],
 [-4.193538, 1.378917, 2.482969],
 [ 4.837986, 1.415972, 0.26635 ],
 [ 0.851508, 4.388824,-0.203433],
 [ 0.941988, 1.908594, 1.10248 ],
 [ 1.769506, 2.267875, 1.073481],
 [ 0.203994, 0.952656, 0.647212],
 [-0.667143,-5.747803,-0.195   ],
 [ 0.687396,-0.689959,-4.971437],
 [ 1.885465, 3.246103, 0.548863],
 [ 0.955515,-0.510405,-4.783267],
 [ 0.590267,-0.60661 ,-1.494323],
 [ 0.053924, 1.888483,-0.614406],
 [ 7.118239, 2.519483, 0.517488],
 [-0.212826, 3.841302, 4.174161],
 [ 3.597124,-0.995466, 0.441857],
 [-2.276471, 2.303198, 1.439056],
 [-1.012792,-0.406995, 5.5395  ],
 [ 4.879638, 2.73439 ,-0.001562],
 [ 4.303552, 1.309045,-0.168936],
 [ 4.035529, 0.87961 ,-0.402642],
 [ 5.179476, 2.456397, 1.313619],
 [ 5.185483, 2.450025, 1.29583 ],
 [ 1.84728 , 3.270272, 0.573234],
 [ 0.3101  ,-0.957388,-4.319931],
 [ 0.991234,-0.570461,-4.654837],
 [-2.885802, 0.492143,-0.262399],
 [-2.40516 ,-0.085494, 2.455784],
 [-2.366861,-0.041982, 2.465986],
 [-2.418404,-0.044921, 2.342457],
 [-0.337902,-3.944129, 0.964947],
 [ 2.95194 , 3.587085, 2.515103],
 [ 1.02976 ,-0.473675, 3.43644 ],
 [-0.446599,-3.879405,-3.906675],
 [-0.441927, 3.623919, 3.967144],
 [-0.384752, 3.718419, 4.113129],
 [ 4.459105, 2.573814,-0.123435],
 [ 4.90686 , 2.769222, 0.008265],
 [ 3.835758, 2.794517, 1.020718],
 [ 4.820293, 2.354124, 0.153141],
 [ 4.874041, 2.004706,-0.004562],
 [ 4.90137 , 2.769258, 0.015813],
 [ 4.916317, 2.693999,-0.041569],
 [ 4.958022, 2.804594, 0.005282],
 [ 4.926964, 2.744288,-0.001517],
 [ 4.968287, 2.790539, 0.039339],
 [ 4.90846 , 2.74518 , 0.005971],
 [ 4.978506, 2.813241, 0.034405],
 [-5.905728,-0.861542, 2.513016],
 [-0.43943 ,-0.302443,-0.797728],
 [ 3.678414, 0.483698,-1.599749],
 [-1.308269, 0.477512, 3.591737],
 [-3.949627,-0.247257, 3.973489],
 [ 7.521998, 2.099087,-0.069159],
 [-1.378848,-0.445625, 0.883408],
 [-1.2851  , 1.55616 , 1.412945],
 [-6.454017, 0.164307, 1.825131],
 [ 4.971623, 2.835464, 0.012622],
 [ 3.955671, 1.429706,-0.343762],
 [ 4.792293, 2.563509,-0.084224],
 [ 4.838279, 2.789372, 0.019358],
 [ 3.765495,-2.986715,-3.637813],
 [-0.276323,-2.981035,-5.547027],
 [ 2.209133, 3.741177, 1.749489],
 [ 2.8894  , 0.774368, 1.439658],
 [ 4.446637,-0.925573, 0.598115],
 [ 1.441345, 4.106831, 4.22431 ],
 [-4.704618,-0.143652, 4.559537],
 [-3.382411,-1.933716, 1.109988],
 [ 5.459829,-1.07418 , 0.129561],
 [-4.755676,-0.47084 , 1.085794],
 [-2.728144, 0.526982,-0.053643],
 [-4.68168 , 0.14729 , 2.256626],
 [ 2.606721, 1.783773, 0.847868],
 [ 2.496062, 1.717815, 0.643256],
 [ 6.767879, 2.188104, 0.054425],
 [ 5.923207, 1.465104, 2.155646],
 [ 4.447476, 1.718426,-0.023233],
 [ 2.889769, 2.606743, 1.994971],
 [-4.799164,-0.093887, 4.79992 ],
 [ 4.536734, 0.741404, 3.229264],
 [ 4.031695, 2.423587, 0.12115 ],
 [ 2.936491,-1.554528, 1.513408],
 [ 1.206618, 3.657594, 1.528065],
 [-7.280526,-0.209518, 3.218249],
 [ 0.544522,-1.378907,-1.464678],
 [-5.055338,-0.057411, 4.864139],
 [ 3.956703, 1.143352, 0.530512],
 [-5.928965,-1.09603 , 2.394595],
 [ 4.034387, 0.244953,-0.208305],
 [ 4.027242, 2.485511, 0.118541],
 [ 6.930007, 0.388159,-0.001406],
 [ 4.705636, 1.37365 ,-0.469425],
 [ 0.073554, 0.39818 , 1.262578],
 [ 3.502477, 0.132354,-1.25898 ],
 [-4.007537, 0.677858, 6.280673],
 [-1.724385, 2.05241 , 3.390798],
 [ 1.8424  ,-0.92475 , 1.620387],
 [-0.45688 ,-4.561049,-4.176151],
 [-0.012465,-2.293173,-0.613734],
 [-0.029067,-0.197022, 2.995947],
 [ 3.787897, 1.461033, 2.925245],
 [ 0.2867  ,-0.901259,-4.423123],
 [-4.654659, 1.276269, 4.083574],
 [ 3.291662, 1.983481, 3.805618],
 [-4.663479, 1.189967,-0.81611 ],
 [-2.29293 , 0.154154, 1.11364 ],
 [-2.721086, 1.461982,-0.146483],
 [-2.35799 ,-0.420498, 2.549943],
 [-4.069486, 0.728821, 6.044287],
 [ 5.990563, 1.687241, 1.164484],
 [ 3.666969, 0.373669, 0.147671],
 [-4.128763, 0.200613, 2.318569],
 [ 3.190586,-0.537713,-0.828192],
 [-8.015687, 0.129865, 3.024136],
 [-4.318934,-2.037965,-0.279748],
 [ 6.486191, 0.234484,-1.679174],
 [ 4.482078, 1.677721, 2.039349],
 [ 3.728997, 0.639934,-3.20377 ],
 [-1.738194,-2.17994 ,-2.538671],
 [ 4.422882, 2.768434, 2.88048 ],
 [ 0.166684, 0.047285,-0.090882],
 [ 0.294529, 0.154377,-0.146821],
 [-2.379242, 0.000422, 0.226215],
 [ 4.354798, 1.393115, 0.123196],
 [ 0.541733, 5.114958,-1.279598],
 [-0.822152, 0.826926,-2.074155],
 [-4.307265,-0.801796, 2.455019],
 [-4.121512, 0.274577, 0.666926],
 [-3.236018,-2.067398,-1.182019],
 [ 2.907704,-0.5606  , 2.105584],
 [ 4.105398, 3.29635 , 0.143419],
 [ 3.6484  , 0.543584,-0.51587 ],
 [ 4.08768 , 3.33844 , 0.1596  ],
 [ 3.738106, 0.593598, 0.031828],
 [ 2.412719,-0.586109, 0.912322],
 [ 1.283403, 2.507524, 2.400626],
 [ 1.111147, 0.717033, 2.937551],
 [-1.386335,-0.674458, 3.445158],
 [ 3.702742, 2.164001, 0.084259],
 [-0.260824,-1.600567,-5.101995],
 [-1.667312, 1.026599,-0.053894],
 [ 5.409816, 0.735806, 0.479744],
 [ 1.649111, 0.003623, 2.722264],
 [ 1.019253, 1.475413, 0.942359],
 [ 0.429533,-5.302441,-2.162138],
 [ 6.28446 , 0.297924,-2.166129],
 [ 2.293387, 0.278239, 0.012674],
 [ 2.28632 ,-0.394321, 0.845566],
 [ 1.030373, 1.079077,-1.379567],
 [-3.603356,-0.438215, 3.522089],
 [ 3.204375, 0.859857,-1.823149],
 [ 2.557397,-1.076988,-1.023236],
 [ 2.95444 , 2.321908, 0.36538 ],
 [-2.465468,-2.097255,-5.035731],
 [ 0.727091, 5.129038, 1.974424],
 [ 4.072838, 0.688301,-0.580344],
 [ 5.568881, 0.51706 ,-0.695132],
 [ 4.913886, 0.212322,-3.069108],
 [ 4.90431 , 0.266562,-3.052956],
 [ 4.89688 , 0.200599,-3.021375],
 [ 4.847109, 0.07708 ,-2.847493],
 [ 4.90785 , 0.243984,-3.045518],
 [-4.372669,-0.579653, 0.859024],
 [ 4.025427, 1.712361, 2.439464],
 [ 3.041862, 5.305335, 2.189362],
 [-7.376542,-0.167475, 3.158562],
 [ 4.912697, 0.300932,-3.015292],
 [ 5.7484  , 2.502357, 1.502174],
 [-0.63823 , 2.431865, 5.563394],
 [-0.663313, 2.472513, 5.593458],
 [-0.692905, 2.547644, 5.639949],
 [ 3.406614,-0.545082, 3.013137],
 [-3.2757  ,-0.774912, 1.546368],
 [-3.177418,-0.677483, 1.2768  ],
 [ 1.024703, 0.186638, 0.764812],
 [-3.270873,-0.71181 , 1.453105],
 [ 3.472683, 0.518837,-0.338023],
 [ 0.692887,-0.663802,-4.956828],
 [ 5.38402 , 4.694802,-0.210004],
 [ 1.720277, 3.561812, 1.112504],
 [ 0.841333, 2.861537,-0.406377],
 [ 2.308878, 2.915236, 1.759992],
 [ 5.487419, 4.727618,-0.208118],
 [ 1.724154, 4.050699, 1.069117],
 [ 5.190617, 4.731068,-0.115949],
 [ 4.627163, 0.313042, 0.481729],
 [ 1.698866, 2.65163 , 0.679966],
 [ 0.631557,-2.121493, 2.191916],
 [ 3.416619, 0.336671,-0.683922],
 [-0.656531, 2.363419, 5.538698],
 [-1.131657,-3.338483,-3.84936 ],
 [ 2.717695, 5.193526,-0.105485],
 [ 2.733116, 0.631324,-0.709327],
 [-4.647364,-1.080224, 1.203497],
 [ 1.96929 , 0.431254, 2.902704],
 [-0.956099,-1.051406, 0.348241],
 [ 4.43895 , 0.648622,-1.064589],
 [ 2.990675, 1.182089, 3.306225],
 [ 4.692345, 0.373189,-0.113083],
 [-2.203778,-2.139706,-2.77863 ],
 [ 2.220559, 2.331153, 2.492246],
 [ 0.929922,-0.035661,-0.44712 ],
 [ 2.864057,-2.486542,-1.364791],
 [-4.650761, 1.243053, 3.908736],
 [-4.668242, 1.280318, 4.100431],
 [ 1.514668,-0.328966,-4.737336],
 [ 1.136797,-0.595092,-4.790503],
 [-3.534013, 1.303561, 2.037599],
 [ 2.352248, 0.922028,-0.258662],
 [ 2.73053 , 5.175979,-0.101079],
 [-0.609864,-2.660437,-5.455201],
 [-2.620034,-1.995491,-4.378221],
 [ 6.944461, 2.59512 ,-0.056677],
 [ 4.741512, 2.6124  ,-0.055686],
 [ 4.577624, 0.553019,-0.784581],
 [ 3.525359,-0.580678,-0.427079],
 [ 3.986515, 2.016099, 0.164757],
 [ 4.685477, 1.792974, 0.090542],
 [ 4.422408, 2.088423, 0.002848],
 [ 3.40765 ,-0.381067,-0.404875],
 [ 5.383909, 0.452978,-0.10841 ],
 [ 4.902689, 1.293643,-0.37344 ],
 [ 2.392627, 0.351545, 2.472224],
 [ 2.801065,-2.48674 ,-1.383897],
 [-1.96162 ,-1.313101,-2.210043],
 [ 5.618612, 0.307428,-1.141375],
 [-2.672697,-3.370461,-3.167478],
 [-1.987413,-2.970101, 1.184661],
 [-2.270269,-0.054342,-2.194179],
 [-2.752724,-3.3756  ,-3.319725],
 [-3.154834, 2.301373, 0.277773],
 [-3.282758,-2.578107,-0.162215],
 [ 7.707479, 1.870494,-0.075678],
 [-1.411991,-2.135086, 3.255854],
 [-1.129602,-0.255556,-2.653098],
 [ 3.546605,-2.96772 , 1.439667],
 [-7.684839,-0.050046, 3.088736],
 [-0.259678,-2.962456,-5.742153],
 [-2.362529, 2.566244, 3.513784],
 [-0.755333,-5.729246,-0.398366],
 [-2.601899,-2.094341,-0.607997],
 [ 2.81761 ,-2.473805,-1.376066],
 [-1.398823, 0.203417, 3.878585],
 [-2.200779, 0.682907, 1.263815],
 [ 2.144215, 2.113665, 1.752008],
 [ 4.197999, 1.753591, 2.639649],
 [-1.085837,-2.405574,-0.212468],
 [-3.182385,-2.008519,-0.464885],
 [ 2.824495, 0.56769 ,-0.605785],
 [ 0.36766 ,-3.382935,-3.347898],
 [-6.559467, 0.484445, 1.689746],
 [-1.379298, 2.347194, 2.666089],
 [-1.345788,-1.490211,-1.790635],
 [-3.591255,-3.870935,-0.276459],
 [ 0.921669,-0.604749, 3.364727],
 [ 0.411416,-0.486055, 3.210183],
 [ 4.638039, 1.181002,-0.203812],
 [ 2.83039 ,-0.789824,-1.091509],
 [-0.586548,-2.720417, 0.902332],
 [ 2.545745, 0.646023, 1.671322],
 [-1.072582, 0.147074, 1.273998],
 [ 1.65478 , 2.167189,-0.182345],
 [ 2.930392,-3.427383,-2.648902],
 [ 2.429261, 2.046926,-1.094785],
 [-1.508234,-1.208863, 0.694322],
 [ 0.02081 ,-3.521281,-5.812232],
 [-3.377235, 1.822936, 0.846056],
 [ 0.718087,-0.677563,-4.96753 ],
 [-0.103763,-0.115333, 2.959003],
 [-0.470339,-3.221007,-5.193857],
 [-0.593821,-3.995173,-4.257242],
 [ 0.189262, 2.169917, 2.28362 ],
 [-0.363169,-3.108043,-5.438262],
 [-2.959357, 2.261408,-0.615423],
 [ 1.316319,-1.700451, 1.249587],
 [ 0.020504,-1.853789, 1.997947],
 [ 2.83097 , 0.392032, 2.062942],
 [-1.599971,-0.709879, 0.862253],
 [-4.071417,-2.236712, 0.87421 ],
 [-1.297146,-0.674616, 3.444794],
 [ 5.469242, 2.698332, 0.147724],
 [-0.257009,-3.120679,-5.286864],
 [ 4.109684,-1.18773 , 2.433438],
 [-2.644904, 2.614032, 0.266658],
 [ 0.473569,-0.839546, 1.45922 ],
 [-5.150702,-0.116483, 4.950366],
 [-4.781678,-0.126125, 4.735172],
 [ 2.622229,-3.598308,-0.213652],
 [-0.628134, 2.084321, 5.32036 ],
 [-2.119192, 1.017614, 6.097322],
 [-0.950103,-3.343505, 1.4764  ],
 [ 1.118758,-0.272337,-1.454426],
 [ 1.137058,-0.078929,-1.353086],
 [ 2.656814, 0.255052, 0.344308],
 [ 2.217541, 0.210187,-0.143354],
 [-3.964882, 0.620122, 1.640291],
 [-4.652584, 1.129508, 1.690795],
 [-4.52751 ,-0.185063, 4.328163],
 [-4.442997, 0.561623, 1.38406 ],
 [-2.953881, 2.254379,-0.528547],
 [-0.679438,-2.039653, 3.524116],
 [-4.830639,-1.607136,-0.643185],
 [ 3.740427,-1.193094, 0.761617],
 [ 5.042664,-0.99231 , 0.587344],
 [ 4.408178, 1.559081, 1.495868],
 [-3.870514, 2.388415, 1.312784],
 [-4.65966 , 1.358425, 4.343143],
 [ 4.239691, 1.899824, 1.261699],
 [ 2.949721, 1.049469, 1.205541],
 [-4.770636,-1.821398,-1.082046],
 [-4.679955, 1.301436, 4.14592 ],
 [ 3.580753, 0.979528,-0.080101],
 [-0.757904,-2.21306 , 0.775433],
 [-2.440705,-2.682377, 0.634763],
 [-2.438423,-2.68385 , 0.645582],
 [-2.727485,-1.468795,-4.360086],
 [-4.705663, 0.761203, 2.198973],
 [-0.091919,-0.477627, 3.308745],
 [-2.578736,-0.286992, 3.635277],
 [-6.100797, 0.279404,-1.766339],
 [-3.070153,-0.744267,-2.234734],
 [ 0.141979,-0.523923,-0.083419],
 [-1.141382,-0.435679, 0.850808],
 [ 0.565296, 4.69025 , 3.71506 ],
 [ 1.482937,-0.598306,-1.290405],
 [-3.296367,-0.126083, 2.212684],
 [-3.713263, 0.451732, 6.523548],
 [-0.262822,-3.004738,-5.663898],
 [-2.78634 ,-3.315979,-2.698216],
 [-1.481974,-3.610439,-2.247212],
 [ 2.314457, 0.863521,-0.10243 ],
 [-0.978822,-1.096736,-0.338067],
 [ 0.337277,-1.733133,-1.842029],
 [-0.2294  ,-2.191059,-2.03188 ],
 [ 0.287817,-1.783241,-1.703416],
 [ 0.402219,-1.601339,-1.694829],
 [ 0.227685,-1.733144,-1.663651],
 [ 1.988757, 0.956141, 2.01259 ],
 [ 0.239198,-1.898393,-1.750614],
 [ 0.258231,-1.878945,-1.787797],
 [ 0.320488,-1.858035,-1.782548],
 [ 0.220932,-1.908111,-1.841099],
 [ 0.293454,-1.878078,-1.822244],
 [ 0.386676,-1.735967,-1.690282],
 [ 0.300418,-1.901004,-1.860437],
 [ 3.076502, 0.702314, 0.888945],
 [ 0.169581,-1.951906,-1.765487],
 [ 0.308764,-1.895057,-1.852161],
 [ 0.287142,-1.887469,-1.864885],
 [ 0.29486 ,-1.901857,-1.825576],
 [ 0.498249,-1.6358  ,-1.582215],
 [ 0.473724,-1.710492,-1.668297],
 [ 0.4392  ,-1.686658,-1.657666],
 [ 0.458738,-1.600847,-1.582806],
 [ 0.465535,-1.548875,-1.43177 ],
 [ 0.413285,-1.707733,-1.67326 ],
 [ 0.492466,-1.592819,-1.580744],
 [ 0.833628,-0.982047,-1.918525],
 [ 0.528795,-1.653033,-1.646546],
 [ 0.438128,-1.669142,-1.654096],
 [ 0.420965,-1.509409,-1.485412],
 [ 0.485576,-1.677039,-1.6706  ],
 [ 0.46858 ,-1.690439,-1.542803],
 [ 0.396586,-1.650245,-1.599106],
 [ 0.502034,-1.702088,-1.603076],
 [ 0.426109,-1.806201,-1.68688 ],
 [ 0.305221,-1.858041,-1.809608],
 [ 0.442916,-1.701282,-1.552601],
 [ 0.307782,-1.870456,-1.825332],
 [ 0.440096,-1.675896,-1.44413 ],
 [ 0.323308,-1.877394,-1.826   ],
 [ 0.452048,-1.707129,-1.554177],
 [ 0.305179,-1.869074,-1.816016],
 [ 0.41962 ,-1.711014,-1.570492],
 [ 0.333654,-1.862268,-1.815651],
 [ 0.43022 ,-1.664635,-1.816143],
 [ 0.110929,-0.561709,-2.674453],
 [-0.234766,-2.197468,-2.042083],
 [ 0.862721,-1.033255,-1.801454],
 [ 0.495712,-1.624457,-1.728377],
 [ 0.858407,-1.034189,-1.807648],
 [ 0.433706,-1.678104,-1.815309],
 [ 0.135005,-0.577313,-2.676   ],
 [-0.230683,-2.198993,-2.040332],
 [ 0.872761,-1.026744,-1.828585],
 [ 0.40038 ,-1.806014,-1.894955],
 [ 0.139294,-0.573037,-2.644274],
 [-0.234546,-2.212221,-2.031637],
 [ 0.869583,-1.020074,-1.841597],
 [ 0.467115,-1.82007 ,-1.79663 ],
 [ 0.297963,-0.739952,-2.520492],
 [ 0.81946 ,-1.004116,-1.941929],
 [ 0.279669,-1.903837,-1.84402 ],
 [ 0.185223,-0.62038 ,-2.624624],
 [-0.231483,-2.193377,-2.024712],
 [ 0.884949,-1.092181,-1.865552],
 [ 0.411425,-1.783343,-1.748119],
 [ 0.914371,-1.0959  ,-1.849419],
 [ 0.391729,-1.869765,-1.829395],
 [ 0.127353,-0.584279,-2.648021],
 [-0.223439,-2.198722,-2.034265],
 [ 0.868931,-1.028555,-1.813497],
 [ 0.373793,-1.856289,-1.850713],
 [ 0.131187,-0.577335,-2.644376],
 [-0.226313,-2.192476,-2.035296],
 [ 0.867748,-1.021542,-1.827985],
 [ 0.326375,-1.890174,-1.817251],
 [ 1.31935 , 2.412485, 2.42267 ],
 [ 0.126276,-2.001446,-1.71339 ],
 [ 0.343869,-1.7085  ,-1.546717],
 [ 0.018709,-2.125077,-1.709531],
 [-0.403142,-2.1685  ,-1.997487],
 [ 0.365935,-1.713957,-1.657473],
 [-0.412248,-1.961081,-1.509438],
 [ 0.381389,-1.384519,-1.682681],
 [ 3.038284, 0.919006, 0.517279],
 [ 0.423117,-1.40296 ,-1.678407],
 [ 0.402446,-1.362981,-1.705281],
 [ 0.43493 ,-1.391537,-1.68265 ],
 [ 0.400524,-1.349607,-1.702724],
 [ 0.364056,-1.732554,-1.647821],
 [-0.365212,-1.969284,-1.504746],
 [ 0.381232,-1.384161,-1.682429],
 [ 0.423538,-1.403728,-1.678606],
 [ 0.402606,-1.362857,-1.705492],
 [ 0.435611,-1.391735,-1.682631],
 [ 0.401095,-1.350309,-1.702628],
 [ 0.407105,-1.309224,-1.688945],
 [ 0.359049,-1.675441,-1.640318],
 [ 0.397262, 0.080625,-0.016521],
 [ 1.501444,-1.240283,-2.311839],
 [ 1.24444 ,-1.37991 ,-2.238082],
 [ 2.634134,-0.211091,-0.214363],
 [ 5.409797, 4.678195,-0.239593],
 [ 5.458981,-1.072983, 0.132835],
 [-0.983681,-2.622236,-2.981487],
 [-5.070538, 0.049344, 0.742447],
 [-7.335286,-0.247567, 3.164901],
 [-5.327387,-1.16801 , 0.738567],
 [ 4.430947, 2.739841, 2.860694],
 [-0.206438,-0.930464,-2.719267],
 [-0.19958 ,-0.940057,-2.714284],
 [ 1.182198,-2.002119,-2.345348],
 [ 0.837274,-0.621196,-4.916176],
 [ 4.287004, 0.596219,-1.651397],
 [-5.134672, 0.422689,-1.802613],
 [-3.051816,-1.004376,-2.13004 ],
 [-0.339919,-3.647196, 0.211483],
 [ 2.302291,-3.261338,-3.026472],
 [ 2.14718 , 3.363454, 2.094296],
 [-1.216346, 0.104137, 3.054419],
 [ 3.494762,-3.000336, 1.374569],
 [-4.125797, 0.779581, 5.956372],
 [-2.648546,-1.984083,-4.972689],
 [-0.641101,-5.771624,-0.616031],
 [ 4.9963  ,-0.361444,-3.327311],
 [-0.761809, 2.43862 , 5.533269],
 [-0.740226, 2.460973, 5.584274],
 [-3.665496,-3.908811,-0.03907 ],
 [-5.577868, 0.013762,-0.651565],
 [ 5.272937, 0.433393, 1.316561],
 [ 5.297785,-2.327395, 0.111933],
 [-0.221091,-0.393515, 0.811389],
 [ 0.166761,-0.110382, 0.253725],
 [ 0.7471  ,-2.83377 , 0.240249],
 [-4.71893 , 0.350758,-1.242224],
 [-4.846611,-0.221038, 1.303695],
 [-2.629366, 0.960571, 0.977586],
 [-3.508202, 0.976501, 1.797994],
 [ 0.906417,-0.554275, 3.33461 ],
 [-2.594232,-4.255925, 0.832684],
 [-2.654569, 0.903977, 0.297776],
 [-4.95339 ,-0.041306, 1.040444],
 [-4.852069,-0.138254, 1.436708],
 [-3.940993,-0.396572, 0.884483],
 [-7.227758,-0.232889, 3.110715],
 [-7.321412,-0.210506, 3.089567],
 [ 7.825319, 1.472426,-0.192953],
 [-4.090615, 0.754542, 6.143076],
 [ 1.393306, 0.479076,-2.716227],
 [ 0.685023,-2.372438,-0.074473],
 [-4.186583,-1.919116,-0.277425],
 [-6.538193, 0.536283, 1.687043],
 [ 6.000797, 0.158699,-2.788006],
 [ 1.633205, 2.519182,-0.304786],
 [-4.754305, 0.606785, 2.461271],
 [-4.627608, 1.365963, 4.281091],
 [ 4.179072, 0.720196, 0.195408],
 [ 0.404322, 0.076118, 3.388921],
 [-0.147128,-1.401443, 0.184953],
 [ 3.400988, 1.272766, 1.110466],
 [ 6.901366, 0.156798, 0.009998],
 [-2.528994, 1.854139,-2.191825],
 [ 2.18672 , 3.106325, 0.798622],
 [ 3.406199, 0.545845,-2.629742],
 [-1.622281, 0.22018 , 2.085397],
 [-0.669811,-1.731633,-0.741081],
 [ 0.29345 ,-4.14541 ,-5.805444],
 [-0.813611, 0.59305 ,-1.765772],
 [-4.960966, 0.002009, 1.002252],
 [ 2.923902,-2.100314, 1.261029],
 [ 2.936248,-2.002375, 1.277006],
 [ 0.206212, 0.973383,-3.155318],
 [ 2.632073,-0.688204,-1.098601],
 [ 3.835723, 0.234715,-1.309857],
 [-0.741595,-5.762734,-0.479863],
 [-0.010378,-0.541593, 3.194588],
 [-1.462447,-0.06665 , 3.567914],
 [-3.364945, 0.122174, 0.386903],
 [-1.320703,-1.115271, 0.178233],
 [ 0.874081,-0.492764, 3.226614],
 [-3.395471, 0.835046,-1.979105],
 [-4.619873,-3.82621 ,-0.618999],
 [ 1.356574,-0.166   , 0.643115],
 [ 2.428999,-0.486957, 0.220515],
 [-1.973377, 1.051513, 5.906073],
 [-2.731328,-0.16425 , 1.694251],
 [-7.314692,-0.210312, 3.111358],
 [ 3.759198, 1.887453, 0.087812],
 [-1.025061,-1.570643,-1.245365],
 [ 1.516747,-0.822232,-4.422215],
 [-0.61001 , 2.033368,-2.115159],
 [ 3.001353, 1.203677, 3.26616 ],
 [ 2.665201, 0.349298,-1.053863],
 [-1.167204,-3.192657,-3.473593],
 [ 0.226659,-3.741304,-2.756506],
 [ 0.691217,-2.267947,-2.410511],
 [ 4.963381, 1.725874, 1.342453],
 [ 5.041579, 1.618825, 1.064815],
 [ 6.747475, 0.218215,-1.845694],
 [-0.238281, 1.898638, 2.450763],
 [ 1.017237, 2.023689, 1.807054],
 [ 0.136404,-0.169043, 5.711507],
 [ 0.138573,-0.16783 , 5.676086],
 [ 3.111291, 2.013446, 0.288075],
 [ 4.162522, 0.898151, 1.79518 ],
 [ 2.014556,-3.361216,-1.54568 ],
 [-3.66991 ,-0.193142, 3.152964],
 [ 5.407624, 4.625964,-0.251522],
 [ 2.005725, 2.005486, 1.242176],
 [ 4.843652, 0.445638, 0.169814],
 [ 1.681702, 3.145685, 0.918216],
 [ 3.406907, 1.319683, 0.77745 ],
 [-3.825582, 1.041755, 2.650713],
 [-1.449538, 3.194614, 0.990822],
 [ 3.576794, 0.929671, 0.132922],
 [ 1.661646, 3.345169, 1.053275],
 [-2.914994, 2.883642, 4.125062],
 [ 3.398024, 2.508883, 0.519669],
 [ 2.839306, 3.614172, 0.28205 ],
 [ 1.516576, 2.474585, 1.877089],
 [-4.627176, 1.123997, 3.514656],
 [ 4.217659, 0.650951, 1.025416],
 [-1.020691,-2.515503,-1.499307],
 [-1.906983,-4.082   ,-1.621719],
 [-1.662789,-3.765415,-0.416126],
 [-2.999928,-3.6068  ,-1.344634],
 [-2.039558,-3.645524,-0.367273],
 [-2.007042,-3.567177, 0.003794],
 [-2.23419 ,-2.953243,-1.296999],
 [-2.011068,-3.298253,-0.315509],
 [-2.266772,-2.796941,-1.19983 ],
 [-2.019237,-3.261042,-0.370727],
 [-1.988434,-3.260981,-0.368549],
 [-2.454506,-3.273784,-1.14507 ],
 [-2.829102,-3.387225,-1.01028 ],
 [-2.865905,-3.389307,-1.286118],
 [-2.814852,-3.504943,-1.283288],
 [-2.511416,-3.499407,-1.275535],
 [-2.053117,-3.174506,-0.491402],
 [-1.717304,-3.065126,-0.90281 ],
 [-2.055153,-3.316788,-0.482482],
 [-2.899365,-3.415327,-1.135022],
 [-3.455422,-0.957412,-0.782541],
 [ 1.800899,-2.384675, 0.770489],
 [-2.632916,-3.423251,-1.222238],
 [-1.568063,-3.474947,-0.26704 ],
 [-1.659436,-3.296568,-0.614595],
 [-3.3517  ,-0.862629,-0.412215],
 [-3.041699,-3.240874,-1.43964 ],
 [-3.033724,-3.459404,-1.491761],
 [-2.766134,-3.55552 ,-1.388949],
 [ 1.933075,-2.179209, 0.964774],
 [-2.311399,-3.393157,-1.257308],
 [-0.329425,-1.91324 ,-1.321564],
 [-0.215453,-1.931478,-1.459285],
 [-2.696014,-3.383637,-1.317909],
 [-2.617648,-3.403324,-1.258595],
 [-3.096863,-3.486737,-1.559816],
 [-2.281851,-3.190742,-1.066642],
 [-2.324221,-3.281068,-1.546208],
 [-2.273756,-3.302428,-1.544458],
 [-1.928836,-3.275644,-1.43938 ],
 [ 1.88816 ,-2.330248, 0.866164],
 [-2.559066,-3.340138,-1.256043],
 [-1.501023,-3.541631,-0.339813],
 [-1.988589,-3.379202,-0.475   ],
 [-2.257453,-3.681108,-0.690547],
 [-2.058893,-3.406597,-1.194262],
 [-1.295063,-3.50899 ,-0.226265],
 [-1.132193,-3.431069,-0.266726],
 [-2.298332,-3.845953,-1.062158],
 [-1.607684,-3.953624,-0.29642 ],
 [-3.070594,-3.233754,-0.468374],
 [-2.393222,-3.783785,-0.471581],
 [-2.37028 ,-3.722006,-0.52455 ],
 [-2.364053,-3.712381,-0.537124],
 [-2.208736,-3.730459,-0.619487],
 [-2.355473,-3.827282,-0.665845],
 [-2.145892,-3.764774,-0.597537],
 [-1.56568 ,-3.414339,-1.404137],
 [-3.05289 ,-3.58094 ,-1.235471],
 [-2.121618,-3.307321,-0.819396],
 [-3.343217,-4.002521,-1.192064],
 [-2.953016,-3.235566,-0.453194],
 [-1.44448 ,-3.31798 ,-0.928371],
 [-2.115423,-3.036009, 1.371693],
 [-3.225734,-1.247689, 3.078907],
 [-2.129744,-3.013746, 1.17067 ],
 [-1.447147,-1.956749,-0.405346],
 [-1.083312,-3.416347,-0.198745],
 [-1.512872,-2.461993,-0.735952],
 [-3.083574,-3.623398,-1.536675],
 [-1.077243,-1.319772,-0.188663],
 [ 1.979401,-2.326591,-3.924126],
 [-0.619411, 1.218407,-4.65001 ],
 [-0.621152, 1.226171,-4.654898],
 [-2.248317,-4.155944,-1.372598],
 [-1.855675,-3.415226,-0.154482],
 [-2.295209,-3.677589,-0.361497],
 [-2.191453,-4.016713,-1.310375],
 [-1.125027,-3.299783,-0.747108],
 [-3.925745,-0.524292,-3.11117 ],
 [-1.516223,-3.897179,-0.307137],
 [-0.789877,-1.632376,-0.637375],
 [-0.084597,-1.143259,-5.470006],
 [-4.101147, 0.069538,-0.149053],
 [-1.042007,-1.937144,-0.791678],
 [ 3.821892, 1.455543, 3.007271],
 [-2.010809,-3.589785,-0.002509],
 [-2.312622,-4.146654,-1.187672],
 [-1.690134,-3.753864,-0.882104],
 [-0.848158,-2.341208,-1.270937],
 [-1.549676,-3.427943,-0.579051],
 [-2.129925,-4.11118 ,-0.872706],
 [ 0.922476, 3.392969,-0.043362],
 [-0.597887,-1.657343,-0.696724],
 [-2.359858,-3.868339,-1.112019],
 [-1.692366,-4.309546,-1.331027],
 [-2.293581,-4.044997,-1.179334],
 [-1.007346,-1.277034,-0.091247],
 [-1.716822,-4.2037  ,-1.296138],
 [-1.637432,-4.139697,-1.308338],
 [-0.491508, 0.632129, 4.125284],
 [-0.835625,-1.621059,-0.610785],
 [-0.909134,-1.745876,-0.741716],
 [-2.489933, 3.450578,-1.106279],
 [-1.502295,-3.446628,-1.039517],
 [-1.063773,-1.242839,-0.059064],
 [-2.495497, 3.44807 ,-1.108624],
 [-2.414999,-4.50983 ,-1.344429],
 [-1.533644,-3.47757 ,-1.101563],
 [-1.251317,-1.400922,-0.120121],
 [ 0.923236, 1.072204, 0.700595],
 [-1.647998,-3.610965,-0.493402],
 [-0.301662, 0.674182, 4.275232],
 [-2.483909,-4.34596 ,-1.044924],
 [-0.719646,-1.698359,-0.724406],
 [-2.331367,-4.682247,-1.372746],
 [-1.973036,-4.587847,-1.358287],
 [-1.667592,-3.776274,-0.65031 ],
 [-1.690711,-3.755718,-0.48307 ],
 [-2.329492,-4.449623,-1.357713],
 [-0.852838,-2.073424,-0.867841],
 [-2.306419,-4.639216,-1.391298],
 [-2.345457,-4.543894,-1.431066],
 [-2.315217,-4.486446,-1.463077],
 [-2.35824 ,-4.505387,-1.423235],
 [-2.452226,-4.627945,-1.432159],
 [ 5.920798, 1.505582, 2.085383],
 [ 0.372938,-0.887424,-4.69172 ],
 [-2.048631,-4.319379,-1.652037],
 [-2.446789,-4.629318,-1.430855],
 [-2.355746,-4.455599,-1.403182],
 [-3.096086,-3.587448,-1.200422],
 [-2.376537,-4.469619,-1.419878],
 [-1.450384,-3.183679,-0.850487],
 [-2.380118,-4.446611,-1.404348],
 [-2.539817,-4.256463,-1.404428],
 [-2.252252,-4.619729,-1.365065],
 [-2.288995,-4.358679,-1.291839],
 [-2.27209 ,-4.417894,-1.484412],
 [-1.955966,-4.193815,-1.628637],
 [-2.289526,-4.435612,-1.362496],
 [ 1.968171,-1.164476, 1.527489],
 [-0.783915,-1.641105,-0.633978],
 [-2.333322,-4.367614,-1.314114],
 [-2.354082,-4.374568,-1.257008],
 [-2.364912,-4.271542,-1.207126],
 [-2.507498,-3.647811,-1.131298],
 [-2.402978,-4.404992,-1.185834],
 [-2.283911,-4.208368,-0.938592],
 [-0.691454,-1.713337,-0.773806],
 [-2.380696,-4.449759,-1.233467],
 [-2.126064,-3.75611 ,-0.634032],
 [-2.282862,-4.134629,-1.04279 ],
 [-1.853424,-1.957674, 2.089525],
 [-2.287606,-3.71433 ,-0.604261],
 [-3.02692 ,-3.048368,-0.459872],
 [-1.582079,-3.765088,-0.785414],
 [-2.137703,-3.919462,-0.559291],
 [-2.118158,-3.916498,-0.321862],
 [-1.687834,-3.446157,-0.857161],
 [-2.349511,-3.776265,-0.695873],
 [-1.482867,-3.177729,-0.722684],
 [-2.865058,-3.086693,-0.521421],
 [-0.583478,-2.590885, 0.346411],
 [-4.218372,-0.76668 , 3.853993],
 [ 1.80609 ,-0.739933, 3.285712],
 [-0.936695,-1.79486 ,-0.759144],
 [ 1.195249, 0.101969, 2.111798],
 [ 2.430111, 0.819642, 4.623868],
 [ 2.429143, 0.81874 , 4.611698],
 [ 0.576773, 0.20132 , 2.389778],
 [-1.590784,-3.390181,-0.950876],
 [-3.367456,-4.01335 ,-1.186103],
 [-1.401061,-2.14665 ,-0.613016],
 [-1.327906,-1.910064,-0.448403],
 [-0.338961,-2.55619 ,-5.348416],
 [-3.085482,-3.562119,-1.147331],
 [-1.67033 ,-3.455925,-1.544548],
 [-2.006583,-3.817003,-0.987384],
 [-2.166065,-3.681371,-0.857948],
 [-2.147382,-3.782182,-1.0607  ],
 [-1.979999,-3.750159,-1.302505],
 [-1.693104,-3.750234,-0.403125],
 [-0.627114,-1.439455,-0.411668],
 [-1.038081, 2.549257, 3.874764],
 [-3.030691,-1.056116, 0.794577],
 [ 0.32836 ,-4.159926,-5.842807],
 [ 0.620299,-3.445384,-4.150055],
 [ 1.827446,-1.290075, 1.430557],
 [-4.2904  , 2.161672, 3.288619],
 [-3.280951, 0.192763, 1.435194],
 [-6.06892 , 0.270723,-1.734594],
 [ 2.036014,-0.698804, 1.141401],
 [ 4.188501, 0.998054, 1.811697],
 [-3.435808,-0.057145, 1.616489],
 [-1.838516,-1.957133,-0.8533  ],
 [ 1.403658, 1.652673, 0.81315 ],
 [ 2.293042, 3.424439,-0.351019],
 [-4.988102,-0.797404, 0.353317],
 [ 0.32293 ,-0.200235, 0.051548],
 [ 0.220086, 0.076788,-0.030696],
 [-2.070588,-0.460876, 0.927385],
 [-0.70579 ,-0.229715, 3.122455],
 [ 0.761153,-0.671972,-4.922436],
 [-0.431932,-4.71347 ,-4.176731],
 [-3.164479, 0.035897, 1.023882],
 [-3.49248 ,-0.182313, 0.947151],
 [-6.979966,-0.313119, 3.218253],
 [-1.468963,-0.618099, 3.293491],
 [ 0.893644,-4.224109,-0.608478],
 [ 1.382451,-3.676534,-4.118388],
 [-0.952718,-1.343176, 1.689124],
 [-0.369597,-0.317627, 3.124041],
 [-0.324761,-2.942111,-5.554667],
 [ 4.705758, 0.316067, 0.593643],
 [ 1.68257 , 0.337601, 0.25966 ],
 [-0.103129,-2.082613,-1.742503],
 [-0.122939,-0.397805, 3.279308],
 [ 1.077845, 1.592308, 0.827409],
 [-6.565498, 0.969693, 1.838624],
 [-0.448115,-3.802907,-4.167565],
 [ 3.591401, 1.0302  , 0.043378],
 [-1.958863, 0.432828,-1.740483],
 [ 5.339569,-2.358477, 0.043011],
 [ 5.198042,-2.227905, 0.23149 ],
 [ 4.35126 , 2.927464, 2.169043],
 [-2.619562,-1.643801, 1.073445],
 [-0.341332,-2.941592,-5.122912],
 [-2.27241 ,-2.124848,-2.753289],
 [-2.63673 ,-1.532771, 0.359324],
 [-1.5462  ,-0.446013, 3.107902],
 [-3.365613, 0.917227,-0.581271],
 [ 2.322328,-2.426329,-3.138409],
 [-5.141591, 1.478228,-0.990581],
 [-3.918124, 0.344532, 0.563639],
 [-3.616406,-1.204483,-0.369542],
 [-0.136345,-0.62604 , 3.112475],
 [-0.354853,-2.929545,-5.553665],
 [ 1.192839,-1.001936, 1.501932],
 [-0.040331,-0.42191 , 3.059844],
 [ 4.005487, 1.034131, 0.044889],
 [-1.840147, 0.055514, 4.942276],
 [-0.696389,-5.785976,-0.233469],
 [-4.013549,-1.953424,-0.041969],
 [-1.88999 , 3.176066, 0.898052],
 [ 0.176399, 3.373111,-2.437024],
 [ 2.094536, 0.055354, 0.251542],
 [ 4.13256 ,-0.548487,-1.420454],
 [ 4.254197,-0.613359,-1.432001],
 [ 4.211314,-0.602546,-1.438719],
 [ 4.19142 ,-0.576334,-1.425312],
 [ 4.068923,-0.500452,-1.439878],
 [ 4.200003,-0.576828,-1.350067],
 [-2.116842, 0.30597 , 1.37034 ],
 [ 0.619711,-0.636096,-5.213807],
 [ 0.509053,-0.691277,-5.029572],
 [ 0.08175 ,-0.223064,-4.909367],
 [ 0.404355,-0.780035,-4.925561],
 [-4.339334, 0.064839, 0.653665],
 [-4.39379 , 1.718931, 3.095776],
 [-7.43571 ,-0.163988, 3.184502],
 [ 1.293003, 0.29832 ,-0.148212],
 [-4.271579,-0.081888, 1.336446],
 [-1.508726,-2.224468, 0.954208],
 [-7.264557,-0.222241, 3.096175],
 [-0.785433,-5.816209,-0.234709],
 [-2.217519,-5.44363 , 0.244086],
 [-3.426735, 0.696965,-2.083661],
 [-5.065874, 0.822912, 0.618918],
 [ 3.695899,-3.034206,-3.22119 ],
 [-3.790544,-0.299231, 3.612302],
 [-0.486732, 2.437701, 5.594576],
 [-2.514924,-4.361576, 0.777633],
 [-0.342308, 2.460737, 5.594723],
 [ 3.936733,-0.337   , 0.22859 ],
 [ 1.451209, 4.888519, 3.344102],
 [-5.519849,-0.478376, 0.36643 ],
 [-0.406711,-3.175193,-5.315815],
 [ 1.680179, 1.294142,-1.344811],
 [-2.518239, 0.401772, 1.278419],
 [-0.049788,-1.301704, 0.776576],
 [ 4.121477, 1.734355,-0.278596],
 [ 1.163646, 1.221134, 3.747646],
 [ 0.137407,-0.198042, 3.236424],
 [-3.286035, 0.404184,-2.050919],
 [-0.504845,-3.489689,-4.763258],
 [-2.943956,-1.678221,-4.737687],
 [ 0.253528, 1.214873, 0.809242],
 [-0.813067, 3.244252, 1.266925],
 [ 0.009983, 1.471901, 1.227059],
 [-3.936555, 0.382496, 0.618152],
 [ 4.374517, 0.61838 ,-0.100825],
 [ 4.358496, 1.464263, 1.532714],
 [-0.266873, 1.726312, 2.467068],
 [-4.578694,-3.129161, 0.022732],
 [-4.584436, 0.786228, 2.605821],
 [-4.376897, 1.282476, 4.541244],
 [-3.990852, 0.676342, 6.373098],
 [-4.613476, 1.375541, 4.436201],
 [ 5.555964, 0.564531,-0.832397],
 [ 1.899075, 2.974339, 0.576513],
 [ 3.444592, 2.07092 ,-0.240839],
 [ 3.16813 , 0.157138,-0.326308],
 [ 1.925135, 2.163573, 1.19076 ],
 [-1.491332, 3.463729, 1.36964 ],
 [ 1.253037, 3.575351, 0.997724],
 [ 0.76571 , 2.77685 , 1.179286],
 [-6.858816,-0.212842, 2.842366],
 [ 1.389132, 3.734707, 1.137901],
 [ 1.310635, 3.620369, 1.090308],
 [ 1.237828, 3.588159, 0.995116],
 [ 1.77222 , 3.2257  , 1.345683],
 [ 2.808723, 2.955997, 3.366122],
 [-3.447559, 1.101854, 1.890202],
 [ 3.907053, 4.936635, 1.004305],
 [-0.310095,-2.248065, 4.525887],
 [-6.345078,-0.06797 , 2.287085],
 [ 4.123577, 3.036055,-0.273136],
 [-0.848209,-0.936783,-1.117668],
 [ 5.670035, 4.735488,-0.280522],
 [-6.813497,-0.382718, 0.657358],
 [-1.760081,-0.28417 , 0.927801],
 [-0.604157,-0.318477,-0.955468],
 [-3.222117,-2.084228,-1.124616],
 [ 0.800421,-2.460307, 0.061576],
 [-4.65924 , 0.1352  ,-0.492004],
 [-1.8737  ,-1.19063 ,-2.249244],
 [-4.391986, 0.881549, 2.37811 ],
 [ 0.669584, 2.594562,-0.646036],
 [-3.15557 , 0.08539 , 2.416584],
 [-2.597296, 0.401983,-0.191166],
 [-2.327534, 0.750846, 0.025671],
 [-1.305857,-3.66093 ,-2.055306],
 [ 2.198875, 3.08167 , 0.89754 ],
 [-5.436431,-0.99003 , 0.333548],
 [-1.432995, 1.519481, 5.291785],
 [-3.399727, 0.084435, 0.523692],
 [-0.204144, 1.955461,-1.964796],
 [-6.049326, 2.310103, 1.893028],
 [-3.951684, 1.393694, 2.054132],
 [ 5.946761, 0.267211,-0.987443],
 [ 7.023046, 2.468   ,-0.054111],
 [ 2.302991,-1.681313, 0.152283],
 [ 1.37314 ,-3.18444 ,-3.703653],
 [-0.745561, 0.311857,-0.234954],
 [ 5.729537, 2.040108, 0.353075],
 [-0.291757, 3.884023, 4.308964],
 [ 5.602549,-0.732964,-1.240855],
 [-1.978373,-3.181316, 0.222923],
 [-4.632709, 0.028458,-0.302793],
 [-4.262003, 2.103323, 3.318129],
 [-5.128048,-0.703759, 0.378941],
 [ 0.888784,-0.23333 ,-0.296993],
 [ 3.145421, 0.000802, 0.412881],
 [ 0.835136,-0.343933, 0.023042],
 [ 3.698808,-2.813171,-3.635164],
 [ 4.363429,-2.696403,-2.93222 ],
 [ 0.662247,-0.543754, 0.15736 ],
 [-1.234147,-3.387846,-2.298437],
 [-3.578217,-4.768916,-0.259139],
 [-3.016174,-1.21131 ,-2.054592],
 [-2.468354, 0.316913, 1.570524],
 [-2.417521, 0.304572, 1.45779 ],
 [-2.771301, 0.365083,-0.270921],
 [-4.419358, 0.899579, 1.666908],
 [ 2.195998, 2.600944, 0.313738],
 [ 0.012942,-0.885865, 2.770882],
 [-0.604945, 0.402042, 3.953713],
 [-3.738322,-3.903216,-0.066539],
 [-1.224821,-2.645096,-1.504673],
 [ 4.284196, 3.332975, 2.390427],
 [-3.553422,-3.862287,-0.386729],
 [-3.687563,-3.851433,-0.206159],
 [-5.178731,-0.133875, 1.394966],
 [-4.229807, 1.239845, 0.635334],
 [-2.71344 ,-1.863918,-4.505114],
 [-0.457647,-4.559163,-4.169781],
 [ 0.405826,-3.093195, 0.20932 ],
 [-0.617017,-3.830864, 0.869353],
 [-2.377852, 2.566422, 4.336454],
 [ 3.153517, 1.065716, 0.154343],
 [-0.276443,-2.923548,-5.579355],
 [-2.98766 , 2.245843,-0.577534],
 [-3.079496,-1.674021,-4.142175],
 [-0.329095,-2.942816,-5.593545],
 [-2.771265,-1.74526 ,-4.02924 ],
 [-2.707766, 2.75669 , 0.352329],
 [-1.19934 , 0.754907, 1.545809],
 [-1.687138, 0.385953, 2.040574],
 [-1.304051, 0.00811 , 0.953186],
 [ 4.199934, 4.158984,-0.132344],
 [ 4.634407, 0.703028,-0.074148],
 [-2.670961,-1.96308 ,-4.89283 ],
 [-0.346378,-3.074917,-5.56954 ],
 [-2.401151, 0.545484, 1.088371],
 [ 3.704232,-3.210382,-3.107718],
 [-0.102343,-3.30038 ,-4.182449],
 [-5.093368, 1.271171,-0.985498],
 [-2.571596,-3.301647,-3.41642 ],
 [-0.995455,-3.350385, 1.565808],
 [ 3.720377,-1.369046,-1.014407],
 [ 0.759158, 5.08493 , 1.986848],
 [-3.152167,-2.963807,-2.177417],
 [-2.218977, 1.817791, 3.077518],
 [-2.247183,-0.137882,-2.146194],
 [-2.767236, 1.910696, 0.080716],
 [ 2.490113, 0.911813, 3.390868],
 [ 4.781093, 0.734055,-0.2549  ],
 [-3.148516, 0.082407, 0.936086],
 [ 1.722599, 2.514089, 1.121121],
 [-2.723158, 2.728739, 0.357178],
 [-1.667577,-2.281744,-0.84313 ],
 [ 2.900006, 0.920526,-0.519537],
 [ 4.734897, 0.675425,-1.319063],
 [ 2.519605, 0.287218,-0.414822],
 [-1.553201,-2.012108,-0.875321],
 [-3.257817,-0.987288, 0.14293 ],
 [ 0.072869,-1.023019,-4.44355 ],
 [ 5.945705, 3.111315, 1.615117],
 [ 4.601699,-0.153913, 1.521473],
 [ 2.437628, 0.183496, 0.764281],
 [ 3.000524, 5.207656, 0.253505],
 [ 4.842765, 1.377422,-0.927379],
 [-4.260584, 1.917473, 3.202844],
 [-0.39334 ,-1.033343,-0.515764],
 [-0.751424,-0.551607,-0.886677],
 [-1.087408,-2.313848,-0.286944],
 [ 3.439782, 1.355965,-0.212819],
 [-0.623653,-0.340944,-0.941004],
 [-1.951005,-5.816885,-0.347353],
 [-2.14643 ,-5.81532 ,-0.376025],
 [ 5.560699, 0.335669,-0.534709],
 [ 0.249914,-4.008427,-5.814016],
 [-0.167193,-3.481524,-3.634356],
 [ 2.969294,-1.148278,-0.09763 ],
 [-4.182033,-1.88867 ,-0.221213],
 [ 6.610655, 0.244209,-2.357646],
 [ 1.2894  , 2.373694, 0.914293],
 [-3.668149, 0.033924, 3.685435],
 [ 0.581297,-0.927483,-5.126525],
 [ 0.24759 ,-0.48914 , 3.309071],
 [-3.582594,-1.09609 , 3.428001],
 [-0.688521, 1.869359, 5.129597],
 [-3.799649, 0.179404, 1.671253],
 [-0.94666 ,-0.498667, 0.854426],
 [-1.009744,-0.469519, 0.933917],
 [-1.92675 ,-3.297012,-3.482697],
 [-0.134071,-2.104553,-1.729348],
 [ 2.330363, 1.668362,-0.396402],
 [ 3.240765, 0.062172,-1.484559],
 [ 4.475692, 0.559328, 0.070889],
 [ 6.533513, 0.025292,-0.624372],
 [ 5.940072, 3.152458, 1.619834],
 [ 0.631237,-1.59858 ,-3.402162],
 [ 6.469951, 0.244141,-0.733461],
 [ 3.358536, 0.176976, 1.868097],
 [-2.064673,-1.411335, 1.759705],
 [-5.517103, 2.268988, 0.268528],
 [-2.058819,-1.421333, 1.792137],
 [-5.504409, 2.131506, 0.070155],
 [ 2.232786,-1.384192,-1.097871],
 [-1.725615,-1.670013,-0.273296],
 [-2.654312,-0.564584, 4.307015],
 [-0.1513  ,-3.072683,-5.600124],
 [ 0.178532, 5.239018, 3.062274],
 [-2.843251, 0.478464, 2.514224],
 [-2.960892, 0.176679, 2.494681],
 [ 6.638374, 2.895952, 0.640659],
 [ 2.272765,-0.452411, 0.019951],
 [ 0.915461, 2.298531,-0.627887],
 [ 1.663548, 3.054983,-1.019139],
 [ 3.815334,-2.831421, 0.330262],
 [ 4.799433,-0.267744, 0.903944],
 [-0.201651, 3.872412, 0.873508],
 [-0.333153, 3.864041, 4.315371],
 [ 2.980647, 0.042399,-1.249738],
 [-2.016968,-1.338464, 1.597081],
 [ 3.517493,-3.233871,-3.261381],
 [ 4.407744,-2.685243,-2.883297],
 [ 3.782896,-2.940949,-3.676221],
 [-4.186087,-0.795684, 3.822257],
 [-0.889587,-1.715747, 3.336009],
 [-1.971846, 0.002339, 3.183592],
 [-2.202504, 0.019876, 3.096148],
 [ 3.764613,-0.111383,-0.163342],
 [ 1.743679,-1.27381 , 2.668512],
 [ 3.080481, 1.809217,-0.530145],
 [ 5.031614, 0.455659, 2.297621],
 [ 1.338061, 4.318221, 0.590669],
 [ 2.151413, 0.396858, 0.000407],
 [-0.820199,-2.635351,-0.199084],
 [-3.021507,-0.364185, 0.266834],
 [-3.170398,-1.695996,-4.540438],
 [-5.25877 ,-2.872928, 0.778953],
 [ 4.137012, 1.662213, 1.660332],
 [-2.695914,-0.450781, 4.462749],
 [-3.169121, 0.001294, 2.035587],
 [-2.689384,-0.00921 , 0.74815 ],
 [-4.457053, 1.138403, 2.968266],
 [ 1.663417,-3.653774,-4.559436],
 [-2.875188,-1.760277,-4.567091],
 [ 0.240777,-3.99329 ,-5.818311],
 [-0.583124,-5.744737,-0.618696],
 [-2.355883,-0.775501, 0.968518],
 [-0.889084,-3.603928, 0.965197],
 [ 6.74645 , 0.21729 ,-1.825794],
 [ 4.447767,-1.137158, 0.02073 ],
 [ 5.654767, 4.726096,-0.272496],
 [-4.092121, 0.317149, 1.149126],
 [-5.259293,-2.867644, 0.773771],
 [ 1.385386,-3.713064,-4.317244],
 [ 4.420056,-1.191831, 0.067893],
 [-5.138523,-0.117727, 1.383142],
 [-4.231112, 1.239387, 0.641778],
 [-4.641756, 0.614682, 2.258532],
 [ 1.662747,-3.675472,-4.269403],
 [-2.468026, 0.468269,-0.554756],
 [-4.770604, 0.861101, 1.037134],
 [-4.682405, 1.045916,-0.958247],
 [-2.265539,-0.549381, 1.020186],
 [-4.615376, 1.058577,-0.291858],
 [-0.356051,-3.094172,-5.186374],
 [ 2.142032, 3.476865,-0.324032],
 [ 2.226712, 3.49704 ,-0.384023],
 [ 2.194678, 3.531839,-0.401708],
 [ 3.295976,-0.802097,-1.468143],
 [-2.919427,-1.866288,-1.439023],
 [-1.57923 ,-5.791785,-0.296195],
 [ 0.56023 ,-2.556983, 0.087179],
 [-0.639413,-1.303411, 2.048043],
 [-1.460777,-0.198208,-0.359444],
 [-0.656882,-2.143331, 2.60358 ],
 [-3.362843,-2.267925,-2.944927],
 [-1.253931,-0.578962, 3.40706 ],
 [-1.679096,-0.075278, 0.367511],
 [-2.085435, 0.322571, 4.529353],
 [-0.383259,-3.600131,-4.639161],
 [-2.505608,-2.054661,-4.976864],
 [ 3.423822, 3.80513 ,-0.10695 ],
 [-0.618348, 2.430614, 5.589375],
 [ 4.940235, 1.337096, 0.889838],
 [-0.679914, 2.478393, 5.607349],
 [ 4.787387, 3.412195, 0.027324],
 [ 4.888254, 0.346724,-0.308334],
 [ 0.203499, 0.080582, 0.156866],
 [ 1.368188,-1.092591, 0.94213 ],
 [-2.880155, 0.350345,-0.986659],
 [ 2.525787, 0.525037,-0.18944 ],
 [-0.063223,-0.325446, 1.342063],
 [ 5.430418, 0.472309, 0.084529],
 [-4.683403, 1.046593,-0.959195],
 [-3.818743,-0.130903, 1.138817],
 [-0.195566, 3.856874, 4.224459],
 [-2.694176,-0.62808 , 3.902825],
 [-4.752756, 1.414638, 3.359056],
 [ 1.193783,-0.724428,-2.863697],
 [ 2.957714,-2.063797, 1.277711],
 [-3.357204,-3.768655,-0.777633],
 [ 0.842484,-2.840864, 0.990653],
 [ 2.894166, 0.47479 , 0.878624],
 [-1.096576,-0.37969 , 0.686725],
 [-1.869827, 2.580076,-0.206301],
 [ 5.138455, 0.306528, 1.728722],
 [-4.073379,-0.556129, 5.263892],
 [ 2.586022, 1.060431,-0.245365],
 [ 5.20549 , 0.308572,-2.242716],
 [-4.768577,-0.106215, 4.661348],
 [-0.665493,-0.084872,-2.939455],
 [ 3.576237,-2.949745, 1.449056],
 [ 4.791685, 1.283768,-0.742026],
 [-4.024634, 0.733156, 6.297558],
 [-1.174161,-1.0548  , 0.059452],
 [ 0.752706, 5.116251, 1.979521],
 [ 5.641454, 0.488267,-0.374605],
 [ 4.813041, 0.058398, 0.855131],
 [-2.727208, 2.15961 ,-1.51891 ],
 [ 3.003   , 1.434418,-0.533001],
 [ 6.809115, 0.231164,-2.33666 ],
 [-0.435619,-4.721411,-4.175895],
 [-2.183831, 0.304151, 0.792194],
 [ 0.238928,-0.510248, 1.571286],
 [ 4.798968, 1.248833,-0.71552 ],
 [ 2.812566, 1.016366,-0.041912],
 [-2.588286, 2.37336 , 1.872349],
 [-1.190407,-1.045047, 0.07162 ],
 [-1.065934,-0.426063, 0.849388],
 [ 6.140696, 0.179029,-0.155142],
 [-0.527349, 0.75388 , 5.787624],
 [-1.440176,-2.599438, 0.926334],
 [-0.454546,-3.539052,-1.707254],
 [-6.841211, 1.205994, 1.789317],
 [-2.261175,-0.100817,-2.193318],
 [ 4.627848, 1.661147, 2.590565],
 [ 0.439979,-0.468985,-5.144604],
 [ 0.023162,-1.178348,-5.6779  ],
 [-0.513552,-3.981582,-4.215743],
 [-0.335269,-3.600415,-4.094962],
 [ 3.301023,-3.338001,-3.138641],
 [-2.674507,-1.972567,-4.921115],
 [-0.296634,-2.987625,-5.356804],
 [ 3.437786, 0.619753, 0.537878],
 [-0.365809, 0.306864, 3.816264],
 [-1.250294,-3.476363,-2.252156],
 [ 2.850515, 0.205308,-0.626359],
 [-0.573524, 0.683477, 5.784888],
 [ 3.003264, 5.20268 , 0.253338],
 [ 0.484237,-1.554457,-1.497036],
 [ 0.4272  ,-1.181592,-4.003497],
 [ 0.210601, 0.099979,-0.001268],
 [ 4.535235,-0.141708,-0.200381],
 [ 4.383703, 2.482439,-0.376413],
 [-1.232814,-0.560776,-3.83877 ],
 [-0.002163,-1.990499, 1.752699],
 [ 0.010329,-1.946314, 1.739956],
 [ 2.032083, 3.519483, 0.199984],
 [ 3.65653 , 2.564748, 0.923333],
 [-6.552248, 0.835567, 1.697397],
 [ 1.649526,-1.430194, 0.321902],
 [ 1.780871,-1.419825, 0.201404],
 [ 3.511965,-0.947946, 0.221743],
 [-1.136516, 0.13647 , 2.847948],
 [-2.90448 , 0.267678,-1.227427],
 [ 4.385129, 0.497803, 1.158674],
 [-1.691948,-1.173095,-0.266253],
 [-2.613911,-0.076664, 1.632335],
 [ 2.923289, 0.243226, 0.923833],
 [ 1.588465, 0.057286, 0.339166],
 [-7.237344,-0.146692, 2.866549],
 [ 1.071076, 0.166346,-0.148108],
 [-1.435751,-0.113899, 3.603377],
 [ 0.591798,-0.685139,-4.24305 ],
 [-4.870444,-1.05908 ,-0.817648],
 [-4.609108,-0.746852,-0.491479],
 [ 5.000637,-0.165755, 1.906356],
 [-0.551701,-2.819038,-5.49012 ],
 [ 3.301985, 1.605364,-1.657857],
 [-0.714823, 1.285836,-2.514518],
 [-2.584101,-2.114661,-0.56924 ],
 [ 3.578106,-2.949354, 1.437255],
 [ 5.078388, 0.57019 ,-0.606252],
 [-5.505694,-0.025732, 0.55786 ],
 [-3.689806, 0.767503, 2.900911],
 [ 2.754387, 0.631115,-0.414503],
 [-4.73224 ,-1.060803, 0.182263],
 [-4.037905,-0.252545, 3.981521],
 [ 1.89784 ,-2.60032 , 0.204149],
 [-1.027541, 0.229077, 3.100473],
 [ 3.312222, 1.431886,-1.615706],
 [-1.390562,-0.558236, 1.155868],
 [-1.244718, 1.352011, 1.451929],
 [-0.58426 ,-1.379814, 2.005194],
 [-1.407163, 1.611806, 1.400954],
 [ 4.897655, 0.6736  , 1.06398 ],
 [-5.375918,-0.477223, 0.206013],
 [-4.677652, 1.286649, 0.632547],
 [ 4.187931,-0.34042 , 0.521191],
 [ 1.576498,-2.578287, 0.517818],
 [-1.008498,-0.011897, 0.866867],
 [-4.086614,-0.500211, 0.436978],
 [-3.096098,-1.106701,-1.902361],
 [-4.782725,-0.003043, 1.20284 ],
 [-2.55369 ,-1.372745, 1.775977],
 [-3.891293,-0.155446, 0.210953],
 [-3.434038,-1.205123,-2.927937],
 [-5.01871 ,-0.277552, 0.210719],
 [-4.687376, 2.074753, 0.105143],
 [-4.705772, 2.046543, 0.144241],
 [-4.646025, 1.897231, 0.350844],
 [-2.337324,-3.159626,-0.785856],
 [-3.942194, 2.171649, 1.889849],
 [-2.92786 , 1.748087, 2.86303 ],
 [-2.959806, 1.702024, 2.883792],
 [-4.53552 , 2.159726, 0.497469],
 [-3.164906, 1.622821, 2.916691],
 [ 6.834523, 0.209336,-1.872796],
 [-2.220885, 0.47336 ,-1.953565],
 [-2.274061, 0.894058, 5.931284],
 [-2.129262, 1.004938, 6.076051],
 [ 1.004751, 0.609559, 1.075332],
 [-4.446644, 0.988736, 2.389677],
 [ 2.561613, 0.506481, 2.228801],
 [-2.982645, 2.151861,-0.489564],
 [-4.492611,-0.190495, 1.503703],
 [-1.91807 , 0.230009,-1.0117  ],
 [-2.659987,-1.967948,-4.89178 ],
 [-0.237751,-3.547954,-4.63774 ],
 [ 6.995903, 2.547907,-0.037512],
 [-3.493894, 0.259861, 0.975406],
 [-2.455353, 0.322506, 5.179443],
 [-1.083364,-3.172827, 1.569759],
 [ 2.512784,-0.752891, 3.19359 ],
 [-4.925262,-1.150245,-0.737309],
 [-4.893259,-1.056103,-0.814953],
 [-3.849442,-1.584636,-0.887986],
 [-3.796415,-1.452268,-0.760016],
 [-3.406578, 0.019088, 0.009113],
 [-3.545904, 0.198922,-0.214263],
 [-3.508517,-0.089574, 0.103353],
 [-2.922087,-0.533484, 1.037109],
 [-1.058914,-0.40573 , 5.523004],
 [-0.285953,-3.122724,-5.458347],
 [ 6.214265, 0.963267, 1.933409],
 [-4.5986  , 0.654099, 2.875463],
 [ 2.400351,-0.844946, 1.184758],
 [-2.567049, 0.528388,-0.173407],
 [-1.24274 ,-0.011557,-1.149264],
 [ 0.819225,-2.838355, 1.033634],
 [ 4.079119, 3.608475,-1.003572],
 [ 4.054635, 3.605273,-0.953592],
 [-3.21811 , 0.258382, 0.230951],
 [ 2.839093, 4.211739, 3.184473],
 [ 3.228876, 0.822142,-2.000351],
 [ 3.255791, 1.275898,-1.877773],
 [ 1.919924, 0.838472,-1.926569],
 [ 0.101755, 0.113366,-2.310658],
 [-1.463549, 1.362121, 1.387223],
 [ 1.921025,-2.206464,-3.534795],
 [-2.911644, 0.321789, 0.903084],
 [ 0.352549,-4.027054,-5.840942],
 [-0.591892, 0.079898,-1.587599],
 [ 3.705264, 0.503244,-1.13685 ],
 [-1.008456,-0.407087, 5.54536 ],
 [-7.301243,-0.251191, 3.077029],
 [-1.413775, 0.208766, 1.171183],
 [ 1.780225,-0.858428,-0.116426],
 [-5.169716,-0.296026, 1.464175],
 [-4.832324,-0.320296, 1.129864],
 [-1.583661,-0.122472, 6.538357],
 [-3.619862, 3.195249, 0.300432],
 [-0.225791, 1.13693 ,-2.425532],
 [ 2.910505,-2.816185,-2.634543],
 [ 5.898519, 0.346175, 0.431628],
 [-2.207218, 0.473403,-1.936086],
 [-2.115711, 0.178226, 0.793899],
 [-6.923844,-0.197942, 3.149735],
 [-3.89297 ,-0.800717, 3.611308],
 [ 4.537509, 4.923571, 0.478033],
 [ 5.614152, 4.707286,-0.26531 ],
 [ 1.071399,-0.155813, 0.448122],
 [ 2.503633, 0.570631, 0.202529],
 [-2.795761,-0.971472, 1.435183],
 [-5.613848,-0.126856,-0.42046 ],
 [-4.240759,-0.561692,-0.932946],
 [-4.544574, 0.586353, 2.298652],
 [-2.490199, 0.515009, 1.050944],
 [ 5.705542, 0.912754,-0.005473],
 [-0.796431, 2.460908, 5.491654],
 [-2.934333, 0.610939,-1.972803],
 [-4.040696,-0.740025,-0.80621 ],
 [-2.032712,-0.10407 , 3.148506],
 [-2.427593, 1.857635,-2.174983],
 [ 3.867624, 0.687974, 0.552848],
 [ 5.929486, 0.1883  ,-0.783863],
 [-0.599939,-0.124099,-2.689685],
 [-1.384402,-0.542974, 1.121633],
 [-1.962733,-0.53712 , 2.756981],
 [-4.59086 , 0.632631, 2.318394],
 [-4.354629, 0.596532, 2.325117],
 [ 4.079285, 3.609247,-1.004233],
 [ 4.075541, 3.570597,-0.973617],
 [ 0.774212, 4.313838,-0.192234],
 [ 3.173275, 3.121566, 0.456607],
 [ 2.503053, 2.752425, 0.317666],
 [ 3.075063, 4.172885,-0.308311],
 [ 4.040586,-0.199472, 0.441544],
 [ 2.787769, 0.611971, 0.151144],
 [-4.871351,-0.126971, 4.687613],
 [-1.64213 , 0.126259, 2.073173],
 [-1.517735, 0.236421, 2.009431],
 [-5.152177, 0.42701 ,-1.750839],
 [-4.684823,-1.52445 ,-1.448652],
 [-4.970384,-1.709696,-0.629218],
 [-4.654226,-0.901637,-0.709463],
 [ 4.023771, 3.491031,-0.843218],
 [ 5.492415, 0.484797,-0.646405],
 [-1.854112, 1.478811, 0.280194],
 [-1.779603, 2.82329 , 4.740123],
 [ 4.558285, 0.248144,-0.395804],
 [-0.600634, 0.235031, 1.722004],
 [ 2.669936, 4.149475, 3.362314],
 [-1.988961,-5.679931,-0.127698],
 [ 4.083915, 3.608519,-1.021958],
 [-4.803552,-2.008787,-1.106017],
 [-1.347133, 1.646552, 1.414019],
 [-1.285487,-0.530851, 4.555867],
 [-1.264583,-0.540846, 4.545497],
 [ 3.033419,-1.204684, 2.026523],
 [-1.146743, 2.656733, 3.483208],
 [-5.829718,-0.207943,-0.75559 ],
 [ 4.079638, 3.588737,-0.991599],
 [ 2.912097, 0.121484,-0.337848],
 [-0.477285, 1.767367,-2.328175],
 [-5.132455,-1.17233 ,-0.711627],
 [-1.397765,-0.516117, 1.114371],
 [-4.677454,-1.222521,-1.576186],
 [-5.029863,-1.252558,-0.668681],
 [-0.433353,-3.296922,-4.951879],
 [ 3.22947 , 1.812323,-1.423704],
 [-1.921587, 0.506317, 3.405746],
 [ 3.570925,-0.767426,-0.159445],
 [-5.308249,-2.773873,-1.718274],
 [-5.090415,-1.152882,-0.935101],
 [-4.353503,-0.903681,-0.06565 ],
 [-4.849664,-0.319729, 0.832534],
 [-4.533815, 2.012494, 0.057143],
 [-1.126857, 2.443735, 1.540548],
 [ 5.156476, 0.849819, 0.020014],
 [ 3.020018,-2.374453, 1.076079],
 [-0.039688, 3.887038, 4.150452],
 [ 0.647502, 5.894282, 0.531967],
 [-1.976694,-0.535922, 2.74307 ],
 [-3.643023,-2.232108,-0.040513],
 [-2.823069,-4.112978,-0.868521],
 [-3.659914,-2.079283,-0.076215],
 [ 1.410118,-1.858034,-0.614017],
 [-3.165815, 2.040086, 2.892177],
 [-1.656075, 1.351173, 3.539602],
 [ 3.179979,-0.637308, 1.873814],
 [-2.064   ,-0.091413, 3.273714],
 [-2.048867,-0.171414, 3.420595],
 [-2.052665,-0.086116, 3.310798],
 [-2.04927 ,-0.165649, 3.407468],
 [-2.034588,-0.074503, 3.294911],
 [-1.935325,-0.262306, 3.642373],
 [-1.90993 ,-0.42802 , 3.991987],
 [-2.04264 ,-0.071573, 3.283859],
 [ 3.123949,-0.690757, 1.839449],
 [-1.930275,-0.2582  , 3.643141],
 [-1.985506,-0.080152, 3.368375],
 [ 3.133549,-0.655854, 1.880171],
 [-2.089   ,-0.143062, 3.319407],
 [-2.032656,-0.024199, 3.210828],
 [-2.027319,-0.174685, 3.287622],
 [-2.12365 ,-0.111983, 3.100813],
 [-2.112679,-0.121715, 3.295946],
 [-2.218411,-0.107661, 3.13271 ],
 [-2.045471,-0.169621, 3.353952],
 [-2.108111,-0.106014, 3.209573],
 [-2.078448,-0.146543, 3.351458],
 [-2.073899,-0.107526, 3.314386],
 [-1.99732 ,-0.154019, 3.438121],
 [-2.022556,-0.305593, 3.690139],
 [-2.028188,-0.041113, 3.319273],
 [ 0.27018 , 1.032934, 4.732039],
 [-1.987857,-0.160099, 3.535585],
 [-1.90852 ,-0.415848, 3.945501],
 [-2.017064,-0.063641, 3.282156],
 [ 0.247102, 1.018502, 4.7101  ],
 [-1.9901  ,-0.164802, 3.490816],
 [-1.931592,-0.397811, 3.850826],
 [-2.015005,-0.028452, 2.895105],
 [-2.050225,-0.163704, 3.375818],
 [-1.985233,-0.375645, 3.749297],
 [-2.015392,-0.065206, 2.5381  ],
 [-2.051301,-0.068254, 3.12143 ],
 [-1.981287,-0.174668, 3.554447],
 [-1.909356,-0.402108, 3.911074],
 [-2.030382,-0.071996, 3.19096 ],
 [-1.978259,-0.174256, 3.554214],
 [-2.254541,-0.047542, 2.336493],
 [-1.98096 ,-0.156674, 3.618483],
 [-1.914491,-0.442229, 4.026997],
 [-2.000542,-0.052775, 3.301434],
 [-1.92424 , 0.045476, 3.222432],
 [-2.134193,-0.149934, 3.333876],
 [ 2.958314, 2.255918,-1.745296],
 [ 3.42415 , 1.343955,-0.353567],
 [ 3.42302 , 3.782202,-0.113503],
 [-0.521144, 1.960547,-2.137183],
 [-4.146505,-0.750845,-0.864127],
 [-1.098222, 2.461567, 1.555644],
 [ 2.480204,-2.159836,-4.233493],
 [ 3.586844,-2.510107,-4.166134],
 [ 3.571953,-2.378448,-4.215194],
 [ 1.821254,-2.490156, 0.669108],
 [ 3.115549, 1.887054,-1.394745],
 [-3.174989, 2.102014, 2.781186],
 [ 2.495107,-2.227662,-4.182265],
 [ 4.090617,-2.937021,-4.271678],
 [ 0.632544,-5.157978,-2.185636],
 [-5.347225,-2.825653,-1.738034],
 [ 4.680158, 2.355884,-0.734166],
 [ 3.111144, 1.672668, 1.555463],
 [-2.750171, 2.045091,-1.5327  ],
 [-4.638175, 1.952605, 0.158186],
 [-5.259741, 0.404303,-1.49666 ],
 [-4.652079, 1.575026, 0.416164],
 [-4.48499 , 1.657111, 0.379738],
 [ 0.58969 ,-5.180981,-2.22121 ],
 [-4.529334, 1.783606, 0.230865],
 [-2.067972,-5.821051,-0.361123],
 [-2.073734, 0.965469, 5.990474],
 [ 2.916915, 3.781768, 0.41767 ],
 [ 2.421726, 3.844872,-0.208194],
 [ 0.573492,-5.255223,-2.216769],
 [ 3.759145, 0.655476,-0.037549],
 [-3.195429, 0.10616 , 0.81905 ],
 [ 2.983424,-1.968462, 1.395118],
 [-2.239038, 0.141285, 1.132828],
 [-1.960745,-0.012921, 0.442842],
 [-2.722521, 2.166248,-1.517273],
 [ 0.957516,-1.228963,-0.057614],
 [ 2.699245,-0.593247, 0.193452],
 [ 2.791665, 0.971568,-0.387228],
 [-6.392336,-2.106229,-0.259982],
 [-3.489192, 0.838483,-2.234323],
 [-3.442695, 0.743092,-2.162066],
 [ 5.486859, 0.879739,-0.316453],
 [-6.686278, 0.045761, 2.28174 ],
 [ 4.415702, 0.298764, 0.292506],
 [ 0.990948, 2.929184,-1.396553],
 [-6.578741, 0.887139,-0.288296],
 [ 6.775235, 0.241594,-2.428041],
 [-5.807374,-0.313472,-0.967288],
 [-4.189342,-0.706105,-0.757365],
 [ 0.213561, 2.154616,-1.176634],
 [-6.077216, 0.25676 ,-1.737082],
 [ 3.870185,-0.368643,-4.110389],
 [ 3.78547 ,-0.547431,-3.882629],
 [ 0.476392, 2.832557, 0.379307],
 [-3.272044, 0.311463, 5.499849],
 [ 2.915653,-0.282322,-0.156083],
 [ 6.009704, 0.652994, 0.518978],
 [-0.670796, 0.619023, 3.796   ],
 [-2.19359 ,-5.621806,-0.099764],
 [-1.361138,-0.765856, 1.183788],
 [-0.112287,-0.688471, 3.065231],
 [ 4.897943, 0.734146, 0.393209],
 [-1.814298, 0.27052 , 4.914649],
 [ 6.992289, 0.16998 ,-0.249885],
 [-2.517505, 1.055193, 4.954238],
 [-2.642628,-1.823451, 0.766473],
 [-0.724194, 0.553172, 3.780465],
 [-2.970469,-0.65322 ,-1.813861],
 [-2.828694,-0.001383, 1.571308],
 [-2.406331,-2.441367,-1.136103],
 [ 6.751346, 0.196608,-2.389444],
 [-1.833283, 0.037015, 0.571533],
 [ 3.114179, 1.27381 ,-0.146561],
 [ 4.375966, 1.565776, 1.708977],
 [ 5.150315,-1.126948, 0.636939],
 [ 0.848615, 1.871393,-1.120446],
 [-4.674103, 0.172124, 1.289053],
 [ 1.754547, 0.977966, 4.633006],
 [-0.97197 ,-1.099448, 0.718942],
 [-1.478478, 1.276794, 1.353826],
 [-0.135286, 3.638861, 0.158905],
 [-2.781493, 1.623482, 0.713779],
 [-0.792432, 0.727239, 0.271572],
 [ 1.602275,-3.548865,-4.74003 ],
 [-0.437332,-4.720356,-4.178945],
 [ 4.088809, 0.78377 ,-1.983494],
 [ 4.591015, 0.696458,-0.22396 ],
 [ 2.782609,-2.509184,-0.458889],
 [-3.525343,-1.414312,-0.692957],
 [ 3.261573,-0.61498 , 2.061212],
 [-1.81532 , 0.30763 , 4.933457],
 [ 5.07448 ,-1.170672, 0.673865],
 [-0.696046,-0.964802, 0.899095],
 [ 2.190836, 0.899948,-0.368436],
 [-3.911884, 0.129898, 1.225919],
 [ 1.556779,-2.633255, 0.361711],
 [ 3.506303, 0.685852,-1.437111],
 [ 7.875632, 1.379472,-0.201525],
 [-3.27009 ,-0.673357,-1.037838],
 [ 0.769454,-0.831163,-0.383868],
 [ 6.876846, 2.544194,-0.029961],
 [-4.066584, 2.083833, 3.270464],
 [ 4.046751, 0.767658,-1.970526],
 [-5.169579,-0.249994, 1.611718],
 [-1.072037,-1.423914, 5.319037],
 [ 5.036427, 0.971527,-0.330434],
 [-3.120249,-1.635484,-4.267329],
 [-0.177083,-2.346641,-0.032676],
 [ 0.146732,-2.291266,-0.239315],
 [ 1.115939, 0.856168, 3.489289],
 [ 1.903846,-2.20648 , 1.855613],
 [-0.208028,-2.767925,-0.364322],
 [-2.500492, 0.171162, 2.395985],
 [-1.874347, 0.729927, 0.816603],
 [ 4.407536, 0.227751,-0.493332],
 [-2.594318,-3.769172, 1.261052],
 [-2.232204, 0.723004, 0.771596],
 [-1.757349, 0.361385, 0.48582 ],
 [-1.363692,-1.157725, 0.07152 ],
 [-0.802136, 4.444696, 3.916631],
 [ 2.853035,-0.271974,-0.414111],
 [-2.757864, 0.309864, 1.12666 ],
 [ 0.803533, 0.80516 , 1.60417 ],
 [ 3.692495,-0.958771, 0.752808],
 [-0.4249  , 2.131933, 4.486551],
 [ 1.089853, 2.127027,-1.103931],
 [-1.259352, 0.128098, 2.443268],
 [-0.64792 ,-0.303373, 2.968636],
 [-4.156247,-0.349383, 2.510431],
 [ 4.094131, 0.781889,-1.937534],
 [-1.347081, 0.27743 , 1.974251],
 [-0.765308, 2.350086,-2.003422],
 [ 3.982316, 1.600742,-0.44946 ],
 [-1.776453, 0.230925, 4.993363],
 [-2.051495, 1.987781, 0.586093],
 [-7.358851,-0.244066, 2.958397],
 [-0.777276,-2.860277,-1.935986],
 [-1.798662, 0.293574, 4.95507 ],
 [ 2.041417,-1.012328,-1.88979 ],
 [ 4.065652, 1.009208, 2.271653],
 [-1.259756, 0.186214, 1.968735],
 [-0.605128,-0.531849, 2.361803],
 [ 5.925611, 0.363953,-0.767189],
 [-1.973897, 1.420198, 0.77331 ],
 [ 4.4373  , 0.402243, 1.89008 ],
 [ 0.747868, 1.552986, 0.027129],
 [ 3.021812, 1.087647, 2.477492],
 [-0.904868,-0.030048,-3.920568],
 [ 2.800199, 2.537074,-0.333492],
 [-1.085166,-3.420802,-3.16644 ],
 [-2.130456, 0.987029, 5.975849],
 [ 0.78317 ,-2.067077,-0.740719],
 [ 0.850012,-4.061899,-0.304547],
 [-1.567763,-0.567115,-0.110722],
 [ 3.1777  ,-0.593711, 2.228969],
 [-3.367824,-3.409403,-0.085895],
 [-3.791363,-0.230361, 0.513361],
 [ 4.109836, 1.465997,-0.296801],
 [ 4.726811, 1.514939, 0.186945],
 [ 4.773197, 2.286061, 0.052539],
 [ 3.203559, 2.075036,-0.042997],
 [ 2.899822, 1.050406,-0.19128 ],
 [ 1.260119,-0.611693,-4.776631],
 [ 0.66691 , 4.16195 ,-0.85434 ],
 [ 3.196324, 1.325039, 1.132239],
 [-0.125855,-1.770052, 0.016376],
 [ 1.370755, 3.585669, 0.261737],
 [ 5.482249, 0.280291,-0.648529],
 [ 0.690131, 4.219628,-0.151428],
 [-2.902959, 2.189468, 4.386177],
 [-1.548899,-2.66278 , 1.370622],
 [ 1.447804,-2.715929, 0.396505],
 [-2.355633, 0.723508, 1.846509],
 [-4.514567,-0.698587, 0.077324],
 [-3.031574,-0.157841, 0.656136],
 [-2.997336,-0.483139, 0.446398],
 [ 0.535315,-2.745687,-0.889264],
 [-0.490777, 4.26121 ,-0.921508],
 [-0.570376, 4.368382,-1.059644],
 [ 2.161204, 0.907256, 0.810432],
 [ 1.039352, 2.02075 ,-1.095145],
 [-7.306874,-0.241418, 2.962701],
 [ 4.574331, 1.17902 ,-0.223654],
 [ 5.346214, 0.904761, 0.372078],
 [-3.016191, 0.564753, 5.6     ],
 [-3.276336,-2.134942, 0.544229],
 [ 7.071795, 2.329669, 0.363589],
 [-2.469136, 0.545165, 0.040261],
 [ 5.69115 , 0.252863,-0.630572],
 [-0.62575 , 3.523176, 0.941927],
 [ 4.76992 , 2.293801, 0.083139],
 [ 5.185766, 2.655546, 2.797717],
 [ 7.263833, 0.07853 ,-0.2026  ],
 [ 2.147975, 2.911707, 0.160301],
 [-1.352016,-2.509496, 0.811507],
 [-3.480284, 1.066791,-0.271123],
 [ 3.292734, 1.293801,-0.499725],
 [ 2.535626, 0.669624, 2.63359 ],
 [-0.165059,-3.349827,-1.802692],
 [-3.446557, 3.264919, 0.147175],
 [ 0.950395, 0.238173, 4.039927],
 [ 5.484905, 2.240262,-0.901013],
 [ 0.161573,-0.77532 , 3.063959],
 [ 6.603971,-0.279645,-0.74276 ],
 [-6.083827,-1.032599,-0.947686],
 [ 1.270112, 0.31026 , 0.864533],
 [ 2.065139,-1.701791, 1.921572],
 [-1.645346,-5.749221,-0.17803 ],
 [-0.386624, 2.103861, 4.487246],
 [-2.591091, 0.164872, 0.235514],
 [-4.7736  ,-0.02272 , 1.260407],
 [-0.574939,-5.820843,-0.020449],
 [ 5.781172, 0.31724 ,-0.729267],
 [ 1.910975, 0.580828, 0.127447],
 [-0.95837 ,-0.903886, 1.922359],
 [ 6.517804,-0.916863,-0.223116],
 [-1.214952, 1.56686 , 4.348665],
 [-0.075354, 4.823528, 3.829912],
 [-1.855312,-0.094934, 4.948316],
 [-1.777293, 0.21138 , 4.972309],
 [-3.265532,-0.126494, 6.851452],
 [-1.855359,-0.057033, 4.92912 ],
 [ 3.371144,-0.567331, 3.021486],
 [-0.797828, 0.778723,-1.897971],
 [ 0.806661, 0.949358,-0.138879],
 [-0.967178,-0.852179, 1.978784],
 [-2.609509, 0.940556, 1.057127],
 [ 6.977633, 2.510103,-0.022331],
 [-2.182276, 0.926725, 5.941838],
 [ 6.547808,-0.857326,-0.469815],
 [ 2.922792, 2.267887, 2.183623],
 [ 7.106553, 2.4582  , 0.453   ],
 [-2.553444, 0.626495, 0.005102],
 [-7.260362,-0.211518, 3.141673],
 [ 1.027637, 0.80867 , 0.442838],
 [ 4.024383, 1.786776, 2.687412],
 [-4.871593, 0.109114, 1.625815],
 [ 0.970522, 2.51386 ,-1.014648],
 [-0.702646, 2.113699, 1.772714],
 [-4.052621, 2.342463, 1.348613],
 [-2.638718, 0.660632,-0.068353],
 [-2.816041, 0.558549, 0.725701],
 [-3.134095, 0.631991, 5.390981],
 [-2.131986, 1.021776, 5.918534],
 [-0.830705, 4.418488, 3.887882],
 [-1.489284,-0.361417, 1.094907],
 [ 0.761666,-1.488492, 4.44863 ],
 [-2.1808  , 0.365845, 0.856779],
 [-2.592918,-3.761761, 1.262718],
 [ 5.414611, 0.497776, 0.134987],
 [-0.791665,-5.802787,-0.23125 ],
 [-2.074691,-5.674766,-0.097446],
 [-2.085156,-5.677427,-0.093846],
 [-1.828739,-1.05656 ,-2.044122],
 [ 3.958182, 1.84586 , 2.755165],
 [-0.651826,-2.136919, 2.426624],
 [-4.006826,-0.272154, 3.925679],
 [ 6.59749 , 1.78035 , 0.001881],
 [ 5.157274,-1.184265, 0.636663],
 [ 7.756605, 1.865049,-0.126428],
 [-0.943666,-2.097737,-3.375803],
 [-0.690708, 4.167009,-0.974408],
 [-0.731403, 4.392695,-1.205396],
 [-4.274129,-3.807087,-1.47061 ],
 [-3.689139,-0.3354  , 0.184145],
 [-3.074742,-0.181415, 2.41107 ],
 [ 0.83416 , 5.513329,-0.383096],
 [ 0.226361,-4.335567, 0.082735],
 [ 1.293849,-2.387865, 0.118069],
 [-0.646949,-0.92073 , 0.978369],
 [-0.908827,-0.886018, 0.769504],
 [ 0.745814,-1.603377, 4.643472],
 [ 0.685278,-1.64387 , 4.576621],
 [ 2.017866, 1.806905, 1.108873],
 [ 4.185111,-0.750862, 2.680085],
 [ 0.840519, 3.12078 ,-0.865213],
 [ 0.759532, 1.77027 , 0.040501],
 [ 3.999851,-1.460258, 1.845229],
 [-0.952913, 0.414466, 2.556661],
 [ 1.325775, 0.75656 , 3.754285],
 [-2.641887,-0.734273, 5.40235 ],
 [ 2.63221 ,-0.783239,-0.920288],
 [-0.686395, 1.314663,-2.53912 ],
 [-5.277529,-2.664473,-1.60766 ],
 [-3.929673,-0.425594,-3.006502],
 [ 2.236185, 2.388135,-0.916453],
 [ 1.676577,-3.580744,-4.993684],
 [ 4.634778, 2.26101 ,-0.402718],
 [-1.710117,-5.742093,-0.171987],
 [-1.903972,-5.717237,-0.114104],
 [ 3.726991, 5.297839, 0.433746],
 [ 0.209957,-0.443838, 1.090874],
 [ 0.880896,-2.492741, 0.120961],
 [-3.904153, 1.913889,-1.670989],
 [-3.974649, 1.810549,-1.523291],
 [-3.172582,-2.007735,-0.39221 ],
 [ 1.555557, 5.554602, 2.485346],
 [-4.631179,-0.077974,-0.76226 ],
 [ 4.121125, 3.676635,-0.879623],
 [-1.682486,-0.340124, 1.102134],
 [-1.228676, 0.995413, 1.021894],
 [-4.785312, 0.512944, 1.490441],
 [ 0.632443, 4.194459,-0.181225],
 [-6.110397, 0.292238,-1.785567],
 [ 7.632985, 1.973637,-0.009316],
 [-3.534144, 1.239954, 1.040166],
 [-2.126996,-5.653632,-0.094027],
 [-0.640452,-2.145667, 2.59356 ],
 [-2.190821, 0.905961, 5.961091],
 [-2.317728, 0.886086, 5.713785],
 [-1.306283,-1.018188, 0.15962 ],
 [ 4.39525 , 0.687737, 1.814732],
 [-2.756778, 1.634018, 0.703125],
 [-0.884966, 0.574139, 0.322276],
 [-1.23658 , 0.75622 , 0.601439],
 [ 1.886222, 0.182257, 2.543998],
 [ 4.662029, 1.128637, 0.227952],
 [ 0.531166, 2.175339,-0.858625],
 [ 3.169388, 0.903124, 0.337802],
 [-2.529213, 0.578366, 5.461795],
 [-2.690975,-0.069013, 5.540098],
 [-0.876163,-1.288968, 0.476456],
 [ 6.976916, 2.535506,-0.034023],
 [-6.083909, 0.341116,-1.623471],
 [-2.845339,-3.596854,-0.591379],
 [-2.886709, 2.173704, 4.429807],
 [ 1.09981 , 1.100699,-1.428625],
 [-1.47295 , 0.157058, 1.536892],
 [ 5.547156, 0.603614, 1.013507],
 [-4.384851,-0.005867, 0.261632],
 [-2.127635, 0.404376, 4.605352],
 [-2.782004, 1.766948, 4.466017],
 [ 5.516307, 4.716909,-0.227868],
 [ 2.991296,-0.405263,-0.794122],
 [-3.416065, 0.797497,-2.082834],
 [-0.836434,-5.804983,-0.224402],
 [-1.90762 ,-5.686218,-0.124517],
 [-2.851597,-0.325876,-1.335419],
 [-1.972544, 1.006245, 0.795343],
 [ 7.765689, 1.786866,-0.125762],
 [ 4.358406, 0.555369,-0.041286],
 [ 4.200563, 4.163517,-0.133166],
 [ 0.362431,-1.080724,-4.856859],
 [ 4.448243, 1.169092, 0.005484],
 [ 6.182245,-0.159192,-0.873098],
 [-4.31861 ,-3.798251, 0.651657],
 [ 3.23714 , 2.430922,-0.914147],
 [-2.093139, 1.403271, 4.808244],
 [ 1.86017 , 1.117877,-3.026983],
 [ 2.724936, 2.36957 ,-0.323951],
 [ 0.962876, 2.191634,-0.733198],
 [ 4.837938, 1.248599,-0.20612 ],
 [-1.176946, 0.971741,-2.105746],
 [ 6.836215,-0.320333,-3.056008],
 [ 3.600893, 5.817107, 1.375134],
 [ 5.60698 , 0.235593,-0.555821],
 [ 6.835872,-0.320078,-3.055185],
 [-4.373576, 0.086623, 1.162858],
 [-6.093876, 0.282653,-1.759076],
 [-4.697919,-0.853754, 1.40615 ],
 [ 1.898237, 1.602104, 3.556648],
 [ 7.130395, 2.518362, 0.518244],
 [ 0.88214 ,-2.605756, 0.193089],
 [ 2.333113, 4.166288, 2.343815],
 [-1.793028, 0.262474, 4.933784],
 [-2.437678, 1.376773, 4.99206 ],
 [-2.909833, 0.573271, 5.67076 ],
 [-5.094042,-0.84109 , 0.681401],
 [-0.705096,-2.076331, 2.497779],
 [-1.498744, 0.364946, 1.708067],
 [-3.002061, 0.505584,-1.096444],
 [-4.984449, 1.736369, 0.906045],
 [ 5.550632, 4.702102,-0.240225],
 [-3.293092, 0.278977, 5.478001],
 [ 4.428138, 0.899291, 0.131496],
 [ 5.579043, 4.695364,-0.248834],
 [-2.964735, 0.769284,-0.83933 ],
 [-7.485971,-1.351442, 0.398695],
 [ 1.25709 , 0.668056, 3.788928],
 [ 1.20306 , 0.8635  , 3.570403],
 [ 2.616468, 0.571534,-0.210432],
 [ 6.367805, 1.350606, 0.909728],
 [-4.796872, 1.268576, 1.412232],
 [-2.633813,-4.359776, 0.702453],
 [ 3.11296 , 0.999329, 0.430552],
 [ 6.780418, 2.177609,-0.009267],
 [-0.531776,-5.792674, 0.008341],
 [ 0.58379 , 0.852309, 4.687098],
 [ 6.533014, 1.500229, 0.896549],
 [ 3.31268 , 0.633485, 2.201779],
 [ 0.71292 , 0.726244, 3.618951],
 [-0.610049,-5.83225 ,-0.24679 ],
 [-2.626668,-0.585058, 5.418116],
 [ 4.063618, 0.503431,-0.703297],
 [ 2.767303, 0.751201, 0.03391 ],
 [ 0.359705,-3.870263,-0.827502],
 [-1.005098,-3.259703, 1.61323 ],
 [-4.408562, 1.815528,-0.639033],
 [ 5.219501,-0.082775,-4.038417],
 [ 1.108668, 1.832558, 3.968276],
 [-0.70148 , 0.11773 , 2.922684],
 [ 1.036333, 1.81475 , 3.980076],
 [-0.546012, 2.225149,-2.03717 ],
 [-7.344934,-0.555418, 2.603851],
 [-6.659316,-2.308095,-0.433923],
 [-7.252999,-0.228901, 3.136977],
 [ 2.234069,-2.106179,-1.647532],
 [ 4.284179, 0.974117,-0.067441],
 [ 0.675526,-1.113128,-0.672854],
 [-2.242419, 0.889985, 5.889315],
 [-2.526318, 0.719669,-0.058461],
 [-1.317872, 0.000092, 2.381201],
 [-1.194058,-0.26018 , 3.044801],
 [-2.074073,-2.201355,-0.48226 ],
 [-1.668142, 0.151591, 2.035613],
 [ 4.139665,-1.688058, 1.507393],
 [ 0.232172,-1.108129, 3.270199],
 [-2.891903, 2.169274,-0.192204],
 [ 1.55503 , 0.711078, 0.0146  ],
 [ 2.799264, 3.663004, 0.203359],
 [-2.279943,-0.158561,-1.710455],
 [ 0.695221,-1.619607, 4.514719],
 [ 2.581521, 1.782667, 1.797451],
 [-0.168595,-2.213533, 0.062676],
 [ 0.820172,-2.25332 ,-0.455932],
 [-0.164695,-2.354954,-0.025465],
 [ 0.723851,-1.664886, 4.585413],
 [ 2.571638, 0.407976, 2.226767],
 [ 4.013911, 1.159613, 1.131591],
 [ 2.90791 , 0.883853,-2.924081],
 [ 3.729538, 1.317769,-0.346799],
 [ 4.017827, 1.226541, 2.330787],
 [ 1.609619,-1.186359,-5.45101 ],
 [ 1.105216,-0.09625 , 3.325509],
 [-0.576908,-2.146245, 2.593005],
 [-2.990845, 0.431191, 2.161911],
 [ 2.459378,-0.944449, 2.07044 ],
 [-3.037891, 2.247354,-0.721393],
 [ 4.736104, 0.543651, 0.090517],
 [ 4.457818, 1.251519,-0.420617],
 [ 5.102645, 0.995538,-0.629968],
 [ 4.441068, 1.200531,-0.323504],
 [-2.683755, 0.436209, 5.634947],
 [-6.88339 , 0.779765, 0.037242],
 [ 5.55443 , 4.726949,-0.23741 ],
 [-1.049901, 2.406542,-1.169635],
 [-4.671385, 1.61887 , 0.352563],
 [ 4.364458, 1.374334,-0.415505],
 [-1.496442, 1.312806, 1.509143],
 [ 6.041606, 0.420594,-0.256968],
 [ 3.247358, 0.566809, 0.488002],
 [ 3.985539, 0.290718,-4.925688],
 [ 2.966933, 1.014607,-2.369871],
 [-1.121886,-0.768933, 2.891312],
 [-0.945718,-0.885747, 1.888625],
 [ 3.507161,-1.323354, 1.018852],
 [-0.727661, 2.91702 ,-0.243112],
 [-0.281951, 1.718696, 2.097011],
 [-0.275682, 2.811259, 2.478115],
 [ 2.816396, 1.323957, 1.544191],
 [ 0.750092,-1.59704 , 4.617861],
 [-0.855664,-2.578851,-0.32683 ],
 [ 3.389809, 2.604893, 0.931574],
 [-2.278824,-0.043202, 3.418406],
 [ 1.227209, 2.103113,-1.215073],
 [ 1.274679, 1.746239,-1.316566],
 [ 0.794508, 3.03639 ,-0.855989],
 [ 4.363783, 0.741906, 0.031656],
 [ 3.345446, 0.280573,-0.61489 ],
 [-0.316473, 2.355381, 2.774061],
 [-2.591491,-0.561095, 5.441312],
 [ 3.250503,-0.608199, 2.07828 ],
 [-1.930729,-0.621375, 1.120198],
 [ 5.5143  , 0.531571, 0.569572],
 [-0.642451,-1.239579, 2.109628],
 [-1.243257,-1.193532, 0.004856],
 [ 1.856922, 0.264761, 1.34009 ],
 [ 1.919785,-1.06401 , 0.680238],
 [ 0.833349,-2.62726 , 0.233135],
 [-7.484158,-1.342456, 0.392899],
 [-4.634544,-0.985091, 1.098703],
 [-2.766672, 1.790033, 4.484659],
 [-2.950915, 0.166231, 5.304242],
 [-3.655187,-3.930367,-0.037133],
 [ 3.944952,-0.827389, 0.21508 ],
 [ 3.674682, 1.046213,-0.354611],
 [ 4.941416, 0.884301,-0.389812],
 [-1.476557, 1.293914, 1.425722],
 [-1.047964,-0.859981,-0.122254],
 [ 5.274655, 0.466769, 1.310082],
 [-4.527248, 1.602588,-0.960127],
 [-0.117258,-0.639872, 6.190034],
 [ 0.282414, 3.01961 , 0.512436],
 [-1.325835, 2.402361,-0.226223],
 [-0.964998,-0.305401, 0.229381],
 [ 2.324742, 0.253776,-0.770549],
 [-3.430861, 0.814448,-2.042634],
 [-1.577368, 2.94543 ,-1.802665],
 [-1.163264,-5.628913,-0.212995],
 [ 3.396026, 1.326854, 0.082364],
 [-0.224012,-1.772901, 1.120063],
 [ 2.082396, 0.252251, 1.750019],
 [-1.78976 , 0.200643, 4.935108],
 [-1.682551,-0.108169,-2.25743 ],
 [ 2.329594, 4.173897, 2.352402],
 [-2.410259, 1.386188, 4.985999],
 [-7.476752,-1.34505 , 0.396313],
 [-0.235901, 3.655787, 0.16547 ],
 [-0.164657, 1.819888, 2.178555],
 [ 0.593779,-0.316852, 3.118517],
 [-0.153321,-0.618406, 6.148534],
 [ 3.202272,-0.732795, 1.996272],
 [-2.651042, 0.509992,-0.325629],
 [-3.699261, 1.18867 , 1.259266],
 [ 1.671596, 3.815242, 0.881199],
 [-3.562185,-2.073128, 1.008974],
 [-3.390878,-1.089162, 2.191048],
 [-3.404395,-1.079489, 2.191759],
 [-3.392103,-1.100841, 2.293975],
 [-2.394138,-2.795363, 0.571744],
 [ 3.382723,-0.283118, 0.0854  ],
 [-1.562612, 0.244352,-1.1272  ],
 [ 1.276779,-1.680536,-1.328825],
 [ 0.991293,-4.297106,-2.621342],
 [-1.529503, 0.984016,-0.859353],
 [-3.989147,-0.262223, 3.895937],
 [ 2.88116 ,-1.69514 , 0.226343],
 [ 5.543521, 4.690094,-0.246811],
 [ 4.982995, 0.482321,-0.392505],
 [ 3.555835, 0.973396, 0.912242],
 [ 6.296933, 0.422048,-2.048256],
 [-1.865017, 1.754665, 0.705453],
 [ 3.208372, 0.766744, 0.425331],
 [-6.084157, 0.273047,-1.71654 ],
 [-3.258471,-0.124559, 6.858909],
 [-2.902337, 0.648929, 2.74077 ],
 [-0.537787,-5.833555, 0.021517],
 [ 2.688402, 0.443069,-0.1573  ],
 [ 5.574768, 0.190843,-1.348512],
 [-2.444046, 0.312783, 5.212247],
 [-0.66246 , 1.716849,-2.194057],
 [-4.201019,-0.343692, 2.496862],
 [ 3.130101, 1.119002, 0.378671],
 [ 1.70656 , 3.176936, 0.966503],
 [-2.052302, 1.007565, 5.975508],
 [-6.083786, 0.270036,-1.743519],
 [-1.022108,-0.894604, 2.003475],
 [-0.400571,-0.520881,-3.442762],
 [-3.446425, 0.195843, 2.658392],
 [-3.476029, 0.863296,-2.134894],
 [-2.855151, 0.591857, 5.683118],
 [ 0.944223, 1.572149,-3.958209],
 [-2.13865 , 0.933415, 6.050649],
 [-0.785903,-0.985614, 0.773048],
 [ 2.186957,-0.960956,-1.76307 ],
 [ 3.946532, 0.511825,-0.295327],
 [ 2.300009, 4.153846, 2.347251],
 [-2.356415, 1.345198, 5.134191],
 [-0.712511, 2.117757,-2.045258],
 [-3.190213,-1.596629,-4.269826],
 [-2.644459, 1.926702, 0.143514],
 [ 2.326551, 1.357514, 1.441485],
 [-4.479228,-0.058578, 0.252475],
 [-2.945979, 0.138574, 5.321565],
 [-1.631537,-0.630693, 0.022544],
 [ 1.34103 , 0.483481, 3.979387],
 [ 2.247073,-2.573834,-0.058828],
 [ 4.757577, 0.70081 ,-0.022256],
 [-5.638412,-0.144723, 1.835167],
 [-1.832705, 1.072585, 2.299628],
 [ 0.762016, 5.535896,-0.479566],
 [ 6.599825,-0.52145 ,-0.820179],
 [-6.104197, 0.276289,-1.783185],
 [-2.80499 , 0.640192, 5.809237],
 [-3.209736,-0.179788,-1.819151],
 [ 6.673367, 0.147655, 0.372426],
 [-1.149921,-0.45096 , 1.715957],
 [-0.633806, 2.109655,-2.01174 ],
 [-2.77138 ,-0.192017,-1.210883],
 [ 2.311835, 2.683006, 1.387169],
 [-7.483477,-1.339505, 0.421192],
 [-0.150287,-3.794297,-1.231833],
 [-1.2228  ,-3.434385,-1.05401 ],
 [ 3.449394,-2.862688,-3.715321],
 [ 6.537591,-0.838424,-0.497324],
 [-2.728491, 1.601039, 0.747747],
 [ 2.887377,-1.595677, 0.148571],
 [-2.429456, 0.684   , 5.538304],
 [-3.503857,-0.808022,-2.184583],
 [-1.893679, 1.304164, 2.213358],
 [-2.210246,-2.02248 ,-0.736586],
 [ 0.958431, 0.585656, 3.403664],
 [ 1.178711, 0.767161, 3.357866],
 [ 6.216633,-0.029388, 0.725101],
 [-2.100176, 1.033722, 6.114474],
 [-1.828303, 1.476953, 0.322838],
 [ 6.235412, 3.271014, 0.120163],
 [ 1.97737 ,-1.138685, 0.631237],
 [ 6.122579, 2.062474, 0.194788],
 [-2.34032 , 0.839121, 5.937325],
 [-6.076167, 0.262795,-1.753626],
 [ 1.213689, 0.679876, 3.767031],
 [-2.833834,-0.372251, 0.087717],
 [-0.956259,-0.465992, 0.510399],
 [-4.110292,-0.337531, 0.352475],
 [-0.071538,-0.700593, 3.113885],
 [-2.110939,-0.147373, 3.19345 ],
 [-1.927986, 1.061734, 5.899467],
 [ 2.934851, 0.885786,-2.940751],
 [ 3.126722,-0.085785, 2.629911],
 [ 2.8039  , 2.374996,-0.284174],
 [ 1.921228,-2.440042, 0.919542],
 [-2.518337, 0.744616, 0.014076],
 [ 0.493615, 1.977068,-0.739397],
 [ 3.059372, 1.462642, 0.301319],
 [ 3.570295, 2.30259 ,-1.077366],
 [ 2.112745,-1.55442 ,-2.94736 ],
 [-4.583104,-0.106846, 1.368735],
 [-2.251452, 1.528184,-0.689053],
 [-2.272566, 0.025551, 0.963133],
 [-2.998654,-1.719404,-4.595362],
 [ 4.340469, 5.152705,-0.021195],
 [-2.742813, 0.507781, 1.379229],
 [ 4.816027, 3.520987, 0.078256],
 [ 2.926358, 2.57417 ,-0.475755],
 [ 5.43376 , 0.651172,-2.180148],
 [-2.43337 , 0.329254, 5.177447],
 [ 0.778363, 1.709089, 0.109788],
 [-2.432162, 1.187898, 5.899841],
 [ 3.373782,-0.763332, 1.801708],
 [-3.15815 ,-1.885994,-1.301519],
 [ 0.343821,-0.220146,-0.10607 ],
 [ 3.170128, 1.502778, 2.188519],
 [-0.50659 , 3.786906, 3.786741],
 [-1.035265,-0.893379, 1.822513],
 [ 1.522245, 1.13483 ,-2.827252],
 [-1.388651, 0.815309,-2.41204 ],
 [ 0.707133, 3.078794,-0.885904],
 [-3.650707, 0.358683,-0.144604],
 [ 4.276977,-0.063998, 3.149196],
 [-1.825734, 1.307496, 2.262189],
 [-2.621057, 0.034953, 1.095932],
 [-4.122215, 2.055013, 3.294284],
 [-0.048552, 1.336872, 4.652221],
 [ 0.430218,-0.585339, 3.252499],
 [-4.537126, 0.511899, 2.375744],
 [-2.873334,-0.029052,-1.689439],
 [-3.554955,-0.128104, 4.75564 ],
 [ 5.731974, 2.057456, 0.170652],
 [-3.039527, 0.291659, 1.997535],
 [-1.837022,-0.694942, 0.19393 ],
 [-3.12308 , 0.148332, 5.188383],
 [-2.940717, 0.078976, 5.334655],
 [-7.172956, 0.514637,-0.033308],
 [ 6.545693, 0.412934,-3.169055],
 [-4.926816, 0.49045 ,-0.551493],
 [-2.503836, 0.649014,-0.11834 ],
 [-1.108376,-0.583285, 1.745519],
 [-2.898856, 2.14606 ,-0.137304],
 [-1.421814,-1.40098 , 0.152674],
 [ 4.781424, 4.479175,-0.462317],
 [-2.910131,-0.62209 ,-1.749066],
 [ 0.358996, 1.116372, 4.799382],
 [ 1.23487 , 2.539933, 2.420767],
 [-0.646475,-2.066224, 2.594945],
 [-3.716839,-0.224035, 0.431695],
 [ 5.157787, 0.903421,-1.961781],
 [-1.613024, 0.188796, 2.101673],
 [ 0.050446,-1.449046, 4.06874 ],
 [ 0.230577,-1.645177,-0.897529],
 [ 3.243184,-1.309057, 0.672907],
 [-2.638758, 0.042014, 1.400012],
 [ 1.042558, 3.632646, 0.28891 ],
 [ 4.509907, 0.94798 ,-0.383627],
 [-0.610322,-5.842353,-0.241383],
 [ 1.166706,-2.613999, 0.967166],
 [-2.49646 , 0.157518, 0.782889],
 [ 0.592988,-1.671702, 4.477037],
 [ 0.736976,-1.605201, 4.629911],
 [ 1.883331, 1.800901, 1.143417],
 [-0.633269,-5.843748,-0.236299],
 [-0.968963,-5.720753,-0.273748],
 [-0.66938 ,-5.804565,-0.24109 ],
 [-0.649409,-5.848166,-0.230353],
 [ 3.760948,-0.102737,-0.251322],
 [ 4.588772, 2.288165,-0.827903],
 [ 0.790962, 2.284231,-0.64779 ],
 [-0.197266,-0.716776, 2.825176],
 [-0.389374,-0.676807, 2.785388],
 [ 0.671457,-1.437084, 4.335202],
 [ 1.111603,-0.809405, 2.311113],
 [ 0.763547,-1.496908, 4.614318],
 [ 0.821597,-1.611027, 4.562038],
 [ 0.716081,-1.445739, 4.48833 ],
 [ 0.836562,-1.545103, 4.625628],
 [ 0.751236,-1.152234, 4.574056],
 [ 0.794247,-1.17014 , 4.526814],
 [ 0.755962,-1.427298, 4.483639],
 [ 1.10903 ,-0.823433, 2.258582],
 [ 0.776399,-1.437147, 4.518224],
 [ 0.836856,-1.538519, 4.625686],
 [ 0.76521 ,-1.235932, 4.538763],
 [ 0.812936,-1.611479, 4.579521],
 [ 0.75735 ,-1.500677, 4.624317],
 [ 0.739656,-1.494256, 4.55111 ],
 [-3.193715,-1.72838 , 0.129585],
 [ 0.779642,-1.266686, 4.573537],
 [ 0.770611,-1.270034, 4.568467],
 [ 0.760784,-1.242408, 4.536279],
 [ 0.659943,-1.049243, 4.475887],
 [ 1.5013  ,-0.463852,-4.764174],
 [ 0.767751,-1.167956, 4.585367],
 [ 0.746856,-1.181417, 4.547563],
 [ 0.767409,-1.467307, 4.60609 ],
 [ 0.740299,-1.339728, 4.57758 ],
 [ 0.733068,-1.388413, 4.566368],
 [ 0.673741,-1.074685, 4.464727],
 [ 0.755208,-1.213904, 4.562423],
 [ 0.659793,-1.064695, 4.571614],
 [-2.461324,-0.600188, 0.810166],
 [ 0.529245, 2.139591,-0.644981],
 [-1.376727, 1.810299, 1.424374],
 [-0.662489, 1.404108,-2.458432],
 [ 3.590139, 0.030735, 0.245185],
 [-2.370554, 0.947958, 5.729142],
 [-4.053338,-0.545526, 5.283813],
 [ 1.956647, 2.115714, 2.216055],
 [-2.169929,-0.143286, 0.143222],
 [-0.030972, 4.828233, 3.805975],
 [ 3.086566, 2.722057, 0.05993 ],
 [-2.447563, 0.189525,-2.462769],
 [-6.769278, 1.064798, 1.775928],
 [ 4.50768 ,-0.605023, 2.628982],
 [-0.37626 , 2.137803, 4.491687],
 [-1.012201, 1.836129, 3.239265],
 [-0.53242 , 2.042931, 4.398541],
 [ 3.052928, 3.727858, 2.450333],
 [-0.569929, 1.980006, 4.500268],
 [ 1.696508, 3.086133, 3.697847],
 [ 1.685962, 3.143117, 3.774408],
 [ 2.167896, 3.16255 , 1.448329],
 [-0.37872 , 2.119594, 4.472105],
 [ 2.690165, 4.191113,-0.912417],
 [ 2.688574, 4.185673,-0.926761],
 [ 6.761998, 2.060124, 0.037198],
 [ 6.994426, 2.549236,-0.036311],
 [ 0.148774, 1.461758, 3.42295 ],
 [ 2.07371 ,-2.84444 ,-0.116881],
 [ 3.309284, 4.280079, 1.933546],
 [-0.695019, 2.183016,-2.090893],
 [-2.089124,-5.644903,-0.092097],
 [ 0.989761,-0.360633, 3.282237],
 [ 1.04518 ,-0.2804  , 3.341912],
 [ 1.716977, 3.247148, 3.585338],
 [-0.680328, 2.034784,-2.226763],
 [ 4.442271,-0.642449, 2.624957],
 [ 1.049772,-0.408183, 3.359995],
 [-2.545957, 0.236821, 0.102566],
 [-3.520554,-3.24298 , 0.361211],
 [-0.208142,-2.767713,-0.364437],
 [ 1.080984, 0.577913, 3.965765],
 [ 1.28095 , 0.802551, 3.644167],
 [-0.305473, 2.130508, 4.458096],
 [ 1.008856, 1.639275, 0.552977],
 [ 2.35562 , 0.387671,-0.130632],
 [-2.235134,-0.075072, 0.177346],
 [ 1.412049, 0.541068, 3.958235],
 [ 4.10915 , 4.091481,-0.105915],
 [-0.712991, 0.214965,-2.084148],
 [ 1.764994, 0.995159, 4.632408],
 [-0.670351, 1.957191, 4.465961],
 [ 2.604168, 2.439674,-0.21342 ],
 [ 1.633104, 1.038103, 3.902735],
 [ 0.905294,-0.536525, 3.359107],
 [ 5.785804,-1.081836, 1.778974],
 [-0.937809,-0.64593 ,-3.56047 ],
 [-0.466728,-1.248101, 2.663785],
 [ 1.215625, 0.597214, 3.892746],
 [ 1.427155, 0.492849, 1.512205],
 [ 1.255997, 0.944143, 3.4789  ],
 [-1.885705,-0.323916, 3.119318],
 [-6.0474  , 0.298866,-1.752833],
 [ 1.26481 , 0.713532, 3.74084 ],
 [-1.347037,-1.128862, 0.196889],
 [-3.576255, 0.601133, 1.008006],
 [ 0.900721,-0.985323,-2.007483],
 [-0.580551, 1.564905,-2.203711],
 [ 2.115044,-0.25338 ,-1.092569],
 [ 2.29744 , 0.13709 ,-0.807482],
 [ 2.302828,-0.046377,-0.471561],
 [ 0.941241,-0.501896, 3.324013],
 [-0.628202,-0.359094, 4.641755],
 [ 1.819398, 3.113669, 3.617274],
 [-2.513319, 1.149591, 5.847188],
 [ 1.169227, 0.787532, 3.611574],
 [ 1.034467, 0.51968 , 3.8316  ],
 [ 1.631079, 3.490143, 3.324077],
 [-4.439482,-3.839569, 0.747459],
 [-4.179869, 2.156615,-0.375469],
 [-2.507535, 0.204041, 5.184347],
 [-0.02623 , 2.662275, 3.085694],
 [-3.474252, 0.934161,-2.324714],
 [-3.307481,-0.270475, 0.737721],
 [ 0.090882, 2.661556, 2.920419],
 [ 0.062795, 2.747987, 2.980145],
 [-1.026758,-0.646031,-2.513465],
 [-2.76114 ,-0.216433, 5.270248],
 [-1.601409, 0.420131, 2.302436],
 [-4.05783 ,-2.215707, 0.332977],
 [ 7.728976, 1.389655,-0.175712],
 [ 0.514533, 0.040835, 1.811155],
 [-0.965905, 1.67131 , 3.570936],
 [-1.130847, 1.545923, 3.661083],
 [ 5.908957, 1.761696,-0.713656],
 [ 0.484421,-3.922276,-0.827474],
 [ 1.13167 ,-0.612137,-2.824789],
 [ 1.050692, 0.429101, 3.066061],
 [-3.260996, 0.563946,-1.745003],
 [ 7.452629, 0.253993,-0.12578 ],
 [-0.660894, 2.132906,-2.105401],
 [-2.012876,-1.495301,-2.294801],
 [ 5.008858, 1.645212,-1.179722],
 [ 1.897441, 0.105071, 1.727163],
 [ 0.189786,-0.880746, 3.128146],
 [-0.993191,-0.20217 , 5.088629],
 [-3.036314,-0.384138,-1.146552],
 [-0.07602 , 3.079514, 2.973823],
 [-2.142255, 1.971535, 2.348663],
 [-0.837904, 1.98406 , 1.748714],
 [ 6.15754 , 3.028797,-0.030408],
 [ 0.841042,-0.49527 , 3.0172  ],
 [ 4.272443, 2.057642,-1.184219],
 [ 0.657806, 5.060566, 1.953605],
 [-1.624232, 0.290886, 2.036785],
 [ 2.538087,-0.157125,-1.334007],
 [ 2.777193,-0.010405,-0.969426],
 [-1.788212,-2.289567, 1.275305],
 [-1.502978, 0.961842,-0.817351],
 [-2.08293 ,-2.12252 , 1.467716],
 [ 0.99785 ,-0.315582, 3.256907],
 [-2.325829,-0.642946, 2.852813],
 [ 0.115848, 5.215872, 3.044305],
 [-1.0413  ,-0.423311, 5.344119],
 [-2.374319, 0.00224 ,-2.399708],
 [ 1.16568 , 0.00356 ,-1.159065],
 [-2.302058,-1.796214,-2.332337],
 [ 0.136976, 0.426349, 3.65894 ],
 [ 1.194208,-0.698797,-2.074241],
 [ 0.096977, 0.557804, 3.817488],
 [ 2.435006, 1.236277,-0.836728],
 [ 0.915355, 3.84309 , 0.332095],
 [ 0.757674,-2.477968, 0.725203],
 [-1.530145, 1.08493 , 0.27513 ],
 [ 5.863912, 0.261731,-1.242397],
 [ 4.55838 , 0.388368, 0.177999],
 [-2.109478, 0.971341, 5.960549],
 [ 1.592088, 0.374893,-0.559949],
 [-1.340736, 2.510792, 0.55826 ],
 [-3.235223,-0.779698, 4.090821],
 [-5.544001, 1.107421,-0.810607],
 [ 0.217576,-3.647962, 0.126327],
 [-3.468806,-1.309931,-1.97282 ],
 [-7.238758,-0.217342, 3.046169],
 [ 0.305979,-0.731534,-5.099201],
 [-0.410728, 1.913388, 2.693936],
 [-0.554323, 1.517439, 2.818382],
 [ 2.758917, 1.026398,-0.33098 ],
 [ 1.855291,-2.196906, 1.786504],
 [ 2.738606,-0.158583,-1.711433],
 [ 2.238416, 0.340975, 0.363771],
 [-0.578052, 1.587142, 2.358532],
 [ 5.246155, 0.415146, 0.03921 ],
 [-0.315538, 2.060622, 2.586814],
 [ 1.733714, 3.806288, 2.021079],
 [ 1.966079, 0.022282,-0.447699],
 [ 2.79231 , 1.430083,-0.061637],
 [ 0.546299, 0.251552, 3.213927],
 [ 0.428693, 2.35053 , 3.350663],
 [ 2.582562,-0.602786,-0.592438],
 [ 6.479336,-0.906616,-0.167314],
 [-6.558174, 0.524391,-0.80528 ],
 [-0.454615,-0.188653,-1.399133],
 [-0.737389,-5.795858,-0.292516],
 [ 0.008275, 2.5238  , 2.682524],
 [-0.96501 ,-1.192823,-4.106234],
 [-2.81523 , 0.643649, 5.10964 ],
 [ 4.000134,-1.685989, 0.404922],
 [ 2.82472 ,-1.531683, 1.532874],
 [-3.748585,-0.669867, 0.928778],
 [-3.508347, 0.890445,-2.264301],
 [ 4.410733,-1.660242, 0.437603],
 [-1.563671, 0.216762, 2.049973],
 [ 7.195531, 2.370347,-0.023981],
 [-0.78579 , 0.250576, 4.501562],
 [-0.73725 , 1.524144,-1.862645],
 [-7.483434,-1.350178, 0.39969 ],
 [-5.189609,-1.887124, 1.049734],
 [-3.918566, 0.132816, 2.01069 ],
 [-0.02537 , 0.283994, 1.450239],
 [-3.479826,-3.214766,-0.860774],
 [-1.010285, 1.877008,-0.9991  ],
 [ 0.189839,-0.298458,-4.937797],
 [-2.660722, 0.308881, 2.372411],
 [ 1.754396, 1.02731 ,-2.81603 ],
 [ 6.273791, 3.118129, 0.062425],
 [-3.13899 , 1.883782, 1.284382],
 [ 2.738364, 3.474267,-0.864168],
 [ 1.51414 , 1.238015,-2.878171],
 [ 0.268982,-1.004175,-1.813589],
 [-1.979403,-2.060774, 1.280657],
 [-3.492396,-0.136302, 3.087436],
 [ 1.07613 , 0.424323, 3.025753],
 [ 0.284897,-0.05257 ,-5.087996],
 [ 0.713488, 5.134055, 1.999255],
 [ 0.715515, 5.130518, 1.977557],
 [ 0.589581, 5.193676, 1.937012],
 [-3.093619, 0.07764 , 5.160847],
 [-5.036325, 0.131996, 2.437103],
 [ 3.675573,-1.358992, 1.135268],
 [-3.755966,-0.082348, 2.416885],
 [-5.10486 ,-0.094649, 4.901565],
 [-5.929674, 1.290154, 3.945911],
 [-5.130128,-0.063669, 4.875521],
 [ 1.212594,-0.756493,-2.75232 ],
 [-2.814229, 0.601103, 5.761318],
 [-1.86825 ,-0.39903 ,-1.782135],
 [-1.354986,-0.495085, 1.61961 ],
 [ 2.081841, 0.759124,-0.508987],
 [-1.025864,-1.107086, 0.091288],
 [ 2.868336,-2.482983,-0.526345],
 [ 4.959123,-2.030505,-0.651201],
 [-0.182111, 1.059559, 1.764908],
 [ 0.712265, 0.110577, 1.460688],
 [-0.568138, 1.634626,-2.17156 ],
 [-0.111529,-3.529279,-0.561423],
 [-0.471666, 1.946951, 2.723927],
 [ 0.104177, 0.462417, 1.822333],
 [ 0.708524,-2.576674, 0.764496],
 [-7.514284,-1.327933, 0.367848],
 [ 5.806609,-1.173577, 0.462935],
 [-2.598222, 1.699876, 3.692551],
 [-2.088983, 0.963631, 6.075528],
 [-6.718299,-0.257784, 2.703107],
 [-3.210223, 0.230562, 5.385642],
 [ 0.343058, 2.70658 , 1.103623],
 [-3.187589, 0.346575,-1.973928],
 [ 0.484338, 0.74058 , 1.436437],
 [ 0.551298,-2.255019, 0.491541],
 [ 2.298918, 0.182099, 0.298231],
 [ 1.832695, 0.078051, 2.051175],
 [ 1.563799, 1.607424, 1.815338],
 [-0.968529, 0.637518, 0.730794],
 [-1.042292,-0.210153, 2.811484],
 [ 4.974971,-2.01353 ,-0.640457],
 [ 2.379734, 3.252234, 2.765648],
 [-1.313367,-0.262494, 1.266274],
 [ 3.591409, 0.395633, 0.425067],
 [-3.164336,-3.68751 ,-0.423492],
 [-0.109214,-1.582126, 2.473381],
 [ 2.376054, 3.259822, 2.77278 ],
 [ 4.486087,-0.552991, 2.273652],
 [-1.039565, 0.375213, 1.919898],
 [ 0.371555,-1.54568 ,-1.7628  ],
 [-3.320639,-0.2898  ,-2.715142],
 [ 3.913214,-2.716146,-3.517679],
 [-2.546762, 0.279893, 0.070862],
 [-1.663432, 0.086606, 2.944432],
 [ 4.34409 , 2.641788, 2.630644],
 [-1.156972, 2.038269, 1.122262],
 [ 4.58641 , 0.003137, 3.11799 ],
 [ 4.474674, 0.28756 , 0.507085],
 [ 3.223308, 1.939834, 3.8751  ],
 [-0.646479, 0.157911,-1.561935],
 [ 2.128726, 0.119683, 1.576832],
 [-1.533675,-5.650211,-0.275966],
 [-0.762202,-5.747077,-0.353928],
 [-0.612607,-5.723075,-0.280633],
 [-6.721575,-0.343679, 2.941806],
 [ 3.494442,-0.262668, 1.252037],
 [-1.576926,-5.779672,-0.29224 ],
 [-0.614932,-5.774322,-0.218409],
 [ 1.978624, 0.58989 , 0.48028 ],
 [ 2.558464, 0.307258,-0.753333],
 [-3.019076, 2.183851,-0.530675],
 [-3.346597, 1.528027, 1.881289],
 [-2.424388, 0.154039,-2.456334],
 [ 0.617009,-2.025589,-2.262327],
 [-2.730171,-0.648542, 4.154665],
 [ 5.279882, 0.348154,-0.695533],
 [-3.277771, 1.41659 , 1.741082],
 [-3.09935 , 0.410175, 5.539053],
 [ 4.646152, 0.940911,-0.890192],
 [-3.250127, 1.515013, 1.611226],
 [-2.672312,-0.04598 , 5.572728],
 [-4.439971, 0.157546, 1.66135 ],
 [-3.82783 ,-0.387458, 0.921401],
 [-4.065273,-0.188813, 1.110137],
 [-0.808258, 3.265073, 1.915815],
 [-3.799455, 1.08717 , 1.154616],
 [-3.799337,-0.418666,-1.120571],
 [-1.648317, 1.728733, 1.009159],
 [-7.202294,-0.231153, 3.005305],
 [ 0.975085, 0.37189 , 1.193979],
 [-1.483038, 0.24677 , 1.475161],
 [ 1.090545,-0.312979, 3.712735],
 [ 2.693874,-0.614722,-0.799263],
 [-0.333486,-1.507783, 4.03948 ],
 [ 3.100994,-1.5643  , 0.580862],
 [-0.137535,-0.627668, 6.17355 ],
 [-1.485694,-0.476677,-0.076493],
 [-1.577306, 2.936351,-1.812005],
 [-1.867305, 1.950244, 0.641284],
 [-0.678873,-2.118688, 2.572479],
 [-3.092982, 2.010785,-0.855942],
 [-5.576986,-0.785896, 1.141896],
 [-5.152859,-0.086288, 5.044265],
 [-3.205615, 0.562623, 5.555063],
 [ 3.706499,-2.982004,-3.812744],
 [ 4.082134,-2.695364,-3.257906],
 [-2.514392, 0.587812, 1.228003],
 [-0.536818,-2.521352,-5.412733],
 [-1.662864,-0.095696,-1.40273 ],
 [ 2.711069, 3.531358,-0.822141],
 [-2.53313 ,-0.440816, 6.371346],
 [-5.877279, 1.803281, 2.282437],
 [ 1.91112 , 4.343481,-0.43977 ],
 [-3.350022,-0.095276, 0.524462],
 [ 4.562427, 0.576349, 2.77906 ],
 [-2.954255,-1.705249,-4.729399],
 [ 2.391957,-2.05656 ,-2.252734],
 [ 2.948947,-0.177429,-0.744954],
 [-0.392913, 1.392237, 0.803486],
 [ 1.232392, 1.245538, 3.011828],
 [ 0.601621,-2.564826,-1.04068 ],
 [-5.301885,-0.538158, 0.40857 ],
 [-3.478075,-1.241875,-0.373973],
 [-7.071359,-0.246103, 2.986587],
 [-0.87232 ,-2.929548,-1.575215],
 [-7.493253,-1.357435, 0.391058],
 [ 1.540521,-3.710499,-4.423624],
 [-0.384604,-5.241979,-2.083861],
 [ 1.210487,-4.560513,-2.014474],
 [-2.704735, 1.255394,-2.439357],
 [-1.616042, 1.172254, 5.778555],
 [-1.663692, 1.175328, 5.759102],
 [ 4.082536, 1.140845, 2.760799],
 [-4.738286,-0.272707, 1.112601],
 [-6.625265, 1.376966, 0.186363],
 [-3.128089,-1.68996 ,-4.516324],
 [ 2.98269 ,-1.412025,-4.508262],
 [-1.569803, 0.561536,-2.629541],
 [ 3.618741,-2.470086,-3.978347],
 [-0.753948, 2.473772, 5.587166],
 [-2.702364, 1.252887,-2.43997 ],
 [ 3.927011, 0.122728, 0.514846],
 [-0.742416, 2.85288 ,-0.566482],
 [-0.116715,-1.038279, 0.342388],
 [ 2.241984,-1.742676,-2.464829],
 [ 3.63254 ,-2.826865,-3.65713 ],
 [ 2.304606,-1.445619,-2.047623],
 [-0.256485, 3.022498, 2.847953],
 [-0.156014,-2.890693,-5.513863],
 [-5.539395,-0.501129, 0.418056],
 [-2.915522, 1.326489,-1.79613 ],
 [ 4.031736, 0.637325, 2.110889],
 [ 2.561834,-0.888418,-3.688799],
 [ 0.701838,-0.74217 ,-5.045886],
 [-5.018975, 0.383642, 0.121853],
 [-3.698306,-0.7758  , 0.825635],
 [-1.069988,-3.253933,-3.522142],
 [-1.93424 ,-3.254455,-3.571755],
 [-3.235856, 0.74886 ,-1.650835],
 [ 3.792268,-2.737918,-4.022795],
 [ 4.013958,-2.869927,-3.98041 ],
 [ 3.323235, 1.090278,-0.775965],
 [ 0.071322, 0.29622 , 3.106543],
 [ 1.353254, 2.531479, 2.651085],
 [ 6.888229, 2.263206, 0.467864],
 [ 2.296139, 0.965863, 4.404446],
 [-4.001941,-1.674364,-1.070252],
 [-3.110483,-1.638656,-4.329179],
 [ 1.042555,-0.158267, 0.304045],
 [ 2.769084,-3.232102,-1.718704],
 [ 0.461863, 1.082704, 1.609596],
 [ 4.00562 ,-1.6055  , 1.299074],
 [ 0.312062, 0.474861, 6.096877],
 [-6.755783,-0.258587, 2.52864 ],
 [ 7.570577, 2.084915,-0.105243],
 [ 0.707518,-2.697958,-4.17513 ],
 [-0.182852,-1.842714,-2.242861],
 [ 6.006812, 1.569826, 2.053656],
 [-3.472324, 0.79137 ,-2.272593],
 [-3.057278,-0.032596, 5.773395],
 [ 0.573424, 5.163796,-1.256137],
 [-2.645691, 0.69399 ,-0.121924],
 [ 0.720481,-3.865402,-4.411085],
 [ 2.611181, 1.215518,-0.98483 ],
 [ 2.480021, 1.635206, 0.775911],
 [ 1.118183,-0.267461,-4.856111],
 [ 3.093655, 0.883301, 0.542723],
 [ 4.438268,-2.218597, 0.008677],
 [ 4.440675,-2.208172, 0.008515],
 [ 3.169332, 0.688308,-0.633708],
 [ 1.797666,-0.575   , 0.585744],
 [-4.541055,-1.884791,-1.122515],
 [ 1.204613,-4.565722,-2.012631],
 [-0.154817,-2.457598,-1.855035],
 [-1.233925,-1.651849,-2.974875],
 [ 2.602658,-1.702424,-0.106334],
 [-0.186002,-0.011015, 6.187929],
 [-0.102829,-1.162298,-3.960677],
 [-3.829651,-0.10314 , 1.675136],
 [ 5.14038 , 0.992611, 0.049077],
 [-0.338889,-3.118923,-5.506054],
 [ 1.07947 ,-0.504891,-1.331862],
 [-0.626997,-5.797909,-0.282984],
 [ 3.593759, 0.765973,-0.139526],
 [-0.628528, 1.599806,-1.858518],
 [ 3.006321, 0.301801,-1.312845],
 [ 1.46399 ,-3.199562,-1.550209],
 [ 4.060352, 0.620364,-3.171647],
 [ 0.136549,-0.1923  , 5.758509],
 [ 6.508931, 0.391898,-3.063125],
 [ 1.410861,-3.793171,-4.121569],
 [ 6.439759, 0.27634 ,-1.501062],
 [ 0.192545,-3.721239,-0.903874],
 [ 1.252531, 0.581987,-1.764664],
 [-2.930358,-1.666661,-4.726064],
 [-5.040251,-0.944079,-0.148037],
 [ 5.862688, 0.190326,-1.22542 ],
 [-1.671147,-1.47571 ,-3.234085],
 [-5.474339,-0.51227 , 0.441322],
 [ 2.058679,-1.662225, 1.898986],
 [ 5.059561,-0.466726,-0.856668],
 [-4.571258, 0.047932, 3.234352],
 [ 2.319146, 3.48203 , 1.486972],
 [ 0.815317,-2.43284 , 0.052654],
 [ 5.043687,-1.488857,-0.005502],
 [ 4.005138,-2.888631,-4.174959],
 [ 0.559015,-3.432407,-4.145322],
 [-5.234941,-0.177742, 4.822449],
 [-0.44673 , 3.758066,-0.861259],
 [ 6.492178, 0.233412,-1.695559],
 [-1.503968,-2.000777, 1.163877],
 [ 1.500046,-3.653494,-4.234311],
 [ 0.655742,-3.327072,-3.861963],
 [ 1.888367,-1.404358,-2.909321],
 [-0.871445,-0.571317, 2.153559],
 [ 0.363965,-4.121467,-5.810547],
 [-7.202566,-0.228601, 3.088713],
 [ 1.010396, 2.992174, 2.180721],
 [ 0.780723,-2.86124 , 1.094663],
 [ 5.056537,-1.495065,-0.001886],
 [-5.530428,-0.236725, 0.50257 ],
 [-2.95509 ,-1.676775,-4.728467],
 [-0.620457, 1.444329, 0.876872],
 [ 1.733105, 1.749684, 1.770423],
 [-2.786625, 0.117798, 0.917244],
 [-5.530406,-0.515635, 0.405251],
 [-4.439913,-1.859339,-1.07717 ],
 [-0.598306, 4.297218,-1.12954 ],
 [-0.681765, 4.392494,-1.188323],
 [-0.732851, 4.19428 ,-1.079826],
 [-0.716546, 4.392103,-1.205613],
 [-0.280169, 3.180554,-0.134121],
 [-0.708256, 4.432539,-1.234521],
 [-0.697332, 4.458879,-1.240073],
 [-3.690504, 3.580822, 0.793263],
 [ 0.384215,-4.188247,-5.818642],
 [-3.362975,-1.593232,-4.0911  ],
 [ 1.804874, 2.519078, 0.304982],
 [-5.252738,-2.023139, 0.932631],
 [-1.156046,-0.201297, 0.657906],
 [ 5.368971, 0.63107 ,-2.156266],
 [ 3.915972,-2.310402,-3.49493 ],
 [ 1.335225,-2.261285,-2.623763],
 [ 1.017146, 1.098178, 2.369682],
 [ 1.687668, 2.069502, 2.156898],
 [ 0.375692, 0.480189, 6.016066],
 [-5.780931, 1.72489 , 2.186131],
 [ 1.775025, 3.235812,-0.258753],
 [-4.008831, 0.722954, 6.342998],
 [ 0.840199, 4.309818,-0.140966],
 [-5.895484,-0.427086,-1.607911],
 [-0.184463,-2.980836, 0.266006],
 [ 0.751431,-0.805425,-5.310827],
 [-0.84645 , 1.369502,-2.940085],
 [-0.387342, 0.648067, 1.630387],
 [-4.819098,-0.147388, 4.667444],
 [-0.659642,-3.226361,-3.724582],
 [-3.519163,-2.574385,-0.323913],
 [ 4.862742, 4.742884, 0.942235],
 [ 1.149196,-0.65034 ,-3.259613],
 [-0.796236, 0.274967,-1.670384],
 [ 1.792175,-2.731699,-3.759915],
 [-0.294987,-5.305194,-1.888484],
 [-0.431034, 2.515898, 2.769305],
 [-1.724021,-1.273894,-2.082695],
 [ 5.331083, 0.690882,-0.315479],
 [ 3.889769,-3.028671,-3.63617 ],
 [-1.827971,-1.640877,-2.005354],
 [-1.15607 ,-2.036486, 0.544792],
 [ 0.404324,-4.110394,-5.739786],
 [ 3.42051 ,-0.507739,-0.90027 ],
 [ 2.437777,-0.851377,-3.621396],
 [-5.446271,-0.515707, 0.675425],
 [-0.855442, 1.353918,-2.949081],
 [-4.800676,-1.87876 ,-1.507735],
 [-2.12135 ,-2.146572,-0.523139],
 [ 2.461883,-2.782374,-2.379072],
 [ 4.599744, 0.657205, 2.751572],
 [ 2.561332,-2.43159 ,-2.850869],
 [ 0.764425,-2.560077, 0.658894],
 [-1.838815,-0.3125  ,-1.903668],
 [ 5.57111 , 0.521112,-0.080845],
 [-1.34225 ,-3.068479,-3.004879],
 [ 5.820886,-1.179295, 2.044863],
 [ 0.777346,-3.365113,-1.429152],
 [ 2.112405,-2.044067,-1.59259 ],
 [ 0.304829, 1.471764, 3.144151],
 [ 1.667227,-3.725127,-4.012868],
 [ 1.827595,-3.646804,-4.664877],
 [ 3.914474,-0.744591,-3.937592],
 [ 1.111509,-4.44154 ,-2.00688 ],
 [-3.493478, 1.415549, 1.467079],
 [-3.566116, 0.38611 , 5.421384],
 [-3.316002,-3.155863,-0.494102],
 [-2.111696, 0.996226, 0.075668],
 [-0.187614,-3.086712,-5.665658],
 [-0.127366, 2.537174, 2.912402],
 [-1.242961,-1.7339  , 3.373431],
 [ 5.099787, 0.45088 , 1.596077],
 [-2.825661, 0.311584, 5.317036],
 [-5.03124 , 2.125903, 1.612969],
 [-0.868836,-2.830258,-3.225954],
 [-0.674318, 4.396416,-1.193636],
 [-4.63679 ,-1.909487,-0.982698],
 [ 1.675246,-1.873462,-1.929896],
 [ 0.233939,-0.333773, 1.587295],
 [ 0.439667, 1.617765, 4.429086],
 [ 2.080064,-2.983552,-1.786373],
 [-1.565066,-4.241929,-3.49547 ],
 [-2.610327, 1.818358,-2.28376 ],
 [-4.089288,-1.94777 , 0.409745],
 [-1.171251, 0.089419,-4.09011 ],
 [-6.083222, 1.059039, 0.615276],
 [-6.59199 , 0.7517  ,-0.489633],
 [-3.014904, 0.061205, 4.824907],
 [-2.400907, 2.065735, 1.916309],
 [ 0.580894,-0.128536, 1.849881],
 [ 3.57307 ,-1.383128,-2.924002],
 [-0.866408,-0.916363,-3.55598 ],
 [ 1.570674,-3.59283 ,-4.419733],
 [-0.172701,-1.81997 ,-5.77869 ],
 [ 0.026194,-1.07024 ,-5.343394],
 [ 2.031286,-1.764722,-2.769163],
 [-5.886403, 1.248505, 3.796233],
 [ 3.099596,-2.433075, 1.266074],
 [ 6.692526, 0.222658,-2.361418],
 [ 1.275112,-0.889406, 4.999465],
 [-0.77387 , 0.698702, 4.313165],
 [ 2.755693,-1.375826,-4.682384],
 [ 0.161383,-4.111931,-0.655373],
 [ 0.315212,-2.050136,-1.439592],
 [-7.152788,-0.19368 , 2.898644],
 [-5.927938, 1.320392, 3.980422],
 [-4.673332, 1.355954, 4.340046],
 [ 5.311642, 0.022776,-2.68082 ],
 [ 1.620364,-3.591792,-4.599523],
 [ 2.457919,-2.204515,-4.159768],
 [-5.375794, 1.185913,-0.663857],
 [ 6.207996, 0.399824,-1.602306],
 [-4.531987,-1.901141,-1.247164],
 [-4.628275,-1.915665,-1.23541 ],
 [ 2.321727,-2.781146,-2.02805 ],
 [-0.598305, 4.301879,-1.131647],
 [-0.696263, 4.447866,-1.248673],
 [ 6.963511, 2.517806,-0.048362],
 [ 6.97618 , 2.572458,-0.112089],
 [-0.597091, 1.447888,-2.242489],
 [-1.136252,-1.896048,-0.362493],
 [ 3.512536,-1.808305,-4.114418],
 [-4.63679 ,-1.909487,-0.982698],
 [-6.12388 , 0.926379, 0.850828],
 [ 2.373525,-0.871456,-3.686008],
 [ 0.007242,-1.691356, 0.99954 ],
 [-4.108454,-2.703874,-0.238748],
 [-2.945559, 1.900615, 0.052714],
 [-3.960639,-1.569762,-1.242495],
 [ 3.238911, 0.909914, 0.265076],
 [-2.259123,-2.957862, 1.673482],
 [-3.784026,-1.568444, 1.309702],
 [ 5.310182, 0.068652,-2.443842],
 [-0.46606 ,-0.044117,-4.907557],
 [ 5.215035,-0.089414,-4.049346],
 [-0.42332 ,-0.088694,-4.904417],
 [-5.42292 ,-0.00599 , 1.379391],
 [-5.261362,-0.188077, 4.815016],
 [-0.316151,-2.924592,-2.082115],
 [ 1.900622,-2.234323,-2.450369],
 [ 0.90868 ,-0.484838, 3.450625],
 [ 1.310673,-3.212993,-3.935631],
 [ 6.340544, 3.186409, 0.127435],
 [-3.48675 ,-1.694776, 1.298427],
 [-3.478564,-1.641663, 1.277479],
 [ 5.237183, 0.526208,-0.170076],
 [-3.153008, 0.100765, 4.851823],
 [ 3.087542,-0.324613, 0.633483],
 [-3.727262, 0.298777,-0.196207],
 [-3.625333, 0.596055,-0.455406],
 [-3.546267, 0.431161,-0.298053],
 [-3.583868, 0.2297  ,-0.096901],
 [-0.077567,-1.500713, 2.435586],
 [-3.018266, 0.105133, 5.286436],
 [ 3.061976,-2.675302,-4.141196],
 [ 1.955492,-3.39699 ,-0.715176],
 [-3.074517, 0.127564, 5.102639],
 [ 0.557026,-0.066696, 1.876955],
 [ 0.565194,-0.108746, 1.879577],
 [ 6.014044, 0.200029,-0.916986],
 [-2.620812, 0.752861,-0.325548],
 [-4.310801,-1.948919,-1.04554 ],
 [ 3.636663,-2.49992 ,-4.199651],
 [ 1.891258,-2.443462, 0.766453],
 [ 2.505313,-2.855596,-3.568944],
 [-0.063903, 0.520582,-3.67696 ],
 [-2.702379,-1.877896,-1.365092],
 [ 0.256964,-4.619334,-1.382843],
 [ 3.439641, 0.51417 ,-0.79629 ],
 [ 3.618862,-3.15064 ,-3.367003],
 [-5.868047, 1.786896, 2.271321],
 [ 4.143126, 0.615837, 0.339477],
 [-4.256994,-0.824951,-0.571438],
 [-5.282765,-0.916787, 0.007445],
 [-4.69005 ,-0.824859,-2.715402],
 [ 2.753676,-3.243286,-1.710317],
 [ 4.183248,-2.476559,-3.003473],
 [-7.281037,-0.217781, 3.05114 ],
 [ 2.097682,-3.446998,-0.595513],
 [-4.08196 , 0.099187,-0.207893],
 [ 2.802445, 0.149242,-1.655835],
 [ 5.803943,-1.241542, 2.06706 ],
 [-1.186972, 4.024029, 3.125139],
 [-0.246293,-3.010893,-5.694379],
 [ 3.124823,-2.402253,-3.421386],
 [ 1.602716,-3.641106,-4.55113 ],
 [ 1.060324, 4.212503,-1.222465],
 [ 6.210907,-0.122072,-0.873403],
 [-0.19571 ,-5.193322,-1.877892],
 [ 2.478125,-3.147105,-1.934057],
 [-5.878664, 1.798849, 2.265487],
 [ 7.002675,-0.219627,-0.552495],
 [ 3.633274,-2.97396 ,-3.589145],
 [ 1.27158 , 0.090404,-1.644212],
 [ 1.402648, 1.643517, 2.844771],
 [ 0.466095, 1.534829, 3.168308],
 [-4.074582,-0.262854,-0.714359],
 [ 0.884956,-4.220584,-0.602916],
 [-2.193831,-1.991402,-1.26648 ],
 [ 5.538904, 1.181035, 0.325399],
 [ 4.394112, 0.67307 ,-1.478853],
 [ 7.080009,-0.551064,-0.085737],
 [ 0.291644,-4.103312,-5.837953],
 [-0.271901,-3.314271,-5.033559],
 [ 1.398654, 2.338654,-0.430191],
 [ 0.383058,-2.968518,-3.889112],
 [ 0.851793,-3.451392,-4.032248],
 [ 2.492792,-3.12979 ,-1.948395],
 [ 1.879197,-1.461756,-5.35471 ],
 [-7.524314,-1.215466, 0.259795],
 [-4.737752,-0.141377, 0.962595],
 [ 4.430735,-0.467708, 2.276302],
 [ 1.084851,-2.60392 , 0.270712],
 [-1.650373, 1.284007,-0.277773],
 [-3.08052 ,-2.582449,-2.402114],
 [ 3.181776,-1.784513, 1.281939],
 [ 0.813404,-3.250258,-2.88536 ],
 [-0.617041,-2.31157 ,-2.670208],
 [ 1.314906,-4.302632,-2.527586],
 [-0.737547, 2.160226,-2.037869],
 [-0.18628 ,-3.091109,-5.663056],
 [ 0.855911,-3.245106,-1.529328],
 [ 4.812254,-1.018755,-0.746276],
 [ 2.456048, 1.345363,-3.411172],
 [ 4.568827, 0.235449, 0.641657],
 [ 4.897328,-0.245491,-2.588381],
 [ 1.600305,-3.651414,-4.373726],
 [-3.273465,-1.047247, 0.363169],
 [-4.933928,-1.667027,-0.683447],
 [-1.358846,-2.19372 , 3.299196],
 [-1.241004,-1.771782,-0.822902],
 [ 2.768153,-0.007145,-3.483592],
 [ 3.270155, 1.059347,-2.013732],
 [-1.365915,-5.545448,-0.21519 ],
 [-7.248893,-0.188523, 3.24739 ],
 [-1.045446,-1.142559,-3.084673],
 [-6.546584,-2.299234,-0.404524],
 [ 2.716455, 0.746679,-0.65449 ],
 [-2.233449, 0.940417, 5.821091],
 [-2.8463  , 0.259306, 5.312515],
 [ 5.898917, 0.180973,-0.693206],
 [-0.105021, 0.162737, 2.346851],
 [ 5.852731,-1.036955, 2.119178],
 [ 1.63474 ,-3.586057,-4.750873],
 [ 0.396329,-2.81528 ,-5.348011],
 [ 1.614871,-3.627558,-4.643935],
 [-0.490089,-3.231379,-5.218663],
 [ 3.72742 ,-0.220278, 1.038074],
 [ 3.175773, 1.614923, 3.846498],
 [ 4.178784, 1.728522, 1.221713],
 [ 3.187361, 1.591331, 3.85859 ],
 [ 1.209211, 1.772012,-0.743655],
 [ 1.9569  , 2.289431, 1.114824],
 [ 3.197856, 1.555499, 3.764169],
 [ 2.852951, 3.702667, 0.35261 ],
 [ 7.286979, 0.467914, 0.897359],
 [-3.308032,-0.891469,-0.776911],
 [ 2.95351 , 0.224199,-3.26593 ],
 [ 1.278976, 2.585234, 0.264191],
 [-2.079601,-2.162112,-3.470942],
 [ 1.152814, 2.095107, 0.01173 ],
 [ 5.854528, 2.459225,-0.04614 ],
 [ 2.331281,-1.409359,-2.020351],
 [ 5.519742, 0.05968 , 0.51925 ],
 [-4.673132, 1.361931, 4.345212],
 [-5.12114 ,-0.076238, 4.890291],
 [-0.891665,-3.972732,-1.406381],
 [-1.473612,-2.027483, 3.350038],
 [-0.009779, 0.128073, 6.110419],
 [ 0.080965, 0.089909, 6.074794],
 [ 5.149975,-1.213018, 0.638757],
 [ 6.38801 , 0.209826,-1.021032],
 [-7.101111,-0.213839, 3.266229],
 [ 0.279149,-0.104519,-1.602409],
 [-2.084092,-2.153396,-3.477258],
 [ 1.044485,-3.503954,-4.136647],
 [ 3.713158, 0.601657, 0.794074],
 [-2.637987, 0.407923,-0.220997],
 [-2.364831, 0.700469, 0.0471  ],
 [-1.605044, 3.474165, 0.628952],
 [ 0.481439,-2.41916 ,-0.409931],
 [ 4.98404 ,-1.074173,-1.958714],
 [ 5.575759, 0.54709 ,-0.348212],
 [-1.131629,-3.474945, 0.152009],
 [-5.253458,-1.014944,-0.460563],
 [ 3.83508 ,-1.881389,-3.73828 ],
 [ 0.71331 ,-2.94612 , 0.968175],
 [-1.725142,-3.872169,-2.993082],
 [ 6.634893, 2.898477, 0.620291],
 [ 2.006062, 5.490996, 1.011007],
 [ 1.288395, 4.829383, 1.348634],
 [-5.094216, 0.893016,-0.01256 ],
 [-3.384507,-3.356298,-0.268883],
 [-5.170436,-0.088624, 0.514018],
 [-1.375171,-0.931174,-0.120137],
 [-1.113906,-1.228263,-0.289944],
 [-1.678669,-1.607252,-3.194874],
 [ 4.427161,-1.681397, 1.325062],
 [ 0.829614, 5.758986, 1.364644],
 [-0.117479,-2.42276 ,-1.842158],
 [-0.104182,-0.956251,-3.834295],
 [ 1.160165,-1.244568,-5.04263 ],
 [ 3.47978 ,-1.162031, 0.12876 ],
 [ 4.977714, 0.109011, 0.993477],
 [-3.422023, 0.800221,-2.123532],
 [ 0.227491,-0.029856, 2.403276],
 [ 4.233852, 4.19732 ,-0.156289],
 [ 0.629648,-0.613289,-4.86802 ],
 [ 0.941657,-3.441072,-3.781549],
 [ 0.144578,-0.936134,-4.802471],
 [ 3.643504,-2.607472,-4.1176  ],
 [ 2.844653,-0.555066,-3.474355],
 [-1.715547,-0.730542, 0.159439],
 [ 0.609201,-4.218197,-5.554686],
 [ 2.437972,-0.848695,-3.61271 ],
 [-1.105784, 0.584201,-1.968401],
 [ 1.559969,-3.617131,-4.491583],
 [ 2.431299,-0.392173,-5.072277],
 [ 2.374292,-0.279137,-5.07858 ],
 [-3.202404, 0.299289, 5.319156],
 [ 0.13707 , 1.344862,-1.167554],
 [ 2.99395 ,-2.661682,-4.175769],
 [ 0.828019, 5.766278, 1.362566],
 [-4.829759,-0.093134, 4.83529 ],
 [ 3.913635,-0.76719 ,-3.997322],
 [ 0.763799,-2.995325,-2.376849],
 [ 1.60771 ,-1.702614,-5.170662],
 [ 2.295184, 0.030077,-0.334747],
 [-0.6096  , 1.573184,-1.935461],
 [ 6.299291, 3.252146, 0.130683],
 [-1.582345,-4.259427,-3.495356],
 [ 3.304875,-2.777651,-3.46888 ],
 [ 4.400754,-0.062875, 2.427852],
 [-7.002389,-0.142257, 3.329628],
 [-4.486754, 0.038186, 5.040013],
 [ 4.355468, 4.088171,-0.360394],
 [ 1.537373,-0.983799,-4.338976],
 [-0.916728,-0.590168, 1.185055],
 [ 2.877962,-1.234572, 1.438538],
 [ 1.849916,-1.378257,-0.85331 ],
 [ 2.206748,-1.276346,-0.854522],
 [-5.699856,-0.104736, 0.39648 ],
 [-3.126843,-3.38807 ,-1.402298],
 [ 2.235469, 0.164625,-0.132396],
 [-3.480399, 0.929915,-2.005731],
 [ 2.051938, 5.53666 , 0.938761],
 [-0.293695,-2.979383,-5.57361 ],
 [ 1.858972, 1.706922, 3.018187],
 [-3.167775, 0.572356,-1.759235],
 [ 3.879344, 1.109612,-1.202883],
 [-0.890395,-0.137559, 0.585526],
 [-0.39779 , 2.603915, 1.584737],
 [-4.920057,-0.778301, 0.210199],
 [ 6.831939,-0.313197,-3.042054],
 [ 4.332638, 0.916947, 0.668357],
 [-2.848305,-3.01212 ,-2.495866],
 [-1.991015, 1.117402,-0.67219 ],
 [-3.295644,-1.944176,-0.317443],
 [-5.555469,-2.290514, 0.319536],
 [-3.969299, 0.686185, 6.376409],
 [ 7.854889, 1.537414,-0.191376],
 [ 4.075701, 0.526806, 0.904682],
 [ 2.932238, 0.797179,-1.770304],
 [ 5.358602,-2.355942,-0.00528 ],
 [ 2.596735, 1.203736,-0.743965],
 [ 3.18066 , 3.643668, 0.37334 ],
 [ 4.262887, 0.472855, 0.334738],
 [ 3.675415, 0.450549, 0.844822],
 [-1.212469,-3.315763, 0.163316],
 [ 5.122321, 4.639437, 1.175939],
 [-4.997435,-1.790718,-0.769672],
 [-1.390472,-1.013509, 4.379195],
 [-0.261582,-2.940045,-5.616927],
 [-4.781217,-1.508439,-1.805518],
 [ 7.791384, 1.793658,-0.127193],
 [-5.515747,-0.811148, 0.329853],
 [-5.105903,-1.161077,-0.020653],
 [-1.367945, 0.099159,-1.066397],
 [-4.627263,-0.252957, 0.641301],
 [-2.066795,-1.713004, 0.09734 ],
 [ 7.778719, 1.765919,-0.127169],
 [-3.06347 , 0.519181,-1.75737 ],
 [-2.739554,-0.541553, 4.376453],
 [ 0.498736,-0.788893, 1.616933],
 [-1.577654,-2.805577,-2.99339 ],
 [ 2.654168,-2.567689,-0.36834 ],
 [ 1.551835, 4.601542, 0.908073],
 [-4.449442,-3.832713,-0.995114],
 [ 2.167477,-0.497706,-1.728302],
 [-5.878647, 1.804037, 2.285649],
 [-1.278333, 3.962721, 3.112568],
 [-2.478146, 0.281075, 0.515703],
 [ 0.332178,-2.764502,-5.365494],
 [-5.124622,-0.084734, 5.050578],
 [ 5.404792, 3.122369,-0.431786],
 [-4.049876,-2.319328,-2.290684],
 [ 5.787477,-1.266555, 2.033204],
 [ 2.096645,-0.570512,-1.761066],
 [-7.1891  ,-0.223682, 3.096655],
 [-7.048198,-0.230704, 3.066645],
 [ 3.794288,-1.828105,-3.692235],
 [ 4.997814, 1.362208, 0.938913],
 [-0.16359 ,-4.538588, 0.29311 ],
 [-5.879987,-1.791741, 1.268919],
 [ 1.65029 ,-3.633466,-4.7107  ],
 [-4.29968 ,-3.274009,-0.8519  ],
 [ 1.144679,-1.184464, 0.054632],
 [ 1.406958, 1.55453 , 2.974569],
 [ 0.464046, 1.534365, 3.13645 ],
 [ 1.571786,-2.658042, 0.345694],
 [ 0.799428,-3.305187,-1.628648],
 [ 4.02396 , 0.618723,-3.19975 ],
 [ 0.417893,-2.353615,-2.122923],
 [ 1.502282,-3.749271,-4.304671],
 [ 1.603023,-1.262673,-0.532259],
 [ 0.587438,-2.305429,-3.644291],
 [ 3.30526 ,-2.777639,-3.468726],
 [ 1.103408,-1.264644, 2.237436],
 [ 6.930522,-0.077465, 0.136321],
 [-1.454052,-0.057242,-1.247941],
 [ 4.890088,-0.87703 , 0.846103],
 [ 0.981002,-0.269864,-4.780152],
 [-0.121833,-1.626558,-5.761124],
 [-3.619525,-3.726444, 0.470447],
 [-0.396478,-3.002719,-5.518848],
 [-0.29333 , 0.577136, 1.231022],
 [-0.024645,-0.439393, 3.350361],
 [-0.198992,-0.313381, 3.636052],
 [-0.396669,-2.276332,-5.497662],
 [ 6.520347,-0.821661,-0.51171 ],
 [-4.596478,-3.071786, 0.049355],
 [-1.259668, 0.053591,-1.231987],
 [-5.400891,-0.602351,-1.73176 ],
 [-0.852772,-3.237731,-4.546198],
 [ 6.524231, 3.182874, 0.119276],
 [-5.719482,-1.677098, 0.652418],
 [ 3.438202, 1.006089,-1.404102],
 [ 2.899108,-1.342419, 0.356956],
 [-1.103287,-2.986767, 0.205007],
 [ 3.711594,-1.210157,-3.03509 ],
 [ 4.587991,-0.084624, 0.49508 ],
 [ 1.798337, 4.579091, 0.996311],
 [-0.611943, 1.951044,-2.136634],
 [-0.648469, 1.529828,-1.950806],
 [-5.334103, 0.880022,-1.23438 ],
 [ 3.291885,-0.65843 ,-0.515993],
 [ 4.206994,-1.55857 ,-0.848308],
 [ 0.493442,-3.997173,-5.570239],
 [ 2.324001,-0.94945 ,-3.65453 ],
 [ 3.678853,-2.130257,-3.895468],
 [ 3.112405,-2.222801,-3.118366],
 [ 3.203178, 0.219169,-2.615819],
 [-5.790151, 0.819863,-0.331216],
 [ 0.286237,-0.192439, 1.295945],
 [-1.941099,-3.271331,-3.483238],
 [-0.981564,-1.438351,-1.508315],
 [-1.534391,-5.757164,-0.210138],
 [ 2.551847, 0.629781,-4.690986],
 [ 2.4839  ,-1.141606,-1.680615],
 [-6.55328 , 0.81766 ,-0.350878],
 [ 1.7533  ,-2.356612,-2.771375],
 [ 4.157637,-2.509132,-3.105738],
 [-4.639606,-1.911586,-0.993204],
 [-0.966574,-5.732481,-0.284222],
 [-1.061648, 2.886441, 2.193273],
 [-2.888775,-1.755467,-4.563252],
 [-2.954958,-0.000144,-1.658095],
 [ 3.430903,-0.480807, 1.562874],
 [-0.51331 , 1.338278, 0.982692],
 [ 0.864309, 4.238362,-0.856501],
 [ 5.189599, 0.491244,-0.372126],
 [-5.77861 , 1.652252, 1.964926],
 [-2.508621,-0.433975, 6.385476],
 [-2.339603, 2.602095, 1.470417],
 [-4.966216,-0.717885,-0.047509],
 [ 7.389675, 0.160274,-0.154412],
 [ 1.670408,-0.984541,-3.601898],
 [-3.066457, 0.940157,-2.889848],
 [ 0.999387, 0.206574, 3.401229],
 [ 2.493127, 0.807397,-0.351135],
 [ 1.155121, 1.085059,-1.399726],
 [-1.05516 ,-1.829166,-0.511066],
 [ 1.446709, 4.808856, 0.813285],
 [ 1.990294,-2.968771,-0.151368],
 [ 5.241932,-0.074383,-4.037827],
 [ 1.677334,-1.019248,-3.572944],
 [-3.401027,-2.184388,-2.900074],
 [-5.363368,-0.145066, 1.340976],
 [ 3.051207, 0.554116,-0.114627],
 [-7.129934,-0.221307, 3.256042],
 [ 0.670108, 1.238697, 2.638274],
 [-4.009184,-0.632973,-2.875111],
 [ 4.219243, 4.203483,-0.163404],
 [ 0.728652,-3.702169,-3.648474],
 [-3.03252 ,-1.972751,-0.262946],
 [-3.228564, 3.192572, 2.742312],
 [-6.775347,-2.032463,-0.620486],
 [-3.801908,-0.291241, 0.398116],
 [-4.621802,-3.774178,-0.519742],
 [-3.946876,-1.008663, 0.486788],
 [-1.264178,-3.274059,-3.059517],
 [-1.068367,-3.29306 ,-3.489769],
 [ 4.350082,-0.062342, 2.959625],
 [ 5.170732, 4.683573, 1.46989 ],
 [ 5.325521, 0.370329,-2.322329],
 [ 3.854275,-0.679116,-4.02632 ],
 [ 3.741942,-0.599581,-4.076949],
 [ 0.021776,-0.830776, 5.252027],
 [ 4.112754,-2.929605,-4.201961],
 [ 4.049189,-1.271836,-3.602962],
 [-3.32618 ,-2.091829,-1.299604],
 [-1.787588,-1.915872, 1.110917],
 [-2.171156,-1.899676, 1.06695 ],
 [-2.445378,-1.796903, 1.306209],
 [-3.376575,-1.300133, 1.991201],
 [ 5.83257 , 2.641528, 1.497324],
 [-0.732555, 0.580037, 4.35988 ],
 [ 0.739488,-0.814904,-5.177058],
 [ 1.523644,-3.582943,-4.282085],
 [-4.427569,-0.722822, 2.574799],
 [-0.497787,-0.884884,-3.228274],
 [ 4.566332, 0.539387, 2.766139],
 [-6.572565, 0.477729, 1.749961],
 [ 5.228491, 0.519259,-0.532761],
 [ 2.402426,-1.9321  ,-0.998918],
 [-1.839482,-1.772069,-4.18167 ],
 [ 2.75079 ,-3.189753,-1.855264],
 [-2.494817,-3.910498,-0.597812],
 [-4.4056  ,-1.880533,-1.25354 ],
 [-0.722586, 4.42492 ,-1.233331],
 [-0.287039, 4.201456,-0.963656],
 [-0.711049, 4.460599,-1.26137 ],
 [ 4.586655,-0.86678 , 1.362456],
 [-0.679793, 4.422712,-1.220011],
 [-1.320843,-0.888763,-1.947574],
 [-1.470334, 4.275226,-0.911238],
 [-3.14522 , 3.384893, 2.728949],
 [-0.750042, 4.418894,-1.214901],
 [-2.736562, 0.112044,-1.467472],
 [-4.97861 ,-3.142815,-1.081433],
 [-7.165246,-0.185481, 3.234399],
 [-0.694367, 2.457817, 5.582673],
 [ 6.711542, 0.22378 ,-2.376565],
 [ 1.634964,-3.681623,-4.647342],
 [ 1.770879,-3.776494,-4.428682],
 [ 3.251367,-1.072649,-1.709613],
 [ 0.662846,-0.599302,-5.435399],
 [-0.660402,-0.959736, 1.302376],
 [-1.985667, 1.877462,-0.634027],
 [ 7.481052, 2.08472 ,-0.059243],
 [ 1.366992, 0.803796, 0.443968],
 [-1.86833 ,-1.781866,-4.247663],
 [-2.645959,-0.686083, 4.313804],
 [-0.236538,-5.199189,-1.855307],
 [-1.249321,-0.795998, 0.193159],
 [ 0.010134,-3.617019,-3.247426],
 [-2.593072, 2.85214 , 1.667473],
 [-5.254593,-0.0144  , 1.27323 ],
 [-3.277953, 0.436454,-1.968162],
 [ 0.531972,-4.152435,-5.587566],
 [-5.865614,-2.360563,-0.381094],
 [-0.366137, 0.074652,-2.879852],
 [ 1.637995,-3.643999,-4.619012],
 [ 6.631134, 0.294941,-0.072994],
 [ 3.079484, 3.309139,-0.38292 ],
 [-0.11298 ,-1.453419,-0.198023],
 [-0.20871 , 1.213901,-2.588387],
 [ 1.221826,-3.160986,-3.606305],
 [-0.788149, 1.083935,-1.897867],
 [-2.813411, 0.619768, 1.026034],
 [-3.171586,-1.666689,-4.309188],
 [ 0.900406,-0.099811, 4.241161],
 [-4.466125,-1.321395, 3.292205],
 [ 0.683912, 2.718359, 2.077153],
 [-0.929556,-1.312315, 0.21329 ],
 [ 5.910675, 0.268118,-1.019272],
 [ 0.496777,-3.107806,-3.909066],
 [ 4.015164,-3.0589  ,-3.497066],
 [ 5.494052, 0.381125,-0.665141],
 [-1.380524,-5.448072,-0.231288],
 [ 4.768484, 0.556871,-0.223392],
 [-3.356859,-2.196455,-2.970406],
 [ 0.62377 ,-2.617808,-0.97388 ],
 [-1.254198,-2.814643,-3.254565],
 [ 1.007043,-3.276095,-1.792268],
 [-3.168738, 0.084718, 4.844468],
 [ 3.298698,-0.595974, 2.248599],
 [-0.428941, 3.894112,-0.882799],
 [-0.063202, 4.827159, 3.826317],
 [ 5.904976, 1.783459,-0.17068 ],
 [-0.735499,-2.436842, 1.202356],
 [-5.432036,-0.788572, 0.569739],
 [-3.468204,-1.010994, 4.617504],
 [ 4.838076, 0.793486, 1.456189],
 [-3.628142,-0.400655, 0.861056],
 [ 1.565987,-2.716822, 0.565499],
 [-2.558841,-3.279777,-3.358114],
 [-6.486523,-0.05689 ,-1.012318],
 [ 0.345383,-3.58965 ,-2.919436],
 [ 0.81492 ,-4.643754,-1.800592],
 [-2.418946, 2.031341, 1.895201],
 [ 0.594863,-0.173806, 1.879151],
 [ 0.620973,-0.261005, 1.827004],
 [-2.959836, 0.149752, 5.251436],
 [-7.171277,-0.20727 , 3.110555],
 [-2.868741,-0.03287 , 0.072173],
 [-5.012626,-1.258138,-0.597345],
 [-5.213145,-0.831778,-0.021225],
 [-7.56398 ,-1.487358, 0.381468],
 [-0.42097 ,-3.057333,-3.612706],
 [-5.869192,-0.056957,-0.544326],
 [ 3.503244,-1.828879,-4.196316],
 [-0.444119,-0.753716,-3.121117],
 [-6.102695, 0.10337 ,-0.828025],
 [-1.45842 , 0.773442,-0.424759],
 [ 3.04728 , 0.600391, 0.864441],
 [-1.294076,-1.628016,-0.722355],
 [ 1.42944 ,-3.754067,-4.113969],
 [-0.206657,-3.065797,-5.574474],
 [ 1.538407,-1.179637,-3.602208],
 [ 4.171889, 0.595204,-3.075789],
 [-0.775861,-2.498502,-1.253518],
 [-3.775556, 0.478997,-0.392721],
 [ 0.859484,-0.055712, 4.321415],
 [-1.85576 ,-1.58208 ,-2.00205 ],
 [ 4.034753, 0.620796,-3.141477],
 [-5.697145,-0.462853, 0.390583],
 [ 3.447495,-0.516688,-1.5173  ],
 [ 4.259408, 0.666629,-2.117098],
 [-4.756875,-0.011172, 1.282322],
 [ 0.477768,-0.81728 ,-4.974736],
 [ 0.282035,-0.960205,-5.065903],
 [ 0.668926,-0.496706,-5.328153],
 [ 2.891465,-0.274494,-0.159431],
 [-5.537576, 1.312788,-0.605974],
 [ 4.145   ,-1.703631, 1.51407 ],
 [-1.020061,-3.25091 ,-3.674453],
 [-5.215244, 2.361404,-0.300397],
 [ 1.288206,-1.655562,-4.36687 ],
 [ 3.848057,-0.275712, 1.477452],
 [ 5.343351, 4.212108, 1.168377],
 [-3.933182, 1.826431,-1.60691 ],
 [-2.933615,-1.747014,-4.712584],
 [-0.159677,-0.470974, 3.022956],
 [-1.840456,-0.597565,-1.351124],
 [-1.059023,-1.238534, 0.403671],
 [ 0.852675,-2.648534,-0.195406],
 [ 1.490514,-3.624929,-4.437755],
 [ 3.391323, 0.659863,-1.052102],
 [ 1.705227,-3.699883,-4.360262],
 [-0.618083,-5.262521,-2.4485  ],
 [-5.471141,-0.506705, 0.458259],
 [ 2.361037,-1.781907,-4.486856],
 [-0.26991 ,-2.96204 ,-5.644897],
 [-1.794163, 0.238063,-3.076953],
 [-1.500626, 2.802778, 4.889119],
 [ 2.017176,-0.574262, 0.755292],
 [-3.103588, 0.35615 ,-1.824886],
 [-0.618676, 3.964859, 1.676416],
 [-3.960015,-0.877495, 1.96914 ],
 [-2.955368, 0.132352, 4.964431],
 [-2.960229, 0.126401, 5.254873],
 [-5.613561, 0.055007, 0.441249],
 [ 6.499838,-0.400569,-0.700438],
 [ 5.607726, 0.18664 ,-0.467125],
 [ 4.904219,-0.390229,-0.255029],
 [-3.788524,-1.933947, 1.43195 ],
 [-2.873382, 2.15999 , 4.437042],
 [-2.895719, 2.196793, 4.410028],
 [-5.138572,-0.10358 , 4.974449],
 [-5.423669,-0.20895 , 4.795253],
 [-2.936247, 0.404939, 2.528272],
 [-3.608622, 0.363763, 5.387878],
 [ 0.956167, 0.23038 ,-2.058906],
 [-4.425617,-1.875541,-1.075945],
 [-0.683211, 4.393406,-1.185193],
 [-0.674394, 4.396474,-1.193507],
 [-5.215972,-0.644231, 0.045216],
 [-0.642174, 1.446192,-1.921961],
 [ 4.242723, 1.017178,-0.049721],
 [-4.015717,-4.71434 ,-0.362028],
 [ 1.962756,-2.117225, 0.490267],
 [ 1.628539,-3.587225,-4.680804],
 [-2.669656,-1.95349 ,-4.798498],
 [ 1.640399,-3.673122,-4.620534],
 [ 1.825464,-3.616076,-4.689164],
 [ 5.235769,-0.082606,-4.060624],
 [-0.866939,-2.476493, 1.143023],
 [-1.679617,-1.509041,-0.337577],
 [ 1.795441,-1.514592,-5.214421],
 [ 6.836142,-0.320249,-3.055644],
 [ 5.919528,-0.59454 , 0.047622],
 [-1.117117,-1.734216,-0.578678],
 [ 6.692671, 0.276531,-2.373846],
 [ 1.337568,-3.597682,-4.183975],
 [-1.914055,-2.84588 ,-2.35901 ],
 [ 1.075335,-3.577161,-3.971849],
 [-1.377026,-1.022711,-1.251922],
 [-1.441888,-2.095219,-2.071579],
 [-0.565395, 0.34217 , 2.859327],
 [ 0.062694,-3.782223,-0.893502],
 [-0.259123,-3.46395 ,-1.447326],
 [-0.597555, 4.091814,-1.019062],
 [-0.377412,-0.271863, 3.77686 ],
 [-2.063164, 1.121451, 3.068134],
 [-0.653014,-0.119683, 0.43008 ],
 [-2.325357, 0.946442,-0.984679],
 [-1.952121,-0.079112, 1.937994],
 [-2.674671, 0.271364, 0.551488],
 [-2.329201, 0.958232,-0.961204],
 [ 4.701712, 4.889138, 0.686373],
 [ 0.827011,-2.758852, 0.251158],
 [-4.034862,-1.848964,-1.558275],
 [ 1.504786,-2.88607 , 0.165624],
 [ 2.997096,-1.537236,-4.400046],
 [ 3.384593, 2.923949,-1.09354 ],
 [ 0.076065,-0.988783,-5.163343],
 [ 4.136826,-1.210491, 2.465895],
 [-0.255511, 1.721134, 2.105823],
 [ 0.956001, 3.28111 , 2.630916],
 [ 0.84512 , 0.092588, 3.114838],
 [ 4.396084, 2.900426, 2.125715],
 [-2.096488, 0.284432, 0.115801],
 [-1.045882,-3.241266, 1.587017],
 [-5.517218, 1.523869,-0.449806],
 [-4.583478,-1.890649,-0.987458],
 [ 2.686571,-3.129388,-1.776266],
 [ 3.54251 ,-3.033552,-3.451962],
 [-0.847044, 0.792085,-2.067351],
 [ 1.296976,-1.44954 ,-3.754692],
 [-0.256942,-1.944987,-1.190464],
 [-0.005364,-1.025344,-2.911688],
 [ 0.339908, 3.0431  , 0.519337],
 [ 0.079236,-3.397638,-3.545553],
 [-4.457404,-1.890752,-1.065058],
 [ 1.883885,-0.584155, 1.615742],
 [-5.197985, 2.403088,-0.317338],
 [-1.101282,-0.964031,-3.921257],
 [-0.011977, 2.711563, 3.119678],
 [-0.698316, 1.493236, 2.237982],
 [ 1.607736,-0.834695,-4.269509],
 [ 3.946491,-1.497604,-3.799305],
 [ 3.543436, 0.92156 ,-0.588022],
 [-0.435741, 3.921125,-0.878142],
 [ 1.289447,-3.18447 ,-3.722479],
 [-2.945204, 0.936807, 0.948923],
 [-1.301889, 3.936672, 3.137378],
 [-0.151497,-3.110217,-5.655991],
 [-0.242876,-3.019093,-5.694721],
 [-5.550034,-0.560105, 0.386731],
 [ 2.865171, 0.682506, 0.179769],
 [ 3.954285,-2.714992,-3.789774],
 [-0.779357, 0.771998,-1.881795],
 [-1.212141,-1.189808, 0.174412],
 [-1.960039,-0.080031, 1.943756],
 [-2.776715, 0.17553 , 0.462687],
 [ 0.162839,-3.785757,-0.85459 ],
 [ 0.142534,-1.786876, 2.349325],
 [ 0.483206,-0.619166,-5.255477],
 [-0.360238,-3.729691,-3.92618 ],
 [ 3.722178,-1.61858 ,-4.318619],
 [-0.274713,-0.641954,-3.092586],
 [ 2.205276, 0.64146 , 2.110212],
 [-0.283302,-2.873486,-5.595452],
 [-2.730232,-1.601175,-4.488161],
 [ 5.9733  , 1.678062, 1.175906],
 [ 3.613864,-1.279299, 1.097992],
 [-1.965982,-3.327902,-3.102819],
 [-0.229265,-1.868195,-2.297061],
 [ 3.354168, 0.832943,-2.172719],
 [ 2.709635,-1.619364, 1.617804],
 [ 0.826662,-2.852788, 1.076097],
 [-1.360659,-3.869623,-3.279958],
 [ 0.960369,-3.930164,-0.946473],
 [ 1.828962,-1.975228,-3.549706],
 [-0.092339,-1.324948, 0.742957],
 [ 5.918375, 0.30375 ,-0.38802 ],
 [ 4.699148,-0.168509, 1.187001],
 [ 0.142445,-1.415781,-2.343097],
 [ 3.086579, 0.386533, 0.524297],
 [-3.119298,-1.638327,-4.298797],
 [ 2.680523, 3.904308, 0.011556],
 [ 0.76289 , 1.19387 , 0.183603],
 [-6.146606,-1.053739,-1.103127],
 [ 1.306213,-3.656599,-1.930857],
 [ 0.834004,-3.324847,-3.884088],
 [-4.517956, 1.875015, 1.952193],
 [-3.457873, 0.852296,-2.024686],
 [ 1.822221,-0.568201, 1.569269],
 [ 1.322181, 5.225186, 2.820138],
 [-3.149691, 1.0338  , 0.418229],
 [-3.373093,-1.543537,-3.508307],
 [-2.083556,-2.678674,-2.225047],
 [-0.7548  ,-0.802555, 1.118388],
 [-2.549986, 1.266793, 2.560483],
 [ 7.350632, 0.162379, 0.423129],
 [ 1.664661,-3.677351,-4.45001 ],
 [ 4.50399 ,-0.574931, 2.449207],
 [-0.692107,-1.532279, 1.299442],
 [-0.557678, 2.448178, 5.571989],
 [ 5.084439, 0.423611,-0.215099],
 [ 4.287124, 1.875676, 1.468617],
 [ 3.444931, 1.831463, 1.361978],
 [-5.519748,-0.532339, 0.400492],
 [-7.54258 ,-1.376427, 0.436448],
 [ 1.57586 ,-1.246406,-0.247718],
 [ 2.75403 ,-1.692155, 1.754481],
 [-4.047696, 0.735006, 6.213878],
 [ 1.441232,-1.080334,-4.577223],
 [ 1.510733,-1.159589,-3.512388],
 [ 4.183812, 0.196823,-0.059041],
 [ 0.66411 ,-0.500479,-5.483428],
 [-2.621453,-0.69054 , 2.178588],
 [-2.656216, 0.184162, 0.006697],
 [-0.161014, 3.903955, 4.358361],
 [-0.350696, 4.161833,-0.979709],
 [-0.597974, 2.340713, 1.964126],
 [-0.32085 , 3.094021, 3.399035],
 [ 1.237435,-3.196048,-3.729324],
 [ 0.200658,-1.314621, 0.059921],
 [ 1.104378, 1.452731, 0.660994],
 [-2.875394, 0.569912, 5.69457 ],
 [ 0.141611, 5.252002, 3.082135],
 [ 2.777952, 0.420941, 3.792411],
 [ 1.418875,-0.586824,-3.349087],
 [-3.942804,-2.09206 , 0.516852],
 [ 1.817749,-0.754735, 1.630015],
 [ 4.290864, 0.839352,-0.194988],
 [ 4.387803,-1.653913, 1.327271],
 [ 2.733011,-0.284157,-3.404925],
 [ 6.419111,-0.219744, 0.76816 ],
 [ 0.720342,-3.366057,-4.143602],
 [ 4.176168,-0.592185, 0.984223],
 [-4.058684, 1.562551, 1.937488],
 [ 3.266245, 0.503531, 3.666315],
 [ 0.015668,-1.615948, 2.402464],
 [ 1.373016, 2.532536, 2.176355],
 [-4.667387,-0.799827,-0.599307],
 [-4.428541,-0.721876,-0.571308],
 [ 0.953698, 1.54651 ,-3.948673],
 [ 2.307553,-0.763314,-1.364384],
 [-5.694949, 0.859778,-0.152058],
 [ 5.085308, 0.588904,-1.965171],
 [-1.008301,-3.350448,-3.53528 ],
 [ 1.507695,-3.20074 ,-3.821542],
 [ 0.096433, 0.917679,-3.012437],
 [-0.898593,-1.727115,-1.046641],
 [ 0.183417, 5.250192, 3.058872],
 [-2.755873, 0.470264,-0.264152],
 [-5.829331, 1.752703, 2.243161],
 [ 3.363339, 0.391367, 0.404048],
 [ 6.422348, 0.25805 ,-1.464814],
 [-3.939053,-1.853758, 1.375672],
 [ 0.616583,-0.538583,-5.452431],
 [-4.491109,-1.923737,-1.042424],
 [ 1.679811,-1.855911,-5.115158],
 [-2.921511, 0.031207, 5.273421],
 [-2.893831, 1.248392,-1.728767],
 [ 0.808263,-0.531982, 3.352282],
 [-0.124932,-1.2905  ,-5.270463],
 [-2.886545, 1.898217, 0.059536],
 [ 1.589382,-1.984743,-0.836959],
 [-3.687265, 0.331999, 5.341602],
 [ 2.188723,-3.202092,-3.579121],
 [-4.482411,-1.885358,-1.08059 ],
 [ 0.026909, 0.089504, 6.105063],
 [ 0.064362, 0.061276, 6.104073],
 [ 1.023709,-0.388426, 3.35576 ],
 [ 1.933078,-2.068035, 0.428176],
 [ 0.409615,-0.329367,-5.20968 ],
 [-6.075256,-0.175051, 2.451412],
 [-6.231315,-0.204333, 2.553542],
 [-0.757117,-3.16301 ,-3.464032],
 [-0.644556,-3.14198 ,-3.657832],
 [ 1.533368, 0.347158, 0.383261],
 [ 1.058488, 2.93327 , 2.808695],
 [ 1.212956,-2.567538, 0.247015],
 [ 1.287092,-2.776119,-0.322751],
 [ 1.672586,-3.568269,-4.707619],
 [-1.634563,-1.622029,-0.674109],
 [ 1.203852,-4.549939,-2.012017],
 [ 2.510053,-0.085753,-4.796555],
 [ 2.630103,-0.415432,-3.444931],
 [-0.321015,-2.713642,-3.17233 ],
 [-1.269136,-3.017973,-2.796845],
 [ 4.530194,-2.379075,-2.912311],
 [ 4.966624,-1.155897,-1.87535 ],
 [ 3.437785,-3.049995,-3.343868],
 [-2.117384,-2.155371,-0.472206],
 [-0.70317 , 2.108141,-2.121916],
 [-0.376023,-1.764343,-0.454202],
 [ 0.382514,-1.788978,-1.565729],
 [-2.051027, 1.030963, 5.947606],
 [-5.142971,-1.185324,-0.274239],
 [ 1.177619,-3.034594,-3.674495],
 [ 4.440053, 0.636354, 0.161592],
 [ 0.368561,-3.556371,-2.759329],
 [ 4.475818, 0.158419, 3.691811],
 [ 4.517162, 0.439391,-0.453333],
 [ 1.407351,-3.699018,-4.132555],
 [ 5.237211, 0.345915,-0.522788],
 [ 1.221875, 0.666207, 3.733968],
 [-4.269528,-2.375772,-2.018069],
 [-2.657211,-1.928264,-4.789916],
 [-3.195368,-3.394911,-0.481909],
 [ 3.496414,-0.909145, 0.370986],
 [ 2.407887,-2.240963,-4.186408],
 [ 0.66933 ,-2.31501 ,-3.679315],
 [ 0.587518,-1.113227,-5.212541],
 [ 1.000672, 1.147071,-1.498651],
 [ 2.444497, 1.314561,-3.416313],
 [-0.565707, 0.392232, 2.852093],
 [ 4.069419, 0.547689,-0.824554],
 [ 0.995199,-1.675894,-1.70466 ],
 [ 2.154319, 4.343358, 2.431965],
 [-3.950214, 1.491591, 2.199769],
 [ 1.379319,-3.043293,-3.897424],
 [-0.830404,-2.979181,-1.628352],
 [ 1.932924,-1.004422,-1.194944],
 [ 2.523128, 0.56486 ,-4.66902 ],
 [ 1.3943  ,-2.632667,-3.036761],
 [ 1.493828,-3.58506 ,-4.465826],
 [ 1.981811,-2.497826, 0.580264],
 [-0.366413, 1.958606,-1.964187],
 [-4.510392, 1.789129, 1.960519],
 [-0.073402,-1.209079, 0.634035],
 [ 6.30298 , 1.174115, 0.900453],
 [-0.375598,-0.064578, 6.814924],
 [-0.362501,-0.056657, 6.795121],
 [-0.378079,-0.058919, 6.806329],
 [ 3.328197, 1.556912,-0.599996],
 [ 3.64953 ,-2.415589,-4.29812 ],
 [-6.057671, 0.232555,-1.725368],
 [-2.866523, 2.124824, 4.454092],
 [ 1.999138,-2.463998, 0.85717 ],
 [-0.71447 ,-3.139465,-3.678422],
 [-5.463514, 0.05903 , 0.977636],
 [ 3.710282,-1.350894,-3.149634],
 [ 0.133175, 0.699977,-4.828713],
 [-4.574181, 1.89718 , 2.301469],
 [-3.249007,-0.280518, 5.793783],
 [ 4.167933, 0.781158, 0.350912],
 [-4.624948, 0.151248, 1.766087],
 [-3.797251, 0.838548, 1.186422],
 [-2.926163,-1.666285,-4.71911 ],
 [-2.907915,-1.647795,-4.531139],
 [-1.337439,-1.611774,-0.349267],
 [-0.464467, 0.101529,-1.903299],
 [ 0.272495,-3.66026 ,-2.829601],
 [ 4.461269, 0.12948 , 3.714842],
 [ 1.409372,-3.257331,-4.04639 ],
 [ 1.311904, 0.351292,-3.507054],
 [-1.532387, 0.093007, 2.14727 ],
 [-3.029508, 0.44459 ,-1.53965 ],
 [-3.191742, 0.590742,-1.739615],
 [ 0.201885, 5.230031, 3.048675],
 [ 5.968238, 3.04418 , 1.583835],
 [ 0.125601, 5.251944, 3.088975],
 [-0.696121, 2.011729,-2.079296],
 [-4.369383,-1.86032 ,-1.017732],
 [ 1.69535 ,-2.416759, 0.792735],
 [ 4.03581 ,-2.912067,-4.208148],
 [ 0.115376,-1.025932,-5.565623],
 [-2.718581,-0.146857,-0.147319],
 [-1.851415, 0.289012,-1.051697],
 [ 1.600525,-3.198763,-3.814598],
 [ 6.884095, 0.216629, 0.019662],
 [-6.907458,-0.193694, 3.383154],
 [-5.163352,-0.131365, 4.659907],
 [ 0.823918, 1.946819, 0.228771],
 [-5.140728,-0.104864, 4.984897],
 [ 0.855449,-2.654005, 0.396957],
 [-1.731882,-3.672284,-2.761445],
 [ 2.955145, 0.117321,-3.052572],
 [ 0.110246,-0.017022,-1.484425],
 [-5.429705,-0.622721, 0.31165 ],
 [-2.552682, 0.421313,-0.007317],
 [-6.025005, 0.241015,-1.749795],
 [ 0.842693,-2.596907, 0.492879],
 [-2.326951,-1.352279, 0.223977],
 [ 6.771064, 0.241668,-2.436026],
 [-2.887966,-1.726806,-4.710492],
 [ 5.646537, 4.73826 ,-0.271111],
 [ 3.043131, 0.683181, 0.591464],
 [ 3.658801,-2.759247,-3.7606  ],
 [-0.53843 , 4.160132, 1.771342],
 [ 6.515041, 1.688343,-0.046289],
 [-5.15234 ,-0.107568, 4.961562],
 [-1.476518,-1.275354,-1.205049],
 [ 2.005089,-1.026126,-1.500528],
 [-0.544015,-1.773519,-0.652446],
 [-4.221072, 2.226858,-0.414348],
 [ 6.583473, 2.583717, 0.071573],
 [-3.91905 ,-2.162264, 0.676399],
 [-1.136294,-1.675491,-1.111735],
 [-0.308095,-3.240134,-1.106074],
 [-0.636576, 2.434043, 5.576542],
 [ 1.572036,-2.741616, 0.532628],
 [ 3.890345,-0.54786 ,-3.872243],
 [-4.976311,-1.575911, 1.336903],
 [ 2.692853,-3.183212,-1.697644],
 [-2.17203 ,-3.08576 , 0.752159],
 [ 0.966414,-3.007016, 0.071029],
 [-5.282658,-0.469808, 0.485549],
 [ 4.192395, 1.597318, 1.243085],
 [ 4.872167, 4.684311, 0.917899],
 [ 2.834502, 0.440726, 3.848584],
 [ 3.945244, 0.692748, 1.078901],
 [ 0.929652, 0.485976,-1.946195],
 [ 3.136343,-0.868037, 1.986836],
 [ 3.737953, 0.682785,-0.035892],
 [-1.356938,-2.515073,-1.299835],
 [-0.318072,-1.522474,-0.070753],
 [ 6.733934, 0.228297,-2.391832],
 [-0.200379, 1.668185, 1.057834],
 [ 5.855741,-1.055307, 2.12567 ],
 [-7.467289,-1.355873, 0.397882],
 [ 1.490641,-0.690481,-3.405674],
 [ 3.917962,-2.794555,-4.106538],
 [ 3.584406,-1.284263, 1.016215],
 [ 2.295057, 0.323302,-0.772829],
 [-0.312935, 3.413926, 2.970777],
 [ 1.458771,-3.224598,-3.333862],
 [ 0.398326,-3.907386,-0.752462],
 [-0.512264,-1.154375, 0.737375],
 [ 2.236191,-2.148284,-1.475034],
 [-0.841645, 1.289464,-2.917207],
 [-2.964406,-1.695656,-4.738794],
 [-4.634617,-1.911876,-0.986868],
 [-0.598012, 2.40504 , 1.910008],
 [ 1.47388 , 4.768224, 0.808971],
 [ 6.489639,-0.893401,-0.15663 ],
 [-1.203972, 0.070629, 0.553618],
 [ 5.079939, 4.644431, 1.196284],
 [ 2.176399, 1.091332, 1.459327],
 [-5.563116,-0.552392, 0.441117],
 [ 0.891626,-1.688962,-1.620456],
 [ 1.665392,-3.566868,-4.383328],
 [-0.275864,-2.9852  ,-5.355525],
 [-0.387183,-2.274329,-5.492765],
 [-2.05874 , 1.314633, 0.809889],
 [-2.033751, 1.936672, 0.616575],
 [-1.119875,-3.388195,-3.784716],
 [-0.293449,-3.083814,-5.544036],
 [-0.3065  ,-2.72181 ,-6.425733],
 [ 1.303176,-0.590976,-3.389116],
 [ 0.288886, 0.163791,-1.344053],
 [-2.629996, 0.422052,-2.444762],
 [ 3.385076, 2.90535 ,-1.13888 ],
 [-3.6391  , 0.665983,-2.072563],
 [-3.521233,-2.757815,-0.522356],
 [ 1.486234,-2.573715, 0.503405],
 [ 0.008025, 1.01238 ,-2.077723],
 [ 5.365781, 0.244935, 0.156011],
 [ 1.752422, 1.156157,-3.406649],
 [-0.306364,-2.721747,-6.423013],
 [ 2.991022, 0.793528,-3.634863],
 [-3.005949,-0.560319, 3.932217],
 [ 0.346236,-2.55706 , 2.130862],
 [-0.504734, 2.91919 , 0.96986 ],
 [-0.510287, 4.149979, 1.742811],
 [ 1.349254,-2.549771, 0.151906],
 [-1.291861, 3.582183, 0.345619],
 [-1.054155, 1.77252 , 0.343579],
 [-3.721492, 2.22326 ,-2.015533],
 [-4.044823, 1.00407 , 0.279172],
 [-0.894036,-1.590343, 0.9205  ],
 [-7.329456,-0.199322, 3.155903],
 [ 5.30633 , 0.052776,-2.564333],
 [-0.045554,-1.254478, 0.692354],
 [ 0.785493,-0.47566 ,-2.478477],
 [ 0.572958, 0.843452, 0.837679],
 [-4.55607 ,-1.969388,-1.22128 ],
 [ 0.866801,-2.656201,-0.055498],
 [-0.408553, 0.923747,-2.268122],
 [-4.508093,-1.868341,-1.081446],
 [-0.683233, 4.39348 ,-1.185285],
 [-0.726476, 4.179594,-1.065956],
 [ 0.519165,-0.769664,-5.109208],
 [-0.392324,-2.431624,-1.892813],
 [ 1.645932, 0.573712, 0.816436],
 [-0.98646 ,-1.471161,-0.698524],
 [ 2.522135, 1.013372,-3.496911],
 [ 1.917935,-1.579155,-5.127393],
 [ 5.640097, 1.051645, 0.277094],
 [-3.390548,-2.645321, 1.08399 ],
 [-3.764853,-2.650891, 0.016641],
 [-3.299865,-2.624035, 1.007859],
 [ 2.489256, 0.052155, 0.293621],
 [ 3.64977 , 1.14387 , 0.252585],
 [-0.548688, 0.316796, 2.693269],
 [-0.685825, 0.414284, 2.879746],
 [ 0.987606,-0.285311, 3.340104],
 [ 5.171989, 1.711132, 2.235048],
 [-1.528421, 0.870795, 2.450198],
 [-0.231039,-3.087912,-5.709207],
 [-5.082356,-0.879414,-0.028558],
 [-1.32328 ,-3.989287,-3.396343],
 [-3.009062, 0.116211, 5.269517],
 [ 3.735888, 0.482544,-2.673438],
 [ 4.94366 ,-0.135941,-0.351052],
 [ 1.714745,-3.480691,-4.702211],
 [ 4.625729, 0.216946,-1.090819],
 [-3.600141, 2.231624,-2.01499 ],
 [-3.730521, 2.17075 ,-2.004937],
 [-3.743722, 2.200385,-2.002786],
 [-2.618143, 2.920617, 1.678685],
 [-5.498518,-0.348939, 0.461245],
 [-4.560274, 0.090819, 3.221057],
 [ 5.375621, 0.686287,-2.154639],
 [-2.591306,-0.88888 , 2.839573],
 [ 5.937386, 0.129715,-0.774873],
 [-3.456496, 0.662999,-2.204893],
 [-2.549268, 0.244631, 1.121731],
 [-0.205193, 3.892348, 4.347431],
 [ 1.536127,-1.004676,-3.440067],
 [ 0.101361, 0.516385,-4.912725],
 [-0.097796,-1.098026,-5.446662],
 [ 0.171673,-2.244961,-5.428311],
 [-0.453853,-2.997747,-4.979665],
 [-4.024551, 1.111435, 2.213277],
 [ 3.929351,-1.427866, 1.580999],
 [-3.069852, 0.451856,-0.006475],
 [ 2.556657,-2.51796 ,-4.000802],
 [ 0.389397,-0.73706 ,-1.47763 ],
 [ 3.513751, 1.272361, 1.557836],
 [ 0.715787,-0.385915,-0.349   ],
 [-1.488119,-1.09263 ,-1.360178],
 [-5.714698,-1.797379, 0.610675],
 [-0.678752,-3.072727,-3.610079],
 [-0.281122, 3.424521, 2.97783 ],
 [-0.0715  , 0.0639  , 0.909231],
 [ 0.638663, 2.058032, 4.177831],
 [-3.647719,-2.415352,-1.974072],
 [-2.84034 ,-1.629088,-4.599638],
 [ 4.02845 , 0.633469,-3.134727],
 [ 0.812479,-2.551846,-3.873809],
 [-2.825084,-0.148339, 5.417247],
 [ 0.735115,-0.777502,-5.08556 ],
 [ 1.176758,-1.104958,-4.232762],
 [-4.817597,-1.881538,-1.475716],
 [-3.765153, 0.272692, 5.371796],
 [-2.912378, 0.446286, 5.73858 ],
 [ 3.990197,-3.042959,-3.701082],
 [-2.389917,-2.799136, 0.491793],
 [ 2.832341,-0.076289,-3.655148],
 [-0.911509,-3.326274,-3.584063],
 [-1.091223,-3.536206,-3.132169],
 [-5.517415,-0.596891, 0.26359 ],
 [-4.919989,-1.799631,-0.799863],
 [-6.514266, 0.100386,-0.932053],
 [ 0.238685,-0.321793,-5.084584],
 [ 0.888032, 1.229993,-1.452353],
 [-1.452577,-2.785227,-2.842548],
 [ 0.337802,-2.654924,-2.126624],
 [ 0.634295,-2.385338,-0.062406],
 [ 2.201642, 1.078307,-1.057376],
 [-0.567893, 1.907264,-1.951985],
 [-0.698932, 2.075356,-2.222127],
 [-2.822298,-1.840679,-4.508176],
 [ 0.342191,-1.479045,-1.644721],
 [-4.938617,-1.884944,-0.557785],
 [-5.560217,-0.566403, 0.405226],
 [ 0.216207,-0.763042,-5.107094],
 [-4.703263,-0.622724,-0.229541],
 [-4.110248,-0.741069,-0.706797],
 [-3.63024 ,-1.878228, 1.43136 ],
 [ 2.235739, 2.847867,-0.01861 ],
 [-4.933965, 0.43125 ,-0.100062],
 [ 1.252406,-0.771005,-4.629916],
 [-0.488182, 4.03657 ,-1.013107],
 [-4.113707,-1.757507,-0.873858],
 [-0.683222, 4.393472,-1.185271],
 [ 0.328914, 2.656554, 0.183568],
 [ 0.922192,-2.712934,-4.214377],
 [ 2.769787,-3.246415,-1.710134],
 [-3.123485,-1.705988,-4.513174],
 [-3.601241,-1.757588, 1.511036],
 [ 1.460155,-3.505929,-4.084952],
 [-5.870139,-1.193768, 2.181429],
 [ 3.685966,-2.584117,-4.092721],
 [ 3.689744,-2.709518,-3.842238],
 [-0.308141,-3.075316,-5.501128],
 [-4.641835,-1.933956,-1.012516],
 [-3.103329, 0.014979, 0.013975],
 [-5.588531, 1.284613,-0.885434],
 [ 0.479608,-2.306527,-1.958693],
 [-0.671894, 1.9182  ,-2.079735],
 [-3.9644  ,-1.797106, 1.404621],
 [-2.161049, 0.956147, 5.944215],
 [-0.09799 , 3.114648, 3.359306],
 [ 1.09081 ,-3.583063,-3.963653],
 [ 0.796136,-2.465755, 0.079905],
 [ 0.906501,-0.066374,-1.666534],
 [-4.585626,-1.882422,-0.990275],
 [-2.789141,-1.559103, 0.472722],
 [-5.665331, 1.256054, 2.019727],
 [-3.292924,-1.009822,-0.027159],
 [-0.273612,-3.381523,-1.862532],
 [-0.720877, 4.419968, 3.890767],
 [ 1.416274,-0.133183,-3.460883],
 [ 1.29151 ,-2.672825, 0.370397],
 [ 6.387175, 1.36948 , 0.903653],
 [-2.947489, 2.559215,-0.264551],
 [ 0.181801,-3.792292,-4.271004],
 [ 1.478804, 2.426923,-0.201128],
 [-0.350239,-4.655511,-1.739996],
 [-5.952095, 1.21181 , 3.900437],
 [-0.729484, 0.56835 , 4.262465],
 [ 2.238662, 4.060495, 2.329368],
 [-3.842866, 1.412528, 2.226821],
 [ 1.509254,-4.134132,-2.168284],
 [ 2.399068, 2.336039,-1.28764 ],
 [-1.269119,-2.379364,-3.278417],
 [ 0.491005, 1.958555, 1.813354],
 [-3.572678, 1.097017,-0.202091],
 [ 0.143595,-0.702572, 0.06429 ],
 [ 1.529306,-1.231113,-0.511539],
 [ 3.444474, 0.285409,-2.384267],
 [ 6.647748, 0.218216,-2.392139],
 [ 3.657538,-2.972569,-3.791048],
 [ 1.502484,-4.101058,-1.998115],
 [ 0.871377,-3.288251,-3.64924 ],
 [ 3.634043,-2.637887,-3.961276],
 [-1.351438,-3.895419,-3.445957],
 [-0.521212, 2.965894, 1.068664],
 [-0.117479,-2.42276 ,-1.842158],
 [ 6.929944,-0.137919, 0.169673],
 [-0.257135, 0.29416 , 2.99207 ],
 [ 0.848684,-0.349979, 3.190595],
 [ 2.743546,-1.440784,-3.835974],
 [ 4.135859,-2.945958,-4.252467],
 [ 3.368861,-1.072139,-3.82472 ],
 [-7.523158,-1.221337, 0.267072],
 [ 1.532997,-2.725904, 0.501541],
 [-1.38067 ,-3.805378,-3.010765],
 [ 0.635307,-2.328817,-3.687032],
 [ 1.349973,-1.009547,-4.775509],
 [-0.149772,-2.93778 ,-5.667977],
 [-2.863914, 0.984857,-0.261595],
 [-1.959648, 1.055313, 5.765773],
 [-2.878386, 0.714011,-0.151829],
 [ 0.709222,-0.71636 ,-5.112717],
 [-4.431874,-1.861793,-1.118109],
 [-0.265423, 4.188711,-0.954194],
 [ 4.585511,-0.869846, 1.36308 ],
 [-1.333543,-0.891693,-1.915871],
 [-1.608202,-1.518157,-0.129633],
 [ 2.045182,-2.540204,-2.621629],
 [-4.403689,-1.868421,-1.205784],
 [-1.608178,-1.518232,-0.129797],
 [ 2.526782,-1.881996,-2.873376],
 [-4.437258,-1.848803,-1.064858],
 [-1.608404,-1.518369,-0.13015 ],
 [-2.1471  , 0.344474, 0.213836],
 [ 7.279582, 0.090916,-0.229125],
 [ 4.850284, 4.809728, 0.856866],
 [ 1.294214, 0.370892,-3.416931],
 [ 4.064612, 0.61741 ,-3.166575],
 [ 2.697716,-2.084348,-3.191838],
 [-1.426489,-1.651026,-1.633903],
 [-0.294765,-1.666184,-2.498237],
 [ 6.796351, 0.2327  ,-2.320639],
 [-0.349606,-3.681337,-1.40677 ],
 [-1.298232,-3.472281,-2.674073],
 [-4.885843,-3.162752,-1.26216 ],
 [ 1.319099, 1.969745,-3.498593],
 [-0.432346,-4.748924,-4.192357],
 [-0.758315,-2.597985,-0.709647],
 [-2.788637,-1.605204,-4.513824],
 [ 0.595667,-2.384779,-0.030268],
 [-0.433165,-3.927074,-4.072183],
 [ 2.958846,-3.295265,-1.868208],
 [ 1.956528,-0.663403,-0.02105 ],
 [ 5.277852, 0.328257,-2.136993],
 [-0.130912,-3.200071,-4.744972],
 [ 4.916127, 0.630456,-2.334677],
 [ 0.9858  , 0.792936,-1.826999],
 [ 4.618302, 0.787108,-0.524138],
 [ 1.090181, 0.840809,-1.913224],
 [ 0.171129,-3.112985,-5.722274],
 [-2.889451,-1.662232,-4.725231],
 [-0.421458,-4.790232,-4.17899 ],
 [-0.068665,-3.661377,-4.436961],
 [-0.839588, 0.75044 ,-1.97712 ],
 [ 0.777051,-3.326705,-2.822195],
 [ 5.733979, 0.609577,-1.633795],
 [ 0.281407,-2.320486, 0.383308],
 [ 6.505188,-0.708242,-0.539823],
 [-2.229092,-4.406244,-1.785404],
 [-4.418405,-0.078683,-1.408975],
 [-1.085483,-1.706316,-0.912959],
 [ 3.206552,-0.576514, 0.403264],
 [ 4.91137 , 0.632807,-2.32806 ],
 [ 3.797959,-2.174611,-3.671604],
 [ 1.741727,-1.631653,-5.178791],
 [-4.587383, 1.193145,-0.787944],
 [-4.119538,-2.713576,-0.204139],
 [ 0.204029,-0.229114,-0.172092],
 [-0.74987 ,-3.828666,-0.719293],
 [ 3.93628 ,-2.859092,-4.085082],
 [ 4.121379, 0.112329,-0.364035],
 [ 0.282016,-3.843935,-0.816854],
 [ 0.298208,-0.25167 ,-2.654587],
 [ 0.406659,-0.000493, 0.418037],
 [-4.527203,-1.778141,-1.005004],
 [ 2.889759,-2.442549,-3.81141 ],
 [ 1.672881,-1.764923,-3.674115],
 [ 1.620821,-1.592013,-3.713886],
 [ 1.75133 ,-2.174208,-2.086373],
 [-6.337457,-2.095989,-0.318021],
 [-4.467501, 0.037086, 1.396088],
 [-4.43943 ,-0.014825, 1.295411],
 [-2.27952 ,-1.108815,-0.455777],
 [-0.277933,-3.419312,-1.641196],
 [-0.693708, 1.509723,-1.95818 ],
 [-4.137041,-2.016971,-1.757727],
 [ 3.810413, 0.15321 ,-1.439223],
 [ 5.113277, 4.59275 , 1.140443],
 [ 1.370426,-0.086372,-3.262499],
 [ 4.073133, 0.615645,-3.16199 ],
 [ 1.546908,-1.270847,-2.596571],
 [-0.54167 , 1.861609,-2.285646],
 [ 1.522939,-0.550032,-2.235174],
 [ 1.712496,-1.216947,-3.660041],
 [ 1.312012,-2.114425,-1.14314 ],
 [ 0.286669,-0.27647 ,-2.651288],
 [-1.541493,-1.536616,-1.24365 ],
 [-0.691014,-1.82077 ,-0.842075],
 [-1.004775,-3.312278, 1.560011],
 [-1.988089, 0.935108,-1.000519],
 [ 1.908679, 4.380344,-0.42525 ],
 [ 2.415347, 0.7915  ,-4.234696],
 [ 1.360811, 2.461348, 2.631132],
 [ 2.378179, 1.720748, 0.972455],
 [ 1.585075,-2.029221,-5.18451 ],
 [ 3.311404,-3.101111,-3.255453],
 [-5.058317, 0.6973  ,-0.566666],
 [-0.563494, 2.5659  ,-2.124246],
 [ 1.006726, 1.550872,-3.927959],
 [ 1.773119,-1.179685,-5.160387],
 [-4.88532 ,-2.498533,-0.884383],
 [-2.972509,-0.457582,-2.636761],
 [-4.522802,-1.893587,-1.094837],
 [-1.3215  ,-0.893305,-1.941866],
 [-1.613064,-1.539886,-0.125206],
 [ 3.822698,-3.023938,-3.643883],
 [-3.977648,-1.716345,-1.14244 ],
 [-4.423393,-1.913313,-1.284501],
 [-0.591769, 4.300312,-1.136531],
 [-1.468969, 4.274799,-0.910605],
 [-3.146641, 3.385361, 2.730944],
 [-0.762577, 4.430639,-1.226052],
 [ 2.447237,-0.901046,-2.892323],
 [-0.86889 , 1.355704,-2.938651],
 [-5.91661 ,-0.639384, 0.52703 ],
 [ 3.382497,-2.776949,-3.63793 ],
 [ 0.016311,-2.795595,-0.462462],
 [ 0.401642,-3.683687,-0.832153],
 [ 0.179395,-3.658418,-0.813712],
 [ 0.649667,-4.088493,-5.669894],
 [ 0.041745,-2.953386, 0.215242],
 [-3.728571,-1.232937,-0.318156],
 [ 0.467706,-2.889131,-2.210615],
 [ 1.024066, 1.542178,-3.950979],
 [ 3.566285, 0.446669, 0.912089],
 [ 0.834021,-2.963136,-4.069962],
 [ 4.381851,-2.439191,-3.048011],
 [-0.995718,-1.689482,-1.568486],
 [ 2.389693,-2.366674,-2.236214],
 [ 1.165138,-0.783259, 0.963599],
 [ 2.651802,-3.09184 ,-1.867318],
 [ 1.226176,-0.702978,-4.673681],
 [ 1.847175,-1.763932,-4.99026 ],
 [-3.013194, 0.468867,-0.092341],
 [-3.597569,-0.150661,-0.114757],
 [-3.81982 ,-0.394014,-0.043712],
 [ 3.827688,-2.147399,-3.007411],
 [ 3.463731, 1.015441,-4.026967],
 [-2.199034,-4.905451,-1.080896],
 [-5.6067  , 0.072407, 1.306954],
 [-4.880614,-0.141444, 1.460278],
 [ 2.670804,-2.238583,-4.208924],
 [ 0.209901,-4.784826,-3.429861],
 [ 3.099071,-2.565515,-3.697018],
 [ 4.044694,-1.541773,-3.639558],
 [-0.765936,-2.560866,-0.436628],
 [-0.360825,-3.874454, 0.947422],
 [ 3.456506, 1.020272,-4.050169],
 [ 3.373024, 4.23804 , 1.380237],
 [ 3.470653, 1.019366,-3.98421 ],
 [ 1.992609,-2.480488,-3.001966],
 [ 1.777973,-1.639283,-5.17662 ],
 [ 0.791257,-0.470185, 3.069016],
 [-0.348372,-4.615041,-1.736292],
 [-2.329099, 0.378267,-0.188802],
 [ 3.121067, 1.090244, 1.318191],
 [ 1.419801,-4.850384,-4.518303],
 [ 3.449634, 1.010626,-4.059446],
 [ 3.463704, 1.01622 ,-4.020262],
 [ 2.908975, 0.302971,-3.190132],
 [ 6.735335, 0.141167, 0.378904],
 [ 3.210856,-0.609683, 1.810651],
 [ 2.34005 ,-2.434555,-0.126878],
 [-0.371152,-5.308024,-2.068459],
 [ 3.69614 ,-1.735447,-2.407002],
 [-1.861755, 1.748273,-0.671689],
 [ 1.538061,-4.000511,-4.236271],
 [ 2.622653, 0.357144,-0.525437],
 [ 0.93797 ,-0.654166,-4.903688],
 [-1.170033,-3.382521,-3.74753 ],
 [-0.182414,-3.593235,-2.458984],
 [ 2.523584,-0.883712,-5.10388 ],
 [-0.059072,-1.264946, 0.702713],
 [ 1.366236,-1.806209,-2.220704],
 [ 1.321211, 0.315521,-3.427718],
 [ 2.223589,-1.568224,-2.269721],
 [ 1.440147, 0.254128,-3.420371],
 [ 5.726836,-1.414213,-0.396668],
 [-0.090611,-3.65439 ,-3.192356],
 [-0.590659, 0.016664,-1.893697],
 [ 1.41328 ,-3.665503,-4.153257],
 [ 1.858854,-1.761008,-5.031366],
 [ 2.425949,-1.231755,-3.058105],
 [-4.590385,-1.936093,-1.01343 ],
 [-0.308935, 4.226833,-1.001709],
 [ 4.594157,-0.877872, 1.36683 ],
 [-1.334766,-0.887525,-1.899754],
 [-0.598857, 4.298378,-1.130176],
 [-0.689335, 4.441719,-1.229093],
 [-0.747763, 4.412677,-1.210056],
 [ 2.571278,-3.019903,-1.979933],
 [ 1.723751, 0.222259,-2.653703],
 [ 2.362485,-2.282951,-2.324692],
 [ 2.200184,-2.177354,-2.367076],
 [ 2.327805,-2.29676 ,-2.309786],
 [ 1.588327,-1.427511,-3.963845],
 [-4.415992,-1.907431,-1.698976],
 [ 1.489311,-3.606611,-4.534356],
 [ 1.5574  ,-3.61444 ,-4.760977],
 [ 6.055257, 2.102339, 0.226126],
 [ 5.098681, 4.608355, 1.128378],
 [-1.779349,-1.88928 ,-0.472699],
 [-0.947532,-1.470599,-0.361634],
 [ 0.742045, 0.000956, 3.077657],
 [ 0.954246,-3.073015,-3.985184],
 [ 3.474632, 1.011824,-3.933291],
 [ 1.85171 ,-1.79099 ,-4.985635],
 [ 0.473489,-3.496577,-2.757224],
 [ 7.776317, 1.656917,-0.137522],
 [ 2.518619, 0.659049, 0.172523],
 [ 1.386655,-0.724475, 0.969629],
 [ 0.074673,-1.828149,-4.895579],
 [-3.850607,-0.315489,-0.421041],
 [-4.418697,-1.894188,-1.262408],
 [ 4.212011, 0.559662,-3.020997],
 [ 1.428131,-3.805145,-4.122698],
 [ 1.533066,-3.531034,-4.332352],
 [ 1.490226,-3.657798,-4.215823],
 [ 2.466208,-1.396311,-1.85016 ],
 [ 2.524182,-0.675942,-5.114635],
 [ 0.232621,-4.825331,-3.413745],
 [ 3.491214,-1.877791,-4.133234],
 [ 7.863907, 0.832679,-0.596985],
 [ 1.232273, 0.636607,-2.242139],
 [ 3.380801, 0.988098,-2.192246],
 [-2.915199, 0.463682,-0.961559],
 [ 4.628196,-0.144464,-1.299619],
 [-2.745191,-3.198385,-3.473364],
 [ 4.352355,-0.521558,-0.100679],
 [ 0.141849, 0.906502,-3.141492],
 [ 2.047845, 1.161902,-1.401219],
 [ 3.988103, 0.616123,-3.031145],
 [ 5.183513, 0.401952,-0.138568],
 [-1.588134, 0.342168,-1.145171],
 [ 1.683754,-2.395492,-2.369414],
 [-7.298623,-0.215907, 3.115718],
 [-7.259016,-0.225635, 3.136878],
 [ 2.98608 ,-1.666934, 0.163868],
 [ 2.485923, 1.671321, 0.778062],
 [-1.13825 ,-3.20158 ,-3.527261],
 [-0.651641, 0.746074, 0.175311],
 [-0.840809,-5.220246,-2.639912],
 [ 1.240013,-2.253983,-0.77658 ],
 [ 5.264653, 4.579015, 1.19405 ],
 [ 7.866749, 0.988603,-0.484954],
 [ 1.408344,-2.673855,-2.774874],
 [ 0.920753,-0.603864,-4.88745 ],
 [ 5.354939,-0.874178,-1.068265],
 [-0.631996, 1.361846,-2.04154 ],
 [ 2.022768,-2.635267,-3.924175],
 [ 5.149412, 4.655459, 1.22814 ],
 [ 0.814885,-1.520506,-3.2076  ],
 [ 2.161941,-0.941781, 0.464827],
 [ 6.976542, 0.025185, 0.113849],
 [ 2.788826,-2.594422,-3.63449 ],
 [ 0.45629 ,-0.057798, 1.191284],
 [-0.861506,-0.598508, 2.144572],
 [ 4.96945 , 0.187456,-2.365867],
 [-4.162337,-1.392112,-1.118855],
 [ 2.428616, 3.980056,-0.445752],
 [ 0.072977,-2.921766,-5.410739],
 [ 1.421531,-2.429037,-1.060506],
 [ 1.123972,-0.544198,-2.807697],
 [-0.24991 ,-3.124612,-5.458201],
 [-1.271972,-3.185823, 0.788971],
 [-2.426199,-2.108184,-4.780354],
 [ 7.798892, 1.789621,-0.111419],
 [ 0.84624 ,-3.459142,-4.587458],
 [ 3.097743,-2.499684,-3.963603],
 [ 3.827315,-2.882933,-3.752622],
 [ 5.12393 , 4.568441, 1.140814],
 [ 1.733239,-2.084097,-4.916488],
 [ 0.329334,-3.345371,-3.717115],
 [-2.937809,-1.662831,-4.735097],
 [ 0.639569,-5.135896,-2.198293],
 [-4.481457,-1.872761,-1.132569],
 [-0.750042, 4.418894,-1.214901],
 [-0.276706,-3.021121,-5.387454],
 [ 0.818427,-2.800458,-2.253648],
 [-1.345154,-3.183331,-3.276968],
 [-0.201231, 1.502436,-2.645898],
 [-4.915011,-2.593506,-0.864053],
 [ 4.322842, 0.340077, 1.807547],
 [ 3.939486,-1.668563,-3.636175],
 [ 1.482172,-3.500074,-4.209984],
 [-1.081772,-3.313737,-3.700802],
 [ 0.75545 ,-0.666   ,-5.238374],
 [ 1.803681,-2.284732,-2.24121 ],
 [ 1.835818,-2.600872,-2.762021],
 [-4.492728,-1.732949, 0.122483],
 [-4.435719,-1.883039,-1.27457 ],
 [ 1.69376 ,-3.128538,-3.681079],
 [ 5.289076, 1.338786, 2.039372],
 [-0.043447,-1.740349,-2.332405],
 [-0.854814, 1.111015,-2.519257],
 [ 2.4548  , 0.244673, 0.065346],
 [ 7.799758, 1.782838,-0.117523],
 [-4.494814,-1.889875,-1.151968],
 [-0.606634, 4.202958,-1.010348],
 [-0.683972, 4.446739,-1.228104],
 [-1.036591,-3.302265,-3.466058],
 [-1.395593,-3.83784 ,-3.092986],
 [ 1.744843,-1.380399,-5.086447],
 [ 2.417377,-1.10752 ,-3.002079],
 [ 1.597581,-2.220123,-2.260822],
 [ 1.134335,-3.380683,-4.571255],
 [-0.29813 , 1.20406 ,-1.8758  ],
 [-2.696347,-1.679826,-4.431584],
 [ 3.090085,-2.308746,-4.318931],
 [ 0.504681,-3.346501,-2.875645],
 [ 5.836026,-1.145047, 2.045036],
 [ 3.806984,-2.903766,-3.682061],
 [-3.660411,-1.76329 ,-0.304834],
 [-4.513031,-1.675768,-1.181636],
 [-3.791986,-2.448963,-0.237069],
 [ 2.305076,-0.878174, 1.407387],
 [ 5.122189, 4.602176, 1.181213],
 [ 2.068718, 2.694229, 2.470646],
 [ 3.593536, 1.451888,-0.092614],
 [ 0.172547, 0.945963,-3.201233],
 [ 3.804342,-2.849683,-3.771167],
 [-1.274657,-2.984994,-3.295869],
 [-0.449314, 4.119946, 0.782518],
 [ 1.876632,-1.485298,-5.24258 ],
 [ 2.93302 , 0.316214,-0.025586],
 [ 1.616646,-2.079811,-5.116568],
 [-2.342874,-3.296562,-3.39154 ],
 [ 1.863989,-3.862298,-4.136935],
 [ 0.158995, 3.962238, 2.248863],
 [ 1.705915, 1.434638, 3.451354],
 [ 1.267236, 2.209055, 2.666876],
 [ 3.4346  , 1.00879 ,-4.082491],
 [ 0.099582,-3.363767,-2.02969 ],
 [-4.365359,-1.889966,-1.303971],
 [ 0.827842,-2.559559, 0.135413],
 [ 6.413299, 0.357823,-0.313684],
 [ 3.445599, 1.027888,-3.923071],
 [ 2.527707,-2.860832,-2.063963],
 [ 1.827813,-2.150396,-2.656482],
 [ 6.171426, 3.537112, 0.423239],
 [-0.445688, 1.374882,-1.951305],
 [ 2.655961,-3.129853,-1.837632],
 [-0.446045, 1.282725,-1.922119],
 [ 0.352997,-3.863783,-0.805993],
 [-4.791664, 0.226945, 0.637815],
 [ 3.907913, 1.305185,-0.086026],
 [ 1.331956, 0.208818,-3.495727],
 [ 2.093508,-2.206145,-1.777444],
 [ 1.938628, 4.404164,-0.424351],
 [-2.655702, 0.57899 ,-0.378149],
 [ 0.400774, 1.380953, 3.225833],
 [-2.937482,-0.428177,-2.651388],
 [-1.707131,-1.498463,-3.21177 ],
 [-4.162792,-1.437737,-0.990677],
 [ 2.379732, 1.789447, 0.825966],
 [ 4.370923,-0.034513, 3.19592 ],
 [ 5.42885 , 0.24006 , 0.10472 ],
 [ 2.683313,-2.502512,-2.871055],
 [ 3.630115, 1.659632, 0.1009  ],
 [-0.057127, 1.9027  , 3.596199],
 [ 2.854229, 0.353522,-3.050631],
 [ 3.339798, 1.946531, 1.101694],
 [-1.765447, 0.281857,-3.037696],
 [ 2.663669,-2.486138,-2.848457],
 [ 1.101727,-3.026642,-2.894627],
 [-1.292844, 2.059325, 6.344211],
 [ 1.316041,-2.653965, 0.429242],
 [-4.03759 ,-2.072608,-0.597735],
 [ 4.652853, 1.881018,-0.502913],
 [ 0.33711 ,-3.453465,-3.771488],
 [ 2.806336, 1.153935,-2.124682],
 [ 1.649913,-3.657332,-4.582949],
 [ 2.502861, 1.765324, 0.492038],
 [ 5.332508, 0.05496 , 0.495064],
 [-4.293597,-1.789836,-1.027636],
 [ 0.413437, 1.438577, 3.222453],
 [ 0.423935,-0.83251 ,-4.867974],
 [ 5.412692, 0.353149,-2.451128],
 [ 1.380002,-0.59862 ,-3.005745],
 [ 1.391421, 0.152524,-3.457441],
 [ 0.156806,-3.775533,-0.829405],
 [ 5.682341, 2.132448, 0.195176],
 [-0.523183, 1.888184,-2.103908],
 [ 4.818868, 0.351398,-0.77291 ],
 [ 1.295597,-0.14188 ,-3.225422],
 [ 1.785235,-3.780989,-4.395324],
 [ 4.927065, 0.624672,-2.325857],
 [ 0.333404, 0.700877,-3.837634],
 [ 1.657181,-2.343964,-2.682241],
 [ 1.061114,-0.409132, 3.215747],
 [ 4.857016, 4.794619, 0.876254],
 [ 0.431388,-0.36831 , 4.578835],
 [ 0.460253,-0.593419, 4.545282],
 [ 3.973324,-2.54318 ,-3.49279 ],
 [ 2.429593, 3.967321,-0.432181],
 [-6.121814, 1.590559, 2.37457 ],
 [ 0.552026,-3.706853,-2.765357],
 [ 0.091515,-1.86781 , 5.662315],
 [-1.208597, 0.473327,-1.855086],
 [ 3.515745,-1.741673,-0.239572],
 [ 3.56531 ,-2.451021,-4.134503],
 [ 3.887758,-3.348806,-3.476573],
 [ 5.152837, 0.535369,-2.242825],
 [ 3.32008 , 0.515122,-2.661456],
 [ 2.040669, 0.463657,-0.919107],
 [ 3.795982, 0.166905,-4.494603],
 [ 6.513287, 0.436641,-3.113305],
 [-4.552806,-1.826454,-1.067845],
 [ 2.740871,-2.344423,-3.781064],
 [ 1.752039,-1.653046,-3.753676],
 [ 1.579477,-1.527971,-3.734616],
 [ 1.01438 ,-3.059757,-2.790997],
 [ 0.849924,-1.915085,-2.488955],
 [-0.583335, 4.304489,-1.154448],
 [ 1.095357,-2.928418,-2.790078],
 [ 3.929116,-0.589066,-4.030581],
 [ 3.897002,-3.283858,-3.502183],
 [-0.327127, 0.773947, 0.758327],
 [ 6.489113, 0.221074,-1.670492],
 [ 1.599844,-3.608826,-4.22369 ],
 [-3.83019 ,-0.388589,-1.417128],
 [ 1.348697, 0.369795,-3.543067],
 [-1.81226 ,-4.034038,-2.595016],
 [ 1.164618,-0.212914,-3.197363],
 [ 1.316449, 0.533088,-3.597383],
 [ 0.183169,-2.091642, 0.261896],
 [ 0.401156, 0.676259,-3.652002],
 [ 3.529986,-1.210526,-2.49177 ],
 [-0.475903,-0.850999,-3.239444],
 [-0.689843, 2.38047 ,-1.807378],
 [-0.489609,-0.885766,-3.2685  ],
 [ 2.444638, 3.980286,-0.513259],
 [-0.674164, 0.198433,-1.524844],
 [-2.679754,-0.429527, 3.710109],
 [ 1.936101,-2.418008,-2.180112],
 [ 2.332126,-0.150966,-4.81149 ],
 [ 0.017785,-1.271118,-4.102911],
 [-1.174326,-1.741261,-0.824126],
 [-1.727242, 0.315028,-1.123518],
 [ 4.503458, 4.996904, 0.389652],
 [ 1.102128,-2.884659,-2.783584],
 [ 3.695153,-2.519153,-4.07197 ],
 [ 0.603217,-0.787021, 4.555874],
 [-0.788308,-5.239797,-2.629729],
 [ 1.734324,-2.091215,-3.485953],
 [ 6.436682, 0.275021,-1.526229],
 [ 1.385204, 0.010545,-3.432283],
 [ 0.346426, 0.045631, 0.188137],
 [ 5.12612 , 0.017917,-2.348811],
 [-0.493809, 1.21527 ,-2.037378],
 [-4.051628,-2.105071,-2.18498 ],
 [ 2.730893,-2.568911,-3.585001],
 [-1.201736,-5.136086,-2.687958],
 [-0.92985 ,-2.911877,-3.965057],
 [ 0.092706,-1.868312, 5.660839],
 [ 1.29225 ,-3.58476 ,-2.107007],
 [ 1.472771,-3.877406,-2.157592],
 [ 1.495978,-0.891352,-3.272847],
 [ 3.343516,-2.283957,-4.235944],
 [ 3.369268,-2.301004,-4.214433],
 [ 0.312522,-0.368195, 4.580441],
 [-4.228631,-1.85965 ,-1.483088],
 [-0.721578, 4.090335,-0.95239 ],
 [ 4.499322, 0.701862,-1.529598],
 [ 0.24436 ,-0.605368, 4.19503 ],
 [ 3.494288,-2.526604,-4.057053],
 [ 3.328152,-2.420589,-4.183326],
 [ 2.345803, 0.771775, 0.035586],
 [ 4.064469, 0.597945,-2.976204],
 [ 0.27754 ,-0.584846, 4.226654],
 [-0.339021, 0.633237,-0.964523],
 [ 1.678441,-0.620681,-3.578596],
 [-4.374599,-0.49104 , 0.236248],
 [ 3.378413,-2.264506,-4.267925],
 [ 0.999174,-0.70974 ,-4.8974  ],
 [ 0.753383,-1.21214 , 4.584691],
 [ 1.510271,-0.916075,-3.269845],
 [ 2.740472, 1.935687,-1.192478],
 [ 3.499539,-2.315051,-4.241773],
 [-0.099127,-0.240615, 4.387304],
 [-0.723796,-5.214824,-2.57266 ],
 [ 0.425595,-0.543172, 4.559483],
 [ 1.809592,-2.676399,-3.283593],
 [ 0.371656,-0.535891, 4.440364],
 [ 3.560196,-2.742517,-3.577805],
 [ 3.62357 ,-2.465245,-4.231194],
 [ 3.440361,-2.565287,-4.187733],
 [-0.512242, 1.503142,-2.127023],
 [ 3.625196,-2.507509,-4.051572],
 [-0.736804,-5.257505,-2.602899],
 [ 2.075665, 0.31204 , 0.990887],
 [ 0.777758,-2.71291 ,-0.750062],
 [ 2.062454,-4.582999,-1.205551],
 [ 3.713874,-2.370122,-4.185343],
 [-4.923401,-3.204066,-1.317348],
 [-0.142542,-3.368763,-1.344168],
 [ 3.302598,-2.44669 ,-4.326304],
 [-1.553945,-1.406758,-1.185507],
 [ 3.696233,-2.552183,-4.012651],
 [ 0.233863,-3.188317,-5.744049],
 [ 2.721771,-2.536594,-3.585548],
 [ 1.624992,-0.202536,-2.856985],
 [ 1.966333, 0.032327,-2.584655],
 [-0.07535 ,-2.872452,-5.392154],
 [ 1.324282, 2.502584, 2.733871],
 [ 1.472459,-0.21773 , 0.455547],
 [ 1.502405, 0.008142,-3.234583],
 [ 3.849203,-3.303706,-3.474528],
 [-4.659285, 0.017712, 2.10575 ],
 [-3.696325,-2.925409,-1.708209],
 [-0.085302,-1.43213 ,-5.501102],
 [ 0.728814,-0.616922,-5.005855],
 [ 5.367034, 0.668334,-2.145344],
 [ 2.13314 ,-1.003786, 3.801188],
 [-0.693174,-5.245543,-2.500232],
 [ 4.031603,-2.751107,-4.116699],
 [ 3.977013, 3.065036, 1.643535],
 [ 4.027534, 3.121821, 1.65963 ],
 [-2.604603, 2.95243 ,-1.901187],
 [-1.130856,-3.211545,-3.490842],
 [-4.726608,-0.283655, 2.059823],
 [ 6.730619, 0.229511,-2.38548 ],
 [ 0.808254, 3.621674, 4.955026],
 [-7.053479,-0.187008, 2.939347],
 [ 4.112285,-2.854138,-4.220637],
 [ 2.041989,-4.544703,-1.253772],
 [ 2.061519,-4.587055,-1.210402],
 [ 3.689333, 0.198038,-0.470747],
 [-0.733733, 4.331218,-1.169878],
 [-3.166431, 3.324429, 2.750979],
 [-2.507675, 0.374768,-0.261859],
 [-0.808828,-5.235014,-2.631488],
 [ 3.722457,-2.996208,-3.729395],
 [ 3.524342,-2.472376,-3.793667],
 [ 2.251631,-2.439697,-2.983374],
 [ 0.049143, 6.267357, 1.67095 ],
 [ 0.852749, 0.513387, 3.29085 ],
 [ 0.695671,-2.625239,-0.384272],
 [-1.286159, 0.859228,-2.437052],
 [ 3.801576,-2.802083,-4.041262],
 [ 5.304363,-0.2339  ,-1.06491 ],
 [-0.563748, 4.24798 ,-1.146862],
 [ 5.442574, 4.045351, 1.11911 ],
 [ 0.039886,-0.961297,-1.527874],
 [ 2.067885, 2.825016, 0.322706],
 [ 5.771207,-1.260411, 2.086103],
 [ 5.528001, 0.266634,-0.615585],
 [-0.212101,-0.668449,-3.273448],
 [ 1.443522,-0.301696,-3.304371],
 [ 3.81369 ,-3.199931,-3.548109],
 [ 1.465171,-4.000152,-2.108055],
 [ 0.467778,-0.627081, 4.517582],
 [-0.001049,-1.048519, 2.896994],
 [-0.000637,-1.100919, 2.770775],
 [ 0.15901 , 1.926952, 1.345845],
 [ 0.001174,-1.106109, 2.858261],
 [ 1.23578 , 1.216822, 1.6889  ],
 [ 1.247021, 1.196389, 1.734928],
 [ 0.386214, 4.686663,-1.000733],
 [ 0.452101, 4.709075,-1.003887],
 [-1.847591, 0.344802,-1.067662],
 [ 6.570822,-0.139435,-0.703036],
 [ 1.423912,-0.881203,-3.149265],
 [ 2.358855,-0.833773,-1.625682],
 [-1.135621,-5.14858 ,-2.695753],
 [ 3.730029,-2.106283, 0.193933],
 [-1.348356, 3.669381, 0.644185],
 [-1.294383, 3.769631, 0.604872],
 [ 1.172334,-2.235432,-2.720866],
 [ 0.360928,-2.741699,-2.143909],
 [-3.665349, 0.455913,-0.458227],
 [ 1.272544,-3.654356,-1.579705],
 [ 0.620501,-2.585479, 0.88931 ],
 [ 1.076542,-3.030514,-2.762825],
 [ 2.0324  , 1.092498,-0.969265],
 [ 2.696634, 0.835875,-0.159555],
 [ 5.362968, 0.280217,-1.710072],
 [ 1.478643,-0.443528,-3.259016],
 [ 1.139971, 0.585182,-1.946896],
 [ 3.874128,-2.816937,-3.602638],
 [ 1.148561,-2.875123,-2.78004 ],
 [ 1.898417,-3.040747,-3.771896],
 [ 0.796488, 1.269276, 1.507188],
 [-4.322198, 1.811736, 2.950649],
 [-0.130084,-3.178747,-5.494063],
 [ 3.893274,-2.677144,-3.543644],
 [ 1.467796,-2.937805,-3.27205 ],
 [ 3.622469,-3.341022,-3.285951],
 [-0.452867,-0.874469,-3.249042],
 [ 3.873363,-3.252214,-3.527646],
 [ 5.067857, 4.62663 , 1.112778],
 [-4.767631, 0.000771, 2.447249],
 [ 2.701335, 0.116087, 0.546984],
 [ 1.442012, 0.335967, 1.782068],
 [ 3.694314,-3.191004,-3.449715],
 [-3.163958, 3.342813, 2.702605],
 [ 1.400107,-2.567321,-2.731826],
 [ 0.582846, 0.328138, 3.061395],
 [ 4.814841,-2.19482 ,-2.506484],
 [ 3.916482,-3.099297,-3.654571],
 [ 0.746429,-2.672308, 0.632613],
 [ 3.048439, 1.248453,-1.192827],
 [ 1.442997,-3.774541,-2.057309],
 [-0.582179, 2.02961 ,-2.229693],
 [-4.688622, 1.294552, 4.138566],
 [-0.74039 , 4.329618,-1.177082],
 [ 4.969016, 1.067785, 1.494689],
 [-5.346834,-0.507381, 0.420019],
 [-4.369873,-1.790198,-1.016813],
 [ 2.890133, 0.345499,-3.232998],
 [ 5.078306, 4.478015, 1.199697],
 [-1.159057, 1.024038,-2.863788],
 [ 0.845049, 0.505552, 3.283359],
 [ 0.716296, 0.175704, 3.498003],
 [ 6.43391 ,-0.226376, 0.825096],
 [ 1.403361,-0.475473,-3.227293],
 [ 3.730155,-3.072343,-3.580471],
 [ 5.758406,-1.302029, 2.032715],
 [ 1.650952,-3.631613,-4.285992],
 [ 3.875332, 0.653701,-2.228739],
 [ 5.075684, 4.505941, 1.085244],
 [ 6.381874, 1.537917, 0.771728],
 [ 0.108726,-1.635954,-3.863475],
 [-0.693031, 2.216699,-2.102641],
 [ 1.435979,-3.257797,-1.6822  ],
 [ 3.793012,-3.337606,-3.454619],
 [ 6.763738, 0.240299,-1.932959],
 [ 0.661567,-1.445421, 3.020309],
 [ 6.576986,-0.917286,-0.459152],
 [ 3.897735,-3.330283,-3.505701],
 [ 0.501093,-3.572433,-2.722789],
 [-4.718063, 0.032632, 2.281067],
 [-0.057814, 0.490692, 0.623659],
 [-4.602348,-0.285099, 2.356187],
 [-4.940103,-0.183555, 0.907495],
 [ 2.460963,-0.668895,-3.522031],
 [ 7.084939,-0.568041,-0.093786],
 [ 0.631543,-5.157449,-2.185619],
 [ 0.492962,-3.707384,-2.727422],
 [-0.812373,-5.234338,-2.633095],
 [ 1.503662,-4.095023,-2.063799],
 [ 3.763335,-3.159732,-3.611227],
 [-0.496663,-0.821204,-3.251743],
 [ 2.652925, 1.462544,-1.847321],
 [ 3.826291,-3.425267,-3.385683],
 [-0.833569, 0.79348 ,-2.068899],
 [-2.469339, 0.027375, 1.893687],
 [ 0.785687,-2.997592,-2.313541],
 [ 0.947435, 1.295089, 0.802431],
 [ 3.802225, 0.160798,-4.504746],
 [-0.775511,-5.241686,-2.606268],
 [ 0.413517, 1.438566, 3.222692],
 [-4.239338, 1.190032, 2.527711],
 [ 0.69314 ,-1.46186 , 3.011791],
 [ 4.530487, 3.955224,-0.29728 ],
 [ 0.384365,-4.387969, 0.071963],
 [ 1.345105,-3.655156,-2.00847 ],
 [ 1.208851,-3.382113,-3.830501],
 [ 1.625616,-3.625948,-4.580693],
 [ 4.585061, 0.603373, 0.434278],
 [ 5.566037,-0.666986,-1.338635],
 [ 0.315603,-3.521685,-3.84168 ],
 [ 1.791431,-3.652201,-4.307938],
 [ 2.963642,-0.527175,-3.41976 ],
 [ 3.049493, 0.309514,-2.732376],
 [ 5.43555 , 0.49875 ,-0.179973],
 [ 3.424786, 0.594165,-2.777595],
 [ 3.408648, 0.606153,-2.764189],
 [ 5.111328, 4.49059 , 1.160502],
 [ 0.882993,-3.17719 ,-2.769941],
 [ 3.02702 ,-2.256646,-4.451062],
 [ 1.698217,-3.708853,-4.348183],
 [ 3.706428,-2.647769,-3.989574],
 [-1.467007,-0.247632, 3.881512],
 [ 1.014133, 1.422212, 0.813686],
 [-2.675877, 0.481296,-0.300797],
 [ 1.637143,-3.292276,-4.211688],
 [ 1.288512,-0.384466,-3.02545 ],
 [ 1.504535,-3.591418,-4.503264],
 [-3.856429, 1.140872, 1.55329 ],
 [ 1.13955 ,-1.554005,-3.563798]]
