import React, { useState } from "react";

const Description1 = () => (
    <div>
        <h2>Q's BRAIN (AI Technology)</h2>
        <p> Discover the intelligence behind Quartermaster. Our AI combines advanced neural networks, natural language processing, and machine learning to create an adaptive system that learns and evolves. Built with cutting-edge technology and rigorous safety protocols, Q's brain represents the future of AI assistance.</p>
    </div>
)

const Description2 = () => (
    <div>
        <h2>Description 2</h2>
        <p>This is the second description.</p>
    </div>
);

const Description3 = () => (
    <div>
        <h2>Description 3</h2>
        <p>This is the third description.</p>
    </div>
);

const SwitchableDescriptor = () => {
    const [statePointer, setStatePointer] = useState(1);

    return (
        <div>
            <div>
                <button onClick={() => setStatePointer(1)}>Q's BRAIN (AI Technology)</button>
                <button onClick={() => setStatePointer(2)}>Description 2</button>
                <button onClick={() => setStatePointer(3)}>Description 3</button>
            </div>

            {statePointer === 1 && <Description1 />}
            {statePointer === 2 && <Description2 />}
            {statePointer === 3 && <Description3 />} 
        </div>
    )
}

export default SwitchableDescriptor;