import React from "react";
import SwitchableDescriptor from "../../components/SwichableDesc";

const Examples = ( ) => {

    return (
        <div>
            <h1>Examples</h1>

            <SwitchableDescriptor />
        </div>
    );
};

export default Examples;