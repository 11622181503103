// components/Buttons/index.jsx
import React, { useContext } from 'react';
import { ThemeContext } from '../../ThemeContext';
import './button.scss';

const Button = ({ children, onClick, className, ...props }) => {
  const theme = useContext(ThemeContext);

  return (
    <button
      className={`custom-button button text ${className}`} // Added 'button' for background and 'text' for color
      onClick={onClick}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
