import React from 'react';
import './PriceTable.scss';

const PriceTable = () => {
  const pricingData = [
    {
      provider: 'GPT-4o',
      models: [
        {
          name: 'gpt-4o',
          inputTokens: 128000,
          outputTokens: 4096,
          inputPrice: 2500,
          outputPrice: 10000,
        },
        {
          name: 'gpt-4o-mini',
          inputTokens: 128000,
          outputTokens: 4096,
          inputPrice: 150,
          outputPrice: 600,
        },
      ],
    },
    {
      provider: 'OpenAI o1',
      models: [
        {
          name: 'o1-preview',
          inputTokens: 128000,
          outputTokens: 4096,
          inputPrice: 15000,
          outputPrice: 60000,
        },
      ],
    },
    {
      provider: 'Claude 3',
      models: [
        {
          name: 'Haiku',
          inputTokens: 200000,
          outputTokens: 4096,
          inputPrice: 250,
          outputPrice: 1250,
        },
        {
          name: 'Sonnet',
          inputTokens: 200000,
          outputTokens: 4096,
          inputPrice: 3000,
          outputPrice: 15000,
        },
        {
          name: 'Opus',
          inputTokens: 200000,
          outputTokens: 4096,
          inputPrice: 15000,
          outputPrice: 75000,
        },
      ],
    },
    {
      provider: 'Claude 3.5',
      models: [
        {
          name: 'Haiku',
          inputTokens: 200000,
          outputTokens: 8192,
          inputPrice: 800,
          outputPrice: 4000,
        },
        {
          name: 'Sonnet',
          inputTokens: 200000,
          outputTokens: 8192,
          inputPrice: 3000,
          outputPrice: 15000,
        },
      ],
    },
    {
      provider: 'Llama',
      models: [
        {
          name: 'Llama 3.2 1B (Preview)',
          inputTokens: 128000,
          outputTokens: 8192,
          inputPrice: 40,
          outputPrice: 40,
        },
        {
          name: 'Llama 3.2 3B (Preview)',
          inputTokens: 128000,
          outputTokens: 8192,
          inputPrice: 60,
          outputPrice: 60,
        },
        {
          name: 'Llama 3.1 70B Versatile',
          inputTokens: 128000,
          outputTokens: 32768,
          inputPrice: 590,
          outputPrice: 790,
        },
        {
          name: 'Llama 3.1 8B Instant',
          inputTokens: 128000,
          outputTokens: 8192,
          inputPrice: 50,
          outputPrice: 80,
        },
        {
          name: 'Llama 3 70B',
          inputTokens: 8192,
          outputTokens: 4096,
          inputPrice: 590,
          outputPrice: 790,
        },
        {
          name: 'Llama 3 8B',
          inputTokens: 8192,
          outputTokens: 4096,
          inputPrice: 50,
          outputPrice: 80,
        },
      ],
    },
    {
      provider: 'Google',
      models: [
        {
          name: 'Gemma 7B 8k Instruct',
          inputTokens: 8192,
          outputTokens: 8192,
          inputPrice: 70,
          outputPrice: 70,
        },
        {
          name: 'Gemma 2 9B 8k',
          inputTokens: 8192,
          outputTokens: 8192,
          inputPrice: 200,
          outputPrice: 200,
        },
      ],
    },
    {
      provider: 'Mistral AI',
      models: [
        {
          name: 'Mixtral 8x7B Instruct',
          inputTokens: 32768,
          outputTokens: 32768,
          inputPrice: 240,
          outputPrice: 240,
        },
      ],
    },
  ];

  const formatNumber = (num) => {
    return num.toLocaleString();
  };

  // const formatPrice = (price) => {
  //   return `$${price.toFixed(0)}`;
  // };

  return (
    <div className="price-table-container">
      <table className="price-table">
        <thead>
          <tr>
            <th>Provider</th>
            <th>Model</th>
            <th>Input Tokens</th>
            <th>Output Tokens</th>
            <th>#Credits per 1M Input</th>
            <th>#Credits per 1M Output</th>
          </tr>
        </thead>
        <tbody>
          {pricingData.map((provider, providerIndex) =>
            provider.models.map((model, modelIndex) => (
              <tr key={`${providerIndex}-${modelIndex}`}>
                {modelIndex === 0 && (
                  <td
                    rowSpan={provider.models.length}
                    className="provider-cell"
                  >
                    {provider.provider}
                  </td>
                )}
                <td>{model.name}</td>
                <td>{formatNumber(model.inputTokens)}</td>
                <td>{formatNumber(model.outputTokens)}</td>
                <td>{formatNumber(model.inputPrice)}</td>
                <td>{formatNumber(model.outputPrice)}</td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

export default PriceTable;
