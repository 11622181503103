// src/pages/Pricing/index.jsx
import React from 'react';
import './Pricing.scss';
import PricingCard from '../../components/Card';
import PriceTable from '../../components/PriceTable';

import Button from '../../components/Buttons';
import { FaRocket, FaCube, FaCode, FaStar } from 'react-icons/fa'; // Import icons

import { HiSparkles, HiCube, HiBeaker, HiStatusOnline } from 'react-icons/hi';
import { GiProgression } from 'react-icons/gi';

const Pricing = () => {
  const pricingData = [
    {
      title: 'Free',
      icon: HiSparkles,
      iconColor: '#6366F1', // Indigo

      features: [
        { label: 'Runs', value: '100' },
        { label: 'Credits', value: '0' },
        { label: 'Storage', value: '1Gb' },
        { label: 'CPU', value: '1 Ghz' },
        { label: 'Max execution time', value: '5sec' },
      ],
      buttonText: 'Start Free Trial',
    },
    {
      title: 'Basic',
      icon: HiCube,
      iconColor: '#8B5CF6', // Purple
      monthlyPrice: '12',
      yearlyPrice: '9',
      yearlyCents: '99',
      monthlyCents: '99',
      features: [
        { label: 'Runs', value: '1,000' },
        { label: 'Credits', value: '7,500' },
        { label: 'Storage', value: '10 Gb' },
        { label: 'CPU', value: '2.4 Ghz' },
        { label: 'Max execution time', value: '1 min' },
      ],
      buttonText: 'Contact Sales',
    },
    {
      title: 'Develop',
      icon: FaRocket,
      iconColor: '#EC4899', // Pink
      monthlyPrice: '64',
      yearlyPrice: '49',
      yearlyCents: '99',
      monthlyCents: '99',
      features: [
        { label: 'Runs', value: '7,500' },
        { label: 'Credits', value: '37,500' },
        { label: 'Storage', value: '50 GB' },
        { label: 'CPU', value: '2x2.4 Ghz' },
        { label: 'Max execution time', value: '5 min' },
      ],
      buttonText: 'Contact Sales',
    },
    {
      title: 'Pro',
      icon: GiProgression, // Changed to FaRocket
      iconColor: '#F59E0B', // You can keep this color or change it
      monthlyPrice: '129',
      yearlyPrice: '99',
      yearlyCents: '99',
      monthlyCents: '99',
      features: [
        { label: 'Runs', value: '15,000' },
        { label: 'Credits', value: '75,000' },
        { label: 'Storage', value: '100 GB' },
        { label: 'CPU', value: '4x2.4 Ghz' },
        { label: 'Max execution time', value: '10 min' },
      ],
      buttonText: 'Contact Sales',
      isPopular: true,
    },
  ];

  return (
    <div className="pricing-container bg-main">
      <div className="pricing-header">
        <div className="container py-5">
          <div className="row justify-content-center mb-5">
            <div className="col-lg-8 text-center">
              <span className="pricing-badge">Pricing Plans</span>
              <h1 className="pricing-title">
                Straightforward pricing that rewards you as you grow
              </h1>
              <p className="pricing-subtitle">
                Choose your path with Quartermaster's flexible pricing plans.
                Every tier includes our core features, ensuring seamless
                inventory management and real-time tracking capabilities.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="pricing-cards-container container">
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4 pricing-cards">
          {pricingData.map((plan, index) => (
            <div className="col" key={index}>
              <PricingCard
                title={plan.title}
                icon={plan.icon}
                iconColor={plan.iconColor}
                monthlyPrice={plan.monthlyPrice}
                monthlyCents={plan.monthlyCents}
                yearlyPrice={plan.yearlyPrice}
                yearlyCents={plan.yearlyCents}
                features={plan.features}
                buttonText={plan.buttonText}
                isPopular={plan.isPopular}
              />
            </div>
          ))}
        </div>
      </div>

      <div className="pricing-table-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12">
              <h2 className="text-center text pt-6 mt-7 py-3">
                Model Pricing Details
              </h2>
              <div className="table-container">
                <PriceTable />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="support-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 text-center">
              <h2 className="mb-4">Need Help Choosing?</h2>
              <p className="mb-4 text">
                Our team is here to help you find the perfect plan for your
                needs. Contact us for a personalized consultation.
              </p>
              <Button
                variant="success"
                onClick={() =>
                  (window.location.href = 'mailto:info@mindmade.ai')
                }
              >
                Contact Support
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
