import axios from 'axios';

const distributionApi = axios.create({
  baseURL: '/files/Quartermaster/556bbacb9088a2226783e9a22bc8a1d5',
  // baseURL: 'https://distribution.quartermaster.io/',
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
  },
});

export const getLastVersion = async () => {
  return Promise.resolve()
    .then(() => distributionApi.get('/versions.json'))
    .then((response) => {
      const versions = response.data.filter(({ rollout }) => rollout === 100);
      const name = versions[versions.length - 1].name;
      return name;
    });
};
