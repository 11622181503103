import React, { useContext, useMemo } from 'react';
import { FaWindows, FaApple, FaLinux } from 'react-icons/fa';
import Card from '../../../components/CustomCard';
import { ThemeContext } from '../../../ThemeContext';

const OSIcon = ({ os, theme }) => {
  const iconProps = {
    size: 64,
    style: {
      color: (() => {
        switch (os) {
          case 'windows':
            return theme === 'dark' ? '#0078D4' : '#00A4EF';
          case 'macos':
            return theme === 'dark' ? '#ffffff' : '#000000';
          case 'linux':
            return theme === 'dark' ? '#ffb938' : '#000000';
          default:
            return '#000000';
        }
      })(),
    },
  };

  switch (os) {
    case 'windows':
      return <FaWindows {...iconProps} />;
    case 'macos':
      return <FaApple {...iconProps} />;
    case 'linux':
      return <FaLinux {...iconProps} />;
    default:
      throw new Error(`Unsupported OS: ${os}`);
  }
};

const DownloadOption = ({ option, isFirst, isLast }) => (
  <a
    href={option.url}
    className={`btn btn-success cursor-pointer py-3 w-100 rounded-4 rounded-top-0 text-white ${isFirst ? 'rounded-end-0 border-end' : ''} ${isLast ? 'rounded-start-0 border-start' : ''}`}
    download
    role="button"
  >
    {option.architecture}
  </a>
);

const OSCard = ({ os, options, isCurrentOs, currentVersion, theme }) => {
  const osDisplayName =
    os === 'macos' ? 'Mac Os' : os.charAt(0).toUpperCase() + os.slice(1);
  console.log(
    'OS display name ',
    osDisplayName,
    ' is current os ',
    isCurrentOs
  );
  return (
    // Lower opacity for non-current OS
    <Card className={`${isCurrentOs ? 'border-success' : 'opacity-50'}`}>
      <div className={`card-body p-0 m-0`}>
        <div className="d-flex flex-column align-items-center mt-3">
          <OSIcon os={os} theme={theme} />

          <h3 className="my-3 text">{osDisplayName}</h3>

          <div className="d-flex w-100">
            {options.map((option, index) => (
              <DownloadOption
                key={`${option.os}-${option.architecture}`}
                option={option}
                isFirst={index === 0 && options.length > 1}
                isLast={index === options.length - 1 && options.length > 1}
              />
            ))}
          </div>
        </div>
      </div>
    </Card>
  );
};

const DownloadCard = ({ downloads, currentVersion }) => {
  const { theme } = useContext(ThemeContext);

  const userOS = useMemo(() => {
    const platform = navigator.platform.toLowerCase();
    if (platform.includes('win')) return 'windows';
    if (platform.includes('mac')) return 'macos';
    if (platform.includes('linux')) return 'linux';
    return null;
  }, []);

  const downloadsByOS = useMemo(() => {
    return downloads.reduce((acc, download) => {
      if (!acc[download.os]) {
        acc[download.os] = [];
      }
      acc[download.os].push(download);
      return acc;
    }, {});
  }, [downloads]);

  const operatingSystems = ['windows', 'macos', 'linux'];

  return (
    <div className="row g-4 w-100 ">
      {operatingSystems.map((os) => (
        <div key={os} className="col-12 col-md-4">
          <OSCard
            os={os}
            options={downloadsByOS[os] || []}
            isCurrentOs={userOS === os}
            currentVersion={currentVersion}
            theme={theme}
          />
        </div>
      ))}
    </div>
  );
};

export default DownloadCard;
