import { CameraControls } from '@react-three/drei';
import { Canvas, extend, useFrame } from '@react-three/fiber';
import gsap from 'gsap';
import { CustomEase } from 'gsap/CustomEase';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ShaderMaterial } from 'three';
import * as THREE from 'three';

import { colorId2rgb, pointColors as colorList } from './colors';
import Controls from './Controls';
import Effects from './effects/Effect';
import { matrix } from './matrix';
import ExplosionParticles from './effects/ExplosionParticle';
import { generateGaussianSpherePoints } from './utils';
import { ThemeContext } from '../../ThemeContext';
import useIsVisible from '../../hooks/useIsVisible';

gsap.registerPlugin(CustomEase);
extend({ ShaderMaterial });

const AnimatedPointCloud = ({
  spherePoints,
  matrixPoints,
  pointColors,
  cameraControlRef, // Add this prop
  onAnimationComplete,
  onExplosion,
}) => {
  const meshRef = useRef(null);
  const progressRef = useRef(0);

  const glowRef = useRef(null);
  const rotationSpeedRef = useRef(0);
  const explosionProgressRef = useRef(0);
  const [, setIsAnimating] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const timelineRef = useRef(null);
  const rotationRef = useRef(0);

  const [geometry, material, matrix, colorArray] = useMemo(() => {
    const geometry = new THREE.SphereGeometry(0.05, 16, 16);
    const material = new THREE.MeshStandardMaterial({
      metalness: 0.2,
      roughness: 0.3,
    });
    const matrix = new THREE.Matrix4();

    const colorArray = new Float32Array(spherePoints.length * 3);
    pointColors.forEach((color, i) => {
      const threeColor = new THREE.Color(color);
      colorArray[i * 3] = threeColor.r;
      colorArray[i * 3 + 1] = threeColor.g;
      colorArray[i * 3 + 2] = threeColor.b;
    });

    return [geometry, material, matrix, colorArray];
  }, [pointColors, spherePoints.length]);

  useEffect(() => {
    if (meshRef.current) {
      meshRef.current.instanceColor = new THREE.InstancedBufferAttribute(
        colorArray,
        3
      );
      meshRef.current.instanceColor.needsUpdate = true;
    }
    if (glowRef.current) {
      glowRef.current.instanceColor = new THREE.InstancedBufferAttribute(
        colorArray,
        3
      );
      glowRef.current.instanceColor.needsUpdate = true;
    }
  }, [colorArray]);

  const startAnimation = useCallback(() => {
    if (timelineRef.current) {
      timelineRef.current.kill();
    }

    setIsAnimating(true);
    setIsComplete(false);
    progressRef.current = 0;
    rotationSpeedRef.current = 0;
    explosionProgressRef.current = 0;
    rotationRef.current = 0;

    const tl = gsap.timeline({
      onComplete: () => {
        setIsAnimating(false);
        setIsComplete(true);
        onAnimationComplete?.();

        gsap.to(rotationSpeedRef, {
          current: 0.1,
          duration: 0.5,
          ease: 'power2.inOut',
        });
      },
    });

    timelineRef.current = tl;

    // Phase 1: Initial rotation
    tl.to(rotationSpeedRef, {
      current: 2,
      duration: 2,
      ease: 'power1.inOut',
    });

    // Phase 2: Accelerating rotation while zooming out
    tl.to(rotationSpeedRef, {
      current: 6,
      duration: 1.5,
      ease: 'power2.in',
    });
    const cameraPosition = { z: 12, y: 5 };

    // Zoom out using CameraControls
    tl.to(
      cameraPosition,
      {
        z: 150,
        y: 50,
        duration: 1.25,
        ease: 'power1.in',
        onUpdate: () => {
          if (cameraControlRef.current) {
            cameraControlRef.current.setLookAt(
              0,
              cameraPosition.y,
              cameraPosition.z,
              0,
              0,
              0,
              false
            );
          }
        },
        onComplete: () => {
          onExplosion?.();
          explosionProgressRef.current = 0;
        },
      },
      '<'
    );

    // Zoom back in with explosion
    tl.to(
      {},
      {
        duration: 1,
        ease: 'power4.out',
        onStart: () => {
          if (cameraControlRef.current) {
            cameraControlRef.current.setLookAt(0, 5, 12, 0, 0, 0, true);
          }
        },
      },
      '>'
    );

    // Explosion animation
    tl.to(
      explosionProgressRef,
      {
        current: 1,
        duration: 1.75,
        ease: 'power4.out',
      },
      '<'
    );

    // Slow down rotation during settling
    tl.to(
      rotationSpeedRef,
      {
        current: 0.1,
        duration: 1.5,
        ease: 'power2.inOut',
      },
      '-=1.5'
    );
  }, [onAnimationComplete, onExplosion, cameraControlRef]);

  // Start animation when component becomes visible
  useEffect(() => {
    setTimeout(startAnimation, 100);
    return () => {
      if (timelineRef.current) {
        timelineRef.current.kill();
      }
    };
  }, [startAnimation]);

  useFrame((state) => {
    if (meshRef.current) {
      rotationRef.current += rotationSpeedRef.current * 0.02;
      meshRef.current.rotation.y = rotationRef.current;
      glowRef.current.rotation.y = rotationRef.current;

      for (let i = 0; i < spherePoints.length; i++) {
        const startPoint = spherePoints[i];
        const endPoint = matrixPoints[i];

        let x, y, z;

        if (explosionProgressRef.current === 0) {
          // Pre-explosion: rotating sphere (no scaling)
          x = startPoint[0];
          y = startPoint[1];
          z = startPoint[2];
        } else {
          // Explosion phase with speed decay
          const progress = explosionProgressRef.current;
          const speedDecay = Math.pow(1 - progress, 2);
          const currentSpeed = 1 + speedDecay * 2;
          const adjustedProgress = Math.pow(progress, currentSpeed);

          const directX = THREE.MathUtils.lerp(
            startPoint[0],
            endPoint[0],
            adjustedProgress
          );
          const directY = THREE.MathUtils.lerp(
            startPoint[1],
            endPoint[1],
            adjustedProgress
          );
          const directZ = THREE.MathUtils.lerp(
            startPoint[2],
            endPoint[2],
            adjustedProgress
          );

          const arcHeight = Math.sin(progress * Math.PI) * 0.9 * (1 - progress);
          const arcEffect = {
            x: Math.cos(i * 0.5) * arcHeight,
            y: Math.sin(i * 0.5) * arcHeight,
            z: Math.cos(i * 0.7) * arcHeight,
          };

          x = directX + arcEffect.x;
          y = directY + arcEffect.y;
          z = directZ + arcEffect.z;
        }

        if (isComplete) {
          const floatEffect =
            Math.sin(state.clock.elapsedTime + i * 0.1) * 0.01;
          x += floatEffect;
          y += floatEffect;
          z += floatEffect;
        }

        // Use constant scale of 1 for points
        matrix.compose(
          new THREE.Vector3(x, y, z),
          new THREE.Quaternion(),
          new THREE.Vector3(1, 1, 1)
        );

        meshRef.current.setMatrixAt(i, matrix);
        glowRef.current.setMatrixAt(i, matrix);
      }
      meshRef.current.instanceMatrix.needsUpdate = true;
      glowRef.current.instanceMatrix.needsUpdate = true;
    }
  });

  return (
    <group>
      {/* Glow points */}
      <instancedMesh
        ref={glowRef}
        args={[geometry, material, spherePoints.length]}
      >
        <sphereGeometry args={[0.11, 16, 16]} /> {/* Larger radius */}
        <meshStandardMaterial
          transparent
          opacity={0.05}
          depthWrite={false} // Important for proper transparency
        />
      </instancedMesh>

      {/* Main points */}
      <instancedMesh
        ref={meshRef}
        args={[geometry, material, spherePoints.length]}
      >
        <sphereGeometry args={[0.03, 16, 16]} />
        <meshStandardMaterial transparent opacity={0.8} />
      </instancedMesh>
    </group>
  );
};

// Updated AnimatedPointCloudVisualization component
const AnimatedPointCloudVisualization = () => {
  const [matrixPoints] = useState(() => {
    const center = matrix.reduce(
      (acc, [x, y, z]) => {
        acc.x += x / matrix.length;
        acc.y += y / matrix.length;
        acc.z += z / matrix.length;
        return acc;
      },
      { x: 0, y: 0, z: 0 }
    );

    return matrix.map(([x, y, z]) => [
      x - center.x,
      y - center.y,
      z - center.z,
    ]);
  });

  const [spherePoints] = useState(() => {
    return generateGaussianSpherePoints({
      center: new THREE.Vector3(0, 0, 0),
      radius: 2,
      numPoints: matrixPoints.length,
      standardDeviation: 1,
      pointiness: 1.4,
    });
    // return generateTightSpiralGalaxy();
  });
  const { theme } = useContext(ThemeContext);
  const { elementRef, isVisible } = useIsVisible();

  const cameraControlRef = useRef(null);
  const [key, setKey] = useState(0);
  const [isExploding, setIsExploding] = useState(false);
  const [, setIsAnimationComplete] = useState(false);
  const pointColors = useMemo(() => {
    return colorList.map((color) => colorId2rgb(color, theme));
  }, [theme]);

  const handleReset = useCallback(() => {
    setKey((prev) => prev + 1);
    setIsExploding(false);
    setIsAnimationComplete(false);
  }, []);

  const handleExplosion = useCallback(() => {
    setIsExploding(true);
    setTimeout(() => setIsExploding(false), 1500);
  }, []);

  const handleAnimationComplete = useCallback(() => {
    setIsAnimationComplete(true);
  }, []);

  return (
    <div style={{ height: '500px', position: 'relative' }} ref={elementRef}>
      <Canvas
        camera={{
          position: [0, 5, 12],
          fov: 75,
        }}
      >
        <Effects />
        <CameraControls ref={cameraControlRef} />
        {isVisible && (
          <AnimatedPointCloud
            key={key}
            spherePoints={spherePoints}
            matrixPoints={matrixPoints}
            pointColors={pointColors}
            onAnimationComplete={handleAnimationComplete}
            onExplosion={handleExplosion}
            cameraControlRef={cameraControlRef}
          />
        )}
        <ExplosionParticles active={isExploding} />
      </Canvas>
      <Controls cameraControlRef={cameraControlRef} onReset={handleReset} />
    </div>
  );
};

export default AnimatedPointCloudVisualization;
