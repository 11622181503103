// PricingCard.jsx (components/Card/index.jsx)
import React from 'react';
import './card.scss';
import Button from './../Buttons';
import CustomCard from './../CustomCard';
import money from './../Buttons';

const PricingCard = ({
  title,
  icon: Icon,
  iconColor,
  monthlyPrice,
  monthlyCents,
  yearlyPrice,
  yearlyCents,
  features,
  buttonText,
}) => {
  const handleButtonClick = () => {
    switch (buttonText) {
      case 'Contact Sales':
        const email = 'your-email@company.com';
        const subject = `${title} Plan Inquiry`;
        const body = `I'm interested in learning more about the ${title} plan.`;
        window.location.href = `mailto:${email}?subject=${encodeURIComponent(
          subject
        )}&body=${encodeURIComponent(body)}`;
        break;

      case 'Start Free Trial':
        window.location.href = '/download';
        break;

      default:
        console.log('Button clicked');
    }
  };

  return (
    <CustomCard>
      <div className="pricing-header">
        <div className="title-section">
          {Icon && (
            <div
              className="icon-wrapper"
              style={{ backgroundColor: `${iconColor}20` }}
            >
              <Icon size={24} color={iconColor} />
            </div>
          )}
          <h3>{title}</h3>
        </div>

        {title === 'Free' ? (
          <div className="pricing-toggle-placeholder"></div>
        ) : (
          <div className="pricing-toggle">
            <div className="toggle-options">
              <div className="price-block">
                <span className="price-label">Annual</span>
                <div className="price">
                  <span className="currency">€</span>
                  <span className="amount">{yearlyPrice}</span>
                  <span className="cents">{yearlyCents}</span>
                </div>
              </div>
              <div className="divider"></div>
              <div className="price-block">
                <span className="price-label">Monthly</span>
                <div className="price">
                  <span className="currency">€</span>
                  <span className="amount">{monthlyPrice}</span>
                  <span className="cents">{monthlyCents}</span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="pricing-body">
        <ul className="features-list">
          {features &&
            features.map((feature, index) => (
              <li key={index}>
                <i className="bi bi-check2-circle"></i>
                {typeof feature === 'object' ? (
                  <>
                    <span className="feature-label">{feature.label}</span>
                    <span className="feature-value">{feature.value}</span>
                  </>
                ) : (
                  <span>{feature}</span>
                )}
              </li>
            ))}
        </ul>
        <Button onClick={handleButtonClick}>{buttonText}</Button>
      </div>
    </CustomCard>
  );
};

export default PricingCard;
