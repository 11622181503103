import { useFrame } from '@react-three/fiber';
import gsap from 'gsap';
import React, { useEffect, useMemo, useRef } from 'react';
import * as THREE from 'three';

const ExplosionParticles = ({ active }) => {
  const particlesCount = 300;
  const particlesRef = useRef(null);
  const opacityRef = useRef(0);
  const speedRef = useRef({ value: 0 });

  const [positions, scales] = useMemo(() => {
    const positions = new Float32Array(particlesCount * 3);
    const scales = new Float32Array(particlesCount);

    for (let i = 0; i < particlesCount; i++) {
      // Initial sphere formation
      const radius = Math.random() * 0.2;
      const theta = Math.random() * Math.PI * 2;
      const phi = Math.acos(2 * Math.random() - 1);

      positions[i * 3] = radius * Math.sin(phi) * Math.cos(theta);
      positions[i * 3 + 1] = radius * Math.sin(phi) * Math.sin(theta);
      positions[i * 3 + 2] = radius * Math.cos(phi);

      scales[i] = Math.random() * 0.5 + 0.5;
    }

    return [positions, scales];
  }, []);

  useEffect(() => {
    if (active) {
      gsap.to(speedRef.current, {
        value: 2,
        duration: 0.3,
        ease: 'power2.out',
      });
      gsap.to(opacityRef, {
        current: 0.8,
        duration: 0.2,
        ease: 'power2.out',
        onComplete: () => {
          gsap.to(opacityRef, {
            current: 0,
            duration: 1,
            ease: 'power2.in',
          });
          gsap.to(speedRef.current, {
            value: 0,
            duration: 1,
            ease: 'power2.in',
          });
        },
      });
    }
  }, [active]);

  useFrame(() => {
    if (
      particlesRef.current &&
      particlesRef.current.geometry.attributes.position
    ) {
      const positions = particlesRef.current.geometry.attributes.position.array;

      for (let i = 0; i < particlesCount; i++) {
        const i3 = i * 3;
        const x = positions[i3];
        const y = positions[i3 + 1];
        const z = positions[i3 + 2];

        const distance = Math.sqrt(x * x + y * y + z * z);
        const speed = speedRef.current.value * (distance + 0.1);

        positions[i3] += (x / distance) * speed * 0.01;
        positions[i3 + 1] += (y / distance) * speed * 0.01;
        positions[i3 + 2] += (z / distance) * speed * 0.01;
      }

      particlesRef.current.geometry.attributes.position.needsUpdate = true;
    }
  });

  return (
    <points ref={particlesRef}>
      <bufferGeometry>
        <bufferAttribute
          attach="attributes-position" // ty
          count={particlesCount}
          array={positions}
          itemSize={3}
        />
        <bufferAttribute
          attach="attributes-scale"
          count={particlesCount}
          array={scales}
          itemSize={1}
        />
      </bufferGeometry>
      <pointsMaterial
        size={0.02}
        color="#ffffff"
        transparent
        opacity={opacityRef.current}
        sizeAttenuation
        blending={THREE.AdditiveBlending}
      />
    </points>
  );
};

export default ExplosionParticles;
