import * as THREE from 'three';

export function generateSpherePoints({ center, radius, numPoints }) {
  const points = [];

  for (let i = 0; i < numPoints; i++) {
    const r = radius * Math.cbrt(Math.random());
    const theta = Math.random() * 2 * Math.PI;
    const phi = Math.acos(2 * Math.random() - 1);

    const x = r * Math.sin(phi) * Math.cos(theta) + center.x;
    const y = r * Math.sin(phi) * Math.sin(theta) + center.y;
    const z = r * Math.cos(phi) + center.z;

    points.push([x, y, z]);
  }

  return points;
}

export function generateGaussianSpherePoints({
  center,
  radius,
  numPoints,
  standardDeviation = 1,
}) {
  const points = [];

  // Box-Muller transform to generate normally distributed random numbers
  function generateGaussian() {
    let u1, u2;
    do {
      u1 = Math.random();
      u2 = Math.random();
    } while (u1 === 0); // u1 must not be zero

    const z0 = Math.sqrt(-2.0 * Math.log(u1)) * Math.cos(2 * Math.PI * u2);
    return z0;
  }

  for (let i = 0; i < numPoints; i++) {
    // Generate three independent Gaussian random variables
    const x = generateGaussian() * standardDeviation * radius + center.x;
    const y = generateGaussian() * standardDeviation * radius + center.y;
    const z = generateGaussian() * standardDeviation * radius + center.z;

    points.push([x, y, z]);
  }

  return points;
}

export function generatePointySpherePoints({
  center,
  radius,
  numPoints,
  pointiness = 2, // Default pointiness factor
  standardDeviation = 1,
}) {
  const points = [];

  // Box-Muller transform for gaussian distribution
  function generateGaussian() {
    let u1, u2;
    do {
      u1 = Math.random();
      u2 = Math.random();
    } while (u1 === 0);

    const z0 = Math.sqrt(-2.0 * Math.log(u1)) * Math.cos(2 * Math.PI * u2);
    return z0;
  }

  // Function to make distribution more pointy
  function makePointy(value) {
    // Preserve the sign while applying pointiness
    const sign = Math.sign(value);
    const absValue = Math.abs(value);

    // Apply power transformation to make it more pointy
    return sign * Math.pow(absValue, pointiness);
  }

  for (let i = 0; i < numPoints; i++) {
    // Generate basic gaussian distribution
    let x = generateGaussian() * standardDeviation;
    let y = generateGaussian() * standardDeviation;
    let z = generateGaussian() * standardDeviation;

    // Calculate distance from center for normalization
    const distance = Math.sqrt(x * x + y * y + z * z);

    // Normalize and apply pointiness
    if (distance > 0) {
      const pointyFactor = makePointy(distance) / distance;
      x *= pointyFactor;
      y *= pointyFactor;
      z *= pointyFactor;
    }

    // Scale by radius and add center offset
    x = x * radius + center.x;
    y = y * radius + center.y;
    z = z * radius + center.z;

    points.push([x, y, z]);
  }

  return points;
}

function generateGalaxyPoints({
  center,
  radius,
  numPoints,
  numArms = 2,
  armWidth = 0.3,
  rotationFactor = 3,
  centerDensity = 0.5,
  heightFactor = 0.3,
  armDensityFalloff = 0.5,
  minArmWidth = 0.4, // Controls the minimum width as a fraction of armWidth
}) {
  const points = [];

  function gaussianRandom() {
    let u1, u2;
    do {
      u1 = Math.random();
      u2 = Math.random();
    } while (u1 === 0);

    return Math.sqrt(-2.0 * Math.log(u1)) * Math.cos(2 * Math.PI * u2);
  }

  function shouldKeepPoint(r) {
    const normalizedR = r / radius;
    const densityProbability = Math.pow(1 - normalizedR, armDensityFalloff);
    return Math.random() < densityProbability;
  }

  let attempts = 0;
  const maxAttempts = numPoints * 3;

  while (points.length < numPoints && attempts < maxAttempts) {
    attempts++;

    const isCentralBulge = Math.random() < centerDensity;

    let r, theta, armOffset;

    if (isCentralBulge) {
      r = Math.abs(gaussianRandom() * radius * 0.2);
      theta = Math.random() * 2 * Math.PI;
      armOffset = gaussianRandom() * armWidth * 0.2;
    } else {
      r = Math.pow(Math.random(), 0.5) * radius;

      if (!shouldKeepPoint(r)) {
        continue;
      }

      theta = Math.random() * 2 * Math.PI;

      const armAngle = (2 * Math.PI) / numArms;
      const arm = Math.floor(theta / armAngle);
      const armCenterAngle = arm * armAngle;

      theta =
        armCenterAngle +
        (theta - armCenterAngle) +
        rotationFactor * Math.log(r / radius + 1);

      // Modified arm width calculation
      const normalizedR = r / radius;
      // Use smooth transition between full width and minimum width
      const widthTransition =
        armWidth *
        (minArmWidth +
          (1 - minArmWidth) * Math.exp((-normalizedR * normalizedR) / 0.3));

      // Add some randomness to the arm width
      const randomWidth = widthTransition * (1 + 0.2 * gaussianRandom());

      // Calculate offset with maintained width at edges
      armOffset = gaussianRandom() * randomWidth;
    }

    const offsetTheta = theta + armOffset;
    const x = r * Math.cos(offsetTheta) + center.x;
    const z = r * Math.sin(offsetTheta) + center.z;

    // Modified height calculation to maintain some thickness
    const distanceFromCenter = Math.sqrt(
      Math.pow(x - center.x, 2) + Math.pow(z - center.z, 2)
    );
    const normalizedDistance = distanceFromCenter / radius;

    // Modified height dampening to maintain minimum thickness
    const heightDampening =
      minArmWidth +
      (1 - minArmWidth) *
        Math.exp(-(normalizedDistance * normalizedDistance) / 0.2);

    const y =
      gaussianRandom() * heightFactor * radius * heightDampening + center.y;

    points.push([x, y, z]);
  }

  return points;
}

// Classic spiral galaxy
export function generateClassicGalaxy() {
  const config = {
    center: new THREE.Vector3(0, 0, 0),
    radius: 7,
    numPoints: 10000,
    numArms: 2,
    armWidth: 0.8,
    rotationFactor: 3,
    centerDensity: 0.4,
    heightFactor: 0.2,
    armDensityFalloff: 0.4,
    minArmWidth: 0.5,
  };
  return generateGalaxyPoints(config);
}

// Tight spiral with multiple arms
export function generateTightSpiralGalaxy() {
  const config = {
    center: new THREE.Vector3(0, 0, 0),
    radius: 7,
    numPoints: 10000,
    numArms: 4,
    armWidth: 0.6,
    rotationFactor: 4.5,
    centerDensity: 0.3,
    heightFactor: 0.15,
    armDensityFalloff: 0.5,
    minArmWidth: 0.4,
  };
  return generateGalaxyPoints(config);
}

// Loose, grand design spiral
export function generateLooseGrandGalaxy() {
  const config = {
    center: new THREE.Vector3(0, 0, 0),
    radius: 7,
    numPoints: 10000,
    numArms: 2,
    armWidth: 1.2,
    rotationFactor: 2,
    centerDensity: 0.5,
    heightFactor: 0.25,
    armDensityFalloff: 0.3,
    minArmWidth: 0.6,
  };
  return generateGalaxyPoints(config);
}

// Barred spiral galaxy
export function generateBarredGalaxy() {
  const config = {
    center: new THREE.Vector3(0, 0, 0),
    radius: 7,
    numPoints: 10000,
    numArms: 2,
    armWidth: 1.0,
    rotationFactor: 3.5,
    centerDensity: 0.6,
    heightFactor: 0.2,
    armDensityFalloff: 0.45,
    minArmWidth: 0.5,
  };
  return generateGalaxyPoints(config);
}

// Multi-arm fluffy galaxy
export function generateFluffyGalaxy() {
  const config = {
    center: new THREE.Vector3(0, 0, 0),
    radius: 7,
    numPoints: 10000,
    numArms: 3,
    armWidth: 1.4,
    rotationFactor: 2.5,
    centerDensity: 0.35,
    heightFactor: 0.3,
    armDensityFalloff: 0.35,
    minArmWidth: 0.7,
  };
  return generateGalaxyPoints(config);
}

// Dense core with defined arms
export function generateDenseCoreGalaxy() {
  const config = {
    center: new THREE.Vector3(0, 0, 0),
    radius: 7,
    numPoints: 10000,
    numArms: 2,
    armWidth: 0.9,
    rotationFactor: 3.2,
    centerDensity: 0.7,
    heightFactor: 0.25,
    armDensityFalloff: 0.5,
    minArmWidth: 0.45,
  };
  return generateGalaxyPoints(config);
}

// Wide, loosely wound spiral
export function generateWideLooseGalaxy() {
  const config = {
    center: new THREE.Vector3(0, 0, 0),
    radius: 7,
    numPoints: 10000,
    numArms: 2,
    armWidth: 1.5,
    rotationFactor: 1.8,
    centerDensity: 0.4,
    heightFactor: 0.2,
    armDensityFalloff: 0.3,
    minArmWidth: 0.8,
  };
  return generateGalaxyPoints(config);
}
