import React, { useState } from 'react';
import './faqs.scss';
import Logo from './../../components/Logo';
import Card from './../../components/CustomCard'; // Import your Card component
import { FaPlus, FaMinus } from 'react-icons/fa'; // Import icons for expand/collapse
import Button from '../../components/Buttons';

const FAQ = () => {
  const faqItems = [
    {
      question: 'What is Quartermaster?',
      answer:
        'Quartermaster is a powerful inventory management solution designed to streamline your business operations.',
    },
    {
      question: 'How much does Quartermaster cost?',
      answer:
        'We offer various pricing plans. Please check our pricing page for more details.',
    },
    {
      question: 'Do you offer a free trial?',
      answer: 'Yes, we offer a 14-day free trial for all our plans.',
    },
    // Add more FAQ items as needed
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="w-100 bg-main text-body">
      <div className="container py-5">
        {/* Header Section */}
        <div className="row justify-content-center">
          <div style={{ textAlign: 'center', paddingTop: '5rem' }}>
            <span className="pricing-badge">FAQ</span>
          </div>
          <div className="col-lg-8 text-center">
            <h1
              className="display-4 mb-4 text-body"
              style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)' }}
            >
              <Logo className="my-auto ms-2" style={{ maxHeight: '55px' }} />
              <span>uartermaster FAQ</span>
            </h1>
            <p className="lead mb-4 text-body">
              <span style={{ fontWeight: 600 }}>
                Find answers to common questions about Quartermaster's features,
                pricing, and support. If you can't find what you're looking for,
                don't hesitate to contact our support team.
              </span>
            </p>
          </div>
        </div>
        {/* FAQ Section */}
        <div className="row justify-content-center">
          <div className="col-lg-8">
            {faqItems.map((item, index) => (
              <Card
                key={index}
                className={`faq-card mb-4 ${
                  activeIndex === index ? 'open  ' : ''
                }`}
              >
                <div className="faq-item">
                  <h3
                    className="h5 mb-0 faq-question" // Removed text-center class
                    onClick={() => toggleAnswer(index)}
                    style={{
                      cursor: 'pointer',
                      textAlign: 'center',
                      width: '100%',
                    }} // Center text using inline style
                  >
                    {item.question}
                    <span
                      className={`faq-icon ${
                        activeIndex === index ? 'open' : ''
                      }`}
                    >
                      <FaPlus />
                    </span>
                  </h3>
                  <div
                    className={`faq-answer ${
                      activeIndex === index ? 'open' : ''
                    }`}
                  >
                    <p>{item.answer}</p>
                  </div>
                </div>
              </Card>
            ))}
          </div>
          <div className="support-section ">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-8 text-center">
                  <h2 className="mb-4">Need Help Choosing?</h2>
                  <p className="mb-4 text">
                    Our team is here to help you find the perfect plan for your
                    needs. Contact us for a personalized consultation.
                  </p>
                  <Button
                    variant="success"
                    onClick={() =>
                      (window.location.href = 'mailto:info@mindmade.ai')
                    }
                  >
                    Contact Support
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
