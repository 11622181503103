import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { ThemeContext } from '../ThemeContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faGlobe } from '@fortawesome/free-solid-svg-icons';
import { Sun, Moon } from 'react-feather';
import '../style/Navbar.scss';
import { DOWNLOAD_URL } from '../utils/urls';
import { FAQ_URL } from '../utils/urls';
import Logo from '../components/Logo';

function Navbar() {
  const { theme, setTheme } = useContext(ThemeContext);
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    console.log('Searching for:', searchQuery);
    document.getElementById('searchModal1').classList.remove('show');
    document.body.classList.remove('modal-open');
    document.getElementsByClassName('modal-backdrop')[0]?.remove();
  };

  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
  };

  return (
    <>
      <header className={`navbar navbar-expand-lg bg-main`}>
        <div className="container">
          <Link className="navbar-brand" to="/">
            <Logo />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse bg-transparent"
            id="navbarNav"
          >
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <li className="nav-item">
                  <Link className="nav-link" to={DOWNLOAD_URL}>
                    Download
                  </Link>
                </li>
              </li>

              {/* <li className="nav-item">
                <Link className="nav-link" to="/features">
                  Features
                </Link>
              </li> */}
              {/* <li className="nav-item">
                {/* <Link className="nav-link" to={EXAMPLES_URL}>
                  Examples
                </Link>
              </li> */}
              <li className="nav-item">
                <Link className="nav-link" to="/pricing">
                  Pricing
                </Link>
              </li>

              <li className="nav-item">
                <Link className="nav-link" to="/faq">
                  FAQ
                </Link>
              </li>
              {/* <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="resourcesDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                >
                  Resources
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <Link className="dropdown-item" to="/resources/blog">
                      Blog
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/resources/documentation"
                    >
                      Documentation
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/resources/support">
                      Support
                    </Link>
                  </li>
                </ul>
              </li> */}
              {/* <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="aboutDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                >
                  About
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <Link className="dropdown-item" to="/about/team">
                      Our Team
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/about/mission">
                      Our Mission
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="contactDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                >
                  Contact
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <Link className="dropdown-item" to="/contact/support">
                      Support
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/contact/sales">
                      Sales
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/contact/general">
                      General Inquiries
                    </Link>
                  </li>
                </ul>
              </li> */}
            </ul>
            <div className="d-flex align-items-center">
              {/* <button
                className="btn btn-outline-secondary me-2"
                data-bs-toggle="modal"
                data-bs-target="#searchModal1"
              >
                <FontAwesomeIcon icon={faSearch} />
              </button> */}
              {/* <div className="dropdown me-2">
                <button
                  className="btn btn-outline-secondary dropdown-toggle"
                  type="button"
                  id="languageDropdown"
                  data-bs-toggle="dropdown"
                >
                  <FontAwesomeIcon icon={faGlobe} />
                </button>
                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" href="#">
                      English
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Español
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Français
                    </a>
                  </li>
                </ul>
              </div> */}
              <div className="form-check form-switch d-flex align-items-center ms-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="themeSwitch"
                  checked={theme === 'dark'}
                  onChange={toggleTheme}
                />
                <label className="form-check-label ms-2" htmlFor="themeSwitch">
                  {theme === 'dark' ? (
                    <Moon className="theme-icon" color="white" size={20} />
                  ) : (
                    <Sun className="theme-icon" color="black" size={20} />
                  )}
                </label>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* Search Modal */}
      <div
        className="modal fade"
        id="searchModal1"
        tabIndex="-1"
        aria-labelledby="searchModalLabel1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="searchModalLabel1">
                Search
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSearchSubmit}>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    placeholder="Enter your search query"
                    aria-label="Search query"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                  <button className="btn btn-primary" type="submit">
                    Search
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Navbar;
