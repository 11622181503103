import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import './ImageViewer.scss';

// Import images
import darkAssistant from './../../assets/dark_assistant.png';
import darkGraph from './../../assets/dark_graph.png';
import darkHome from './../../assets/dark_home.png';
import darkStore from './../../assets/dark_store.png';
import whiteAssistant from './../../assets/white_assistant.png';
import whiteGraph from './../../assets/white_graph.png';
import whiteHome from './../../assets/white_home.png';
import whiteStore from './../../assets/white_store.png';

import { ThemeContext } from '../../ThemeContext';

const darkImages = [
  { src: darkHome, alt: 'Dark Home' },
  { src: darkGraph, alt: 'Dark Graph' },
  { src: darkAssistant, alt: 'Dark Assistant' },
  { src: darkStore, alt: 'Dark Store' },
];
const whiteImages = [
  { src: whiteHome, alt: 'White Home' },
  { src: whiteGraph, alt: 'White Graph' },
  { src: whiteAssistant, alt: 'White Assistant' },
  { src: whiteStore, alt: 'White Store' },
];

const ROTATION_DELAY = 5000; // 5 seconds
const ANIMATION_DURATION = 1200; // Match with SCSS animation duration

const ImageViewer = () => {
  const { theme } = useContext(ThemeContext);
  const [images, setImages] = useState([]);
  const [isAnimating, setIsAnimating] = useState(false);
  const timerRef = useRef(null);
  const isHoveredRef = useRef(false);

  const moveTopToBottom = useCallback(() => {
    if (isAnimating) return;

    setIsAnimating(true);

    setTimeout(() => {
      setImages((prev) => {
        const newImages = [...prev];
        const firstImage = newImages.shift();
        if (firstImage) newImages.push(firstImage);
        return newImages;
      });
      setIsAnimating(false);
    }, ANIMATION_DURATION);
  }, [isAnimating]);

  const startTimer = useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    if (!isHoveredRef.current) {
      timerRef.current = setTimeout(() => {
        moveTopToBottom();
      }, ROTATION_DELAY);
    }
  }, [moveTopToBottom]);

  useEffect(() => {
    const imgs = theme === 'dark' ? darkImages : whiteImages;
    setImages(imgs.slice(0, 3));
  }, [theme]);

  // Initialize and cleanup timer
  useEffect(() => {
    startTimer();

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [startTimer]);

  // Restart timer after animation completes
  useEffect(() => {
    if (!isAnimating) {
      startTimer();
    }
  }, [isAnimating, startTimer]);

  const handleClick = (index) => {
    if (index === 0 && !isAnimating) {
      moveTopToBottom();
      startTimer();
    }
  };

  const handleMouseEnter = () => {
    isHoveredRef.current = true;
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
  };

  const handleMouseLeave = () => {
    isHoveredRef.current = false;
    startTimer();
  };

  return (
    <div
      className="images-stack"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {images.map((image, index) => (
        <div
          key={'header-img-' + index}
          className={`image-wrapper ${isAnimating ? 'animating' : ''}`}
          data-position={index}
          onClick={() => handleClick(index)}
        >
          <img src={image.src} alt={image.alt} className="stacked-image" />
        </div>
      ))}
    </div>
  );
};
export default ImageViewer;
